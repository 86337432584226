import React, {Component} from 'react';
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import Parse from 'parse';
import * as db from '../../../lib/dbStructure';
import Select from 'react-select';
import _ from 'lodash';
import AsyncSelect from 'react-select/async';
import assert from 'assert';

export default class CreateHomeOnUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            users: [],
            selectedUser: null
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onPositiveButtonClick = this.onPositiveButtonClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onUserSelected = this.onUserSelected.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
    }

    componentDidMount() {
        this.props.setToggleModal(this.toggleModal);
        this.getUsers();
    }

    onFieldChange(e){
        let fieldName = e.target.name;
        let fieldValue = e.target.value;

        this.setState({[fieldName]: fieldValue});
    }

    async getUsers(email) {
        if(!email) return [];
        if(email.length < 2) return [];

        let users = await Parse.Cloud.run('search-user-from-email', {email});

        return users.map(user => {
            return {
                value: user.id,
                label: `${user.get(db._User.USERNAME)} (${user.get(db._User.EMAIL)})`,
                object: user
            }
        });
    }

    toggleModal() {
        this.getUsers();
        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    onUserSelected(e) {
        let userId = e.target.value;

        this.setState({selectedUser: userId});
    }

    async onPositiveButtonClick() {
        try {
            assert(this.state.selectedUser != null, 'User is required');
            assert(this.state.selectedUser.object != null, 'User is required');

            await this.props.save(this.state.selectedUser.object, this.state);
            swal({title: 'Success!', text: ' ', icon: 'success', button: [''], timer: 1000});
            this.toggleModal();
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async onCancelClick(){
        this.toggleModal();
    }

    render() {

        const allCantons = ['GR', 'BE', 'ZH', 'LU', 'UR', 'SZ', 'OW', 'NW', 'GL', 'ZG', 'SO', 'BS', 'BL', 'SH', 'AR',
            'AI', 'SG', 'AG', 'TG', 'VD', 'NE', 'GE', 'JU', 'FR', 'VS', 'TI'];
        const languages = ['IT', 'DE', 'FR', 'EN'];
        const typesOfCustomer = [
            db.Home.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER,
            db.Home.TYPE_OF_CUSTOMER$FULL_SYSTEM_CUSTOMER
        ]
        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Create building and link to an user
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <label>Select user:</label>
                            <AsyncSelect
                                value={this.state.selectedUser}
                                onChange={selectedUser => this.setState({selectedUser})}
                                loadOptions={this.getUsers}
                                isMulti={false}
                            />
                            <Label for={'roomName'}>Building name:</Label>
                            <Input
                                type={'text'}
                                name={db.Home.HOME_NAME}
                                id={db.Home.HOME_NAME}
                                value={this.state[db.Home.HOME_NAME]}
                                onChange={this.onFieldChange}>
                            </Input>

                            <Label for={db.Home.AREA}>Area:</Label>
                            <Input type={'number'} name={db.Home.AREA} id={db.Home.AREA} value={this.state[db.Home.AREA]}
                                onChange={this.onFieldChange} />

                            <Label for={db.Home.CITY}>City:</Label>
                            <Input type={'text'} name={db.Home.CITY} id={db.Home.CITY} value={this.state[db.Home.CITY]}
                                   onChange={this.onFieldChange} />

                            <Label for={db.Home.CANTON}>Canton:</Label>
                            <Select
                                value={{value: this.state[db.Home.CANTON], label: this.state[db.Home.CANTON]}}
                                onChange={(item) => this.setState({[db.Home.CANTON]: item.value})}
                                options={allCantons.map(canton => ({value: canton, label: canton}))}
                                isMulti={false}
                            />

                            <Label for={db.Home.ADDRESS}>Address:</Label>
                            <Input type={'text'} name={db.Home.ADDRESS} id={db.Home.ADDRESS} value={this.state[db.Home.ADDRESS]}
                                   onChange={this.onFieldChange} />

                            <Label for={db.Home.YEAR}>Year:</Label>
                            <Input type={'number'} name={db.Home.YEAR} id={db.Home.YEAR} value={this.state[db.Home.YEAR]}
                                   onChange={this.onFieldChange} />

                            <Label for={db.Home.LANGUAGE}>Language:</Label>
                            <Select
                                value={{value: _.toLower(this.state[db.Home.LANGUAGE]), label: this.state[db.Home.LANGUAGE]}}
                                onChange={(item) => this.setState({[db.Home.LANGUAGE]: item.value})}
                                options={languages.map(language => ({value: _.toLower(language), label: language}))}
                                isMulti={false}
                            />

                            <label>Type of customer:</label>
                            <Select
                                value={{value: this.state[db.Home.TYPE_OF_CUSTOMER], label: this.state[db.Home.TYPE_OF_CUSTOMER]}}
                                onChange={(item) => this.setState({[db.Home.TYPE_OF_CUSTOMER]: item.value})}
                                options={typesOfCustomer.map(typeOfCustomer => ({value: typeOfCustomer, label: typeOfCustomer}))}
                                isMulti={false}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={this.onPositiveButtonClick}>Create home on user</Button>
                        <Button outline color="secondary" onClick={this.onCancelClick}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

CreateHomeOnUser.propTypes = {
    save: PropTypes.func,
    cancel: PropTypes.func,
    setToggleModal: PropTypes.func
};