import React, {Component} from 'react';
import {Alert, Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Parse from 'parse';
import * as db from '../../../lib/dbStructure';
import assert from 'assert';
import swal from 'sweetalert';
import AsyncSelect from "react-select/async";
import Select from "react-select";

export default class RemoveUserFromBuildingModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            isModalOpen: false,
            homes: [],
            selectedHome: null,
            selectedUser: null,
            writeAccess: false,
            administrator: false,
            loading: {
                userSearch: false,
                buildingSearch: false
            }
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onPositiveButtonClick = this.onPositiveButtonClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onHomeSelectedChange = this.onHomeSelectedChange.bind(this);
        this.getHomes = this.getHomes.bind(this);
        this.onRoomFieldChange = this.onRoomFieldChange.bind(this);
        this.onUserSelected = this.onUserSelected.bind(this);
    }

    async getHomes(user) {
        if(!user) return [];

        let query = new Parse.Query('Home');

        query.select([
            db.Home.HOME_NAME,
            db.Home.CITY,
            db.Home.ACL
        ]);
        query.notEqualTo(db.Home.DELETED, true);
        query.notEqualTo(db.Home.HIDDEN, true);
        query.limit(10000);

        let homes = await query.find();

        homes = homes.filter(home => {
            let acl = home.get(db.Home.ACL);

            let hasReadAccess = acl.getReadAccess(user);
            return hasReadAccess;
        });

        homes = homes.map(home => {
            return {
                value: home.id,
                label: `${home.get(db.Home.HOME_NAME)} (${home.get(db.Home.CITY)})`,
                object: home
            }
        });

        this.setState({homes});
    }

    async getUsers(email) {
        if(!email) return [];
        if(email.length < 2) return [];

        let users = await Parse.Cloud.run('search-user-from-email', {email});

        return users.map(user => {
            return {
                value: user.id,
                label: `${user.get(db._User.USERNAME)} (${user.get(db._User.EMAIL)})`,
                object: user
            }
        });
    }

    componentDidMount() {
        this.props.setToggleModal(this.toggleModal);
    }

    toggleModal() {
        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    onHomeSelectedChange(selectedHome) {
        this.setState({selectedHome});
    }

    onUserSelected(e) {
        let userId = e.target.value;

        this.setState({selectedUser: userId});
    }

    onRoomFieldChange(e){
        let value = e.target.value;
        let name = e.target.name;

        this.setState({[name]: value});
    }

    async onPositiveButtonClick(){
        this.setState({error: false, errorMessage: null});

        try {
            assert(this.state.selectedUser != null, 'User is required');
            assert(this.state.selectedUser.object != null, 'User is required');
            assert(this.state.selectedHome != null, 'Home is required');
            assert(this.state.selectedHome.object != null, 'Home is required');
    
            let willDelete = await swal({
                title: 'Are you sure?',
                text: 'The user will not have access anymore to the selected building.',
                icon: 'warning',
                buttons: true,
                dangerMode: true,
            });

            if (!willDelete) {
                return this.setState({error: true, errorMessage: 'Aborted by user'});
            }
    
            await Parse.Cloud.run('admin-remove-user-from-building', {
                userId: this.state.selectedUser.object.id,
                buildingId: this.state.selectedHome.object.id
            });
    
            swal('User unlinked correctly', '', 'success');
        } catch (e) {
            this.setState({error: true, errorMessage: e.message});
        }
    }

    onCancelClick(){
        this.toggleModal();
        this.props.cancel(this.state.config);
    }

    render() {

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Remove user from building
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <FormGroup>
                            <label>Select user:</label>
                            <AsyncSelect
                                value={this.state.selectedUser}
                                onChange={selectedUser => {
                                    this.setState({selectedUser});
                                    this.getHomes(selectedUser.object);
                                }}
                                loadOptions={this.getUsers}
                                isMulti={false}
                            />
                            
                            {
                                this.state.selectedUser && this.state.homes && <>
                                    <label>Select a building:</label>
                                    <Select 
                                        options={this.state.homes} 
                                        value={this.state.selectedHome ? {
                                            value: this.state.selectedHome.object.id,
                                            label: `${this.state.selectedHome.object.get(db.Home.HOME_NAME)} (${this.state.selectedHome.object.get(db.Home.CITY)})`,
                                            object: this.state.selectedHome.object   
                                        } : null}
                                        onChange={this.onHomeSelectedChange}
                                    />
                                </>   
                            }
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={this.onPositiveButtonClick}>Unlink user</Button>
                        <Button outline color="secondary" onClick={this.onCancelClick}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

RemoveUserFromBuildingModal.propTypes = {
    setToggleModal: PropTypes.func
};