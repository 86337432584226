import React, {Component} from 'react';
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Parse from 'parse';
import * as db from '../../../lib/dbStructure';
import assert from 'assert';
import swal from 'sweetalert';
import AsyncSelect from "react-select/async";
import Select from "react-select";

export default class ChangeBuildingFieldsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            homes: [],
            selectedHome: '',
            loading: {
            }
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onPositiveButtonClick = this.onPositiveButtonClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onUserSelected = this.onUserSelected.bind(this);
        this.getHomes = this.getHomes.bind(this);
    }

    componentDidMount() {
        this.props.setToggleModal(this.toggleModal);
    }

    toggleModal() {
        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    onUserSelected(e) {
        let userId = e.target.value;

        this.setState({selectedHome: userId});
    }

    changeUserField(fieldName, newValue){
        this.setState(prev => {
            let user = prev.selectedHome.object;

            user.set(fieldName, newValue);

            prev.selectedHome.object = user;

            return prev;
        });
    }

    async onPositiveButtonClick(){
        this.setState({error: false, errorMessage: null});

        try {
            assert(this.state.selectedHome != null, 'User is required');
            assert(this.state.selectedHome.object != null, 'User is required');
    
            let willDelete = await swal({
                title: 'Are you sure?',
                text: 'Changing building fields can cause the user to have changes in the visualization of the webapp.',
                icon: 'warning',
                buttons: true,
                dangerMode: false
            });

            if (!willDelete) {
                return this.setState({error: true, errorMessage: 'Aborted by user'});
            }

            this.state.selectedHome.object.save();

            swal('Field changed correctly', '', 'success');
        } catch (e) {
            this.setState({error: true, errorMessage: e.message});
        }
    }

    onCancelClick(){
        this.toggleModal();
        this.props.cancel(this.state.config);
    }

    async getHomes(homeName) {
        if(!homeName) return [];
        if(homeName.length < 2) return [];

        let query = new Parse.Query('Home');

        /*query.select([
            db.Home.HOME_NAME,
            db.Home.CITY,
            db
        ])*/
        query.notEqualTo(db.Home.DELETED, true);
        query.notEqualTo(db.Home.HIDDEN, true);
        query.include(db.Home.OWNER)
        query.limit(10);

        query.fullText(db.Home.HOME_NAME, homeName);
        //query.ascending('$score');

        let homes = await query.find();

        return homes.map(home => {
            let owner = home.get(db.Home.OWNER);
            let ownerEmail = owner && owner.get(db._User.EMAIL);
            let city = home.get(db.Home.CITY) || '';
            let homeName = home.get(db.Home.HOME_NAME) || '';

            return {
                value: home.id,
                label: `${homeName} (${city})`,
                object: home
            }
        });
    }

    render() {
        const typesOfCustomer = [
            db.Home.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER,
            db.Home.TYPE_OF_CUSTOMER$FULL_SYSTEM_CUSTOMER,
            db.Home.TYPE_OF_CUSTOMER$READ_ONLY_DEMO_USER
        ];

        const textFields = [
            db.Home.HOME_NAME,
            db.Home.CITY
        ];

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Change building fields
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <FormGroup>
                            <label>Select building:</label>
                            <AsyncSelect
                                value={this.state.selectedHome}
                                onChange={selectedHome => this.setState({selectedHome})}
                                loadOptions={this.getHomes}
                                isMulti={false}
                            />

                            {
                                !!this.state.selectedHome && <>
                                    <label>Type of customer:</label>
                                    <Select
                                        value={{value: this.state.selectedHome && this.state.selectedHome.object.get(db.Home.TYPE_OF_CUSTOMER), label: this.state.selectedHome && this.state.selectedHome.object.get(db.Home.TYPE_OF_CUSTOMER)}}
                                        onChange={item => this.changeUserField(db.Home.TYPE_OF_CUSTOMER, item.value)}
                                        options={typesOfCustomer.map(typeOfCustomer => ({value: typeOfCustomer, label: typeOfCustomer}))}
                                        isMulti={false}
                                    />

                                    {
                                        textFields.map(key => {
                                            return <>
                                                <Label for={key}>{key}:</Label>
                                                <Input
                                                    type={'text'}
                                                    name={key}
                                                    id={key}
                                                    value={this.state.selectedHome && this.state.selectedHome.object.get(key)}
                                                    onChange={e => this.changeUserField(key, e.target.value)}>
                                                </Input>
                                            </>
                                        })
                                    }
                                </>
                            }


                        </FormGroup>

                        
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={this.onPositiveButtonClick}>Change building fields</Button>
                        <Button outline color="secondary" onClick={this.onCancelClick}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

ChangeBuildingFieldsModal.propTypes = {
    setToggleModal: PropTypes.func
};