import React, {Component} from 'react';
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';

export default class ConfigModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            config: props.config || {}
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.setConfigValue = this.setConfigValue.bind(this);
    }

    componentDidMount(){
        this.props.setToggleModal(this.toggleModal);
    }

    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    }

    setConfigValue(key, e){
        let type = e.target.type;
        let value = e.target.value;

        if (type === 'number'){
            value = parseInt(value);
        }

        this.setState(prev => {
            if(value === '' || value == null || (type === 'number' && isNaN(value))) {
                delete prev.config[key];

                return prev;
            }
            prev.config[key] = value;

            return prev;
        })
    }

    render() {

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Set config
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <FormGroup>
                            <Label for={'config-modal-version'}>Enter version</Label>
                            <Input
                                type={'number'}
                                name={'version'}
                                id={'config-modal-version'}
                                value={this.state.config.version}
                                onChange={(e) => this.setConfigValue('version', e)}>
                            </Input>
                            <Label for={'config-modal-wifiName'}>Enter wifiName</Label>
                            <Input
                                type={'text'}
                                name={'wifiName'}
                                id={'config-modal-wifiName'}
                                value={this.state.config.wifiName}
                                onChange={(e) => this.setConfigValue('wifiName', e)}>
                            </Input>
                            <Label for={'config-modal-wifiPassword'}>Enter wifiPassword</Label>
                            <Input
                                type={'text'}
                                name={'wifiPassword'}
                                id={'config-modal-wifiPassword'}
                                value={this.state.config.wifiPassword}
                                onChange={(e) => this.setConfigValue('wifiPassword', e)}>
                            </Input>
                            <Label for={'config-modal-serverAddress'}>Enter serverAddress</Label>
                            <Input
                                type={'text'}
                                name={'serverAddress'}
                                id={'config-modal-serverAddress'}
                                value={this.state.config.serverAddress}
                                onChange={(e) => this.setConfigValue('serverAddress', e)}>
                            </Input>
                            <Label for={'config-modal-serverPort'}>Enter serverPort</Label>
                            <Input
                                type={'number'}
                                name={'serverPort'}
                                id={'config-modal-serverPort'}
                                value={this.state.config.serverPort}
                                onChange={(e) => this.setConfigValue('serverPort', e)}>
                            </Input>
                            <Label for={'config-modal-protocol'}>Enter protocol</Label>
                            <Input
                                type={'text'}
                                name={'protocol'}
                                id={'config-modal-protocol'}
                                value={this.state.config.protocol}
                                onChange={(e) => this.setConfigValue('protocol', e)}>
                            </Input>
                            <Label for={'config-modal-fingerprint'}>Enter fingerprint</Label>
                            <Input
                                type={'text'}
                                name={'fingerprint'}
                                id={'config-modal-fingerprint'}
                                value={this.state.config.fingerprint}
                                onChange={(e) => this.setConfigValue('fingerprint', e)}>
                            </Input>
                            <Label for={'config-modal-userUrl'}>Enter userUrl</Label>
                            <Input
                                type={'text'}
                                name={'userUrl'}
                                id={'config-modal-userUrl'}
                                value={this.state.config.userUrl}
                                onChange={(e) => this.setConfigValue('userUrl', e)}>
                            </Input>
                            <Label for={'config-modal-saveUrl'}>Enter saveUrl</Label>
                            <Input
                                type={'text'}
                                name={'saveUrl'}
                                id={'config-modal-saveUrl'}
                                value={this.state.config.saveUrl}
                                onChange={(e) => this.setConfigValue('saveUrl', e)}>
                            </Input>
                            <Label for={'config-modal-loginUrl'}>Enter loginUrl</Label>
                            <Input
                                type={'text'}
                                name={'loginUrl'}
                                id={'config-modal-loginUrl'}
                                value={this.state.config.loginUrl}
                                onChange={(e) => this.setConfigValue('loginUrl', e)}>
                            </Input>

                            <hr/>
                            <pre>
                                {JSON.stringify(this.state.config, null, 2)}
                            </pre>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={() => {
                            this.setState({
                                error: false,
                                errorMessage: null
                            });

                            this.props.save(this.state.config)
                                .then(() => this.toggleModal())
                                .catch(err => {
                                    this.setState({
                                        error:true,
                                        errorMessage: err.message
                                    });
                                });
                        }}>Save</Button>
                        <Button outline color="secondary" onClick={() => {
                            this.toggleModal();
                            this.props.cancel(this.state.config);
                        }}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

ConfigModal.propTypes = {
    save: PropTypes.func,
    cancel: PropTypes.func,
    setToggleModal: PropTypes.func,
    setConfigValue: PropTypes.func,
    config: PropTypes.object
};