let Parse = require('parse');

let customParseServerHost = localStorage.getItem('customParseServerHost');
let customParseServerApplicationId = localStorage.getItem('customParseServerApplicationId');

if(!customParseServerApplicationId){
    Parse.initialize('NjSUT8HxvCz706ldcwUn');
} else {
    Parse.initialize(customParseServerApplicationId);
}

if (!customParseServerHost) {
    Parse.serverURL = 'https://server.cleveron.ch/api'; //'http://localhost:1337/api';
} else {
    Parse.serverURL =  `https://${customParseServerHost}/api`;
}

if(localStorage.getItem('roles')){
    Parse.roles = localStorage.getItem('roles').split(',');
}

export default Parse;