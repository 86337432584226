import React, {Component} from 'react';
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import * as db from '../../../lib/dbStructure';
import Select from "react-select";
import _ from "lodash";

export default class CreateUserModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            username: '',
            email: '',
            password: '',
            language: '',
            city: '',
            typeOfCustomer: ''
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onPositiveButtonClick = this.onPositiveButtonClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onUserFieldChange = this.onUserFieldChange.bind(this);

    }

    componentDidMount(){
        this.props.setToggleModal(this.toggleModal);
    }

    toggleModal() {
        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    onUserFieldChange(e){
        let value = e.target.value;
        let name = e.target.name;
        this.setState({[name]: value});
    }

    async onPositiveButtonClick(){
        try {
            this.setState({error: false, errorMessage: null});

            let result = await this.props.save(_.pick(this.state, ['username', 'password', 'email', 'city', 'language', 'typeOfCustomer']));

        } catch (e) {
            this.setState({error: true, errorMessage: err.message});
        }
    }

    onCancelClick(){
        this.toggleModal();
        this.props.cancel();
    }

    render() {
        const allCantons = ['GR', 'BE', 'ZH', 'LU', 'UR', 'SZ', 'OW', 'NW', 'GL', 'ZG', 'SO', 'BS', 'BL', 'SH', 'AR',
            'AI', 'SG', 'AG', 'TG', 'VD', 'NE', 'GE', 'JU', 'FR', 'VS', 'TI'];
        const languages = ['IT', 'DE', 'FR', 'EN'];
        const typesOfCustomer = [
            db.Home.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER,
            db.Home.TYPE_OF_CUSTOMER$FULL_SYSTEM_CUSTOMER,
            db.Home.TYPE_OF_CUSTOMER$READ_ONLY_DEMO_USER
        ];

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Create user
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <FormGroup>
                            <Label for={'username'}>Username</Label>
                            <Input
                                type={'text'}
                                name={'username'}
                                id={'username'}
                                value={this.state.username}
                                onChange={this.onUserFieldChange}>
                            </Input>
                            <Label for={'password'}>Password</Label>
                            <Input
                                type={'text'}
                                name={'password'}
                                id={'password'}
                                value={this.state.password}
                                onChange={this.onUserFieldChange}>
                            </Input>
                            <Label for={'email'}>E-mail</Label>
                            <Input
                                type={'text'}
                                name={'email'}
                                id={'email'}
                                value={this.state.email}
                                onChange={this.onUserFieldChange}>
                            </Input>
                            <Label for={'city'}>City</Label>
                            <Input
                                type={'text'}
                                name={'city'}
                                id={'city'}
                                value={this.state.city}
                                onChange={this.onUserFieldChange}>
                            </Input>

                            <Label for={db.Home.LANGUAGE}>Language:</Label>
                            <Select
                                value={{value: _.toLower(this.state[db.Home.LANGUAGE]), label: this.state[db.Home.LANGUAGE]}}
                                onChange={(item) => this.setState({[db.Home.LANGUAGE]: item.value})}
                                options={languages.map(language => ({value: _.toLower(language), label: language}))}
                                isMulti={false}
                            />

                            <label>Type of customer:</label>
                            <Select
                                value={{value: this.state[db.Home.TYPE_OF_CUSTOMER], label: this.state[db.Home.TYPE_OF_CUSTOMER]}}
                                onChange={(item) => this.setState({[db.Home.TYPE_OF_CUSTOMER]: item.value})}
                                options={typesOfCustomer.map(typeOfCustomer => ({value: typeOfCustomer, label: typeOfCustomer}))}
                                isMulti={false}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={this.onPositiveButtonClick}>Create user</Button>
                        <Button outline color="secondary" onClick={this.onCancelClick}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

CreateUserModal.propTypes = {
    save: PropTypes.func,
    cancel: PropTypes.func,
    setToggleModal: PropTypes.func
};