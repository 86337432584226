import React, {Component} from 'react';
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import * as db from "../../../lib/dbStructure";

export default class ModifyHomeFieldsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            home: this.props.home
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onOpened = this.onOpened.bind(this);
    }

    componentDidMount(){
        this.props.setToggleModal(this.toggleModal);
    }

    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    }

    async onOpened(){}

    render() {
        let fields = [
            db.Home.CITY,
            db.Home.ADDRESS,
            db.Home.COUNTRY,
            db.Home.TYPE_OF_CUSTOMER,
            db.Home.LANGUAGE,
            db.Home.HOME_NAME,
            db.Home.CRM_BUILDING_ID,
            db.Home.BUILDING_IMAGE
        ];

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()} onOpened={this.onOpened}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Set building configuration
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <h2>Home fields</h2>
                        <hr/>
                        {
                            fields.map(field => {
                                return <FormGroup key={field}>
                                    <Label for={field}>{field}</Label>&nbsp;
                                    <Input
                                        type={'text'}
                                        name={field}
                                        id={field}
                                        value={this.state.home.get(field)}
                                        onChange={async (e) => {{
                                            this.state.home.set(field, e.target.value);

                                            this.setState({home: this.state.home});
                                        }}}>
                                    </Input>
                                </FormGroup>
                            })
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={async () => {
                            await this.state.home.save();
                            swal('Success', '', 'success');
                        }}>Save</Button>
                        <Button outline color="secondary" onClick={() => {
                            this.toggleModal();
                        }}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

ModifyHomeFieldsModal.propTypes = {
    setToggleModal: PropTypes.func
};