import React, {Component} from 'react';
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import Parse from 'parse';
import {toPointerFromId} from '../../../lib/util';
import * as db from '../../../lib/dbStructure';
import Select from 'react-select';

export default class LinkRoomModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            homes: [],
            rooms: [],
            selectedHome: '',
            selectedRoom: ''
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onHomeSelected = this.onHomeSelected.bind(this);
        this.getHomes = this.getHomes.bind(this);
        this.getRoomsFromHome = this.getRoomsFromHome.bind(this);
        this.onRoomSelected = this.onRoomSelected.bind(this);
        this.onHomeSelectedChange = this.onHomeSelectedChange.bind(this);
    }

    async getHomes(){
        try {
            let query = new Parse.Query('Home');
            query.include('Owner');
            query.notEqualTo(db.Home.DELETED, true);
            query.notEqualTo(db.Home.HIDDEN, true);
            query.limit(1000);

            let homes = await query.find();

            homes = homes.map(home => {
                return {
                    value: home.id,
                    label: `${home.get(db.Home.HOME_NAME)} (${home.get(db.Home.CITY)})`,
                    object: home
                }
            });

            this.setState({ homes });
        } catch(e){
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    getRoomsFromHome(homeId){
        let query = new Parse.Query('Room');
        query.equalTo('home', toPointerFromId(homeId, 'Home'));
        query.limit(500);

        return query.find()
            .then(rooms => this.setState({rooms}))
            .catch(console.error);
    }

    componentDidMount(){
        this.props.setToggleModal(this.toggleModal);
        this.getHomes();
    }

    toggleModal() {
        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    onHomeSelected(e){
        let homeId = e.target.value;

        this.setState({selectedHome: homeId});

        this.getRoomsFromHome(homeId);
    }

    onRoomSelected(e){
        let roomId = e.target.value;

        this.setState({selectedRoom: roomId});
    }

    onHomeSelectedChange(selectedHome) {
        this.setState({selectedHome});

        this.getRoomsFromHome(selectedHome.object.id);
    }

    render() {

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Link device to room
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <FormGroup>
                            <Label for={'config-modal-version'}>Home</Label>
                            <Select
                                options={this.state.homes}
                                value={this.state.selectedHome}
                                onChange={this.onHomeSelectedChange}
                            />
                            <Label for={'config-modal-wifiName'}>Room</Label>
                            <Input
                                type={'select'}
                                name={'room'}
                                id={'room'}
                                value={this.state.selectedRoom}
                                onChange={this.onRoomSelected}>
                                <option>Select a room</option>
                                {
                                    this.state.rooms.map(room => {
                                        return <option key={room.id} value={room.id}>
                                            {room.get('roomName')} {room.get(db.Room.FLOOR)}
                                        </option>
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={() => {
                            this.setState({error: false, errorMessage: null});

                            let home = this.state.selectedHome.object;
                            let room = this.state.rooms.filter(room => room.id === this.state.selectedRoom)[0];

                            this.props.save(home, room)
                                .then(() => this.toggleModal())
                                .catch(err => {
                                    this.setState({error: true, errorMessage: err.message});
                                })
                        }}>Link</Button>
                        <Button outline color="secondary" onClick={() => {
                            this.toggleModal();
                            this.props.cancel(this.state.config);
                        }}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

LinkRoomModal.propTypes = {
    save: PropTypes.func,
    cancel: PropTypes.func,
    setToggleModal: PropTypes.func
};