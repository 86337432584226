import React, {Component} from 'react';
import {Badge, Row, Table} from 'reactstrap';
import * as db from '../../lib/dbStructure';
import moment from 'moment';
import Parse from '../../lib/parse';
import {createLinkFromRooms, getOptionFromUser, groupByKey} from '../../lib/util';

export default class PageSupportRequests extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openSupportRequests: [],
            draftSupportRequests: [],
            resolvedSupportRequests: [],
            openSupportRequestsByBuilding: {}
        }

        this.loadSupportRequests = this.loadSupportRequests.bind(this);
    }

    componentDidMount() {
        this.loadSupportRequests();
    }

    async loadSupportRequests(){
        let query = (new Parse.Query(db.classes.SupportRequest))
            .notEqualTo(db.SupportRequest.DELETED, true)
            .descending(db.RoomFeedback.CREATED_AT)
            .include([
                db.SupportRequest.ROOM,
                db.SupportRequest.USER,
                db.SupportRequest.OWNER,
                db.SupportRequest.HOME,
                `${db.SupportRequest.ROOM}.${db.Room.HOME}`,
                `${db.SupportRequest.ROOM}.${db.Room.ROOM_NAME}`,
            ])
            //.descending(db.SupportRequest.HOME)
            .limit(1000);

        let supportRequests = await query.find();


        let openSupportRequests = supportRequests.filter(supportRequest => supportRequest.get(db.SupportRequest.STATUS) === db.SupportRequest.STATUS$OPEN);
        let draftSupportRequests = supportRequests.filter(supportRequest => supportRequest.get(db.SupportRequest.STATUS) === db.SupportRequest.STATUS$DRAFT);
        let resolvedSupportRequests = supportRequests.filter(supportRequest => supportRequest.get(db.SupportRequest.STATUS) === db.SupportRequest.STATUS$RESOLVED);


        let openSupportRequestsByBuilding = groupByKey(openSupportRequests, db.SupportRequest.HOME);

        this.setState({
            openSupportRequests,
            draftSupportRequests,
            resolvedSupportRequests,
            openSupportRequestsByBuilding
        });
    }

    async assignResponsiblePerson(supportRequest){
        let options = [
            'Tarik',
            'Dennis',
            'Simone',
            'Clod',
            'Swen'
        ];
        let choosedOption = await getOptionFromUser(options);

        let userIdMap = {
            'Tarik': '',
            'Dennis': '',
            'Simone': '',
            'Clod': '',
            'Swen': ''
        }
    }

    render() {

        let renderSupportRequests = (supportRequests) => {
            return <Table>
                <thead>
                <tr>
                    <th>{db.SupportRequest.CREATED_AT}</th>
                    <th>{db.SupportRequest.REFERENCE_NUMBER}</th>
                    <th>{db.SupportRequest.ROOM}</th>
                    <th>{db.SupportRequest.INITIAL_SITUATION}</th>
                    <th>{db.SupportRequest.STATUS}</th>
                    <th>{db.SupportRequest.PRIORITY}</th>
                    <th>Last customer msg.</th>
                    <th>Building</th>
                    <th>City</th>
                    <th>Date of problem</th>
                    <th>Created by</th>
                    <td>Owner</td>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {
                    supportRequests.map((supportRequest, i) => {
                        let objectId = supportRequest.id;
                        let createdAt = supportRequest.get(db.SupportRequest.CREATED_AT);
                        let referenceNumber = supportRequest.get(db.SupportRequest.REFERENCE_NUMBER);
                        let room = supportRequest.get(db.SupportRequest.ROOM);
                        let home = supportRequest.get(db.SupportRequest.HOME);
                        let status = supportRequest.get(db.SupportRequest.STATUS);
                        let initialSituation = supportRequest.get(db.SupportRequest.INITIAL_SITUATION);
                        let priority = supportRequest.get(db.SupportRequest.PRIORITY);
                        let owner = supportRequest.get(db.SupportRequest.OWNER);
                        let ownerEmail = owner != null ? owner.get(db._User.USERNAME) : '';
                        let building = room && room.get(db.Room.HOME);
                        var zerofilled = ('00000'+referenceNumber).slice(-5);
                        let dateOfProblem = moment(supportRequest.get(db.SupportRequest.DATE_OF_PROBLEM));
                        let user = supportRequest.get(db.SupportRequest.USER);
                        let userEmail = user && user.get(db._User.USERNAME);
                        let fromNow = moment(createdAt).fromNow();
                        let city = home && home.get(db.Home.CITY);
                        let homeName = home && home.get(db.Home.HOME_NAME);
                        let lastUnansweredCustomerMessageDate = supportRequest.get(db.SupportRequest.LAST_UNANSWERED_CUSTOMER_MESSAGE_DATE);

                        lastUnansweredCustomerMessageDate = lastUnansweredCustomerMessageDate == null ?
                            null : moment(lastUnansweredCustomerMessageDate);

                        let statusBadge = <Badge color={'success'}>{status}</Badge>;
                        if(status === db.SupportRequest.STATUS$OPEN){
                            statusBadge = <Badge color={'warning'}>{status}</Badge>;
                        } else if(status === db.SupportRequest.STATUS$DRAFT){
                            statusBadge = <Badge>{status}</Badge>;
                        }else if(status === db.SupportRequest.STATUS$RESOLVED){
                            statusBadge = <Badge color={'success'}>{status}</Badge>;
                        }

                        let priorityBadge = <Badge color={'success'}>{priority}</Badge>;
                        if(priority === db.SupportRequest.PRIORITY$LOW){
                            priorityBadge = <Badge color={'success'}>{priority}</Badge>;
                        } else if(priority === db.SupportRequest.PRIORITY$NORMAL){
                            priorityBadge = <Badge>{priority}</Badge>;
                        } else if(priority === db.SupportRequest.PRIORITY$MEDIUM){
                            priorityBadge = <Badge color={'warning'}>{priority}</Badge>;
                        } else if(priority === db.SupportRequest.PRIORITY$HIGH){
                            priorityBadge = <Badge color={'danger'}>{priority}</Badge>;
                        }

                        return <tr key={supportRequest.id}>
                            <td>{moment(createdAt).format('DD/MM/YYYY HH:mm')} ({fromNow})</td>
                            <td>#{zerofilled}</td>
                            <td>
                                {room && room.get(db.Room.ROOM_NAME)}
                            </td>
                            <td>{initialSituation}</td>
                            <td>{statusBadge}</td>
                            <td>{priorityBadge}</td>
                            <td>{lastUnansweredCustomerMessageDate && lastUnansweredCustomerMessageDate.fromNow()}</td>
                            <td>{homeName}</td>
                            <td>{city}</td>
                            <td>{dateOfProblem.format('DD/MM/YYYY')}</td>
                            <td>{userEmail}</td>
                            <td>{ownerEmail}</td>
                            <td>
                                <a href={`https://app.cleveron.ch/support-request/${supportRequest.id}`} target={'_blank'}>
                                    Link webapp
                                </a><br/>
                                <a href={`/homes/${building && building.id}/room-temperature-chart?selectedRoomId=${room && room.id}&startDate=${dateOfProblem.format('DD/MM/YYYY')}&endDate=${dateOfProblem.format('DD/MM/YYYY')}`}
                                   target={'_blank'}
                                   rel="noreferrer">
                                    Admin dashboard chart
                                </a>
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </Table>;
        };

        let openSupportRequestsByBuilding = this.state.openSupportRequestsByBuilding;

        return (<div>
                <Row>
                    {
                        Object.keys(openSupportRequestsByBuilding).map(buildingId => {
                            let {groupObject: building, objects: supportRequests} = openSupportRequestsByBuilding[buildingId];
                            let rooms = supportRequests.map(supportRequest => supportRequest.get(db.SupportRequest.ROOM));

                            return <div>{building.get(db.Home.HOME_NAME)} ({building.get(db.Home.CITY)}) {createLinkFromRooms(buildingId, rooms)}</div>
                        })
                    }
                </Row>

                <h1>Open support requests</h1>
                {renderSupportRequests(this.state.openSupportRequests)}
                <h1>Draft support requests</h1>
                {renderSupportRequests(this.state.draftSupportRequests)}
                <h1>Resolved support requests</h1>
                {renderSupportRequests(this.state.resolvedSupportRequests)}
            </div>
        );
    }
}

PageSupportRequests.propTypes = {};