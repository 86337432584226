import React, {Component} from 'react';
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Parse from 'parse';
import * as db from '../../../lib/dbStructure';
import assert from 'assert';
import AsyncSelect from 'react-select/async';

export default class ChangePasswordToUserModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            isModalOpen: false,
            selectedUser: ''
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onPositiveButtonClick = this.onPositiveButtonClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onUserSelected = this.onUserSelected.bind(this);
    }

    componentDidMount() {
        this.props.setToggleModal(this.toggleModal);
        this.getUsers();
    }

    toggleModal() {
        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    onUserSelected(e) {
        let userId = e.target.value;

        this.setState({selectedUser: userId});
    }

    async onPositiveButtonClick(){
        this.setState({error: false, errorMessage: null});

        try {
            assert(this.state.selectedUser != null, 'User is required');
            assert(this.state.password != null, 'New password is required');
            assert(this.state.password.length >= 6, 'New password should be at least of 6 characters.');

            let sure = await swal({
                title: 'Are you sure?',
                text: 'The client user will not be able to login with his old credentils.',
                icon: 'warning',
                buttons: true,
                dangerMode: true,
            });

            if (!sure) {
                return this.setState({error: true, errorMessage: 'Aborted by user'});
            }

            await Parse.Cloud.run('admin-change-user-password', {
                userId: this.state.selectedUser.object.id,
                newPassword: this.state.password
            });
            swal('Password changed correctly', '', 'success');
        } catch (e){
            this.setState({error: true, errorMessage: e.message});
        }
    }

    onCancelClick(){
        this.toggleModal();
        this.props.cancel(this.state.config);
    }

    async getUsers(email) {
        if(!email) return [];
        if(email.length < 2) return [];

        let users = await Parse.Cloud.run('search-user-from-email', {email});

        return users.map(user => {
            return {
                value: user.id,
                label: `${user.get(db._User.USERNAME)} (${user.get(db._User.EMAIL)})`,
                object: user
            }
        });
    }

    render() {

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Change password of user
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <FormGroup>
                            <label>Select user:</label>
                            <AsyncSelect
                                value={this.state.selectedUser}
                                onChange={async selectedUser => {
                                    this.setState({selectedUser});
                                }}
                                loadOptions={this.getUsers}
                                isMulti={false}
                            />

                            <Label for={'password'}>New password</Label>
                            <Input
                                type={'text'}
                                name={'password'}
                                id={'password'}
                                value={this.state.password}
                                onChange={(e) => this.setState({password: e.target.value})}>
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={this.onPositiveButtonClick}>Change password</Button>
                        <Button outline color="secondary" onClick={this.onCancelClick}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

ChangePasswordToUserModal.propTypes = {
    save: PropTypes.func,
    cancel: PropTypes.func,
    setToggleModal: PropTypes.func
};