import React, {Component} from 'react';
import {Alert, Badge, Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Parse from 'parse';
import * as db from '../../../lib/dbStructure';
import assert from 'assert';
import swal from 'sweetalert';
import AsyncSelect from "react-select/async";
import Select from "react-select";

export default class ChangeUserRolesModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            isModalOpen: false,
            selectedUser: '',
            currentRoles: [],
            loading: {
                userSearch: false,
                buildingSearch: false
            }
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onPositiveButtonClick = this.onPositiveButtonClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onUserSelected = this.onUserSelected.bind(this);
    }

    async getUsers(email) {
        if(!email) return [];
        if(email.length < 2) return [];

        let users = await Parse.Cloud.run('search-user-from-email', {email});

        return users.map(user => {
            return {
                value: user.id,
                label: `${user.get(db._User.USERNAME)} (${user.get(db._User.EMAIL)})`,
                object: user
            }
        });
    }

    componentDidMount() {
        this.props.setToggleModal(this.toggleModal);
    }

    toggleModal() {
        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    async onUserSelected(e) {
        let userId = e.target.value;

        let currentRoles = await Parse.Cloud.run('admin-get-user-roles', {
            userId: userId.object.id
        });

        this.setState({selectedUser: userId, currentRoles});
    }

    changeUserField(fieldName, newValue){
        this.setState(prev => {
            let user = prev.selectedUser.object;

            user.set(fieldName, newValue);

            prev.selectedUser.object = user;

            return prev;
        });
    }

    async onPositiveButtonClick(){
        this.setState({error: false, errorMessage: null});

        try {
            assert(this.state.selectedUser != null, 'User is required');
            assert(this.state.selectedUser.object != null, 'User is required');
            assert(this.state.action != null, 'Action is required');
            assert(this.state.role != null, 'Role is required');
    
            let willDelete = await swal({
                title: 'Are you sure?',
                text: 'Changing user role can cause the user to not be able to login with it\'s previous credentials as well as visualization changes into the webapp.',
                icon: 'warning',
                buttons: true,
                dangerMode: true
            });

            if (!willDelete) {
                return this.setState({error: true, errorMessage: 'Aborted by user'});
            }
        
            await Parse.Cloud.run('admin-change-user-role', {
                userId: this.state.selectedUser.object.id,
                action: this.state.action.value,
                roleName: this.state.role.value
            });

            swal('Role changed correctly', '', 'success');
        } catch (e) {
            this.setState({error: true, errorMessage: e.message});
        }
    }

    onCancelClick(){
        this.toggleModal();
        this.props.cancel(this.state.config);
    }

    render() {
        let roles = Object.values(db.roles);
        let actions = ['delete', 'add'];

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Change user roles
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <FormGroup>
                            <label>Select user:</label>
                            <AsyncSelect
                                value={this.state.selectedUser}
                                onChange={async selectedUser => {
                                    let currentRoles = await Parse.Cloud.run('admin-get-user-roles', {
                                        userId: selectedUser.object.id
                                    });
                            
                                    this.setState({selectedUser, currentRoles});
                                }}
                                loadOptions={this.getUsers}
                                isMulti={false}
                            />

                            {
                                !!this.state.selectedUser && <>
                                    <label>Roles:</label>
                                    <Select
                                        value={this.state.role}
                                        onChange={role => this.setState({role})}
                                        options={roles.map(role => ({value: role, label: role}))}
                                        isMulti={false}
                                    />

                                    <label>Action:</label>
                                    <Select
                                        value={this.state.action}
                                        onChange={action => this.setState({action})}
                                        options={actions.map(action => ({value: action, label: action}))}
                                        isMulti={false}
                                    />

                                    Actual roles: {this.state.currentRoles.map(role => role.get(db._Role.NAME)).map(roleName => <Badge>{roleName}</Badge>)}
                                </>
                            }
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={this.onPositiveButtonClick}>Change user role</Button>
                        <Button outline color="secondary" onClick={this.onCancelClick}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

ChangeUserRolesModal.propTypes = {
    setToggleModal: PropTypes.func
};