import React from 'react';
import {Button, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from 'reactstrap';
import Parse from '../../lib/parse';
import db from '../../lib/dbStructure';
import paths from '../../lib/paths';

export default class Topbar extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    async logout(e) {
        e.preventDefault();

        try {
            await Parse.User.logOut();

            localStorage.removeItem('roles');

            window.location = paths.login;
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message, 'error');
        }
    }

    render() {
        return (
            <div>
                <Navbar color="red" light expand="md">
                    <NavbarBrand href="/">Dashboard</NavbarBrand>
                    <Nav>
                        <NavItem>
                            <NavLink>
                                Version: v1.0.0
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink>
                                Server: <span style={{color: 'red'}}>{Parse.serverURL}</span>
                            </NavLink>
                        </NavItem>
                        {
                            Parse.User.current() && <NavItem>
                                <NavLink>
                                    User: <span>{Parse.User.current().get(db._User.USERNAME)}</span>
                                    <Button outline className={'float-right'} onClick={this.logout}>Logout</Button>
                                </NavLink>
                            </NavItem>
                        }
                    </Nav>
                    <NavbarToggler onClick={this.toggle} />
                </Navbar>
            </div>
        );
    }
}