import React, {Component} from 'react';
import swal from 'sweetalert';
import * as db from '../../lib/dbStructure';
import lib from './lib';
import HomeTable from './home-table';
import Loader from '../loader';
import Toggle from 'react-toggle';

export default class PageMonitoring extends Component {
    constructor(props) {
        super(props);

        this.state = {
            homeDevices: [],
            notActiviatedHomes: [],
            loading: {
                monitoring: false
            }
        };
    }

    async componentDidMount() {
        this.setState({loading: {monitoring: true}});
        let offlineDevices = await lib.getOfflineDevices();
        let rooms = await lib.getAllRooms();
        let roomHomeMap = await lib.createRoomHomeMap(rooms);
        let homeDevices = lib.divideByHome(offlineDevices, roomHomeMap);
        lib.printHomesAndOfflineDevices(homeDevices);
        let notActiviatedHomes = await lib.loadNotActivatedHomes();

        this.setState({homeDevices, notActiviatedHomes, loading: {monitoring: false}});
    }

    async toggleMonitoringHome(home){
        try {
            home.set(db.Home.MONITORING_ACTIVATED, !home.get(db.Home.MONITORING_ACTIVATED));

            await home.save();

            swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message, 'error');
        }
    }

    async toggleMonitoringRoom(room){
        try {
            room.set(db.Room.MONITORING_ACTIVATED, !room.get(db.Room.MONITORING_ACTIVATED));

            await room.save();

            swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message, 'error');
        }
    }

    render() {
        return (
            <div>
                <h1 className="text-center">Page Monitoring</h1>
                {
                    this.state.loading.monitoring && <Loader />
                }
                {
                    this.state.homeDevices && this.state.homeDevices.map((homeDevice, i) => {
                        return <HomeTable key={i}
                                          homeDevice={homeDevice}
                                          toggleMonitoringHome={this.toggleMonitoringHome}
                                          toggleMonitoringRoom={this.toggleMonitoringRoom}
                        />
                    })
                }
                <hr/>
                <h1 className="text-center">Not activated Homes</h1>
                {
                    this.state.notActiviatedHomes.map(home => {
                        return <div>
                            <h2 style={{display: 'inline'}}>{home.get(db.Home.HOME_NAME)} ({home.get(db.Home.CITY)})</h2>
                            <Toggle
                                checked={home.get(db.Home.MONITORING_ACTIVATED)}
                                onChange={() => this.toggleMonitoringHome(home)} />
                        </div>
                    })
                }
            </div>
        );
    }
}

PageMonitoring.propTypes = {};