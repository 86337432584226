'use strict';

// import modules here
import React from 'react';
import ReactDOM from 'react-dom';
import {Route, Router, Switch} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.css';
import "react-toggle/style.css";
import "react-datepicker/dist/react-datepicker.css";
import './style/main.scss';

// import components here
import {Col, Container, Row} from 'reactstrap';
import {createBrowserHistory} from 'history';
import DeviceView from './components/DeviceView';
import UserSettings from './components/UserSettings';
import Rooms from './components/Rooms';
import Devices from './components/Devices';
import Homes from './components/Homes';
import RoomView from './components/RoomView';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import CreateUserAndRoom from './components/create-user-and-room';
import HomeDeviceMeasurementOverview from './components/HomeDeviceMeasurementOverview';
import BatteryAnalysis from './components/battery-analysis';
import paths from './lib/paths';
import RoomTemperatureChart from './components/room-temperature-chart';
import PageMonitoring from './components/page-monitoring';
import PageLinkRoomQr from './components/page-link-room-qr';
import PageLogin from './components/page-login';
import PageRoomFeedbacks from './components/page-room-feedbacks';
import PageUsers from './components/page-users';
import PageHomeDiagnostics from './components/page-home-diagnostics';
import PageDiagnosticDetail from './components/page-home-diagnostics/home-diagnostics-detail';
import PageSupportRequests from './components/page-support-request';
import PageAIDetection from './components/page-ai-detection';

const customHistory = createBrowserHistory();

if (document.getElementById('app')) {
    ReactDOM.render(
        (
            <Router history={customHistory} >
                <Container className="p-0" fluid>
                    <Row className="m-0">
                        <Col xs="0" sm="1" className="p-0">
                            <Sidebar history={customHistory} ></Sidebar>
                        </Col>
                        <Col xs="12" sm="11" className="p-0">
                            <Topbar></Topbar>
                            <Switch>
                                <Route exact history={customHistory}
                                    path={'/'} component={PageLogin} />
                                <Route exact history={customHistory}
                                    path={'/login'} component={PageLogin} />
                                <Route exact history={customHistory}
                                    path={paths.homes} component={Homes} />
                                <Route exact history={customHistory}
                                    path={paths.homeRooms} component={Rooms} />
                                <Route exact history={customHistory}
                                    path={'/homes/:homeId/installation-overview'}
                                    component={HomeDeviceMeasurementOverview} />
                                <Route exact history={customHistory}
                                    path={'/homes/:homeId/room-temperature-chart'}
                                    component={RoomTemperatureChart} />
                                <Route exact history={customHistory}
                                    path={'/rooms/:roomId/devices'} component={Devices} />
                                <Route exact history={customHistory}
                                    path={'/devices/:deviceId'} component={DeviceView} />
                                <Route exact history={customHistory}
                                    path={'/device/:deviceId'} component={DeviceView} />
                                <Route exact history={customHistory}
                                    path={'/devices'} component={Devices} />
                                <Route exact history={customHistory}
                                    path={'/user-settings'} component={UserSettings} />
                                <Route exact history={customHistory}
                                    path={'/room-view'} component={RoomView} />
                                <Route exact history={customHistory}
                                    path={'/create-user-and-room'} component={CreateUserAndRoom} />
                                <Route exact history={customHistory}
                                    path={'/battery-analysis'} component={BatteryAnalysis} />
                                <Route exact history={customHistory}
                                    path={paths.linkRoomQr} component={PageLinkRoomQr} />
                                <Route exact history={customHistory}
                                    path={paths.monitoring} component={PageMonitoring} />
                                <Route exact history={customHistory}
                                    path={paths.roomFeedbacks} component={PageRoomFeedbacks} />
                                <Route exact history={customHistory}
                                    path={paths.supportRequests} component={PageSupportRequests} />
                                <Route exact history={customHistory}
                                    path={paths.users} component={PageUsers} />
                                <Route exact history={customHistory}
                                    path={paths.homeDiagnostics} component={PageHomeDiagnostics}/>
                                <Route exact history={customHistory}
                                    path={paths.homeDiagnosticsDetail} component={PageDiagnosticDetail}/>
                                <Route exact history={customHistory}
                                    path={paths.aiDetection} component={PageAIDetection} />
                            </Switch>
                        </Col>
                    </Row>
                </Container>
            </Router>
        ),
        document.getElementById('app')
    );
}




