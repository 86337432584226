import React, {Component} from 'react';
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';

export default class AddMotorPositionCommandModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            motorPosition: 0
        };

        this.toggleModal = this.toggleModal.bind(this);
    }



    componentDidMount(){
        this.props.setToggleModal(this.toggleModal);
    }

    toggleModal() {
        this.setState({isModalOpen: !this.state.isModalOpen});
    }


    render() {

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Link device to room
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <FormGroup>
                            <Label for={'motorposition'}>Home</Label>
                            <Input
                                type={'numbebr'}
                                name={'motorposition'}
                                id={'motorposition'}
                                value={this.state.motorPosition}
                                onChange={(e) => {
                                    this.setState({motorPosition: e.target.value})
                                }}
                            >
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={() => {
                            this.setState({error: false, errorMessage: null});

                            this.props.save(this.state.motorPosition)
                                .then(() => this.toggleModal())
                                .catch(err => {
                                    this.setState({error: true, errorMessage: err.message});
                                })
                        }}>Add command</Button>
                        <Button outline color="secondary" onClick={() => {
                            this.toggleModal();
                            this.props.cancel();
                        }}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

AddMotorPositionCommandModal.propTypes = {
    save: PropTypes.func,
    cancel: PropTypes.func,
    setToggleModal: PropTypes.func
};