import React from 'react';
import {Button, Card, CardText, CardTitle, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from 'reactstrap';

export default class UserSettingsTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'rooms-tab',
            selectedRows: [],
            rooms: props.rooms
        };

        this.onRowSelect = this.onRowSelect.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    onRowSelect({ id }, isSelected) {
        if (isSelected && this.state.selectedRows.length !== 2) {
            this.setState({
                selectedRows: [...this.state.selectedRows, id].sort()
            });
        } else {
            this.setState({ selectedRows: this.state.selectedRows.filter(it => it !== id) });
        }
    }

    onDeleteSelectedRows() {
        this.setState({
            rooms: this.state.rooms.filter((row, index) => {
                if (this.state.selectedRows.indexOf(row.id) === -1) {
                    return row;
                }
            }),
            selectedRows: []
        });
    }

    render() {
        const selectRowProps = {
            mode: 'checkbox',
            onSelect: this.onRowSelect
        };

        return (
            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={this.state.activeTab === 'rooms-tab' ? 'active' : ''}
                            onClick={() => { this.toggleTab('rooms-tab'); }}
                        >
                            Rooms
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={this.state.activeTab === 'issues-tab' ? 'active' : ''}
                            onClick={() => { this.toggleTab('issues-tab'); }}
                        >
                            Issues
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="rooms-tab">
                        <Button
                            color="danger"
                            onClick={() => this.onDeleteSelectedRows()}
                        >
                            Delete ({this.state.selectedRows.length})
                        </Button>
                        <BootstrapTable
                            insertRow={true}
                            selectRow={selectRowProps}
                            data={this.state.rooms}>
                            <TableHeaderColumn isKey dataField='id'>Room ID</TableHeaderColumn>
                            <TableHeaderColumn dataField='name'>User Name</TableHeaderColumn>
                            <TableHeaderColumn dataField='price'>Room Price</TableHeaderColumn>
                            <TableHeaderColumn dataField='temp'>Room Temprature</TableHeaderColumn>
                        </BootstrapTable>
                    </TabPane>
                    <TabPane tabId="issues-tab">
                        <Row>
                            <Col sm="6">
                                <Card body>
                                    <CardTitle>Issue 1</CardTitle>
                                    <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                                    <Button>Go to issue</Button>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card body>
                                    <CardTitle>Issue 2</CardTitle>
                                    <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                                    <Button>Go to issue</Button>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </div>
        );
    }
}