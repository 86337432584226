import React, {Component} from 'react';
import {Alert, Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Parse from 'parse';
import * as db from '../../../lib/dbStructure';
import assert from 'assert';
import swal from 'sweetalert';
import AsyncSelect from "react-select/async";

export default class LinkUserToAccountModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            isModalOpen: false,
            homes: [],
            selectedHome: '',
            selectedUser: '',
            writeAccess: false,
            administrator: false,
            loading: {
                userSearch: false,
                buildingSearch: false
            }
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onPositiveButtonClick = this.onPositiveButtonClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onHomeSelected = this.onHomeSelected.bind(this);
        this.getHomes = this.getHomes.bind(this);
        this.onRoomFieldChange = this.onRoomFieldChange.bind(this);
        this.onUserSelected = this.onUserSelected.bind(this);
    }

    async getHomes(homeName) {
        if(!homeName) return [];
        if(homeName.length < 2) return [];

        let query = new Parse.Query('Home');

        query.select([
            db.Home.HOME_NAME,
            db.Home.CITY
        ])
        query.notEqualTo(db.Home.DELETED, true);
        query.notEqualTo(db.Home.HIDDEN, true);
        query.limit(100);

        query.fullText(db.Home.HOME_NAME, homeName);
        //query.ascending('$score');

        let homes = await query.find();

        return homes.map(home => {
            return {
                value: home.id,
                label: `${home.get(db.Home.HOME_NAME)} (${home.get(db.Home.CITY)})`,
                object: home
            }
        });
    }

    async getUsers(email) {
        if(!email) return [];
        if(email.length < 2) return [];

        let users = await Parse.Cloud.run('search-user-from-email', {email});

        return users.map(user => {
            return {
                value: user.id,
                label: `${user.get(db._User.USERNAME)} (${user.get(db._User.EMAIL)})`,
                object: user
            }
        });
    }

    componentDidMount() {
        this.props.setToggleModal(this.toggleModal);
    }

    toggleModal() {
        if(!this.state.isModalOpen) this.getHomes();

        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    onHomeSelected(e) {
        let homeId = e.target.value;

        this.setState({selectedHome: homeId});
    }

    onUserSelected(e) {
        let userId = e.target.value;

        this.setState({selectedUser: userId});
    }

    onRoomFieldChange(e){
        let value = e.target.value;
        let name = e.target.name;

        this.setState({[name]: value});
    }

    async onPositiveButtonClick(){
        this.setState({error: false, errorMessage: null});

        try {
            assert(this.state.selectedUser != null, 'User is required');
            assert(this.state.selectedHome != null, 'Home is required');
    
            if(this.state.administrator === true){
                let willDelete = await swal({
                    title: 'Are you sure?',
                    text: 'The old administrator will be overridden by the new one.',
                    icon: 'warning',
                    buttons: true,
                    dangerMode: true,
                });
    
                if (!willDelete) {
                    return this.setState({error: true, errorMessage: 'Aborted by user'});
                }
            }
    
            await this.props.save(this.state.selectedUser.object.id, this.state.selectedHome.object.id, this.state.writeAccess, this.state.administrator);
        } catch (e) {
            this.setState({error: true, errorMessage: e.message});
        }
    }

    onCancelClick(){
        this.toggleModal();
        this.props.cancel(this.state.config);
    }

    render() {

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Link user to a building
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <FormGroup>
                            <label>Select user:</label>
                            <AsyncSelect
                                value={this.state.selectedUser}
                                onChange={selectedUser => this.setState({selectedUser})}
                                loadOptions={this.getUsers}
                                isMulti={false}
                            />
                            <label>Select a building:</label>
                            <AsyncSelect
                                value={this.state.selectedHome}
                                onChange={selectedHome => this.setState({selectedHome})}
                                loadOptions={this.getHomes}
                                isMulti={false}
                            />

                            <Label>Write access &nbsp;</Label>
                            <input type={'checkbox'} checked={this.state.writeAccess} onClick={(e) => {
                                this.setState({writeAccess: !this.state.writeAccess});
                            }}/> <br/>

                            <Label>Administrator &nbsp;</Label>
                            <input type={'checkbox'} checked={this.state.administrator} onClick={(e) => {
                                this.setState({administrator: !this.state.administrator});
                            }}/>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={this.onPositiveButtonClick}>Link user to building</Button>
                        <Button outline color="secondary" onClick={this.onCancelClick}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

LinkUserToAccountModal.propTypes = {
    save: PropTypes.func,
    cancel: PropTypes.func,
    setToggleModal: PropTypes.func
};