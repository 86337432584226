'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import Parse from '../../lib/parse';
import paths from '../../lib/paths';
import db from '../../lib/dbStructure';


// import components here
import {Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row} from 'reactstrap';

export default class PageLogin extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            username: '',
            password: '',
            error: false,
            errorMessage: ''
        };

        this.login = this.login.bind(this);
        this.changeInputField = this.changeInputField.bind(this);
        this.getRoles = this.getRoles.bind(this);
    }

    componentDidMount(){
        if(Parse.User.current()){
            this.props.history.push(paths.homes)
        }
    }

    async getRoles() {

        if(!Parse.User.current()) return this.props.history.push('/');

        try {
            let queryRole = (new Parse.Query(Parse.Role)).equalTo('users', Parse.User.current());

            let roles =  await queryRole.find();

            return roles;
        } catch (e) {
            console.error(e);
        }
    }


    async login(e){
        e.preventDefault();
        try {
            await Parse.User.logIn(this.state.username, this.state.password);
            let roles = await this.getRoles();

            localStorage.setItem('roles', roles.map(role => role.get(db._Role.NAME)).join(','));

            this.props.history.push(paths.homes);
        } catch (e) {
            console.error(e);
            this.setState({error: true, errorMessage: e.message});
        }
    }

    changeInputField(e){
        let name = e.target.name;
        let value = e.target.value;

        this.setState({[name]: value});
    }

    render() {
        return (
            <Container fluid>
                <Row style={{marginBottom: 40, marginTop: 20}}>
                    <Col className="text-center" md={{size: 6, offset: 3}}>
                        <h1>Login page</h1>
                    </Col>
                </Row>

                <Row>
                    <Col md={{size: 6, offset: 3}}>
                        <div>
                            {
                                this.state.error && <Alert color="danger">
                                    {this.state.errorMessage}
                                </Alert>
                            }

                            <Form>
                                <FormGroup>
                                    <Label for="exampleEmail">Username</Label>
                                    <Input type="email" name="username" placeholder="Enter your username"
                                           onChange={this.changeInputField}
                                           value={this.state.username}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="examplePassword">Password</Label>
                                    <Input type="password" name="password" placeholder="Enter your password"
                                           onChange={this.changeInputField}
                                           value={this.state.password}
                                    />
                                </FormGroup>
                                <Button outline onClick={this.login}>Login</Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

PageLogin.propTypes = {
    history: PropTypes.any,
    router: PropTypes.any
};
