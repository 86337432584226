let cfg = {
    api: {
        baseUrl: '/parse',
    },
    nbIot: {
        REQUIRED_NB_IOT_FIRMWARE_VERSION: 'BC660KGLAAR01A05_01.200.01.200'
    }
};

cfg.uploadConfig = {
    maxFileSize: 10 * 1024 * 1024
};



export default cfg;