import React, {Component} from 'react';
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import {downloadXLSX} from '../../../lib/util';
import {v4 as uuidv4} from 'uuid';
import moment from 'moment';


export default class GenerateQrCodeUuidsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            numberQrCodes: 50
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onPositiveButtonClick = this.onPositiveButtonClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onUserSelected = this.onUserSelected.bind(this);
    }

    componentDidMount() {
        this.props.setToggleModal(this.toggleModal);
    }

    toggleModal() {
        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    onUserSelected(e) {
        let userId = e.target.value;

        this.setState({selectedUser: userId});
    }

    async onPositiveButtonClick(){
        this.setState({error: false, errorMessage: null});

        try {
            let nameFile = `qr-code-uuids-${moment().format('DDMMYYYYHHmmSSSSS')}.csv`;
            let numberQrCodes = this.state.numberQrCodes || 50;
            let roomBaseUrl = 'https://room.cleveron.ch/room/';

            let qrCodes = [];
            for(let i = 0; i<numberQrCodes; i++){
                let uuid = uuidv4();
                let lastDigits = uuid.substr(uuid.length - 6);
                let url = `${roomBaseUrl}${uuid}`;

                let object=  {
                    uuid,
                    lastDigits,
                    url
                };

                qrCodes.push(object);
            }

            downloadXLSX(qrCodes, nameFile);
        } catch (e){
            swal('Error', e.message, 'error');
            this.setState({error: true, errorMessage: e.message});
        }
    }

    onCancelClick(){
        this.toggleModal();
        this.props.cancel(this.state.config);
    }

    render() {

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Change password of user
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <FormGroup>
                            <Label for={'numberQrCode'}>Number of qr code uuids:</Label>
                            <Input
                                type={'number'}
                                name={'numberQrCode'}
                                id={'numberQrCode'}
                                value={this.state.numberQrCodes}
                                onChange={(e) => this.setState({numberQrCodes: parseInt(e.target.value)})}>
                            </Input>
                        </FormGroup>

                        <a href={'https://cleveron-assets.s3.amazonaws.com/operations/Avery-Etikett-L7914-room-qr-code-070320231718.avery'} target={'_blank'}><i className="fa fa-download" aria-hidden="true"></i> Avery-Etikett-L7914.avery</a>
                        <br/>
                        <a href={'https://cleveron-assets.s3.amazonaws.com/operations/Avery-Etikett-3666-room-qr-code-small.avery'} target={'_blank'}><i className="fa fa-download" aria-hidden="true"></i> Avery-Etikett-3666.avery</a>
                        <br/>
                        <br/>
                        <a href={'https://app.avery-zweckform.com/dpo8/appeu/target;name=DE_de;ep=EUP/product;profile=YouPrint;templateCategory=Weitere%20Produkte;product=45a354f29672b52d561b1f0468ed96f7;search=3659/design;source=OpenProject;product=6d155c6028616dd5e7f48d9ad1d41bf4/customize;path=tmpUser%2Fprojects%2Fe29619ab42efade53921afddb1d5c9d7%2Fe29619ab42efade53921afddb1d5c9d7.xml;storage=fsEUPTemp/ctx;consumer=AveryEU;mmdUploaded=true/view?customize&'} target={'_blank'}>Link Avery portal (Create PDF)</a>
                        <br/>
                        <a href={'https://workdrive.zohopublic.eu/external/writer/6e4c1595c352420d67c5e11d773fb030c712827cdc1a47ed02bdc15af0e308e1'} target={'_blank'}>Guide generating PDF on Avery portal</a>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={this.onPositiveButtonClick}>Generate QR Code CSV</Button>
                        <Button outline color="secondary" onClick={this.onCancelClick}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

GenerateQrCodeUuidsModal.propTypes = {
    save: PropTypes.func,
    cancel: PropTypes.func,
    setToggleModal: PropTypes.func
};