import React, {Component} from 'react';
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Parse from 'parse';
import * as db from '../../../lib/dbStructure';
import AsyncSelect from 'react-select/async';
import assert from 'assert';

export default class CreateRoomOnUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            homes: [],
            selectedHome: null,
            selectedRoom: '',
            roomName: '',
            height: '',
            windows: '',
            tempMin: '',
            tempMax: '',
            area: '',
            numberRadiators: '',
            numberSense: '',
            floor: ''
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onPositiveButtonClick = this.onPositiveButtonClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onHomeSelected = this.onHomeSelected.bind(this);
        this.getHomes = this.getHomes.bind(this);
        this.onRoomFieldChange = this.onRoomFieldChange.bind(this);
    }

    async getHomes(homeName) {
        if(!homeName) return [];
        if(homeName.length < 2) return [];

        let query = new Parse.Query('Home');

        query.select([
            db.Home.HOME_NAME,
            db.Home.CITY
        ])
        query.notEqualTo(db.Home.DELETED, true);
        query.notEqualTo(db.Home.HIDDEN, true);
        query.limit(100);

        query.fullText(db.Home.HOME_NAME, homeName);
        //query.ascending('$score');

        let homes = await query.find();

        return homes.map(home => {
            return {
                value: home.id,
                label: `${home.get(db.Home.HOME_NAME)} (${home.get(db.Home.CITY)})`,
                object: home
            }
        });
    }

    componentDidMount() {
        this.props.setToggleModal(this.toggleModal);
    }

    toggleModal() {
        this.getHomes();
        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    onHomeSelected(e) {
        let homeId = e.target.value;

        this.setState({selectedHome: homeId});
    }

    onRoomFieldChange(e){
        let value = e.target.value;
        let name = e.target.name;

        this.setState({[name]: value});
    }

    onPositiveButtonClick(){
        this.setState({error: false, errorMessage: null});
        assert(this.state.selectedHome != null, 'Home is required');
        assert(this.state.selectedHome.object != null, 'Home object is required');

        let home = this.state.selectedHome.object;

        let room = _.pick(this.state, [
            'roomName',
            'height',
            'windows',
            'tempMin',
            'tempMax',
            'area',
            'numberRadiators',
            'numberSense',
            'floor'
        ]);



        let result = this.props.save(home, room);

        if(result && result.then){
            result.catch(err => {
                this.setState({error: true, errorMessage: err.message});
            });
        }
    }

    onCancelClick(){
        this.toggleModal();
        this.props.cancel(this.state.config);
    }

    render() {

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Create room on building
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <FormGroup>
                            <label>Select building (to):</label>
                            <AsyncSelect
                                value={this.state.selectedHome}
                                onChange={selectedHome => this.setState({selectedHome})}
                                loadOptions={this.getHomes}
                                isMulti={false}
                            />
                            <Label for={'roomName'}>Room name</Label>
                            <Input
                                type={'text'}
                                name={'roomName'}
                                id={'roomName'}
                                value={this.state.roomName}
                                onChange={this.onRoomFieldChange}>
                            </Input>
                            <Label for={'tempMin'}>Temperature min</Label>
                            <Input type={'number'} name={'tempMin'} id={'tempMin'} value={this.state.tempMin}
                                onChange={this.onRoomFieldChange} />

                            <Label for={'tempMax'}>Temperature max</Label>
                            <Input type={'number'} name={'tempMax'} id={'tempMax'} value={this.state.tempMax}
                                onChange={this.onRoomFieldChange} />

                            <Label for={'area'}>Area</Label>
                            <Input type={'number'} name={'area'} id={'area'} value={this.state.area}
                                onChange={this.onRoomFieldChange} />

                            <Label for={'height'}>Height</Label>
                            <Input type={'number'} name={'height'} id={'height'} value={this.state.height}
                                onChange={this.onRoomFieldChange} />

                            <Label for={'windows'}>Windows</Label>
                            <Input type={'number'} name={'windows'} id={'windows'} value={this.state.windows}
                                onChange={this.onRoomFieldChange} />

                            <Label for={'numberRadiators'}>Number radiators</Label>
                            <Input type={'number'} name={'numberRadiators'} id={'numberRadiators'} value={this.state.numberRadiators}
                                onChange={this.onRoomFieldChange} />

                            <Label for={'numberSense'}>Number sense</Label>
                            <Input type={'number'} name={'numberSense'} id={'numberSense'} value={this.state.numberSense}
                                onChange={this.onRoomFieldChange} />

                            <Label for={'floor'}>Floor</Label>
                            <Input type={'number'} name={'floor'} id={'floor'} value={this.state.floor}
                                onChange={this.onRoomFieldChange} />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={this.onPositiveButtonClick}>Create room on building</Button>
                        <Button outline color="secondary" onClick={this.onCancelClick}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

CreateRoomOnUser.propTypes = {
    save: PropTypes.func,
    cancel: PropTypes.func,
    setToggleModal: PropTypes.func
};