import React, {Component} from 'react';
import DatePicker from 'react-datepicker';
import ReactHighcharts from 'highcharts-react-official';
import moment from 'moment';

const config = {
    title: {
        text: 'Average Room Temperature for the day'
    },
    xAxis: {
        categories: [...Array(24).keys()].map(number => number + ':00'),
        crosshair: true
    },
    yAxis: [{ // Primary yAxis
        labels: {
            format: '{value}°C',
        },
        title: {
            text: 'Temperature',
        }
    }],
    series: [{
        data: [65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 65, 59, 80, 81, 56, 55, 40, 56, 55, 40]
    }]
};

export default class RoomDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: moment()
        };
    }

    handleDateChange(date) {
        this.setState({
            startDate: date
        });
    }

    render() {
        return (
            <div>
                <DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleDateChange}
                />
                <div>
                    <ReactHighcharts config={config}></ReactHighcharts>
                </div>
            </div>
        )
    }
}