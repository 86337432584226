import React, {Component} from 'react';
import {Alert, Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Parse from 'parse';
import * as db from '../../../lib/dbStructure';
import assert from 'assert';
import swal from 'sweetalert';
import AsyncSelect from "react-select/async";
import Loader from '../../loader';

export default class RotateImagesFromBuildingModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            isModalOpen: false,
            homes: [],
            selectedHome: '',
            loading: {
                buildingSearch: false,
                rotateImages: false
            }
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onPositiveButtonClick = this.onPositiveButtonClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onHomeSelected = this.onHomeSelected.bind(this);
        this.getHomes = this.getHomes.bind(this);
        this.onRoomFieldChange = this.onRoomFieldChange.bind(this);
        this.onUserSelected = this.onUserSelected.bind(this);
    }

    async getHomes(homeName) {
        if(!homeName) return [];
        if(homeName.length < 2) return [];

        let query = new Parse.Query('Home');

        query.select([
            db.Home.HOME_NAME,
            db.Home.CITY
        ])
        query.notEqualTo(db.Home.DELETED, true);
        query.notEqualTo(db.Home.HIDDEN, true);
        query.limit(100);

        query.fullText(db.Home.HOME_NAME, homeName);
        //query.ascending('$score');

        let homes = await query.find();

        return homes.map(home => {
            return {
                value: home.id,
                label: `${home.get(db.Home.HOME_NAME)} (${home.get(db.Home.CITY)})`,
                object: home
            }
        });
    }

    componentDidMount() {
        this.props.setToggleModal(this.toggleModal);
    }

    toggleModal() {
        if(!this.state.isModalOpen) this.getHomes();

        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    onHomeSelected(e) {
        let homeId = e.target.value;

        this.setState({selectedHome: homeId});
    }

    onUserSelected(e) {
        let userId = e.target.value;

        this.setState({selectedUser: userId});
    }

    onRoomFieldChange(e){
        let value = e.target.value;
        let name = e.target.name;

        this.setState({[name]: value});
    }

    async onPositiveButtonClick(){
        this.setState({error: false, errorMessage: null});

        try {
            assert(this.state.selectedHome != null, 'Home is required');
            assert(this.state.selectedHome.object != null, 'Home is required');
    
            let willDelete = await swal({
                title: 'Are you sure?',
                text: 'All the vertical images will be rotate to horizontal',
                icon: 'warning',
                buttons: true,
                dangerMode: true,
            });

            if (!willDelete) {
                return this.setState({error: true, errorMessage: 'Aborted by user'});
            }
    
            this.setState({loading: {rotateImages: true}});
            await Parse.Cloud.run('admin-rotate-image-of-building', {
                buildingId: this.state.selectedHome.object.id
            });
            this.setState({loading: {rotateImages: false}});
    
            swal('Images rotated correctly', '', 'success');
        } catch (e) {
            console.error(e);
            this.setState({error: true, errorMessage: e.message});
        }
    }

    onCancelClick(){
        this.toggleModal();
        this.props.cancel(this.state.config);
    }

    render() {

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Rotate images on building
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <FormGroup>
                            <label>Select a building:</label>
                            <AsyncSelect
                                value={this.state.selectedHome}
                                onChange={selectedHome => this.setState({selectedHome})}
                                loadOptions={this.getHomes}
                                isMulti={false}
                            />
                        </FormGroup>

                        {
                            this.state.loading.rotateImages && <Loader/>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={this.onPositiveButtonClick}>Rotate images</Button>
                        <Button outline color="secondary" onClick={this.onCancelClick}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

RotateImagesFromBuildingModal.propTypes = {
    setToggleModal: PropTypes.func
};