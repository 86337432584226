const paths = {
    home: '/',
    login: '/login',
    homes: '/homes',
    homeRooms: '/homes/:homeId/rooms',
    homeDiagnostics: '/homes/diagnostics',
    homeDiagnosticsDetail: '/homes/diagnostics/:id',
    linkRoomQr: '/link-room-qr',
    roomTemperatureChart: '/homes/:homeId/room-temperature-chart',
    monitoring: '/monitoring',
    roomFeedbacks: '/room-feedbacks',
    supportRequests: '/support-requests',
    aiDetection: '/ai-detection',
    users: '/users',
    devices: '/devices',
    error: '/error/:code/:message'
};

export default paths;
