import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Parse from 'parse';
import {findDevice, isWNBPThermo, isWNBThermo, moveDeviceToRoom, toPointerFromId} from '../../lib/util';
import swal from 'sweetalert';
import {Button, Col, Form, FormGroup, Input, Label, Row, Table} from 'reactstrap';
import * as db from '../../lib/dbStructure';
import moment from 'moment';
import LinkRoomModal from './link-room-modal';
import assert from 'assert';
import LinkDeviceRoomModal from './link-device-room-modal';
import paths from '../../lib/paths';


function dayMap(dayNumber){
    let map = {
        1: 'Sun',
        2: 'Mon',
        3: 'Tue',
        4: 'Wed',
        5: 'Thu',
        6: 'Fri',
        7: 'Sat'
    };

    return map[dayNumber];
}

export default class Devices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            room: null,
            devices: [],
            roomStateFlag: 'online',
            startDate: moment().subtract(2, 'week'),
            endDate: moment(),
            measures: null
        };

        this.onDeviceOpen = this.onDeviceOpen.bind(this);
        this.getRoom = this.getRoom.bind(this);
        this.setStateToRoom = this.setStateToRoom.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.getTempWeekOverview = this.getTempWeekOverview.bind(this);
        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
        this.onDownloadCsv = this.onDownloadCsv.bind(this);

        this.toggleLinkRoomModal = null;
        this.toggleLinkDeviceRoomModal = null;
    }

    async loadDevices(){
        try {
            let query = new Parse.Query(db.classes.Device);
            query.include(db.Device.ROOM_ID);
            query.include(db.Device.HOME);

            if(this.state.searchSerial){
                if(this.state.searchSerial.length === 5){
                    query.endsWith(db.Device.MAC_ADDRESS, this.state.searchSerial.toUpperCase());
                } else if(this.state.searchSerial.length === 20){
                    query.equalTo(db.Device.ICCID, this.state.searchSerial);
                }
                else {
                    query.equalTo(db.Device.SERIAL_NUMBER, parseInt(this.state.searchSerial));
                }
            }

            if (this.props.match.params.roomId)
                query.equalTo(db.Device.ROOM_ID, toPointerFromId(this.props.match.params.roomId, db.classes.Room));

            let devices = await query.find();

            if(devices.length === 0) throw new Error('Cannot find device with this parameters');

            let testBatchDevices = [];

            for(let device of devices){
                let testBatchDevice = await (new Parse.Query(db.classes.TestBatchDevice))
                    .equalTo(db.TestBatchDevice.DEVICE, device)
                    .include(db.TestBatchDevice.BATCH)
                    .first();

                testBatchDevices.push(testBatchDevice);
            }

            this.setState({devices, testBatchDevices});
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    componentDidMount(){
        if(this.props.match.params.roomId){
            this.loadDevices();
        }
        this.getRoom(this.props.match.params.roomId)
    }

    getRoom(roomId){
        let query = new Parse.Query('Room');

        query.include(db.Room.HOME);
        query.include(`${db.Room.HOME}.${db.Home.OWNER}`);

        query.get(roomId)
            .then(room => this.setState({room: room, roomStateFlag: room.get(db.Room.STATE_FLAG)}))
            .catch(console.error);
    }

    getDevices() {
        return this.state.devices;
    }

    onDeviceOpen(e, cell, row) {
        this.props.history.push(`/devices/${row.objectId}`);
    }

    onDeviceDelete(e, cell, row) {
        alert('should delete device')
    }

    onDeviceMove(e, cell, row) {
        alert('should move device')
    }

    actionButtons(cell, row) {
        return <div>
            <Button outline size="sm" color="success" onClick={(e) => this.onDeviceOpen(e, cell, row)}>Show</Button>
            {/*<Button size="sm" color="danger" onClick={(e) => this.onDeviceDelete(e, cell, row)}>Delete</Button>
            <Button size="sm" color="warning" onClick={(e) => this.onDeviceMove(e, cell, row)}>Move</Button>3*/}
        </div>;
    }

    setStateToRoom(){
        this.state.room.set(db.Room.STATE_FLAG, this.state.roomStateFlag);

        return this.state.room.save()
            .then(() => {

                return this.getRoom(this.props.match.params.roomId);
            })
            .then(() => {
                swal('Completed', '', 'success');
            });
    }

    onInputChange(event){
        event.persist();
        this.setState(prev => {
            if(!event.target) return ;
            _.set(prev, event.target.name, event.target.value);

            return prev;
        });
    }

    getTempWeekOverview(){
        return Parse.Cloud.run('room-temp-mean-by-day-of-week-and-hour', {
            roomId: this.props.match.params.roomId,
            startDate: this.state.startDate.startOf('day').toDate(),
            endDate: this.state.endDate.endOf('day').toDate()
        })
            .then(measures => {
                this.setState({measures});
            })
            .catch(err => console.error(err));
    }

    onStartDateChange(date){
        this.setState({startDate: date})
    }

    onEndDateChange(date){
        this.setState({endDate: date})
    }

    onDownloadCsv(){
        let csvContent = 'data:text/csv;charset=utf-8,';

        csvContent += `Day,${[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23].join(',')}\n`;

        Object.keys(this.state.measures).forEach((day) => {
            let dataString = `${dayMap(day)},${this.state.measures[day].map(hour => hour.avgTemp.toFixed(1)).join(',')}\n`;
            csvContent += dataString;
        });

        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }


    async moveListDevicesToRoom(home, room, serials){
        async function findDevices(serials){
            let query = new Parse.Query(db.classes.Device);

            query.containedIn(db.Device.SERIAL_NUMBER, serials);
            query.limit(1000);

            let devices = query.find();

            return devices;
        }

        try {
            assert(room != null, 'Room required');
            assert(home != null, 'Home required');
            assert(serials != null, 'Devices required');
            assert(serials.length > 0, 'Serials required');

            let devices = await findDevices(serials);

            let devicesToSave = [];

            for (let device of devices) {

                devicesToSave.push(moveDeviceToRoom(device, room));
            }

            await Parse.Object.saveAll(devicesToSave);

            swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message, 'error');
        }
    }

    async moveDevicesToRoom(home, deviceRooms){
        let devicesToSave = [];
        let roomsToSave = [];

        for(let deviceRoom of deviceRooms){
            let room = deviceRoom.room;
            let serial = deviceRoom.deviceSerial;
            let uuid = deviceRoom.uuid;

            if(!serial){
                let device = await findDevice(serial);
                devicesToSave.push(moveDeviceToRoom(device, room));
            }
        }

        await Parse.Object.saveAll(devicesToSave);
        await Parse.Object.saveAll(roomsToSave);

        await swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
    }

    render() {
        const selectRow = { mode: 'checkbox' };

        return (
            <div>
                <LinkRoomModal
                    save={this.moveListDevicesToRoom}
                    cancel={() => {}}
                    setToggleModal={toggleModal => this.toggleLinkRoomModal = toggleModal}
                />
                <LinkDeviceRoomModal
                    save={this.moveDevicesToRoom}
                    cancel={() => {}}
                    setToggleModal={toggleModal => this.toggleLinkDeviceRoomModal = toggleModal}
                />


                <h1>Devices {this.state.room ? 'for room ' + this.state.room.get('roomName') : ''}</h1>

                <Button outline color="warning" size="sm" onClick={() => this.toggleLinkRoomModal()}>
                    Move devices to room
                </Button>
                <Button outline color="warning" size="sm" onClick={() => this.toggleLinkDeviceRoomModal()}>
                    Move device to room list
                </Button>
                <Row style={{marginTop: 20}}>
                    <Col md={6}>
                        <Form>
                            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                <Label for="serial" className="mr-sm-2">Serial/Last 4 digit Mac Address: </Label>
                                <Input type="text" name="serial" id="serial" placeholder="ex. 1900457 or C1:f3"
                                    valie={this.state.searchSerial || ''}
                                    onKeyPress={event => {
                                        if(event.key === 'Enter'){
                                            event.preventDefault();
                                            this.loadDevices();
                                        }
                                    }}
                                    onChange={(e) => {
                                        this.setState({searchSerial: e.target.value});
                                    }}
                                />
                            </FormGroup>
                            <Button outline color={'primary'} size={'sm'} onClick={() => this.loadDevices()}>Search</Button>
                        </Form>
                    </Col>
                </Row>


                {
                    this.state.room &&
                    this.state.room.get(db.Room.HOME) &&
                    <h3>Home <a href={`/homes/${this.state.room.get(db.Room.HOME).id}/rooms`}>
                        {
                            this.state.room.get(db.Room.HOME).get(db.Home.HOME_NAME) ||
                            (this.state.room.get(db.Room.HOME).get(db.Home.OWNER) &&
                            this.state.room.get(db.Room.HOME).get(db.Home.OWNER).get(db._User.USERNAME)) ||
                            'Go to home'
                        }
                    </a></h3>
                }

                {
                    this.state.room && <div>
                        <Row>
                            <Col sm={2}>
                                <Label>Set state flag</Label>
                            </Col>
                            <Col sm={3}>
                                <Input type="select"
                                    name={'roomStateFlag'}
                                    onChange={this.onInputChange}
                                    value={this.state.roomStateFlag}>
                                    <option value={'online'}>online</option>
                                    <option value={'work-in-progress'}>work-in-progress</option>
                                    <option value={'client-action-required'}>client-action-required</option>
                                    <option value={'inactive'}>inactive</option>
                                </Input>
                            </Col>
                            <Col sm={4}>
                                <Button outline color="secondary" size="sm" onClick={this.setStateToRoom}>
                                    Set room state
                                </Button>
                            </Col>
                        </Row>
                        <br/><br/>
                        <hr/>
                        <br/><br/>


                    </div>
                }

                <Table>
                    <thead>
                        <tr>
                            <th>Device id</th>
                            <th>Serial</th>
                            <th>Mac address</th>
                            <td>Type</td>
                            <th>ICCID</th>
                            <th>Firmware version</th>
                            <th>Chip version</th>
                            <th>Room</th>
                            <th>Building</th>
                            <th>Batch</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.devices.map((device, i) => {
                                let room = device.get(db.Device.ROOM_ID);
                                let building = device.get(db.Device.HOME);
                                let testBatch = this.state.testBatchDevices[i] &&
                                    this.state.testBatchDevices[i].get(db.TestBatchDevice.BATCH)
                                let testBatchName = testBatch && testBatch.get(db.TestBatch.NAME);
                                let testBatchId = testBatch && testBatch.id;
                                let firmwareVersion = device.get(db.Device.VERSION_FIRMWARE) || '';
                                let chipVersion = device.get(db.Device.NB_IOT_FIRMWARE_VERSION) || '';
                                let isWNBP = isWNBPThermo(device);
                                let isWNB = isWNBThermo(device);

                                return <tr key={device.id}>
                                    <td><Link to={`/devices/${device.id}`} target={'_blank'}>{device.id}</Link></td>
                                    <td>{device.get(db.Device.SERIAL_NUMBER)}</td>
                                    <td>{device.get(db.Device.MAC_ADDRESS)}</td>
                                    <td>
                                        {isWNBP && <span>WNBP</span>}
                                        {isWNB && <span>WNB</span>}
                                    </td>
                                    <td>{device.get(db.Device.ICCID)}</td>
                                    <td>{firmwareVersion}</td>
                                    <td>{chipVersion}</td>
                                    <td>
                                        {/* eslint-disable-next-line max-len */}
                                        <Link to={`${paths.roomTemperatureChart.replace(':homeId', building && building.id)}?selectedRoomId=${room && room.id}`}>{room && room.get(db.Room.ROOM_NAME)}</Link>
                                    </td>
                                    {/* eslint-disable-next-line max-len */}
                                    <td><Link to={`/homes/${building && building.id}/rooms`}>{building && building.get(db.Home.HOME_NAME)}</Link></td>
                                    {/* eslint-disable-next-line max-len */}
                                    <td><Link to={`https://leanmanagement.cleveron.ch/batch/${testBatchId}/edit`}>{testBatchName}</Link></td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>

                <hr/>
                {this.state.room && <h1>Weekly temperature average</h1>}
                {
                    this.state.room && <Row>
                        <Col sm={4}>
                            <Button outline onClick={this.getTempWeekOverview}>Get temp data</Button>
                            <Button outline onClick={this.onDownloadCsv}>Download csv</Button>
                        </Col>
                        <Col sm={12} style={{marginTop: 20}}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Day</th>
                                        {
                                            [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23].map(day =>
                                                <th key={day}>{day}</th>
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.measures && Object.keys(this.state.measures).map(day =>
                                            <tr key={day}>
                                                <td>{dayMap(day)}</td>
                                                {
                                                    this.state.measures[day].map((hour, i) => {
                                                        if(!hour) return <td>N/A</td>;

                                                        return <td key={i}>{hour.avgTemp.toFixed(1)}({hour.count})</td>;
                                                    })
                                                }
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                }

            </div>
        )
    }
}