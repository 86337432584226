import React, {Component} from 'react';
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';

export default class AddCustomCommandModal extends Component {
    constructor(props) {
        super(props);

        let ledConfig = {
            green: {
                duration: 60000,
                dutyCycle: 1
            },
            yellow: {
                duration: 60000,
                dutyCycle: 0.7
            },
            red: {
                duration: 60000,
                dutyCycle: 0.7
            },
        };

        this.state = {
            isModalOpen: false,
            ledConfig: props.config ?  JSON.stringify(props.config, null, 2) : JSON.stringify(ledConfig, null, 2)
        };

        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount(){
        this.props.setToggleModal(this.toggleModal);
    }

    toggleModal() {
        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    render() {

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Custom Led config
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <FormGroup>
                            <Label for={'data'}>Config</Label>
                            <Input
                                type={'textarea'}
                                name={'duration'}
                                value={this.state.ledConfig}
                                onChange={(e) => {
                                    this.setState({ledConfig: e.target.value})
                                }}
                            ></Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={() => {
                            this.setState({error: false, errorMessage: null});

                            try{
                                JSON.stringify(JSON.parse(this.state.ledConfig), null, 2)
                            } catch (e) {
                                return this.setState({error: true, errorMessage: e.message});
                            }

                            this.props.save(this.state.ledConfig)
                                .then(() => this.toggleModal())
                                .catch(err => {
                                    this.setState({error: true, errorMessage: err.message});
                                })
                        }}>Save config</Button>
                        <Button outline color="secondary" onClick={() => {
                            this.toggleModal();
                        }}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

AddCustomCommandModal.propTypes = {
    save: PropTypes.func,
    cancel: PropTypes.func,
    setToggleModal: PropTypes.func,
    config: PropTypes.object
};