import React, {Component} from 'react';
import Parse from 'parse';
import {
    constructChangePeerListCommand,
    constructStartDiscoveryNowCommand,
    createCommandToDevices, getMotorErrorsFromDevice,
    getSenspsOfBuilding,
    getStringFromDevice,
    getThermOfBuilding, groupThermosByMotorError,
    isAValidRoom,
    isRolesInRoles,
    isUserSureToContinue
} from '../../lib/util';
import db from '../../lib/dbStructure';
import moment from 'moment';
import {
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Alert,
    Badge,
    Button,
    Input,
    Label,
    UncontrolledAccordion
} from 'reactstrap';
import Loader from '../loader';
import Select from 'react-select';
import swal from "sweetalert";
import _ from 'lodash';
import AddCustomCommandModal from "../DeviceView/DeviceViewTabs/add-custom-command-modal";

let roomAdded = [];
let homeAdded = [];

function round(x, n) {
    var a = Math.pow(10, n);
    return (Math.round(x * a) / a);
}

function filterDeviceType(devices, type, reserve = false){
    return devices
        .filter(device => {
            let room = device.get(db.Device.ROOM_ID);

            if(!room) return false;

            if(!isAValidRoom(room, reserve)) return false;

            return true;
        })
        .filter(device => device.get(db.Device.DEVICE_TYP) === type);
}

function filterOffline(devices, unit = 'minutes', number = 10){
    return devices.filter(device => {
        let lastMeasurementDate = device.get(db.Device.LAST_MEASUREMENT_DATE);
        let deviceTyp = device.get(db.Device.DEVICE_TYP);

        if(!lastMeasurementDate) return true;

        lastMeasurementDate = moment(lastMeasurementDate);

        let diff=  Math.abs(lastMeasurementDate.diff(moment(), unit));

        if(deviceTyp === db.Device.DEVICE_TYP$THERM){
            return diff > 600;
        }

        return diff > number;
    });
}

function filterDischargedThermo(devices){
    return devices.filter(device => {
        let lastMeasurementDate = device.get(db.Device.LAST_MEASUREMENT_DATE);
        let deviceTyp = device.get(db.Device.DEVICE_TYP);
        let batteryVoltage = device.get(db.Device.BATTERY_VOLTAGE);

        let minimumBattery = 3.5;

        if(batteryVoltage < minimumBattery) return true;

        return false;
    });
}

function isWNBP(device){
    let deviceTyp = device.get(db.Device.DEVICE_TYP);
    let co2 = device.get(db.Device.CO2);
    if(deviceTyp === db.Device.DEVICE_TYP$THERM && co2 != null && co2 >= 1){
        return true;
    }

    return false;
}

function isWNB(device){
    let deviceTyp = device.get(db.Device.DEVICE_TYP);
    let co2 = device.get(db.Device.CO2);
    if(deviceTyp === db.Device.DEVICE_TYP$THERM && (co2 == null || co2 <= 0)){
        return true;
    }

    return false;
}

function getVersionFirmwareFromDevices(devices){
    let mapVersionNumber=  {}
    devices.forEach(device => {
        let firmwareVersion = device.get(db.Device.VERSION_FIRMWARE);

        if(!firmwareVersion) return;

        firmwareVersion = firmwareVersion.replace('-therm', '');
        firmwareVersion = firmwareVersion.replace('-sensp', '');

        if(mapVersionNumber[firmwareVersion] == null){
            mapVersionNumber[firmwareVersion] = 0;
        }
        mapVersionNumber[firmwareVersion]++;
    });

    return Object.keys(mapVersionNumber).map(key => {
        return `${key}(${mapVersionNumber[key]})`;
        /*return {
            firmwareVersion: key,
            number: mapVersionNumber[key]
        }*/
    });
}

function getBatteryMapFromDevices(devices){
    let batteryMap = {};
    let lowerBandBattery = 2.7;
    let highBandBattery = 4.5;
    let interval = 0.5;
    let numberThresholds = Math.round((highBandBattery - highBandBattery) / interval) + 1;

    for(let device of devices){
        let batteryVoltage = device.get(db.Device.BATTERY_VOLTAGE);
        for(let i =0; i<=numberThresholds; i++){
            let lowerValue = lowerBandBattery + (i * interval);
            let higherValue = lowerBandBattery + ((i + 1) * interval);

            if(
                batteryVoltage > lowerValue &&
                batteryVoltage < higherValue
            ){
                let lowerRounded = lowerValue.toFixed(1);
                let higherRounded = higherValue.toFixed(1);
                let key = `${lowerRounded}V-${higherRounded}V`;

                if(batteryMap[key] == null) batteryMap[key] = 0;

                batteryMap[key]++;
            }
        }
    }

    return batteryMap;
}

function getPointerField(object, pointerKey, pointerSubKey){
    let pointer = object.get(pointerKey);
    let subValue = pointer && pointer.get(pointerSubKey);

    return subValue;
}

function createLinkFromRooms(home, rooms){
    return <a target={'_blank'}
              href={`/homes/${home.id}/room-temperature-chart?selectedRoomId=${rooms.map(room => room.id).join(',')}`}>
        Link charts
    </a>;
}

function createLinkListRoomsCsv(home, rooms){
    let string = '';

    string += ['Room name', 'Room code', 'floor'].join(',') + '\n';

    rooms.forEach(room => {
        let roomName = `${room.get(db.Room.ROOM_NAME)}` || '';
        let roomCode = room.get(db.Room.ROOM_CODE) || '';
        let floor = room.get(db.Room.FLOOR) || '';

        string += [roomName, roomCode, '' + floor].join(',') + '\n';
    });

    return <a target={'_blank'}
              href={`javascript: ;`}
              onClick={() => download(`discharged_rooms_${home.get(db.Home.HOME_NAME)}.csv`, string)}
    >
        Download list rooms
    </a>;
}

function createLinkListDevicesCsv(devices, home, name){
    let rows = [];
    devices.forEach(device => {
        let room = device.get(db.Device.ROOM_ID);
        let roomName = room?.get(db.Room.ROOM_NAME);
        let roomCode = room?.get(db.Room.ROOM_CODE);
        let floor = room?.get(db.Room.FLOOR);

        let serialNumber = device.get(db.Device.SERIAL_NUMBER);
        let batteryVoltage = device.get(db.Device.BATTERY_VOLTAGE);
        let deviceType = device.get(db.Device.DEVICE_TYP);

        rows.push({
            serialNumber,
            batteryVoltage,
            deviceType,
            roomName,
            roomCode,
            floor
        });
    });

    let string = '';
    rows.forEach((row, i) => {
        if(i === 0){
            string += `${Object.keys(row).join(',')}\n`;
        }
        string += Object.keys(row).map(key => row[key]).join(',') + '\n';
    });

    let fileName = name != null ?
        `${name}.${home.get(db.Home.HOME_NAME)}.csv` :
        `discharged_devices_${home.get(db.Home.HOME_NAME)}.csv`;

    return <a target={'_blank'}
              href={`javascript: ;`}
              onClick={() => download(fileName, string)}
    >
        Download list devices
    </a>;
}

function download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}


function getIccidFromDevices(devices){
    return devices.map(device => {
        let iccid = device.get(db.Device.ICCID);

        if(iccid == null) return null;
        if(iccid.length !== 20) return null;

        return iccid;
    }).filter(item => !!item);
}

export default class HomeOverview extends Component {
     constructor(props) {
        super(props);

        this.state = {
            graphData: null,
            showGraph: false,
            loading: {
                loadMeasurement: false
            },
            devices: [],
            home: {
                get(){}
            }
        };

        this.loadMeasurementOverview = this.loadMeasurementOverview.bind(this);
        this.addMeasurementIntoNodes = this.addMeasurementIntoNodes.bind(this);
        this.getBatchIds = this.getBatchIds.bind(this);
        this.setAllDevicesInMountMode = this.setAllDevicesInMountMode.bind(this);
        this.setAllDeviceInOnlineMode = this.setAllDeviceInOnlineMode.bind(this);
        this.setPeerListToSensorWithThermoInRoom = this.setPeerListToSensorWithThermoInRoom.bind(this);
        this.sendDiscoveryNowAllSensors = this.sendDiscoveryNowAllSensors.bind(this);
        this.resetUpdateCounterOnDevices = this.resetUpdateCounterOnDevices.bind(this);


        function hashLinkScroll() {
            const { hash } = window.location;
            if (hash !== '') {
                // Push onto callback queue so it runs after the DOM is updated,
                // this is required when navigating from a different page so that
                // the element is rendered on the page before trying to getElementById.
                setTimeout(() => {
                    const id = hash.replace('#', '');
                    const element = document.getElementById(id);
                    if (element) element.scrollIntoView();
                }, 0);
            }
        }

        try {
            this.getHome(this.props.match.params.homeId)
                .then((home) => {
                    this.setState({home});
                    document.title = `${home.get(db.Home.HOME_NAME)}`;
                    hashLinkScroll()
                })
                .catch(err => console.error(err));
        }catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    addMeasurementIntoNodes(graphData, measurement, device){

        if(measurement){
            let data = this.getDataFromMeasurement(measurement, device);
            let roomName = 'Not defined';

            if(measurement.get(db.Measurement.ROOM_ID))
                roomName = measurement.get(db.Measurement.ROOM_ID).get(db.Room.ROOM_NAME);

            let homeName = 'Default';

            if(measurement.get(db.Measurement.ROOM_ID)) {
                let home = measurement.get(db.Measurement.ROOM_ID).get(db.Room.HOME);
                if (home)
                    homeName = home.get('homeName');
            }

            let roomStateFlag = measurement.get(db.Measurement.ROOM_ID) && measurement.get(db.Measurement.ROOM_ID).get(db.Room.STATE_FLAG);
            let deviceStateFlag = measurement.get(db.Measurement.DEVICE_ID).get(db.Device.DEVICE_STATE_FLAG);

            let flag;

            if(roomStateFlag === 'online' || roomStateFlag == null){
                flag = deviceStateFlag || 'online'
            } else {
                flag = roomStateFlag || deviceStateFlag || 'online';
            }


            graphData.nodes.push({
                id: data.deviceMacAddress, time: data.diffMinutes, type: data.deviceTyp, flag: flag
            });

            roomName = roomName.split(' ').join('-');
            if(roomAdded.indexOf(roomName) === -1) {
                roomAdded.push(roomName);
                graphData.nodes.push({
                    id: roomName, time: 0, type: 'room'
                });
            }

            if(homeAdded.indexOf(homeName) === -1) {
                homeAdded.push(homeName);
                graphData.nodes.push({
                    id: homeName, time: 0, type: 'home'
                });


            }

            graphData.links.push({
                source: homeName, target: roomName
            });

            graphData.links.push({
                source: roomName, target: data.deviceMacAddress
            });
        } else {
            console.log('No measurement');
        }

    }

    getRoomNameString(room){
        return`P${room.get(db.Room.FLOOR)}-${room.get(db.Room.ROOM_NAME)}(${room.get(db.Room.ROOM_CODE) || ''})-R${room.get('numberRadiators') || '-'}-S${room.get(db.Room.NUMBER_SENSE) || '-'}` ;
    }

    async loadMeasurementOverview() {
        roomAdded = [];
        homeAdded = [];
        let roomsDevices = {};

        this.setState({showGraph: false, loading: {loadMeasurement: true}});

        let query = new Parse.Query(db.classes.Device);
        query.include(db.Device.ROOM_ID);
        query.include(db.Device.PEER_LIST);
        query.include(db.Device.NEIGHBORS_LIST);
        /*query.select([
            `${db.Device.ROOM_ID}.${db.Room.ROOM_NAME}`,
            `${db.Device.ROOM_ID}.${db.Room.FLOOR}`,
            `${db.Device.NEIGHBORS_LIST}.${db.Device.MAC_ADDRESS}`,
            `${db.Device.PEER_LIST}.${db.Device.MAC_ADDRESS}`,
            db.Device.VERSION_FIRMWARE,
            db.Device.NEIGHBORS_WIFI_STRENGTH,
            db.Device.DEVICE_STATE_FLAG,
            db.Device.MOTOR_SPEED,

            db.Device.BATTERY_VOLTAGE,
            db.Device.LAST_MEASUREMENT_DATE,
            db.Device.DEVICE_TYP,
            db.Device.SERIAL_NUMBER,
            db.Device.WIFI_STRENGTH,
            db.Device.CONFIG_WIFI_SSID,
            db.Device.CONFIG_WIFI_PASSWORD,
            db.Device.CONNECTION_WIFI_SSID,
            db.Device.CONNECTION_WIFI_PASSWORD,
            db.Device.MODE,
            db.Device.CORRECTION_TEMP,
            db.Device.MEASURED_TEMP,
            db.Device.MOTOR_POSITION,
            db.Device.MOTOR_CURRENT_LIMITS,
            db.Device.MOUNTED_ENGINE,
            db.Device.HEADER_X_REQUEST_PORT,
            db.Device.HEADER_REQUEST_HOST,
            db.Device.NUMBER_MOTOR_RESPONSE_VALUE,
            db.Device.MOTOR_TAG,
            db.Device.NUMBER_COMMAND_VALUE,
            db.Device.DEFECT_PREDICTION,
            db.Device.NB_IOT_ENABLED,
            db.Device.ICCID,
            db.Device.NB_IOT_SIGNAL_QUALITY,
            db.Device.MAC_ADDRESS,
            db.Device.REFURBISHED_DATE,
            db.Device.FIRMWARE_UPDATE_STATUS,
            db.Device.DEVICE_CONFIG,
            db.Device.NEIGHBORS_LIST,
            db.Device.LAST_CONNECTION_PROTOCOL,
            db.Device.NB_IOT_FIRMWARE_VERSION
        ])*/
        query.equalTo(db.Device.HOME, this.state.home);
        query.limit(10000);
        //if(this.state.floor != null)
        //    query.equalTo(`${db.Device.ROOM_ID}.${db.Room.FLOOR}`, this.state.floor.value);

        try {
            let devices = await query.find();

            devices = devices.filter(device => device.get(db.Device.ROOM_ID) != null);

            if (this.state.floor != null)
                devices = devices.filter(device => device.get(db.Device.ROOM_ID).get(db.Room.FLOOR) === this.state.floor.value);

            if (this.state.matchText)
                devices = devices.filter(device => device.get(db.Device.ROOM_ID).get(db.Room.ROOM_NAME).includes(this.state.matchText));

            if (this.state.matchTag)
                devices = devices.filter(device => {
                    let tag = device.get(db.Device.TAG);
                    if(!tag) return false;

                    let tags = tag.split(' ');
                    let matchTags = this.state.matchTag.split(' ');

                    let all = true;

                    for(let matchTag of matchTags){
                        if(tags.indexOf(matchTag) < 0){
                            all = false;
                        }
                    }

                    return all;
                });

            this.setState({devices: devices});
            let promises = [];

            devices = devices.sort((a, b) => {
                let roomA = a.get(db.Device.ROOM_ID);
                let roomB = b.get(db.Device.ROOM_ID);

                if (!roomA || !roomB) return 0;

                return roomB.get(db.Room.FLOOR) - roomA.get(db.Room.FLOOR);
            });

            devices.forEach(device => {
                let roomName = 'Not defined';
                let room = device.get(db.Device.ROOM_ID);

                if (!room) {
                    console.log(`Device ${device.get(db.Device.SERIAL_NUMBER)} does not have a room`);
                    return;
                }


                if (!roomsDevices[room.id])
                    roomsDevices[room.id] = {
                        room: room,
                        devices: [device]
                    };
                else
                    roomsDevices[room.id].devices.push(device);
            });
            let mapDeviceBatch = await this.getBatchIds(devices);
            let sensors = devices.filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$SENSP);
            let mapPeerToSensor = {};

            sensors.forEach(sensor => {
                let serialNumber = sensor.get(db.Device.SERIAL_NUMBER);
                let room = sensor.get(db.Device.ROOM_ID);
                let peerList = sensor.get(db.Device.PEER_LIST) || [];

                peerList.forEach(peer => {
                    mapPeerToSensor[peer.id] = sensor;
                });
            });

            let mapNeighborsToRoomsWithWifiStrength = {};

            /*
            sensors.forEach(sensor => {
                let room = sensor.get(db.Device.ROOM_ID);
                let wifiStrengthList = sensor.get(db.Device.NEIGHBORS_WIFI_STRENGTH);

                if(!wifiStrengthList) return;

                Object.keys(wifiStrengthList).forEach(deviceId => {
                    let wifiStrength = wifiStrengthList[deviceId];
                    if(mapNeighborsToRoomsWithWifiStrength[deviceId] == null){
                        mapNeighborsToRoomsWithWifiStrength[deviceId] = [];
                    }
                    mapNeighborsToRoomsWithWifiStrength[deviceId].push({
                        room,
                        wifiStrength
                    });
                });
            });*/

            mapPeerToSensor = {};
            mapNeighborsToRoomsWithWifiStrength = {};

            this.setState({roomsDevices, loading: {loadMeasurement: false}, mapDeviceBatch, mapPeerToSensor, mapNeighborsToRoomsWithWifiStrength});
        } catch (err) {
            this.setState({loading: {loadMeasurement: false}});
            console.error(err);
        }
    }

    async getHome(homeId){
        let home = await (new Parse.Query(db.classes.Home)).get(homeId);

        return home;
    }

    async getBatchIds(devices) {
        let mapDeviceBatch = {};

        let query = new Parse.Query(db.classes.TestBatchDevice);
        query.include(db.TestBatchDevice.BATCH);
        query.containedIn(db.TestBatchDevice.DEVICE, devices);
        query.limit(100000);
        let testBatchDevices = await query.find();

        testBatchDevices.forEach(testBatchDevice => {
            let deviceId = testBatchDevice.get(db.TestBatchDevice.DEVICE).id;

            mapDeviceBatch[deviceId] = testBatchDevice.get(db.TestBatchDevice.BATCH) && testBatchDevice.get(db.TestBatchDevice.BATCH).get(db.TestBatch.NAME);
        });

        return mapDeviceBatch;
    };

    async setAllDevicesInMountMode(){
        let query = new Parse.Query('Device');
        query.include(db.Device.ROOM_ID);
        query.equalTo(db.Device.HOME, this.state.home);
        query.include('roomId.home');
        query.limit(100000);

        let devices = await query.find();

        let devicesToSave = [];
        for(let device of devices){
            device.set(db.Device.DEVICE_STATE_FLAG, db.Device.DEVICE_STATE_FLAG$IN_MOUNT);
            devicesToSave.push(device);
        }

        await Parse.Object.saveAll(devicesToSave);

        await swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
    }

    async setAllDeviceInOnlineMode(){
        let query = new Parse.Query('Device');
        query.include(db.Device.ROOM_ID);
        query.equalTo(db.Device.HOME, this.state.home);
        query.include('roomId.home');
        query.limit(100000);

        let devices = await query.find();

        let devicesToSave = [];
        for(let device of devices){
            device.set(db.Device.DEVICE_STATE_FLAG, db.Device.DEVICE_STATE_FLAG$ONLINE);
            devicesToSave.push(device);
        }

        await Parse.Object.saveAll(devicesToSave);

        await swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
    }

    async sendDiscoveryNowAllSensors(){
        let sendStartMeshModeToSensors = async (sensps) => {
            let minutes = await swal({
                heightAuto: false,
                title: 'How many minutes? Default 10 minutes.',
                content: {
                    element: "input",
                    attributes: {
                        placeholder: "Type the minutes",
                        type: "number",
                        value: 10
                    },
                }
            });

            if(!minutes) minutes = 10;

            let commandsToBeSaved = [];

            for(let sensp of sensps){
                let commands = await new Parse.Query('CommandQueue')
                    .notEqualTo(db.CommandQueue.DELETED, true)
                    .equalTo(db.CommandQueue.DEVICE, sensp)
                    .find();

                let isPeerListCommandAlreadyInQueue = commands.filter(command => command.get(db.CommandQueue.COMMAND_NAME) === db.commands.CHANGE_PEER_LIST).length !== 0;
                let isStartNowDiscoveryCommandAlreadyInQueue = commands.filter(command => command.get(db.CommandQueue.COMMAND_NAME) === db.commands.START_NOW_DISCOVERY).length !== 0;

                if(isStartNowDiscoveryCommandAlreadyInQueue){
                    let startNowDiscoveryCommand = commands.filter(command => command.get(db.CommandQueue.COMMAND_NAME) === db.commands.START_NOW_DISCOVERY)[0];
                    startNowDiscoveryCommand.set(db.CommandQueue.DELETED, true);
                    await startNowDiscoveryCommand.save();
                } else {
                    commandsToBeSaved.push(constructStartDiscoveryNowCommand(sensp, minutes));
                }
            }

            await Parse.Object.saveAll(commandsToBeSaved);
        };

        try {
            if(!this.state.home) throw Error('No home available');

            await isUserSureToContinue();

            let sensps = await getSenspsOfBuilding(this.state.home.id);

            await sendStartMeshModeToSensors(sensps);

            await swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async sendForceStopHeatAllThermos(){


        try {
            await isUserSureToContinue();

            const thermos = await getThermOfBuilding(this.state.home.id);

            createCommandToDevices(thermos, command)

            swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
        } catch(e){
            console.error(e);
            swal('Error', e.message, 'error');
        }

    }

    async sendCalibrateCommandAllThermos(){

    }

    async setPeerListToSensorWithThermoInRoom(){
        let sendChangePeerListCommandToSensors = async (sensps) => {
            let getRoomDevices = async (room) => {
                let roomDevices = await new Parse.Query(db.classes.Device)
                    .equalTo(db.Device.ROOM_ID, room)
                    .equalTo(db.Device.DEVICE_TYP, db.Device.DEVICE_TYP$THERM)
                    .limit(100)
                    .find();

                return roomDevices;
            }

            let commandsToBeSaved = [];

            for(let sensp of sensps){
                let room = sensp.get(db.Device.ROOM_ID);

                let roomDevices = await getRoomDevices(room);
                if(roomDevices.length <= 0) continue;

                let commands = await new Parse.Query('CommandQueue')
                    .notEqualTo(db.CommandQueue.DELETED, true)
                    .equalTo(db.CommandQueue.DEVICE, sensp)
                    .find();

                let isPeerListCommandAlreadyInQueue = commands.filter(command => command.get(db.CommandQueue.COMMAND_NAME) === db.commands.CHANGE_PEER_LIST).length !== 0;

                if(isPeerListCommandAlreadyInQueue){
                    let startNowDiscoveryCommand = commands.filter(command => command.get(db.CommandQueue.COMMAND_NAME) === db.commands.CHANGE_PEER_LIST)[0];

                    startNowDiscoveryCommand.set(db.CommandQueue.DELETED, true);
                    await startNowDiscoveryCommand.save();

                    commandsToBeSaved.push(constructChangePeerListCommand(sensp, roomDevices));
                } else {
                    commandsToBeSaved.push(constructChangePeerListCommand(sensp, roomDevices));
                }
            }

            await Parse.Object.saveAll(commandsToBeSaved);
        };

        try {
            if(!this.state.home) throw Error('No home available');

            let sure = await swal({
                title: 'Are you sure?',
                text: 'This can cause all sensors to loose all the previous peer list configurations. Please be careful.',
                icon: 'warning',
                buttons: true,
                dangerMode: true
            });

            if (!sure) throw Error('Aborted by user');

            let sensps = await getSenspsOfBuilding(this.state.home.id);

            await sendChangePeerListCommandToSensors(sensps);

            await swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }


    async sendUpdateCommandToAllThermo(){
        let sensps = await getSenspsOfBuilding(this.state.home.id);
    }

    async resetUpdateCounterOnDevices(){
        let devicesToSave = [];

        this.state.devices.forEach(device => {
            device.unset(db.Device.NUMBER_UPDATE_COMMANDS_ON_CURRENT_SERIE);
            device.unset(db.Device.DATE_LAST_UPDATE_COMMANDS_SERIE);

            devicesToSave.push(device);
        });

        try {
            await Parse.Object.saveAll(devicesToSave);

            await swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async addCustomCommand(name, data, devices){
        try {
            if(!name)
                throw new Error('No name defined');

            if(!data)
                throw new Error('No data defined');

            if(devices == null)
                throw new Error('No devices');

            if(devices.length <= 0)
                throw new Error('No devices');

            try {
                data = JSON.parse(data);
            } catch (e) {
                throw new Error('Json not valid');
            }

            let sure = await swal({
                title: 'Are you sure?',
                text: 'Custom commands are not checked by the system and can potentially compromise their functions.',
                icon: 'warning',
                dangerMode: true,
                buttons: ['Cancel', 'Yes']
            });

            if (!sure) throw new Error('Aborted by user');

            let value = await swal("Please select what devices should receive a command", {
                buttons: {
                    cancel: "Cancel",
                    thermo: {
                        text: "Only to thermo devices",
                        value: "thermo",
                    },
                    sensp: {
                        text: "Only to sensp devices",
                        value: "sensp",
                    },
                    all: {
                        text: "Send command to all devices",
                        value: "all",
                    }
                }
            });
            let sensps = devices.filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$SENSP);
            let thermos = devices.filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$THERM);

            if(value === 'sensp'){
                devices = sensps;
            } else if (value === 'thermo'){
                devices = thermos;
            } else if (value === 'all'){
                devices = thermos.concat(sensps);
            } else {
                throw new Error('No selection found');
            }

            let commandsToSend = [];
            for(let device of devices){
                
                let commandQueue = new Parse.Object(db.classes.CommandQueue);
                commandQueue.set('commandName', name);
                commandQueue.set('device', device);
                commandQueue.set('data', data);
                commandQueue.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID));

                commandsToSend.push(commandQueue);
            }
            console.log(commandsToSend, commandsToSend.length);
            await Parse.Object.saveAll(commandsToSend);

            await swal('Success', '', 'success');
        } catch (e){
            console.error(e);
            await swal('Error', e.message, 'error');
        }
    }

    render(){
        let homeId = this.props.match.params.homeId;
        let home = this.state.home;
        let thermosWithReserve = filterDeviceType(this.state.devices, db.Device.DEVICE_TYP$THERM, true);
        let thermos = filterDeviceType(this.state.devices, db.Device.DEVICE_TYP$THERM);
        let sencos = filterDeviceType(this.state.devices, db.Device.DEVICE_TYP$SENCO);
        let senses = filterDeviceType(this.state.devices, db.Device.DEVICE_TYP$SENSE);
        let sensps = filterDeviceType(this.state.devices, db.Device.DEVICE_TYP$SENSP);
        let allThermos = this.state.devices
            .filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$THERM);
        let allSensps = this.state.devices
            .filter(device => device.get(db.Device.DEVICE_TYP) ===  db.Device.DEVICE_TYP$SENSP);
        let thermosWNBP = thermosWithReserve.filter(isWNBP);
        let thermosWNB = thermosWithReserve.filter(isWNB);


        let allDevices = this.state.devices;
        let mapDeviceBatch = this.state.mapDeviceBatch;


        let mapDeviceFirmware = {};

        allDevices.forEach(device => {
            let versionFirmware = device.get(db.Device.VERSION_FIRMWARE);
            if(!versionFirmware) return;

            if(mapDeviceFirmware[versionFirmware] == null)
                mapDeviceFirmware[versionFirmware] = [];

            mapDeviceFirmware[versionFirmware].push(device);
        })


        let testBathes = _.uniq(allDevices.map(device => mapDeviceBatch && mapDeviceBatch[device.id]));


        const OFFLINE_SENSOR_MIN = 20;
        const SIM_LOST_SENSOR_MIN = 60;
        const SIM_LOST_THERMO_MIN = 60;

        let offlineThermos = filterOffline(thermos);
        let offlineSensps = filterOffline(sensps, 'minutes', OFFLINE_SENSOR_MIN);

        let dischargedThermos = filterDischargedThermo(thermos);
        let batteryChargingThermos = thermos.filter(thermo => thermo.get(db.Device.BATTERY_IS_CHARGING) === true);
        let notChargingThermos = thermos.filter(thermo => thermo.get(db.Device.BATTERY_IS_CHARGING) !== true);
        let hotThermos = thermos.filter(thermo => thermo.get(db.Device.MEASURED_TEMP) > 25);
        let coldThermos = thermos.filter(thermo => thermo.get(db.Device.MEASURED_TEMP) < 18);
        let wnbpThermos = thermosWithReserve.filter(thermo => {
            let deviceTyp = thermo.get(db.Device.DEVICE_TYP);
            let co2 = thermo.get(db.Device.CO2);

            if(deviceTyp === db.Device.DEVICE_TYP$THERM && co2 != null && co2 >= 1){
                return true;
            }

            return false;
        });
        let wnbThermos = thermosWithReserve.filter(thermo => {
            let deviceTyp = thermo.get(db.Device.DEVICE_TYP);
            let co2 = thermo.get(db.Device.CO2);

            if(deviceTyp === db.Device.DEVICE_TYP$THERM && co2 == null){
                return true;
            }

            return false;
        });

        let simLostSensps = filterOffline(sensps, 'minutes', SIM_LOST_SENSOR_MIN);
        let simLostThermos = filterOffline(thermos, 'minutes', SIM_LOST_THERMO_MIN);

        let versionsOfflineThermos = getVersionFirmwareFromDevices(offlineThermos);
        let versionsOfflineSensors = getVersionFirmwareFromDevices(offlineSensps);

        let versionsThermos = getVersionFirmwareFromDevices(thermos);
        let versionsSensors = getVersionFirmwareFromDevices(sensps);

        let sensorsSimLostIccids = getIccidFromDevices(simLostSensps);
        let thermosSimLostIccids = getIccidFromDevices(simLostThermos);
        let allSensorsIccids = getIccidFromDevices(allSensps);

        let offlineThermoRooms = _.uniqBy(offlineThermos.map(device => device.get(db.Device.ROOM_ID)), 'id');
        let offlineSensorsRooms = _.uniqBy(offlineSensps.map(device => device.get(db.Device.ROOM_ID)), 'id');
        let simLostSensorRooms = _.uniqBy(simLostSensps.map(device => device.get(db.Device.ROOM_ID)), 'id');

        let discharedThermoRooms = _.uniqBy(dischargedThermos.map(device => device.get(db.Device.ROOM_ID)), 'id');
        let chargingRooms = _.uniqBy(batteryChargingThermos.map(device => device.get(db.Device.ROOM_ID)), 'id');
        let notCHhargingRooms = _.uniqBy(notChargingThermos.map(device => device.get(db.Device.ROOM_ID)), 'id');
        let hotThermoRooms = _.uniqBy(hotThermos.map(device => device.get(db.Device.ROOM_ID)), 'id');
        let coldThermoRooms = _.uniqBy(coldThermos.map(device => device.get(db.Device.ROOM_ID)), 'id');

        let offlineThermosWithOnlineSensorsRooms = _.differenceBy(offlineThermoRooms, offlineSensorsRooms, 'id')

        let lostSimSensorsString = `ICC\n${sensorsSimLostIccids.join('\n')}\nEND`;
        let lostSimThermosString = `ICC\n${thermosSimLostIccids.join('\n')}\nEND`;
        let allSensorsString = `ICC\n${allSensorsIccids.join('\n')}\nEND`;

        let batteryMapOfflineThermo = getBatteryMapFromDevices(offlineThermos);

        let thermosByMotorError = groupThermosByMotorError(allThermos);

        console.log(thermosByMotorError);

        function formatBatteryMap(batteryMapOfflineThermo){
            let keys = Object.keys(batteryMapOfflineThermo);

            return keys.map(key => `${key}: ${batteryMapOfflineThermo[key]}`).join(', ');
        }
        function formatListDevices(filteredDevices, allDevices, filteredRooms, batteryMapOfflineThermo, label){
            return <p>
                {label}: {filteredDevices.length} / {allDevices.length} ({Math.round(filteredDevices.length/allDevices.length *100)}%)
                {
                    batteryMapOfflineThermo && <>({formatBatteryMap(batteryMapOfflineThermo)})</>
                }&nbsp;
                ({filteredRooms.map(room => `${room.get(db.Room.ROOM_NAME)}`).join(',')})&nbsp;
                {createLinkFromRooms(home, filteredRooms)} &nbsp;
                {createLinkListRoomsCsv(home, filteredRooms)} &nbsp;
                {createLinkListDevicesCsv(filteredDevices, home)}
            </p>
        }

        let floors = [
            {
                value: -5,
                label: '-5'
            },
            {
                value: -4,
                label: '-4'
            },
            {
                value: -3,
                label: '-3'
            },
            {
                value: -2,
                label: '-2'
            },
            {
                value: -1,
                label: '-1'
            },
            {
                value: 0,
                label: '0'
            },
            {
                value: 1,
                label: '1'
            },
            {
                value: 2,
                label: '2'
            },
            {
                value: 3,
                label: '3'
            },
            {
                value: 4,
                label: '4'
            },
            {
                value: 5,
                label: '5'
            },
            {
                value: 6,
                label: '6'
            },
            {
                value: 7,
                label: '7'
            },
            {
                value: 8,
                label: '8'
            },
            {
                value: 9,
                label: '9'
            },
            {
                value: 10,
                label: '10'
            }
        ];
        const motorErrors = [
            '101',
            '102',
            '130',
            '-1'
        ];

        return (
            <div>
                <AddCustomCommandModal
                    save={(name, data) => this.addCustomCommand(name, data, this.state.devices)}
                    cancel={() => {
                    }}
                    setToggleModal={toggleModal => this.toggleAddCustomCommandModal = toggleModal}
                />
                <h1>{this.state.home && this.state.home.get(db.Home.HOME_NAME)} ({this.state.home && this.state.home.get(db.Home.CITY)})</h1>
                {
                    this.state.home && this.state.home.get(db.Home.SUMMER_MODE) &&
                    <Alert color={'warning'}>Warning! This building is in Summer mode.</Alert>
                }
                <Label>Room name:</Label>
                <Input type="text" value={this.state.matchText}
                       onChange={(e) => this.setState({matchText: e.target.value})}/>
                <Label>Tag:</Label>
                <Input type="text" value={this.state.matchTag}
                       onChange={(e) => this.setState({matchTag: e.target.value})}/>
                <Label>Floor:</Label>
                <Select
                    value={this.state.floor}
                    onChange={(floor) => this.setState({floor})}
                    options={floors}
                    isMulti={false}
                />
                <Button outline color={'primary'} onClick={this.loadMeasurementOverview}>Get measurement
                    overview</Button>
                <Button outline color={'primary'} onClick={this.setAllDevicesInMountMode}>Set all in mount mode</Button>
                <Button outline color={'primary'} onClick={this.setAllDeviceInOnlineMode}>Set all in online
                    mode</Button>

                {
                    isRolesInRoles(['Admin']) && <>
                        <Button outline color={'danger'} onClick={this.sendDiscoveryNowAllSensors}>Turn on discovery now all
                            sensors</Button>
                        <Button outline color={'danger'} onClick={this.setPeerListToSensorWithThermoInRoom}>Set thermo in
                            room in peer list of all sensors</Button>
                        <Button outline color={'danger'} onClick={this.resetUpdateCounterOnDevices}>Reset update counter on
                            devices</Button>
                        <Button outline color={'danger'} onClick={this.toggleAddCustomCommandModal}>Send custom
                            command</Button>

                    </>
                }

                <br/>
                {this.state.loading.loadMeasurement && <Loader/>}

                {
                    this.state.devices.length > 0 && <UncontrolledAccordion defaultOpen="1">
                        <AccordionItem>
                            <AccordionHeader targetId="1">Connection status info</AccordionHeader>
                            <AccordionBody accordionId="1">
                                {formatListDevices(offlineThermos, thermos, offlineThermoRooms, batteryMapOfflineThermo, `Offline thermos (${600}min)`)}
                                {formatListDevices(offlineSensps, allSensps, offlineSensorsRooms, null, `Offline sensors (${OFFLINE_SENSOR_MIN}min)`)}
                                {formatListDevices(simLostSensps, allSensps, simLostSensorRooms, null, `SIM lost sensors (${SIM_LOST_SENSOR_MIN}min)`)}
                                <p>
                                    Difference: {offlineThermosWithOnlineSensorsRooms.map(room => room.get(db.Room.ROOM_NAME)).join(',')}&nbsp;
                                    {createLinkFromRooms(home, offlineThermosWithOnlineSensorsRooms)}
                                </p>
                                Offline thermos versions: {versionsOfflineThermos.join(',')}<br/>
                                Offline sensors versions:{versionsOfflineSensors.join(',')}<br/>
                                Thermos versions: {versionsThermos.join(',')}<br/>
                                Sensors versions: {versionsSensors.join(',')}<br/>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="2">Temperature infos</AccordionHeader>
                            <AccordionBody accordionId="2">
                                {formatListDevices(hotThermos, thermos, hotThermoRooms, null, `Hot thermo >25°C`)}
                                List hot devices: {hotThermos.map(thermo => thermo.id).join(',')}
                                <hr/>
                                {formatListDevices(coldThermos, thermos, coldThermoRooms, null, `Cold thermo >25°C`)}
                                List cold devices: {coldThermos.map(thermo => thermo.id).join(',')}
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="3">Lost SIM ICCID</AccordionHeader>
                            <AccordionBody accordionId="3">
                                <p>
                                    SIM lost iccid Sensors:
                                    <br/>
                                    ICC<br/>
                                    {sensorsSimLostIccids.map(iccid => {
                                        return <><span>{iccid}</span><br/></>;
                                    })}
                                    END<br/>
                                    <a href={'javascript: ;'}
                                       onClick={() => download(`${home.get(db.Home.HOME_NAME).replaceAll(' ', '_')}_offline_sensors_${moment().format('DDMMYYYYHHmmss')}.txt`, lostSimSensorsString)}>Download</a>
                                </p>
                                <p>
                                    SIM lost iccid Thermos:
                                    <br/>
                                    ICC<br/>
                                    {thermosSimLostIccids.map(iccid => {
                                        return <><span>{iccid}</span><br/></>;
                                    })}
                                    END<br/>

                                    <a href={'javascript: ;'}
                                       onClick={() => download(`${home.get(db.Home.HOME_NAME).replaceAll(' ', '_')}_offline_thermos_${moment().format('DDMMYYYYHHmmss')}.txt`, lostSimThermosString)}>Download</a>
                                </p>

                                <a
                                    href={'javascript: ;'}
                                    onClick={() => download(`${home.get(db.Home.HOME_NAME).replaceAll(' ', '_')}_all_sensors_${moment().format('DDMMYYYYHHmmss')}.txt`, allSensorsString)}
                                >Downlaod all sensors ICCIDs</a>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="4">Battery status (lower 3.5V)</AccordionHeader>
                            <AccordionBody accordionId="4">
                                {formatListDevices(dischargedThermos, allThermos, discharedThermoRooms, null, 'Discharged thermos')}
                                <br/>

                                {formatListDevices(batteryChargingThermos, allThermos, chargingRooms, null, 'Currently charging')}
                                {formatListDevices(notChargingThermos, allThermos, notCHhargingRooms, null, 'Currently not charging')}
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="5">Motor errors</AccordionHeader>
                            <AccordionBody accordionId="5">
                                {
                                    motorErrors.map((motorError, i) => {
                                        if (!thermosByMotorError?.[motorError]) return '';

                                        let devices = thermosByMotorError?.[motorError]?.devices;

                                        //let filteredRooms = _.uniqBy(devices.map(device => device.get(db.Device.ROOM_ID)), 'id');

                                        return <div key={i}>
                                            {motorError} ({devices?.length})
                                            {createLinkListDevicesCsv(thermosByMotorError?.[motorError]?.devices ?? [], home, `device_motor_error_${motorError}`)}
                                        </div>
                                    })
                                }
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="6">Device list (MAC)</AccordionHeader>
                            <AccordionBody accordionId="6">
                                <h6>All thermo ({allThermos.length})</h6>
                                <div style={{maxWith: 800, wordWrap: 'break-word'}}>
                                    {allThermos.map(device => device?.get(db.Device.MAC_ADDRESS)).join(',')}
                                </div>
                                <br/>

                                <h6>All thermo ({allThermos.length}) without to configure room</h6>
                                <div style={{maxWith: 800, wordWrap: 'break-word'}}>
                                    {thermos.map(device => device?.get(db.Device.MAC_ADDRESS)).join(',')}
                                </div>
                                <br/>

                                <h6>WNBP thermos ({wnbpThermos.length}) (including reserve)</h6>
                                <div style={{maxWith: 800, wordWrap: 'break-word'}}>
                                    {wnbpThermos.map(device => device?.get(db.Device.SERIAL_NUMBER)).join(',')}
                                </div>
                                <br/>

                                <h6>WNB thermos ({wnbThermos.length}) (including reserve)</h6>
                                <div style={{maxWith: 800, wordWrap: 'break-word'}}>
                                    {wnbThermos.map(device => device?.get(db.Device.SERIAL_NUMBER)).join(',')}
                                </div>
                                <br/>

                                <h6>All sensps ({allSensps.length})</h6>
                                <div style={{maxWith: 800, wordWrap: 'break-word'}}>
                                    {allSensps.map(device => device?.get(db.Device.MAC_ADDRESS)).join(',')}
                                </div>
                                <br/>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="7">Device list (Ids)</AccordionHeader>
                            <AccordionBody accordionId="7">
                                <h6>All thermo ({allThermos.length})</h6>
                                <div style={{maxWith: 800, wordWrap: 'break-word'}}>
                                    {allThermos.map(device => device?.id).join(',')}
                                </div>
                                <br/>

                                <h6>All thermo ({allThermos.length}) without to configure room</h6>
                                <div style={{maxWith: 800, wordWrap: 'break-word'}}>
                                    {thermos.map(device => device?.id).join(',')}
                                </div>
                                <br/>

                                <h6>All sensps ({allSensps.length})</h6>
                                <div style={{maxWith: 800, wordWrap: 'break-word'}}>
                                    {allSensps.map(device => device?.id).join(',')}
                                </div>
                                <br/>
                            </AccordionBody>
                        </AccordionItem>
                    </UncontrolledAccordion>
                }

                {this.state.roomsDevices &&
                    <div>
                        {Object.keys(this.state.roomsDevices).map(roomId => {
                            let {room, devices} = this.state.roomsDevices[roomId];

                            let sensps = devices.filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$SENSP);
                            let sensp = sensps.length > 0 ? sensps[0] : null;

                            return <div key={room.id} className={'measurement-container'}>
                                <h5>
                                    <a href={`/rooms/${room.id}/devices`} target={'_blank'}>
                                        {this.getRoomNameString(room)}
                                    </a> &nbsp;
                                    <a href={`/homes/${this.state.home.id}/room-temperature-chart?selectedRoomId=${roomId}`}
                                       target={'_blank'}>

                                        <Button outline color={'primary'}><i className="fa fa-chart-area"></i></Button>
                                    </a>&nbsp;
                                    {room && room.get(db.Room.DELETED) && <Badge color="danger">Deleted</Badge>}
                                </h5>
                                {devices.map(device => {
                                    return <div key={device.id}>
                                        {this.state.mapDeviceBatch && this.state.mapDeviceBatch[device.id]} {getStringFromDevice(device, sensp, null, null)}
                                    </div>;
                                })}
                            </div>

                        })}
                    </div>
                }

                <h6>Test batches {testBathes.length})</h6>
                <div style={{maxWith: 800, wordWrap: 'break-word'}}>
                    {
                        testBathes.map(name => <div>{name}</div>)
                    }
                </div>
                <br/>
                <h6>Devices by firmware</h6>
                {
                    Object.keys(mapDeviceFirmware).map(key => {
                        let devices = mapDeviceFirmware[key];
                        let serials = devices.map(device => device.get(db.Device.SERIAL_NUMBER));

                        return <div>
                            {key}: {serials.join(',')}
                        </div>;
                    })
                }
            </div>
        )
    }
}