import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Collapse, Table} from 'reactstrap';
import * as db from '../../lib/dbStructure';
import {Link} from 'react-router-dom';
import {getDataFromMeasurement} from '../../lib/util';
import Toggle from 'react-toggle';

function round(x, n) {
    var a = Math.pow(10, n);
    return (Math.round(x * a) / a);
}

export default class HomeTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            homeDevices: [],
            isOpen: {}
        };
    }

    async componentDidMount() {

    }

    getStringFromMeasurementData(data, device){

        return <span>
            <Link to={`/devices/${device.id}`} target="_blank">{data.serial}</Link>&nbsp;{data.deviceStateFlag || 'online'}&nbsp;
            {data.deviceTyp}&nbsp;{data.wifiName}&nbsp;{data.wifiPower}&nbsp;{data.batteryVoltage}&nbsp;dBm&nbsp;{round(data.temp, 1)}&nbsp;
            {data.versionFirmware}&nbsp;{data.setStaticIp}&nbsp;
            {data.currentIp}&nbsp;{data.port}&nbsp;{data.createdAt} - {data.diff}
        </span>;
    }

    getStringFromDevice(device){
        let deviceType = device.get(db.Device.DEVICE_TYP);
        let deviceStateFlag = device.get(db.Device.DEVICE_STATE_FLAG);
        let serial = device.get(db.Device.SERIAL_NUMBER);

        return <span>
            <Link to={`/devices/${device.id}`} target="_blank">{serial}</Link>&nbsp;{deviceStateFlag || 'online'}&nbsp;
            {deviceType} <span style={{color: 'red'}}>Does not connect for a long time</span>
        </span>;
    }

    render() {
        if(!this.props.homeDevice)
            return '';

        let home = this.props.homeDevice.home;

        return (
            <div style={{marginRight: 20}}>
                <h2 style={{display: 'inline', marginRight: 10, cursor: 'pointer'}} onClick={() => {
                    this.setState(prev => {
                        prev.isOpen[home.id] = !prev.isOpen[home.id];

                        return prev;
                    })
                }}>
                    {home.get(db.Home.HOME_NAME)} ({home.get(db.Home.CITY)})
                </h2>
                <Toggle
                    checked={home.get(db.Home.MONITORING_ACTIVATED)}
                    onChange={() => this.props.toggleMonitoringHome(home)} />

                <Collapse isOpen={this.state.isOpen[home.id]}>
                    <Link to={`/homes/${home.id}/installation-overview`} target="_blank">Installation overview</Link>
                    <Table bordered={true} hover={true}>`
                        <thead>
                        <tr>
                            <th>Room Name</th>
                            <th>Offline devices</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.homeDevice.rooms.map((roomObject, i) => {
                                let room = roomObject.room;
                                let url = room.get(db.Room.MAIN_PHOTO) && room.get(db.Room.MAIN_PHOTO)._url;

                                return <tr key={i}>
                                    <td style={{width: 300}}>
                                        <Link to={`/rooms/${room.id}/devices`} target="_blank"
                                              style={{marginRight: 10}}
                                        >{room.get(db.Room.ROOM_NAME)}</Link>
                                        <Toggle
                                            checked={room.get(db.Room.MONITORING_ACTIVATED) || true}
                                            onChange={() => this.props.toggleMonitoringRoom(room)} />
                                        <br/>
                                        <img src={url} width={'auto'} height={'100'} />
                                    </td>
                                    <td>
                                        {
                                            roomObject.offlineDevices.map(device => {
                                                let measurement = device.get(db.Device.LAST_MEASUREMENT);

                                                return <div key={device.id}>
                                                    {
                                                        measurement && this.getStringFromMeasurementData(getDataFromMeasurement(measurement, device), device)
                                                    }
                                                    {
                                                        !measurement && this.getStringFromDevice(device)
                                                    }
                                                </div>;
                                            })
                                        }
                                    </td>
                                </tr>;
                            })
                        }
                        </tbody>
                    </Table>
                </Collapse>

            </div>
        );
    }
}

HomeTable.propTypes = {
    homeDevice: PropTypes.object,
    toggleMonitoringHome: PropTypes.func,
    toggleMonitoringRoom: PropTypes.func,
};