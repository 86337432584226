import React from 'react';
import Parse from 'parse';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import {Button, Col, Label, Row} from 'reactstrap';
import * as db from '../../lib/dbStructure';
import {toPointerFromId} from '../../lib/util';
import DatePicker from "react-datepicker";
import Loader from '../loader';
import _ from 'lodash';
import Select from 'react-select';


export default class BatteryAnalysis extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [],
            homes: [],
            rooms: [],
            devices: [],
            selectedRoom: null,
            selectedHome: null,
            selectedDevice: null,
            startDate: moment().subtract(1, 'month'),
            endDate: moment().endOf('day'),
            measures: [],
            loading: {
                batteryVoltage: false
            }
        };

        this.getHomes = this.getHomes.bind(this);
        this.getRooms = this.getRooms.bind(this);
        this.getDevices = this.getDevices.bind(this);
        this.getBatteryVoltage = this.getBatteryVoltage.bind(this);
        this.onHomeSelectedChange = this.onHomeSelectedChange.bind(this);
        this.onRoomSelected = this.onRoomSelected.bind(this);
        this.onDeviceSelected = this.onDeviceSelected.bind(this);
        this.onClickGetBatteryData = this.onClickGetBatteryData.bind(this);
        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
    }

    async componentDidMount() {
        await this.getHomes();
    }

    getSeries(measures){
        return measures.map(batteryMeasurement => {
            return {
                name: batteryMeasurement._id.deviceId,
                data: batteryMeasurement.measures.map(measure => {
                    //if(!voltage.year || !voltage.month || !voltage.day)
                    //    return;

                    const {day, month, year} = measure;

                    let date = moment(`${day}/${month}/${year}`, 'DD/MM/YYYY').hour(0).minute(0).second(0);

                    return [
                        date.valueOf(),
                        measure.batteryVoltage
                    ]
                })
            }
        });
    }

    getBatteryVoltage(homeId, roomId, deviceId){
        this.setState({loading: {batteryVoltage: true}});

        if(deviceId === 'Select a device') deviceId = null;

        deviceId = _.isEmpty(deviceId)? (_.isEmpty(this.state.selectedDevice) ? null : this.state.selectedDevice.object.id) : deviceId;

        return Parse.Cloud
            .run('device-batteryVoltage', {
                homeId: homeId || _.get(this.state.selectedHome, 'object.id'),
                roomId: roomId || _.get(this.state.selectedRoom, 'object.id'),
                deviceId,
                startDate: this.state.startDate.toDate(),
                endDate: this.state.endDate.toDate()
            })
            .then(measures => {
                /*measures = measures.map(measure => {
                    measure.batteryVoltage = measure.batteryVoltage.filter(mes => {
                        return mes.batteryVoltage > 0 && mes.batteryVoltage < 4.3;
                    });

                    let lastMeasure = measure.batteryVoltage[measure.batteryVoltage.length - 1];

                    measure.lastMeasureVoltage = lastMeasure ? lastMeasure.batteryVoltage : null;

                    return measure;
                });


                measures = measures.map(measure => {
                    let daysUtilsDischarge = (-3 + measure.lastMeasureVoltage) / Math.abs(measure.voltageDayRatio);

                    measure.dischargedDate = moment().add(daysUtilsDischarge, 'days').format('DD/MM/YYYY');

                    return measure;
                });*/

                this.setState({measures, loading: {batteryVoltage: false}});
                return measures;
            })
    }

    async getHomes() {
        let query = new Parse.Query('Home');

        query.select([
            db.Home.HOME_NAME,
            db.Home.CITY,
            db.Home.ACL
        ]);
        query.notEqualTo(db.Home.DELETED, true);
        query.notEqualTo(db.Home.HIDDEN, true);
        query.limit(10000);

        let homes = await query.find();

        homes = homes.map(home => {
            return {
                value: home.id,
                label: `${home.get(db.Home.HOME_NAME)} (${home.get(db.Home.CITY)})`,
                object: home
            }
        });

        this.setState({homes});
    }
    async getRooms(homeId) {
        let query = new Parse.Query('Room');
        query.limit(10000);
        query.select([
            db.Room.OBJECT_ID,
            db.Room.ROOM_NAME,
        ])

        if(homeId)
            query.equalTo(db.Room.HOME, toPointerFromId(homeId, 'Home'));

        let rooms = await query.find();

        rooms = rooms.map(room => {
            return {
                value: room.id,
                label: `${room.get(db.Room.ROOM_NAME)}`,
                object: room
            }
        });

        this.setState({rooms: rooms});
    }

    async getDevices(roomId){
        let query = new Parse.Query('Device');
        query.limit(10000);

        if(roomId)
            query.equalTo(db.Device.ROOM_ID, toPointerFromId(roomId, 'Room'));

        let devices = await query.find();

        devices = devices.map(device => {
            return {
                value: device.id,
                label: `${device.get(db.Device.SERIAL_NUMBER)}`,
                object: device
            }
        });

        this.setState({devices: devices});
    }

    onRoomSelected(selectedRoom){
        this.setState({selectedRoom});
        this.getDevices(selectedRoom.object.id);
    }

    onDeviceSelected(selectedDevice){
        this.setState({selectedDevice});
    }

    onClickGetBatteryData(){
        this.getBatteryVoltage()
            .then(measures => {
                let series = this.getSeries(measures);

                this.setState({series});
            })
            .catch(console.error);
    }

    onStartDateChange(date){
        this.setState({startDate: moment(date)})
    }

    onEndDateChange(date){
        this.setState({endDate: moment(date)})
    }

    onHomeSelectedChange(selectedHome) {
        this.setState({selectedHome});

        this.getRooms(selectedHome.object.id);
    }

    render() {
        const options = {
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return this.point.label;
                        }
                    }
                }
            },
            tooltip: {
                xDateFormat: '%Y-%m-%d %H:%M:%S'
            },
            title: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                plotLines: [{
                    color: 'red', // Color value
                    dashStyle: 'longdashdot', // Style of the plot line. Default to solid
                    value: moment('2019-02-03').unix() * 1000, // Value of where the line will appear
                    width: 2, // Width of the line,
                    label: {
                        text: 'No duplicate "0" command', // Content of the label.
                        align: 'left', // Positioning of the label.
                        x: +10,
                        y: +300,
                        style: {
                            color: 'red'
                        }
                    }
                }]
            },
            yAxis: {
                max: 4.5,
                min: 2.5,
                plotBands: [{
                    from: 0,
                    to: 2.9,
                    color: 'rgba(255, 100, 5, 0.1)',
                    label: {
                        text: 'Too low',
                        style: {
                            color: '#606060'
                        }
                    }
                }]
            },
            chart: {
                type: 'spline',
                zoomType: 'x',
                height: 600
            },
            series: this.state.series
        };

        return <div>
            <h1>BatteryAnalysis</h1>
            <Row>
                <Col md={4}>
                    <label>Select a building:</label>
                    <Select
                        options={this.state.homes}
                        value={this.state.selectedHome ? {
                            value: this.state.selectedHome.object.id,
                            label: `${this.state.selectedHome.object.get(db.Home.HOME_NAME)} (${this.state.selectedHome.object.get(db.Home.CITY)})`,
                            object: this.state.selectedHome.object
                        } : null}
                        onChange={this.onHomeSelectedChange}
                    />
                </Col>
                <Col md={4}>
                    <Label for={'config-modal-version'}>Room</Label>
                    <Select
                        options={this.state.rooms}
                        value={this.state.selectedRoom ? {
                            value: this.state.selectedRoom.object.id,
                            label: `${this.state.selectedRoom.object.get(db.Room.ROOM_NAME)}`,
                            object: this.state.selectedRoom.object
                        } : null}
                        onChange={this.onRoomSelected}
                    />
                </Col>
                <Col md={4}>
                    <Label for={'config-modal-version'}>Devices</Label>
                    <Select
                        options={this.state.devices}
                        value={this.state.selectedDevice ? {
                            value: this.state.selectedDevice.object.id,
                            label: `${this.state.selectedDevice.object.get(db.Device.SERIAL_NUMBER)}`,
                            object: this.state.selectedDevice.object
                        } : null}
                        onChange={this.onDeviceSelected}
                    />
                </Col>
                <Col md={4} style={{marginTop: 20}}>
                    <DatePicker
                        selected={this.state.startDate.toDate()}
                        onChange={this.onStartDateChange}
                        dateFormat="d/M/y"
                    />
                </Col>
                <Col md={4} style={{marginTop: 20}}>
                    <DatePicker
                        selected={this.state.endDate.toDate()}
                        onChange={this.onEndDateChange}
                        dateFormat="d/M/y"

                    />
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col md={4}>
                    <Button outline onClick={this.onClickGetBatteryData}>Get battery data</Button>
                </Col>
            </Row>

            {
                this.state.loading.batteryVoltage && <Loader/>
            }

            {
                !this.state.loading.batteryVoltage && <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            }

            <h1>List devices</h1>
            {/*<BootstapTable data={this.state.measures} striped hover>
                <TableHeaderColumn isKey dataField='macAddress' dataSort={ true }>Device</TableHeaderColumn>
                <TableHeaderColumn dataField='totalMouvements' dataSort={ true }>Motor mouvements</TableHeaderColumn>
                <TableHeaderColumn dataField='negativeMotorRequest' dataSort={ true }>
                    Negative mouvements
                </TableHeaderColumn>
                <TableHeaderColumn dataField={db.Device.TOTAL_MEASUREMENTS} dataSort={ true }>
                    Number of save
                </TableHeaderColumn>
                <TableHeaderColumn dataField={db.Device.MOTOR_MOUVEMENT_SAVE_RATIO} dataSort={ true }>
                    Motor mouvements / savings</TableHeaderColumn>
                <TableHeaderColumn dataField={'negativeMouvementRatio'} dataSort={ true }>Negative mouvement / savings
                </TableHeaderColumn>
                <TableHeaderColumn dataField={'lastMeasureVoltage'} dataSort={ true }>
                    Battery voltage
                </TableHeaderColumn>
                <TableHeaderColumn dataField={'voltageDayRatio'} dataSort={ true }>
                    Voltage day ratio
                </TableHeaderColumn>
                <TableHeaderColumn dataField={'dischargedDate'} dataSort={ true }>
                    dischargedDate
                </TableHeaderColumn>
            </BootstapTable>*/}
        </div>
    }
}

BatteryAnalysis.propTypes = {};