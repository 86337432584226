import React from 'react';
import {Nav, NavItem, NavLink} from 'reactstrap';
import paths from '../../lib/paths';
import Parse from '../../lib/parse';
import {isRolesInRoles} from '../../lib/util';
import PropTypes from "prop-types";

export default class Sidebar extends React.Component {

    render() {
        if(Parse.User.current() == null) return '';

        return (
            <div className="sidebar__container">
                <div className="text-center p-3">
                    <strong>CLEVER<span style={{color: 'green'}}>ON</span></strong>
                </div>
                <Nav className="flex-column">


                    {
                        isRolesInRoles(['Second Level Support', 'Admin']) && <React.Fragment>
                            <NavItem>
                                <NavLink href={paths.homes}>Buildings</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={paths.devices}>Devices</NavLink>
                            </NavItem>
                        </React.Fragment>
                    }
                    {
                        isRolesInRoles(['Admin']) && <React.Fragment>
                            <NavItem>
                                <NavLink href="/battery-analysis">Battery analysis</NavLink>
                            </NavItem>
                        </React.Fragment>
                    }

                    {
                        isRolesInRoles(['Support']) && <React.Fragment>
                            <NavItem>
                                <NavLink href={'/create-user-and-room'}>Create user/building/room</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={paths.roomFeedbacks}>Room feedbacks</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={paths.supportRequests}>Support requests</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={paths.linkRoomQr}>Link Room QR</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={paths.users}>Last registered users</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href={paths.aiDetection}>AI Detection</NavLink>
                            </NavItem>
                        </React.Fragment>
                    }
                </Nav>
            </div>
        )
    }
}
Sidebar.propTypes = {
    history: PropTypes.any
}