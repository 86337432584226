import React, {Component} from 'react';
import {Button, Table} from 'reactstrap';
import Parse from '../../lib/parse';
import ConfigModal from './lib/config-modal';
import swal from 'sweetalert';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import * as db from '../../lib/dbStructure';
import {booleanToString, isRolesInRoles, toPointerFromId} from '../../lib/util';
import paths from '../../lib/paths';
import {Link} from 'react-router-dom';
import GeneralConfigHomeModal from "./lib/general-config-home";
import ModifyHomeFieldsModal from "./lib/modify-home-fields-modal";
import Loader from "../loader";

class Homes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            homes: [],
            isConfigModalOpen: false,
            config: { version: '1'}
        };

        this.toggleConfigModal = {};
        this.toggleGeneralConfigHomeModal = {};
        this.toggleModifyHomeFieldsModal = {};
        this.saveConfig = this.saveConfig.bind(this);
        this.getHomes = this.getHomes.bind(this);
        this.allowUpdates = this.allowUpdates.bind(this);
        this.loadRooms = this.loadRooms.bind(this);
        this.loadDevices = this.loadDevices.bind(this);
        this.deleteHome = this.deleteHome.bind(this);
    }

    async getHomes(){
        let query = new Parse.Query(db.classes.Home);
        query.include(db.Home.OWNER);
        query.notEqualTo(db.Home.DELETED, true);
        query.limit(10000);

        query.exclude([
            db.Home.WEATHER_FORECAST_TODAY,
            db.Home.THRESHOLDS,
            db.Home.PRESENCE_FORECAST_WEEK
        ]);

        let homes = await query.find();

        this.setState({homes});
    }

    async componentDidMount(){
        if(!Parse.User.current()) return this.props.history.push(paths.login);

        await this.getHomes();
    }

    onUserClick(row) {
        alert(row.name);
    }

    buttonAccessUser(cell, row) {
        return <Button outline onClick={(e) => this.onUserClick(row)}>Show</Button>;
    }

    saveConfig(home, config){
        config.type = 'CommunicationConfig';

        home.set(db.Home.DEVICES_CONFIG, {
            deviceConfig: {
                data: [config]
            }
        });

        return home.save()
            .then(() => swal('Config salvata!', '', 'success'))
            .then(() => this.getHomes())
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
    }

    async allowAdminActions(e, home){
        e.preventDefault();
        try {
            let homeName = home.get(db.Home.HOME_NAME);
            let city = home.get(db.Home.CITY);
            let allowAdminActions = home.get(db.Home.ALLOW_ADMIN_ACTIONS)

            if(!allowAdminActions) {
                let result = await swal({
                    title: 'Are you sure?',
                    text: `Do you really want to allow communication config to building ${homeName} in ${city}? This can lead to connection lost to all devices!`,
                    buttons: ['Abort', 'Confirm'],
                });

                if (!result) throw new Error('Action aborted by user');
            }

            home.set(db.Home.ALLOW_ADMIN_ACTIONS, !home.get(db.Home.ALLOW_ADMIN_ACTIONS));


            await home.save();

            swal('Saved', '', 'success');

            this.getHomes();
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async allowUpdates(e, home) {
        e.preventDefault();
        try {
            let homeName = home.get(db.Home.HOME_NAME);
            let city = home.get(db.Home.CITY);
            let allowUpdate = home.get(db.Home.ALLOW_UPDATE);

            if(!allowUpdate){
                let result = await swal({
                    title: 'Are you sure?',
                    text: `Do you really want to allow software udpdate on building ${homeName} in ${city}? This can loose the connections to all devices!`,
                    buttons: ['Abort', 'Confirm'],
                });

                if (!result) throw new Error('Action aborted by user');
            }

            home.set(db.Home.ALLOW_UPDATE, !home.get(db.Home.ALLOW_UPDATE));

            await home.save();
            await swal('Saved', '', 'success');
            return await this.getHomes();
        } catch (e) {
            return await swal('Error', e.message, 'error');
        }
    }


    async loadRooms(home){
        let query = new Parse.Query(db.classes.Room);
        query.equalTo(db.Room.HOME, toPointerFromId(home.id, db.classes.Home));

        return query.find();
    }


    async loadDevices(rooms){
        const query = new Parse.Query(db.classes.Device);
        query.containedIn(db.Device.ROOM_ID,
            rooms.map(room => toPointerFromId(room.id, db.classes.Room))
        );

        return query.find();
    }

    async deleteHome(home) {
        try {
            let owner = home.get(db.Home.OWNER);
            let deleteAlsoUser, deleteRoomsAndUnlinkDevices;

            deleteRoomsAndUnlinkDevices = await swal({
                title: `Are you sure to delete the home data?`,
                text: 'By deleting the home all rooms will be deleted and devices unlinked. It will not be easy to restore so pay attention.',
                buttons: ['No', 'Yes'],
            });

            if(owner)
                deleteAlsoUser= await swal({
                    title: `Do you want to delete the associated user ${owner && owner.get(db._User.USERNAME)}?`,
                    text: '',
                    buttons: ['No', 'Yes'],
                });

            if(!deleteRoomsAndUnlinkDevices) return;

            let rooms = await this.loadRooms(home);

            home.set(db.Home.DELETED, true);
            rooms.forEach(room => room.set(db.Room.DELETED, true));

            await Parse.Object.saveAll(rooms);
            await home.save();

            if(deleteAlsoUser){
                owner.set(db._User.DELETED, true);
                await owner.save();
            }

            await swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 700});
        } catch (e) {
            console.error(e);
            await swal('Error', e.message, 'error');
        }
    }

    render() {
        let configKeys = [
            db.Home.SUMMER_MODE_MOTOR_VALUE,
            db.Home.MOTOR_ON_VALUE,
            db.Home.THERMO_SLEEP_TIME_MINUTES,
            db.Home.THERMO_SLEEP_REPETION,
            db.Home.EMERGENCY_TEMPERATURE,
            db.Home.CUSTOM_THERMO_SLEEP_HOUR_START,
            db.Home.CUSTOM_THERMO_SLEEP_HOUR_END,
            db.Home.CUSTOM_SLEEP_TIME_MINUTES,
            db.Home.FORCE_HEATING_X_HOURS_BEFORE,
            db.Home.THERMO_CORRECTION_TEMP,
            db.Home.MAX_TEMP_CORRECTION,
            db.Home.MIN_TEMP_CORRECTION,
            db.Home.MAX_THERMO_CURRENT_LIMIT
        ];

        let booleanFields = [
            //db.Home.SENSP_COLOR_OFF,
            //db.Home.LED_FAST_REACTION_TIME,
            db.Home.OFFLINE_DEVICES_NOTIFICATION,
            //db.Home.ENERGY_REPORT_NOTIFICATION,
            //db.Home.ENERGY_REPORT_COMPLIANT,
            //db.Home.MONITORING_ACTIVATED,
            db.Home.HIDDEN,
            db.Home.PID_ACTIVE,
            db.Home.SUMMER_MODE,
            //db.Home.MOTOR_ENERGY_SAVING_ACTIVE,
            db.Home.SYNC_INTERNAL_LED_CONFIG,
            //db.Home.DIAGNOSTIC_ACTIONS_ACTIVATED,
            db.Home.ALLOW_NB_IOT_CHIP_FIRMWARE_UPDATE,
            db.Home.ALLOW_NB_IOT_FIRMWARE_UPDATE,
            db.Home.DISABLE_CLOUD_MOTOR_RESPONSE,
            db.Home.FORCE_LOW_TEMPERATURE_DURING_NIGHT,
            db.Home.DISABLE_EXPONENTIAL_CORRECTION,
            db.Home.FORCE_WIFI_ON_ESP_NOW_DEVICES,
            db.Home.SHOW_ONLY_SENSOR_ON_WEB_APP_CHART,
            db.Home.SHOW_ESTIMATED_ROOM_TEMP_APP_CHART,
            db.Home.ENABLE_CUSTOM_THERMO_MAX_CURRENT_LIMIT,
            db.Home.BATTERY_CHARGING_MONITORING_ACTIVE
        ];

        return (
            <div>
                <h1>Homes</h1>
                <Table>
                    <thead>
                        <tr>
                            <th>Home Name</th>
                            <th>Email Owner (BA)</th>
                            <th>City</th>
                            <th>Number radiators</th>
                            <th>Number sensors</th>
                            {
                                isRolesInRoles(['Admin']) && <>
                                    <th style={{minWidth: 200}}>Update allowed</th>
                                    <th>Comm. allowed</th>
                                    <th>Devices config</th>
                                </>
                            }
                            <th style={{minWidth: 300}}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.homes.length <= 0 && <tr><td><Loader /></td></tr>
                        }
                        {
                            this.state.homes.map((home, i) => {
                                let homeJSON = home.toJSON();
                                let owner = home.get(db.Home.OWNER);

                                let username = owner && owner.get(db._User.USERNAME);
                                let email = owner && owner.get(db._User.EMAIL);


                                let userIdentifier;

                                if(!email)
                                    email = username;

                                if(username === email)
                                    userIdentifier = username;
                                else
                                    userIdentifier = `${username} ${email}`;

                                return <tr key={home.id} id={home.id}>
                                    <td>
                                        {home.get(db.Home.HOME_NAME)} ({home.id})<br/>
                                    </td>

                                    <td>{userIdentifier} ({owner && owner.id})</td>
                                    <td>{home.get(db.Home.CITY)} ({home.get(db.Home.COUNTRY) || ''})</td>
                                    <td>{home.get(db.Home.NUMBER_RADIATORS)}</td>
                                    <td>{home.get(db.Home.NUMBER_SENSP)}</td>
                                    {
                                        isRolesInRoles(['Admin']) && <>
                                            <td>
                                                <label>
                                                    <Toggle
                                                        checked={home.get(db.Home.ALLOW_UPDATE)}
                                                        onChange={async (e) => this.allowUpdates(e, home)} /><br/>
                                                    {home.get(db.Home.FIRMWARE_VERSION_SENSP_2x) &&  <span>{home.get(db.Home.FIRMWARE_VERSION_SENSP_2x)}<br/></span>}
                                                    {home.get(db.Home.FIRMWARE_VERSION_SENSP_3x) &&  <span>{home.get(db.Home.FIRMWARE_VERSION_SENSP_3x)}<br/></span>}
                                                    {home.get(db.Home.FIRMWARE_VERSION_SENSP_4x) &&  <span>{home.get(db.Home.FIRMWARE_VERSION_SENSP_4x)}<br/></span>}
                                                    {home.get(db.Home.FIRMWARE_VERSION_SENSP_5x) &&  <span>{home.get(db.Home.FIRMWARE_VERSION_SENSP_5x)}<br/></span>}
                                                    {home.get(db.Home.FIRMWARE_VERSION_SENSP_6x) &&  <span>{home.get(db.Home.FIRMWARE_VERSION_SENSP_6x)}<br/></span>}

                                                    {home.get(db.Home.FIRMWARE_VERSION_THERM_2x) &&  <span>{home.get(db.Home.FIRMWARE_VERSION_THERM_2x)}<br/></span>}
                                                    {home.get(db.Home.FIRMWARE_VERSION_THERM_3x) &&  <span>{home.get(db.Home.FIRMWARE_VERSION_THERM_3x)}<br/></span>}
                                                    {home.get(db.Home.FIRMWARE_VERSION_THERM_4x) &&  <span>{home.get(db.Home.FIRMWARE_VERSION_THERM_4x)}<br/></span>}
                                                    {home.get(db.Home.FIRMWARE_VERSION_THERM_5x) &&  <span>{home.get(db.Home.FIRMWARE_VERSION_THERM_5x)}<br/></span>}
                                                    {home.get(db.Home.FIRMWARE_VERSION_THERM_6x) &&  <span>{home.get(db.Home.FIRMWARE_VERSION_THERM_6x)}<br/></span>}
                                                </label>
                                            </td>
                                            <td>
                                                <label>
                                                    <Toggle
                                                        checked={home.get('allowAdminActions')}
                                                        onChange={async (e) => this.allowAdminActions(e, home)} />
                                                </label>
                                            </td>
                                            <td>
                                                <Button
                                                    outline color={'warning'}
                                                    onClick={() => this.toggleConfigModal[home.id]()}>
                                                    Set config
                                                </Button>

                                                <ConfigModal
                                                    save={(config) => this.saveConfig(home, config)}
                                                    cancel={() => {}}
                                                    config={_.get(homeJSON, 'devicesConfig.deviceConfig.data.0')}
                                                    setToggleModal={
                                                        toggleModal => this.toggleConfigModal[home.id] = toggleModal
                                                    }
                                                />
                                            </td>
                                        </>
                                    }

                                    <td>
                                        <GeneralConfigHomeModal
                                            setToggleModal={(toggleModal) => this.toggleGeneralConfigHomeModal[home.id] = toggleModal}
                                            home={home}
                                        />
                                        <ModifyHomeFieldsModal
                                            setToggleModal={(toggleModal) => this.toggleModifyHomeFieldsModal[home.id] = toggleModal}
                                            home={home}
                                        />
                                        <Link to={`/homes/${home.id}/rooms`} target="_blank">
                                            <Button outline color={'primary'}><i className="fa fa-list"></i></Button>
                                        </Link>

                                        <Link to={paths.roomTemperatureChart.replace(':homeId', home.id)} target="_blank">
                                            <Button outline color={'primary'}><i className="fa fa-area-chart"></i></Button>
                                        </Link>
                                        <Link to={`/homes/${home.id}/installation-overview`} target="_blank">
                                            <Button outline color={'primary'}><i className="fa fa-building"></i><i className="fa fa-wifi"></i></Button>
                                        </Link>
                                        
                                        <Button onClick={() => this.toggleGeneralConfigHomeModal[home.id]()} >
                                            <i className="fa fa-cog"></i>
                                        </Button>
                                        {
                                            isRolesInRoles(['Admin']) && <>

                                                <Button onClick={() => this.toggleModifyHomeFieldsModal[home.id]()} >
                                                    <i className="fa fa-pencil"></i>
                                                </Button>

                                                <Button
                                                    outline
                                                    color={'danger'}
                                                    onClick={async () => this.deleteHome(home)}>
                                                    <i className="fa fa-trash"></i>
                                                </Button>
                                            </>
                                        }
                                        <br/>
                                        {
                                            configKeys.map(key => {
                                                if(home.get(key) == null) return '';

                                                return <div key={key}>{key}: {home.get(key)}</div>;
                                            })
                                        }
                                        {
                                            booleanFields.map(key => {
                                                if(home.get(key) == null) return '';

                                                return <div key={key}>{key}: {booleanToString(home.get(key))}</div>;
                                            })
                                        }
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}

Homes.propTypes = {
    home: PropTypes.any,
    history: PropTypes.any
};

export default Homes;