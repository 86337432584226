import React, {Component} from 'react';
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import assert from "assert";
import swal from "sweetalert";
import Parse from "parse";

export default class UploadCrmBuildingPhotoModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onPositiveButtonClick = this.onPositiveButtonClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onUserFieldChange = this.onUserFieldChange.bind(this);
    }

    componentDidMount(){
        this.props.setToggleModal(this.toggleModal);
    }

    toggleModal() {
        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    onUserFieldChange(e){
        let value = e.target.value;
        let name = e.target.name;
        this.setState({[name]: value});
    }

    async onPositiveButtonClick(){
        try {
            this.setState({error: false, errorMessage: null});

            assert(this.state.crmBuildingId != null, 'crmBuildingId is required');

            let sure = await swal({
                title: 'Are you sure?',
                text: 'The current image on the web app will be overridden with the one from the CRM',
                icon: 'warning',
                buttons: true,
                dangerMode: true,
            });

            if (!sure) {
                return this.setState({error: true, errorMessage: 'Aborted by user'});
            }

            await Parse.Cloud.run('admin-upload-crm-building-photo', {
                crmBuildingId: this.state.crmBuildingId
            });

            swal('Image uploaded correctly', '', 'success');
        } catch (e) {
            this.setState({error: true, errorMessage: err.message});
        }
    }

    onCancelClick(){
        this.toggleModal();
        this.props.cancel();
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Upload photo on building from CRM Building ID
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <FormGroup>
                            <Label for={'crmBuildingId'}>Crm building Id</Label>
                            <Input
                                type={'text'}
                                name={'crmBuildingId'}
                                id={'crmBuildingId'}
                                value={this.state.crmBuildingId || ''}
                                onChange={this.onUserFieldChange}>
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={this.onPositiveButtonClick}>Upload photo</Button>
                        <Button outline color="secondary" onClick={this.onCancelClick}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

UploadCrmBuildingPhotoModal.propTypes = {
    setToggleModal: PropTypes.func
};