import React, {Component} from 'react';
import {Badge, Button, Table} from 'reactstrap';
import Parse from 'parse';
import {createLinkFromRooms, isRolesInRoles, toPointerFromId} from '../../lib/util';
import * as db from '../../lib/dbStructure';
import moment from 'moment';
import _ from 'lodash';

function isRoomTemperatureWarning(room){
    let avgTempLastHour = room.get(db.Room.AVG_TEMP_LAST_HOUR);
    let tempMin = room.get(db.Room.TEMP_MIN);
    let tempMax = room.get(db.Room.TEMP_MAX);

    if(!avgTempLastHour) return false;

    if(avgTempLastHour < tempMin - 1 || avgTempLastHour > tempMax + 1){
        return true;
    }

    return false;
}

function isRoomTemperatureDanger(room){
    let avgTempLastHour = room.get(db.Room.AVG_TEMP_LAST_HOUR);
    let tempMin = room.get(db.Room.TEMP_MIN);
    let tempMax = room.get(db.Room.TEMP_MAX);

    if(!avgTempLastHour) return false;

    if(avgTempLastHour < tempMin - 2 || avgTempLastHour > tempMax + 2){
        return true;
    }

    return false;
}

function isOfflineRoom(room){
    let avgTempLastHour = room.get(db.Room.AVG_TEMP_LAST_HOUR);
    let hidden = room.get(db.Room.HIDDEN);

    if(!avgTempLastHour && !hidden) return true;

    return false;
}

const downloadxls = (data, filename) => {
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'sheet');
    let buf = XLSX.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
    let str = XLSX.write(wb, {bookType:'xlsx', type:'binary'}); // generate a binary string in web browser
    XLSX.writeFile(wb, `${filename}.xlsx`);
}


export default class Rooms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expandedRows: [],
            rooms: [],
            homes: []
        }
        this.loadRooms = this.loadRooms.bind(this);
        this.loadHome = this.loadHome.bind(this);
        this.deleteRoom = this.deleteRoom.bind(this);
    }

    async loadRooms(){
        let rooms = await (new Parse.Query(db.classes.Room))
            .equalTo(db.Room.HOME, toPointerFromId(this.props.match.params.homeId, db.classes.Home))
            .descending(db.Room.FLOOR)
            .notEqualTo(db.Room.DELETED, true)
            .notEqualTo(db.Room.HIDDEN, true)
            .limit(1000)
            .find();

        this.setState({rooms});
    }

    loadHome(){
        let query = new Parse.Query('Home');
        query.include('Owner');
        query.get(this.props.match.params.homeId).then(home => {
            this.setState({
                home
            });
        }).catch(console.error);
    }

    componentDidMount(){
        this.loadRooms();
        this.loadHome();
    }

    getRooms() {
        return this.state.rooms;
    }

    onUserClick(e, cell, row) {
        if (e.target.textContent === 'Show graph') {
            e.target.parentNode.parentNode.childNodes[0].childNodes[0].removeAttribute('hidden');
            e.target.textContent = 'Hide';

            this.setState({
                expandedRows: [...this.state.expandedRows, row.objectId]
            });
        } else if (e.target.textContent === 'Hide') {
            e.target.parentNode.parentNode.childNodes[0].childNodes[0].setAttribute('hidden', '');
            e.target.textContent = 'Show graph';
            
            this.setState({
                expandedRows: this.state.expandedRows.filter((room) => {
                    if (room.objectId !== row.objectId){
                        return room;
                    }
                })
            });
        }
    }

    async deleteRoom(room){
        try{
            let willDelete = await swal({
                title: 'Are you sure?',
                text: 'Are you sure that you want to delete this room?',
                icon: 'warning',
                dangerMode: true,
                buttons: ['Cancel', 'Delete']
            });

            if (!willDelete) return;


            let devices = await (new Parse.Query(db.classes.Device))
                .equalTo(db.Device.ROOM_ID, room)
                .find();

            if(devices.length){
                throw Error('There are still devices linked to the room, it cannot be deleted. Please unlink the devices and try again.');
            }

            room.set(db.Room.DELETED, true);
            room.set(db.Room.ROOM_NAME, `${room.get(db.Room.ROOM_NAME)}_deleted`)
            room.unset(db.Room.UNIQUE_ID);

            await room.save();

            await swal('Success', '', 'success');

            await this.loadRooms();
        } catch (e){
            await swal('Error', e.message, 'error');
        }
    }

    async getDevicesForBuilding(){
        let devices = new Parse.Query(db.classes.Device)
            .equalTo(db.Device.HOME, toPointerFromId(this.props.match.params.homeId, db.classes.Home))
            .notEqualTo(db.Device.DELETED, true)
            .limit(1000)
            .find();

        return devices;
    }

    async downloadRoomsWithDevices(){
        function createMapRoomDevices(devices){
            let mapRoomDevices = {};

            devices.forEach(device => {
                let room = device.get(db.Device.ROOM_ID);
                if(mapRoomDevices[room?.id] == null){
                    mapRoomDevices[room?.id] = {devices: []};
                }
                mapRoomDevices[room?.id].devices.push(device);
            });

            return mapRoomDevices;
        }

        let rooms = this.state.rooms;
        let home = this.state.home;
        let devices = await this.getDevicesForBuilding();
        let mapRoomDevices = createMapRoomDevices(devices);

        let homeName = home.get(db.Home.HOME_NAME);
        let city = home.get(db.Home.CITY);

        let fileName = `room-list-devices-${homeName}_${city}`;

        let excel = [];

        rooms.forEach(room => {
            let roomName = room.get(db.Room.ROOM_NAME);
            let floor = room.get(db.Room.FLOOR);
            let devices = mapRoomDevices[room.id]?.devices;

            devices?.forEach(device => {
                let deviceType = device.get(db.Device.DEVICE_TYP);
                let serialNumber = device.get(db.Device.SERIAL_NUMBER);

                excel.push({
                    roomName,
                    floor,
                    deviceType,
                    serialNumber
                });
            });
        })


        downloadxls(excel, fileName);
    }

    render() {
        const selectRow = { mode: 'checkbox' };
        const options = {
            expandRowBgColor: '#fff'
        };
        let homeId = this.props.match.params.homeId;


        let warningRooms = this.state.rooms.filter(isRoomTemperatureWarning);
        let dangerRooms = this.state.rooms.filter(isRoomTemperatureDanger);
        let offlineRooms = this.state.rooms.filter(isOfflineRoom);

        let linkWarningRooms = createLinkFromRooms(homeId, warningRooms);
        let linkDangerRooms = createLinkFromRooms(homeId, dangerRooms);
        let linkOfflineRooms = createLinkFromRooms(homeId, offlineRooms);

        let roomsObjects = this.state.rooms
            .map(room => room.toJSON())
            .sort((a, b) => {
                if(a.floor == null || b.floor == null) return 0;

                if(a.floor < b.floor) return -1;
                if(a.floor > b.floor) return 1;

                if(a.floor === b.floor) return 0;

                return 0;
            })
            .map(roomObject => _.pick(roomObject, [
                db.Room.ROOM_NAME,
                db.Room.ROOM_CODE,
                db.Room.UNIQUE_ID,
                db.Room.FLOOR,
                db.Room.NUMBER_RADIATORS,
                db.Room.NUMBER_SENSP
            ]));

        let home = this.state.home;
        let owner = home && home.get(db.Home.OWNER);

        let homeName = home?.get(db.Home.HOME_NAME);
        let city = home?.get(db.Home.CITY);

        return (
            <div>
                Rooms for home {home && home.get(db.Home.HOME_NAME)} ({home && home.get(db.Home.CITY)}) of user {owner && owner.get(db._User.USERNAME)}<br/>

                Warning rooms (target +- 1) ({warningRooms.length}): {linkWarningRooms}<br/>
                Danger rooms (target +- 2) ({dangerRooms.length}): {linkDangerRooms}<br/>
                Offline rooms ({offlineRooms.length}): {linkOfflineRooms}<br/>

                Download rooms excel: <Button onClick={() => downloadxls(roomsObjects, `room-list-${homeName}_${city}`)}>Downlaod rooms</Button>
                <Button
                    onClick={() => this.downloadRoomsWithDevices()}
                >
                    Download rooms with devices
                </Button>
                <Table>
                    <thead>
                        <tr>
                            <th>Room id</th>
                            <th>Room code</th>
                            <th>Room name</th>
                            <th>Floor</th>
                            <th>Room type</th>
                            <th>Temp. min.</th>
                            <th>Temp. max.</th>
                            <th>Avg. temp last 30 min.</th>
                            <th>Est. Room temp last 30 min.</th>
                            <th>Co2</th>
                            <th>Thermo correction temp</th>
                            <th>Last update</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.rooms.map(room => {
                                let avgTempLastHour = room.get(db.Room.AVG_TEMP_LAST_HOUR);
                                let avgEstimatedRoomTemp = room.get(db.Room.AVG_ROOM_EST_TEMP_LAST_HOUR);
                                let tempMin = room.get(db.Room.TEMP_MIN);
                                let tempMax = room.get(db.Room.TEMP_MAX);
                                let lastUpdateAvgTemp = room.get(db.Room.LAST_UPDATE_AVG_TEMP);
                                let co2 = room.get(db.Room.CO2);
                                let thermoCorrectionTemp = room.get(db.Room.THERMO_CORRECTION_TEMP);

                                let showEstimatedRoomTempHome = home && home.get(db.Home.SHOW_ESTIMATED_ROOM_TEMP_APP_CHART);
                                let showEstimatedRoomTempRoom = room && room.get(db.Room.SHOW_ESTIMATED_ROOM_TEMP_APP_CHART);

                                //let showEstimatedRoomTemperature = showEstimatedRoomTempRoom || showEstimatedRoomTempHome || false;
                                //if(showEstimatedRoomTemperature === true && showEstimatedRoomTempHome)
                                //avgTempLastHour = avgEstimatedRoomTemp;

                                lastUpdateAvgTemp = lastUpdateAvgTemp == null ? null : moment(lastUpdateAvgTemp);

                                let color = isRoomTemperatureDanger(room) ?
                                    'red' : isRoomTemperatureWarning(room) ?
                                        'orange' : 'green';

                                let co2Color = 'green';

                                if(co2 < 1000) co2Color = 'green';
                                else if(co2 < 2000) co2Color = 'orange';
                                else if(co2 > 2000) co2Color = 'red';

                                return <tr key={room.id}>
                                    <td>
                                        {room.id}
                                    </td>
                                    <td>
                                        {room.get(db.Room.ROOM_CODE)}
                                    </td>
                                    <td>
                                        {room.get(db.Room.ROOM_NAME)}
                                        {room.get(db.Room.DELETED) && <Badge color="danger">deleted</Badge>}
                                        {room.get(db.Room.HIDDEN) && <Badge color="warning">hidden</Badge>}
                                    </td>
                                    <td>{room.get(db.Room.FLOOR)}</td>
                                    <td>
                                        {room.get(db.Room.ROOM_TYPE)}
                                    </td>
                                    <td>{room.get(db.Room.TEMP_MIN)}</td>
                                    <td>{room.get(db.Room.TEMP_MAX)}</td>
                                    <td style={{color}}>{avgTempLastHour && avgTempLastHour.toFixed(2)}</td>
                                    <td style={{color}}>{avgEstimatedRoomTemp && avgEstimatedRoomTemp.toFixed(2)}</td>
                                    <td style={{color: co2Color}}>{room.get(db.Room.CO2)}</td>
                                    <td>{thermoCorrectionTemp}</td>
                                    <td>{lastUpdateAvgTemp && lastUpdateAvgTemp.format('MM/DD/YYYY HH:mm')}</td>
                                    <td>
                                        <Button outline onClick={(e) => {this.props.history.push(`/rooms/${room.id}/devices`);}}>
                                            Show devices
                                        </Button>
                                        {
                                            isRolesInRoles(['Admin', 'Support']) && <Button
                                                outline
                                                color={'danger'}
                                                onClick={async () => await this.deleteRoom(room)}>
                                                <i className="fa fa-trash"></i>
                                            </Button>
                                        }
                                        <a href={`https://admin.cleveron.ch/homes/${homeId}/room-temperature-chart?selectedRoomId=${room.id}`} target={'_blank'}>
                                            <Button>
                                                <i className="fa fa-area-chart"></i>
                                            </Button>
                                        </a>

                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}