'use strict';

import React from 'react';
import PropTypes from 'prop-types';

class Loader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let size = this.props.size || 'medium';

        return <div className={'loader-container'}>
            {this.props.message}
            <span className={`loader loader--${size}`}>&nbsp;</span>
        </div>;
    }
}

Loader.propTypes = {
    size: PropTypes.oneOf([
        'medium',
        'large'
    ]),
    message: PropTypes.string
};

Loader.defaultProps = {
    site: 'medium'
};

export default Loader;