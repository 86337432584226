import React from 'react';
import PropTypes from 'prop-types';
import {Alert, Badge, Button, ButtonGroup, ButtonToolbar, Col, FormGroup, Input, Label, Row, Table} from 'reactstrap';
import Parse from 'parse';
import {moveDeviceToRoom, toPointerFromId} from '../../lib/util';
import swal from 'sweetalert';
import moment from 'moment';
import LinkRoomModal from './DeviceViewTabs/link-room-modal';
import AddMotorPositionCommandModal from './DeviceViewTabs/add-motorposition-command-modal';
import ConfigModal from './lib/config-modal';
import Loader from '../loader';
import axios from 'axios';
import protocolSkeleton from './resources/protocol-skeleton';
import AddCustomCommandModal from './DeviceViewTabs/add-custom-command-modal';
import AddCustomLedConfigModal from './DeviceViewTabs/add-custom-led-config';
import * as db from '../../lib/dbStructure';
import _ from 'lodash';
import assert from 'assert';
import paths from '../../lib/paths';
import {Link} from 'react-router-dom';
import Toggle from 'react-toggle';


const MEASUREMENT = 'measurement';
const DEVICE_STATE = 'deviceState';
const COMMAND_QUEUE ='commandQueue';

const downloadxls = (data, device) => {
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'sheet');
    let buf = XLSX.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
    let str = XLSX.write(wb, {bookType:'xlsx', type:'binary'}); // generate a binary string in web browser
    XLSX.writeFile(wb, `${device.get(db.Device.SERIAL_NUMBER)}_history_motor_tags.xlsx`);
}

export default class DeviceView extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            device: {},
            measurements: [],
            commandQueue: [],
            testBatchDeviceHistory: [],
            issues: [],
            simulatedDeviceSessionToken: null,
            loading: {},
            error: {},
            deviceHistories: [],
            deviceTickets: [],
            firmwares: [],
            motorCurrentValue: -1,
            selectedDate: null,
            deviceDailyStatistics: []
        };

        this.startDebug = this.startDebug.bind(this);
        this.stopDebug = this.stopDebug.bind(this);
        this.getDevice = this.getDevice.bind(this);
        this.getMeasurement = this.getMeasurement.bind(this);
        this.getCommandQueue = this.getCommandQueue.bind(this);
        this.deleteDeviceFromRoom = this.deleteDeviceFromRoom.bind(this);
        this.linkToRoom = this.linkToRoom.bind(this);
        this.addMotorPositionCommand = this.addMotorPositionCommand.bind(this);
        this.addCustomCommand = this.addCustomCommand.bind(this);
        this.saveConfig = this.saveConfig.bind(this);
        this.sendDeviceLoginRequest = this.sendDeviceLoginRequest.bind(this);
        this.sendDeviceSaveRequest = this.sendDeviceSaveRequest.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.isLoading = this.isLoading.bind(this);
        this.manageError = this.manageError.bind(this);
        this.setError = this.setError.bind(this);
        this.setStateToDevice = this.setStateToDevice.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.printLabel = this.printLabel.bind(this);
        this.getDeviceHistory = this.getDeviceHistory.bind(this);
        this.addDeviceTicket = this.addDeviceTicket.bind(this);
        this.updateDeviceTickets = this.updateDeviceTickets.bind(this);
        this.onFirmwareVersionChange = this.onFirmwareVersionChange.bind(this);
        this.addFirmwareUpdateCommand = this.addFirmwareUpdateCommand.bind(this);
        this.addMotorCurrentCommand = this.addMotorCurrentCommand.bind(this);
        this.setForceLedOnDevice = this.setForceLedOnDevice.bind(this);
        this.saveAddCustomLedConfig = this.saveAddCustomLedConfig.bind(this);
        this.downloadHistoryMotorTags = this.downloadHistoryMotorTags.bind(this);
        this.getBatchDeviceHistory = this.getBatchDeviceHistory.bind(this);
        this.setAutonomousMode = this.setAutonomousMode.bind(this);
        this.setTagToDevice = this.setTagToDevice.bind(this);
        this.removeTagFromDevice = this.removeTagFromDevice.bind(this);

        this.toggleLinkRoomModal = null;
        this.toggleAddMotorPositionCommandModal = null;
        this.toggleAddCustomCommandModal = null;
        this.toggleAddCustomLedConfigModal = null;
        this.toggleConfigModal = null;
        this.device = null;
    }

    manageError(err, key){
        this.setLoading(key, false);
        this.setError(key, true);
        console.error(err);
        swal('Error', err.message, 'error');
    }

    setError(key, value){
        this.setState(prev => {
            prev.error[key] = value;

            return prev;
        });
    }

    setLoading(key, value){
        this.setState(prev => {
            prev.loading[key] = value;

            return prev;
        });
    }
    isLoading(key){
        return this.state.loading[key] || false;
    }

    getDevice(){
        let query = new Parse.Query('Device');

        query.include(db.Device.ROOM_ID);

        let isMac = id => id.split(':').length === 6;

        if(isMac(this.props.match.params.deviceId)){
            query.equalTo(db.Device.MAC_ADDRESS,this.props.match.params.deviceId )
        } else {
            query.equalTo(db.Device.OBJECT_ID, this.props.match.params.deviceId)
        }

        return query.first().then(device => {
            this.setState({device: device.toJSON(), deviceObject: device});
            this.device = device;

            return device;
        })
            .catch(err => console.error(err) || swal('Error', err.message, 'error'));
    }

    async getMeasurement(date){
        let getMeasurements = async (date) => {
            let query = new Parse.Query('Measurement');
            query.equalTo('deviceId', toPointerFromId(this.props.match.params.deviceId, 'Device'));
            query.include('request');
            query.include('response');
            query.limit(300);
            query.addDescending('createdAt');
            query.select([
                db.Measurement.TEMP,
                db.Measurement.TARGET_TEMP,
                db.Measurement.VERSION_FIRMWARE,
                db.Measurement.MOTOR_POSITION,
                db.Measurement.CO2,
                db.Measurement.MEASURED_TEMP,
                db.Measurement.ESTIMATED_ROOM_TEMP,
                db.Measurement.CREATED_AT,
                db.Measurement.RESPONSE,
                db.Measurement.REQUEST,
                db.Measurement.REQUEST_DURATION_MS,
                db.Measurement.MOTOR_TARGET_STATE,
                db.Measurement.MOTOR_ERROR,
                db.Measurement.MOTOR_MOUVEMENT_REASON,
                db.Measurement.BATTERY_VOLTAGE
            ]);
    
            if(date != null){
                query.greaterThanOrEqualTo(db.Measurement.CREATED_AT, date.startOf('day').toDate());
                query.lessThanOrEqualTo(db.Measurement.CREATED_AT, date.endOf('day').toDate());
            }
    
            let measurements = await query.find();

            return measurements;
        }

        try {
            date = date || this.state.selectedDate;

            this.setLoading(MEASUREMENT, true);

            let measurements = await getMeasurements(date);
    
            measurements = measurements.map(measurement => measurement.toJSON());

            this.setState({measurements: measurements});
            this.setLoading(MEASUREMENT, false);
        }catch(e){
            this.setLoading(MEASUREMENT, false);
            this.manageError(e, MEASUREMENT);
        }
    }

    getCommandQueue(){
        let query = new Parse.Query('CommandQueue');
        query.equalTo(db.CommandQueue.DEVICE, toPointerFromId(this.props.match.params.deviceId, db.classes.Device));
        query.notEqualTo(db.CommandQueue.DELETED, true);

        return query.find()
            .then(commands => {
                //commands = commands.map(command => command.toJSON());
                this.setState({commandQueue: commands});
            })
            .catch(err => swal('Error', err.message, 'error') && console.error(err));
    }

    async componentDidMount(){
        let device = await this.getDevice();
        await this.getDeviceHistory();
        let deviceTickets = await this.getDeviceTickets();
        let firmwares = await this.getFirmwares(device);

        this.getMeasurement();
        this.getCommandQueue();

        let deviceDailyStatistics = await this.getDailyStatistics();

        let testBatchDeviceHistory = await this.getBatchDeviceHistory();

        this.setState({deviceTickets, firmwares, firmware: firmwares[0], testBatchDeviceHistory, deviceDailyStatistics});
    }

    startDebug(){
        let query = new Parse.Query('CommandQueue');
        query.equalTo('commandName', 'startDebug');
        query.equalTo('device', toPointerFromId(this.props.match.params.deviceId, 'Device'));
        query.notEqualTo(db.CommandQueue.DELETED, true)
        query.find()
            .then(commands => {
                if (commands.length > 0)
                    return Promise.reject(Error('There is already a command startDebug in the queue'));

                
                let commandQueue = new Parse.Object(db.classes.CommandQueue);
                commandQueue.set('commandName', 'startDebug');
                commandQueue.set('device', this.state.deviceObject);
                commandQueue.set(db.CommandQueue.ROOM, this.state.deviceObject.get(db.Device.ROOM_ID));
                return commandQueue.save();
            })
            .then(() => swal({title: 'Command added', text: ' ', icon: 'success', button: [''], timer: 1000}) && this.getCommandQueue())
            .catch(err => swal('Error', err.message, 'error') && console.error(err));
    }

    stopDebug(){
        let query = new Parse.Query('CommandQueue');
        query.equalTo('commandName', 'stopDebug');
        query.equalTo('device', this.state.deviceObject);
        query.notEqualTo(db.CommandQueue.DELETED, true);
        query.find()
            .then(commands => {
                if (commands.length > 0)
                    return Promise.reject(Error('There is already a command stopDebug in the queue'));
                
                let commandQueue = new Parse.Object(db.classes.CommandQueue);
                commandQueue.set('commandName', 'stopDebug');
                commandQueue.set('device', this.state.deviceObject);
                commandQueue.set(db.CommandQueue.ROOM, this.state.deviceObject.get(db.Device.ROOM_ID));

                return commandQueue.save();
            })
            .then(() => swal({title: 'Command added', text: ' ', icon: 'success', button: [''], timer: 1000})&& this.getCommandQueue())
            .catch(err => swal('Error', err.message, 'error') && console.error(err));
    }

    deleteDeviceFromRoom(){
        return swal({
            title: 'Are you sure?',
            text: 'Device will not be visible anymore on the home section of the client.',
            icon: 'warning',
            buttons: true,
            dangerMode: true
        })
            .then((willDelete) => {
                if (willDelete) {
                    this.getDevice()
                        .then(device => {
                            device.set('owner', null);
                            device.set('roomId', null);

                            return device.save();
                        })
                        .then(() => swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000}))
                        .catch(err => swal('Error', err.message, 'error') && console.error(err));
                }
            });
    }

    async linkToRoom(home, room){
        try {
            let willLink = await swal({
                title: 'Are you sure?',
                text: 'Device will not be visible anymore on the home section of the client.',
                icon: 'warning',
                buttons: true,
                dangerMode: true
            });
    
            if (willLink) {
                if (!home ||  !room)
                    throw new Error('Home or room is not selected');
    
                let device = await this.getDevice();

                device = moveDeviceToRoom(device, room);

                await device.save();

                swal({title: 'Success', text: 'Device linked correctly', icon: 'success', button: [''], timer: 1000});
            }
        } catch (e){
            swal('Error', e.message, 'error');
        }
    }

    addMotorPositionCommand(motorPosition){
        let query = new Parse.Query('CommandQueue');
        query.equalTo('commandName', 'changeMotorPosition');
        query.equalTo('device', this.state.deviceObject);
        query.notEqualTo(db.CommandQueue.DELETED, true);
        return query.find()
            .then(commands => {
                if (commands.length > 0)
                    return Promise.reject(Error('There is already a command ChangeMotorPositionCommand in the queue'));

                
                let commandQueue = new Parse.Object(db.classes.CommandQueue);
                commandQueue.set('commandName', 'changeMotorPosition');
                commandQueue.set('device', this.state.deviceObject);
                commandQueue.set('data', {
                    value: parseInt(motorPosition),
                    unit: 'percent'
                });
                commandQueue.set(db.CommandQueue.ROOM, this.state.deviceObject.get(db.Device.ROOM_ID));

                return commandQueue.save();
            })
            .then(() => swal({title: 'Success', text: 'Command added', icon: 'success', button: [''], timer: 1000}) && this.getCommandQueue())
            .catch(err => swal('Error', err.message, 'error') && console.error(err));
    }

    addCustomCommand(name, data){
        if(!name || !data)
            return Promise.reject(Error('No name defined'));

        try {
            data = JSON.parse(data);
        } catch (e) {
            return Promise.reject(Error('Json not valid'));
        }

        
        let commandQueue = new Parse.Object(db.classes.CommandQueue);
        commandQueue.set('commandName', name);
        commandQueue.set('device', this.state.deviceObject);
        commandQueue.set('data', data);
        commandQueue.set(db.CommandQueue.ROOM, this.state.deviceObject.get(db.Device.ROOM_ID));

        return commandQueue.save();
    }

    async deleteCommand(command){
        try {
            command.set(db.CommandQueue.DELETED, true);

            await command.save();

            swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
            this.getCommandQueue();
        } catch(e){
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    saveConfig(config){
        config.type = 'CommunicationConfig';

        this.device.set('deviceConfig', {
            deviceConfig: {
                data: [config]
            }
        });

        return this.device.save()
            .then(() => swal({title: 'Success', text: 'Config salvata', icon: 'success', button: [''], timer: 1000}))
            .then(() => this.getDevice())
            .catch(err => {
                console.error(err);
                return Promise.reject(err);
            });
    }

    sendDeviceLoginRequest(){
        let parts = this.state.device.macAddress.split(':');
        axios({
            method:'get',
            url: `${Parse.serverURL}/login?username=${this.state.device.macAddress}&password=${parts.slice(Math.max(parts.length - 3, 1)).join('').toLowerCase()}`,
            headers: {'X-Parse-Application-Id': 'NjSUT8HxvCz706ldcwUn'}
        })
            .then( (response) => {
                // handle success
                this.setState({simulatedDeviceSessionToken: response.data.sessionToken})
            })
            .catch(function (error) {
                // handle error
                console.error(error);
            });
    }

    sendDeviceSaveRequest(){
        axios({
            method:'POST',
            url: `${Parse.serverURL}/functions/deviceSave`,
            headers: {
                'X-Parse-Application-Id': 'NjSUT8HxvCz706ldcwUn',
                'X-Parse-Session-Token': this.state.simulatedDeviceSessionToken
            },
            data:  _.merge({}, protocolSkeleton, {
                deviceInfo: {
                    typeFirmware: 'therm',
                    macAddress: this.state.device.macAddress
                },
                sensorMeasurement: {
                    data: [
                        {
                            type: 'temperature',
                            values: [
                                {
                                    unit: '°C',
                                    value: this.state.temperature
                                }
                            ]
                        },
                        {
                            type: 'humidity',
                            values: [
                                {
                                    value: '0.5'
                                }
                            ]
                        }
                    ]
                }
            }),
        })
            .then( (response) => {
                swal('Success', 'Simulated request sent successfully', 'success');
            })
            .catch(function (error) {
                swal('Error', error.message, 'error');
            });
    }

    setStateToDevice(){
        this.device.set('deviceStateFlag', this.state.device.deviceStateFlag);

        return this.device.save()
            .then(() => {
                swal('Completed', '', 'success');
            });
    }

    onInputChange(event){
        event.persist();
        this.setState(prev => {
            if(!event.target)return ;
            _.set(prev, event.target.name, event.target.value);

            return prev;
        });
    }

    async printLabel(){
        try {
            assert(this.device != null, 'Device is null');

            let printJob = new Parse.Object('PrintJobQueue');

            let serial = this.device.get(db.Device.SERIAL_NUMBER);
            let macAddress = this.device.get(db.Device.MAC_ADDRESS);
            let deviceTyp = this.device.get(db.Device.DEVICE_TYP);

            if(!serial) throw new Error('Serial is required');
            if(!macAddress) throw new Error('macAddress is required');
            if(!deviceTyp) throw new Error('deviceTyp is required');

            printJob.set(db.PrintJobQueue.PRINTER_ID, '1');
            printJob.set(db.PrintJobQueue.SERIES_N, serial.toString());
            printJob.set(db.PrintJobQueue.MAC_ADDRESS, macAddress);
            printJob.set(db.PrintJobQueue.DEVICE_TYPE, deviceTyp);
            printJob.set(db.PrintJobQueue.STATUS, db.PrintJobQueue.STATUS$IN_QUEUE);

            await printJob.save();

            swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message, 'error');
        }
    }

    async getDeviceHistory(){
        let query = new Parse.Query(db.classes.DeviceHistory);

        query.equalTo(db.DeviceHistory.DEVICE, toPointerFromId(this.device.id, db.classes.Device));
        query.include(db.DeviceHistory.OLD_ROOM);
        query.include(db.DeviceHistory.NEW_ROOM);
        query.include(db.DeviceHistory.OLD_OWNER);
        query.include(db.DeviceHistory.NEW_OWNER);
        query.include(db.DeviceHistory.OLD_HOME);
        query.include(db.DeviceHistory.NEW_HOME);
        query.include(db.DeviceHistory.USER);

        let deviceHistories = await query.find();

        this.setState({deviceHistories: deviceHistories})
    }

    async getBatchDeviceHistory() {
        let testBatchDeviceHistory = await new Parse.Query(db.classes.TestBatchDeviceHistory)
            .equalTo(db.TestBatchDeviceHistory.DEVICE, toPointerFromId(this.device.id, db.classes.Device))
            .include([
                db.TestBatchDeviceHistory.NEW_BATCH,
                db.TestBatchDeviceHistory.OLD_BATCH
            ])
            .find();

        return testBatchDeviceHistory;
    }

    async getDeviceTickets() {
        let query = new Parse.Query(db.classes.DeviceTicket);

        query.equalTo(db.DeviceHistory.DEVICE, toPointerFromId(this.device.id, db.classes.Device));

        return query.find();
    }

    async addDeviceTicket(){
        try {
            let text = await swal("Enter the description for this ticket", {
                content: "input",
                buttons: ['Cancel', 'Ok']
            });

            if (!text) {
                swal('Error', 'Text was empty', 'error');
                return;
            }

            let deviceTicket = new Parse.Object(db.classes.DeviceTicket);
            deviceTicket.set(db.DeviceTicket.COMMENT, text);
            deviceTicket.set(db.DeviceTicket.DEVICE, this.state.deviceObject);

            await deviceTicket.save();
            let deviceTickets = await this.getDeviceTickets();
            this.setState({deviceTickets});
            swal({title: 'Ticket saved!', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message, 'error');
        }
    }

    async updateDeviceTickets(){
        let deviceTickets = await this.getDeviceTickets();
        this.setState({deviceTickets});
    }

    async getFirmwares(device){
        let query = new Parse.Query('DeviceFirmware');
        query.equalTo(db.DeviceFirmware.DEVICE_TYPE, device.get(db.Device.DEVICE_TYP));

        return await query.find();
    }


    async onFirmwareVersionChange(e){
        let firmwareId = e.target.value;

        let firmware = _.find(this.state.firmwares, ['id', firmwareId]);

        this.setState({firmware: firmware});
    }

    async addFirmwareUpdateCommand() {
        function ucFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        let deviceType = this.device.get(db.Device.DEVICE_TYP);

        let firmwareVersion = this.state.firmware.get(db.DeviceFirmware.VERSION);
        let thermBin = this.state.firmware.get(db.DeviceFirmware.THERM_BIN);
        let senspBin = this.state.firmware.get(db.DeviceFirmware.SENSP_BIN);

        try {
            let query = new Parse.Query('CommandQueue');
            query.equalTo('commandName', 'updateFirmware');
            query.equalTo('device', toPointerFromId(this.props.match.params.deviceId, 'Device'));
            query.notEqualTo(db.CommandQueue.DELETED, true)

            let commands = await query.find();

            if (commands.length > 0)
                Promise.reject(Error('There is already a command ChangeMotorPositionCommand in the queue'));

            
            let commandQueue = new Parse.Object(db.classes.CommandQueue);
            commandQueue.set('commandName', 'updateFirmware');
            commandQueue.set('device', this.state.deviceObject);
            commandQueue.set(db.CommandQueue.ROOM, this.state.deviceObject.get(db.Device.ROOM_ID));

            let url = null;
            if(deviceType === db.Device.DEVICE_TYP$SENSP && senspBin){
                url = senspBin.url();
                url = url.replace('https://', 'http://');
            } else if(deviceType === db.Device.DEVICE_TYP$THERM && thermBin){
                url = thermBin.url();

                url = url.replace('https://', 'http://');
            } else {
                url = `http://simplyhome-assets.s3.amazonaws.com/Simply${ucFirstLetter(deviceType)}_${firmwareVersion}.bin`;
            }

            commandQueue.set('data', {
                url,
                hash: "abracadabra",
                size: 500,
                unit: "bytes"
            });

            await commandQueue.save();
            return await swal({title: 'Command added', text: ' ', icon: 'success', button: [''], timer: 1000}) && this.getCommandQueue();
        } catch (err) {
            return await swal('Error', err.message, 'error') && console.error(err);
        }
    }


    async addMotorCurrentCommand(e){
        try {
            let query = new Parse.Query('CommandQueue');
            query.equalTo('commandName', 'changeMotorCurrent');
            query.equalTo('device', toPointerFromId(this.props.match.params.deviceId, 'Device'));
            query.notEqualTo(db.CommandQueue.DELETED, true);

            let commands = await query.find();

            if (commands.length > 0)
                Promise.reject(Error('There is already a command changeMotorCurrent in the queue'));

            
            let commandQueue = new Parse.Object(db.classes.CommandQueue);
            commandQueue.set('commandName', 'changeMotorCurrent');
            commandQueue.set('device', this.state.deviceObject);
            commandQueue.set(db.CommandQueue.ROOM, this.state.deviceObject.get(db.Device.ROOM_ID));
            commandQueue.set('data', {
                unit: 'mA',
                value: parseInt(this.state.motorCurrentValue)
            });

            await commandQueue.save();
            swal({title: 'Command saved', text: ' ', icon: 'success', button: [''], timer: 1000})
            this.getCommandQueue();
        } catch (err) {
            swal('Error', err.message, 'error') && console.error(err);
        }
    }

    async setForceLedOnDevice(){
        try {
            let value = await swal("Choose the color to force to the device:", {
                buttons: {
                    cancel: 'Cancel',
                    turnOff: "TurnOff",
                    green: 'Green',
                    yellow: 'Yellow',
                    red: 'Red',
                    'remove-force': 'Remove force'
                }
            });

            let device = await this.getDevice();

            switch (value) {
                case 'cancel':
                    console.log('Cencel pressed!');
                    return swal('Aborted by user');

                case 'turnOff':
                    device.set(db.Device.FORCE_LED_COLOR, db.Device.FORCE_LED_COLOR$TURNED_OFF);
                    break;

                case 'green':
                    device.set(db.Device.FORCE_LED_COLOR, db.Device.FORCE_LED_COLOR$GREEN);
                    break;

                case 'yellow':
                    device.set(db.Device.FORCE_LED_COLOR, db.Device.FORCE_LED_COLOR$YELLOW);
                    break;

                case 'red':
                    device.set(db.Device.FORCE_LED_COLOR, db.Device.FORCE_LED_COLOR$RED);
                    break;

                case 'remove-force':
                    device.unset(db.Device.FORCE_LED_COLOR);
                    break;                    
            }

            await device.save();

            await swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            swal('Error', e.message, 'error') && console.error(e);
        }
    }

    async saveAddCustomLedConfig(config){
        let device = await this.getDevice();

        device.set(db.Device.LED_COLOR_CONFIG, JSON.parse(config));

        await device.save();

        swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
    }


    async invalidateSession(){
        //--------------- BATCH ID --------------------------------------------
        let sessionToken = await swal('Enter the session token. Ex. "r:fb87786a0a9a5e35426d26308b6e1d02"', {
            content: 'input',
        });

        if (!sessionToken || sessionToken.length !== 34) {
            throw new Error('Session token not valid. Shoud have 34 characters length.');
        }

        let oldSessionToken = Parse.User.current().getSessionToken();

        await Parse.User.become(sessionToken);

        await Parse.User.logOut();

        await Parse.User.become(oldSessionToken);

        swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000})
    }

    downloadHistoryMotorTags(){
        let historyMotorTags = this.device.get(db.Device.HISTORY_MOTOR_TAGS);

        let data = historyMotorTags.map(historyMotorTag => {
            let object = historyMotorTag.motorCurrentLimits;

            object.motorPosition = historyMotorTag.motorPosition;
            object.measurement = historyMotorTag.measurement;

            object.tags = historyMotorTag.tags.join(',');

            return object;
        });
        downloadxls(data, this.device);
    }

    async setAutonomousMode(){
        try {
            this.state.deviceObject.set(db.Device.TEST_AUTONOMOUS_MODE, !this.device.get(db.Device.TEST_AUTONOMOUS_MODE));
            await this.state.deviceObject.save();

            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async toggleField(fieldName){
        try {
            let doIt = await swal({
                title: `Are you sure?`,
                text: ``,
                buttons: ['No', 'Yes'],
            });
            if(!doIt) return;

            this.setState(async prev => {

                let previousValue = !prev.deviceObject.get(fieldName);

                prev.deviceObject = prev.deviceObject.set(fieldName, previousValue);

                prev.deviceObject = await prev.deviceObject.save();

                swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});

                return prev;
            });
        } catch (e) {
            swal('Error', e.message, 'error');
        }
    }

    getDailyStatistics(){
        let query = new Parse.Query(db.classes.DeviceDailyStatistic);

        query.equalTo(db.DeviceDailyStatistic.DEVICE, toPointerFromId(this.device.id, db.classes.Device));
        query.addDescending(db.DeviceDailyStatistic.CREATED_AT);

        return query.find();
    }

    async setTagToDevice(){
        try {
            let deviceObject = this.state.deviceObject;

            console.log(deviceObject);

            let text = await swal({
                heightAuto: false,
                title: 'Enter tag name',
                content: {
                    element: 'input',
                    attributes: {
                        placeholder: 'Enter tag name',
                        type: 'text',
                        value: deviceObject.get(db.Device.TAG) || ''
                    },
                }
            });
            console.log(text);
            if(!text) return;

            deviceObject.set(db.Device.TAG, text);

            await deviceObject.save();
            swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});

            await this.getDevice()
        } catch(e){
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async removeTagFromDevice(){
        try {
            let deviceObject = this.state.deviceObject;

            deviceObject.unset(db.Device.TAG);

            await deviceObject.save();

            swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});

            await this.getDevice()
        } catch(e){
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    render() {
        let events;

        let renderMeasurement = () => {
            return <div>
                <h1>Last measurement</h1>
                <div style={{marginTop: 10, marginBottom: 10}}>
                    <Input
                        style={{width: 200, display: 'inline-block', marginRight: 10}}
                        id="exampleDate"
                        name="date"
                        placeholder="date placeholder"
                        type="date"
                        value={this.state.selectedDate ? this.state.selectedDate.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                        onChange={(e) => {
                            e.preventDefault();

                            let selectedDate = moment(e.target.value, 'YYYY-MM-DD');
                            this.getMeasurement(selectedDate)
                            this.setState({selectedDate});
                        }}
                    />
                    <Button outline style={{width: 100}} onClick={async () => await this.getMeasurement()}>Refresh</Button>
                </div>
                {this.isLoading(MEASUREMENT) ? <Loader/> : ''}
                <div style={{maxHeight: 600, overflowY: 'auto'}}>
                    <Table>
                        <thead>
                            <tr>
                                <th>Created at</th>
                                <th>T.</th>
                                <th>M.T.</th>
                                <th>E.R.T.</th>
                                <th>Firm.V</th>
                                <th>Target. T</th>
                                <th>Motor pos.</th>
                                <th>Motor err.</th>
                                <th>Motor mrea.</th>
                                <th>Target <br/>motor pos.</th>
                                <th>co2</th>
                                <th>bat.</th>
                                <th>Req. ms</th>
                                <th>mstat</th>
                                <th>Request</th>
                                <th>Response</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.measurements.map(measurement => {
                                let requestDurationMs = measurement[db.Measurement.REQUEST_DURATION_MS];
                                let temp = measurement[db.Measurement.TEMP] && measurement[db.Measurement.TEMP].toFixed(2);
                                let measuredTemp = measurement[db.Measurement.MEASURED_TEMP] && measurement[db.Measurement.MEASURED_TEMP].toFixed(2);
                                let estimatedRoomTemp = measurement[db.Measurement.ESTIMATED_ROOM_TEMP] && measurement[db.Measurement.ESTIMATED_ROOM_TEMP].toFixed(2);
                                function printIfNumber(value){
                                    if(typeof value === 'number') return value;

                                    return '';
                                }
                                return <tr key={measurement.objectId}>
                                    <td>{moment(measurement.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                                    <td>{temp}</td>
                                    <td>{measuredTemp}</td>
                                    <td>{estimatedRoomTemp}</td>
                                    <td>{printIfNumber(measurement[db.Measurement.VERSION_FIRMWARE])}</td>
                                    <td>{printIfNumber(measurement[db.Measurement.TARGET_TEMP])}</td>
                                    <td>{printIfNumber(measurement[db.Measurement.MOTOR_POSITION])}</td>
                                    <td>{measurement[db.Measurement.MOTOR_ERROR]}</td>
                                    <td>{measurement[db.Measurement.MOTOR_MOUVEMENT_REASON]}</td>
                                    <td>{printIfNumber(measurement[db.Measurement.MOTOR_TARGET_STATE])}</td>
                                    <td>{printIfNumber(measurement[db.Measurement.CO2])}</td>
                                    <td>{printIfNumber(measurement[db.Measurement.BATTERY_VOLTAGE])}</td>
                                    <td style={{color: requestDurationMs > 10000 ? 'red': 'black'}}>{requestDurationMs && requestDurationMs.toFixed(0)}</td>
                                    <td>
                                        <pre style={{'maxHeight': '200px'}}>
                                            {JSON.stringify(measurement?.request?.mstat, null, 2)}
                                        </pre>
                                    </td>
                                    <td>
                                        <pre style={{'maxHeight': '200px'}}>
                                            {JSON.stringify(measurement.request, null, 2)}
                                        </pre>
                                    </td>
                                    <td>
                                        <pre style={{'maxHeight': '200px'}}>
                                            {JSON.stringify(measurement.response, null, 2)}
                                        </pre>
                                    </td>
                                </tr>
                            })
                        }
                        </tbody>
                    </Table>
                </div>
            </div>
        };
        let renderDeviceDailyStatistics = () => {
            return <div>
                <h1>Device daily statistics</h1>
                <div style={{maxHeight: 600, overflowY: 'auto'}}>
                    <Table>
                        <thead>
                            <tr>
                                <th>Created at</th>
                                <th>ws (min)</th>
                                <th>(tEnd-tStart)</th>
                                <th>bat.soc</th>
                                <th>bat.d_mah</th>
                                <th>bat.charger</th>
                                <th>statistic</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.deviceDailyStatistics.map(dailyStatistic => {
                                    let createdAt = dailyStatistic.get(db.DeviceDailyStatistic.CREATED_AT);
                                    let statistic = dailyStatistic.get(db.DeviceDailyStatistic.STATISTIC);
                                    function printIfNumber(value){
                                        if(typeof value === 'number') return value;

                                        return '';
                                    }

                                    return <tr key={dailyStatistic.objectId}>
                                        <td>{moment(createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                                        <td>{printIfNumber(statistic?.ws / 60)}</td>
                                        <td>{printIfNumber((statistic?.tStart - statistic?.tEnd)/1000/3600)}</td>
                                        <td>{printIfNumber(statistic?.bat?.soc)}</td>
                                        <td>{printIfNumber(statistic?.bat?.d_mah)}</td>
                                        <td>{JSON.stringify(statistic?.bat?.charger)}</td>
                                        <td>
                                            <pre style={{'maxHeight': '300px'}}>
                                                {JSON.stringify(statistic, null, 2)}
                                            </pre>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        };

        if (_.isEmpty(this.state.device)) return <Loader/>;

        events = this.state.deviceHistories.map(deviceHistory => {
            let oldRoom = deviceHistory.get(db.DeviceHistory.OLD_ROOM);
            let newRoom = deviceHistory.get(db.DeviceHistory.NEW_ROOM);
            let oldHome = deviceHistory.get(db.DeviceHistory.OLD_HOME);
            let newHome = deviceHistory.get(db.DeviceHistory.NEW_HOME);


            let oldRoomName = (oldRoom && oldRoom.get(db.Room.ROOM_NAME)) || 'Not defined';
            let newRoomName = (newRoom && newRoom.get(db.Room.ROOM_NAME)) || 'Not defined';
            let oldHomeName = (oldHome && oldHome.get(db.Home.HOME_NAME)) || 'Not defined';
            let newHomeName = (newHome && newHome.get(db.Home.HOME_NAME)) || 'Not defined';

            let canBeLinkedBack = oldRoom != null && oldHome != null;

            return {
                ts: moment(deviceHistory.get(db.DeviceHistory.CREATED_AT)),
                text: <p>
                    <i className="fa fa-user"></i> {deviceHistory.get(db.DeviceHistory.USER) && deviceHistory.get(db.DeviceHistory.USER).get(db._User.USERNAME)}:
                    {oldRoomName} ({oldHomeName})
                    -> {newRoomName}({newHomeName}) &nbsp;
                    {
                        canBeLinkedBack && <a href={'javascript: ;'} onClick={(e) => {
                            e.preventDefault();
                            this.linkToRoom(oldHome, oldRoom);
                        }}>Link back to room {oldRoomName} ({oldHomeName})</a>
                    }
                </p>
            }
        }).reverse();


        let tickets = this.state.deviceTickets.map(deviceTicket => {
            return {
                ts: moment(deviceTicket.get(db.DeviceTicket.CREATED_AT)),
                text: <p>
                    {deviceTicket.get(db.DeviceTicket.COMMENT)}
                    <a onClick={async () => {
                        try {
                            let willDelete = swal({
                                title: "Are you sure?",
                                text: "Once deleted, you will not be able to recover this record",
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                            });

                            if (willDelete) {
                                await deviceTicket.destroy();
                                await this.updateDeviceTickets();
                                swal({title: 'Deleted!', text: ' ', icon: 'success', button: [''], timer: 1000});
                            }
                        } catch (e) {
                            console.error(e.message);
                            swal('Error', e.message, 'error');
                        }
                    }} style={{
                        float: 'right',
                        cursor: 'pointer'
                    }}>Delete</a>
                </p>
            }
        }).reverse();


        let numberMotorResponseValue = null;
        let numberMotorTags = null;
        let numberCommandValue = null;
        let defectPrediction = null;
        let defectScore = null;

        if(this.device){
            numberMotorResponseValue = this.device.get(db.Device.NUMBER_MOTOR_RESPONSE_VALUE);
            numberMotorTags = this.device.get(db.Device.MOTOR_TAG);
            numberCommandValue = this.device.get(db.Device.NUMBER_COMMAND_VALUE);
            defectPrediction = this.device.get(db.Device.DEFECT_PREDICTION);
            defectScore = Math.round(this.device.get(db.Device.DEFECT_SCORE_NORMALIZED));
        }

        let numberRes0 = numberMotorResponseValue && numberMotorResponseValue['0'];
        let numberRes100 = numberMotorResponseValue && numberMotorResponseValue['100'];
        let brokenMotorTag = numberMotorTags && (numberMotorTags['errorBrokenMotor'] || 0);
        let numberCalibrationCommand = numberCommandValue && (numberCommandValue['calibrateMotor'] || 0);

        let roomId = _.get(this.state.device, [db.Device.ROOM_ID, db.Room.OBJECT_ID]);
        let roomName = _.get(this.state.device, [db.Device.ROOM_ID, db.Room.ROOM_NAME]);
        let homeId = _.get(this.state.device, [db.Device.ROOM_ID, db.Room.HOME, db.Home.OBJECT_ID]);

        return (
            <div>
                <LinkRoomModal
                    save={this.linkToRoom}
                    cancel={() => {}}
                    setToggleModal={toggleModal => this.toggleLinkRoomModal = toggleModal}
                />
                <AddMotorPositionCommandModal
                    save={this.addMotorPositionCommand}
                    cancel={() => {}}
                    setToggleModal={toggleModal => this.toggleAddMotorPositionCommandModal = toggleModal}
                />
                <AddCustomCommandModal
                    save={this.addCustomCommand}
                    cancel={() => {}}
                    setToggleModal={toggleModal => this.toggleAddCustomCommandModal = toggleModal}
                />
                <AddCustomLedConfigModal
                    save={this.saveAddCustomLedConfig}
                    setToggleModal={toggleModal => this.toggleAddCustomLedConfigModal = toggleModal}
                    config={this.state.device.ledColorConfig}
                />
                <h3>
                    {this.state.device.deleted && <Badge color="danger">Deleted</Badge>}&nbsp;
                    {this.state.device?.tag && <Badge>{this.state.device?.tag}</Badge>}&nbsp;
                    {this.state.device.deviceTyp} {this.state.device.serialNumber} ({this.state.device.macAddress}) Room <a href={`/rooms/${roomId}/devices`}>{roomName}</a>&nbsp;

                    {
                        this.state.deviceObject &&
                        this.state.deviceObject.get(db.Device.HOME) &&
                        this.state.deviceObject.get(db.Device.HOME).get(db.Home.HOME_NAME)
                    }

                    <Link to={`${paths.roomTemperatureChart.replace(':homeId', homeId)}?selectedRoomId=${roomId}`} target="_blank">
                        <Button outline color={'primary'}><i className="fa fa-area-chart"></i></Button>
                    </Link>
                </h3>


                {!_.isEmpty(_.omit(this.state.device.deviceConfig?.deviceConfig?.data?.[0], 'type')) && <Alert color={'warning'}>Custom config assigned</Alert>}

                <hr/>
                <h1>Actions</h1>
                <ConfigModal
                    save={(config) => this.saveConfig(config)}
                    cancel={() => {}}
                    config={_.get(this.state.device, 'deviceConfig.deviceConfig.data.0')}
                    setToggleModal={
                        toggleModal => this.toggleConfigModal = toggleModal
                    }
                />
                <ButtonToolbar>
                    <ButtonGroup>
                        <Button outline color="warning" size="sm" onClick={() => this.toggleLinkRoomModal()}>
                            Link to room
                        </Button>&nbsp;
                        <Button outline color="danger" size="sm"  onClick={this.deleteDeviceFromRoom}>
                            Unlink device from room
                        </Button>&nbsp;
                        <Button outline color="secondary" size="sm" onClick={() => {this.startDebug()}}>
                            Start Debug
                        </Button>&nbsp;
                        <Button outline color="secondary" size="sm" onClick={() => {this.stopDebug()}}>
                            Stop Debug
                        </Button>&nbsp;
                        <Button outline color="secondary" size="sm" onClick={() => {this.toggleAddMotorPositionCommandModal()}}>
                            Add motor position command
                        </Button>&nbsp;
                        <Button outline color="secondary" size="sm" onClick={() => {this.toggleAddCustomCommandModal()}}>
                            Add custom command
                        </Button>&nbsp;
                        <Button outline color="secondary" size="sm" onClick={() => {this.toggleConfigModal()}}>
                            Set device config
                        </Button>&nbsp;
                        <Button outline color="secondary" size="sm" onClick={() => {this.toggleAddCustomLedConfigModal()}}>
                            Add custom led config
                        </Button>&nbsp;
                        <Button outline color="secondary" size="sm" onClick={this.printLabel}>
                            Print label
                        </Button>&nbsp;
                        <Button outline color="danger" size="sm" onClick={this.setForceLedOnDevice}>
                            Force Led Color
                        </Button>&nbsp;
                        <Button outline color="danger" size="sm" onClick={this.downloadHistoryMotorTags}>
                            Download history motor tags
                        </Button>&nbsp;
                        <Button outline color={'secondary'} size={'sm'} onClick={this.setTagToDevice}>
                            Set tag
                        </Button>&nbsp;
                        <Button outline color={'secondary'} size={'sm'} onClick={this.removeTagFromDevice}>
                            Remove tag
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
                <br/>
                <Row>
                    <Col sm={2}>
                        <Label>Set state flag</Label>
                    </Col>
                    <Col sm={3}>
                        <Input type="select"
                            name={'device.deviceStateFlag'}
                            onChange={this.onInputChange}
                            value={this.state.device.deviceStateFlag}>
                            <option value={'online'}>online</option>
                            <option value={'work-in-progress'}>work-in-progress</option>
                            <option value={'client-action-required'}>client-action-required</option>
                            <option value={'in-mount'}>Mount mode</option>
                            <option value={'inactive'}>inactive</option>
                        </Input>
                    </Col>
                    <Col sm={4}>
                        <Button outline color="secondary" size="sm" onClick={this.setStateToDevice}>
                            Set device state
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm={2}>
                        <Label>Add firmware update command</Label>
                    </Col>
                    <Col sm={3}>
                        <Input type="select"
                            name={'firmwareVersion'}
                            onChange={this.onFirmwareVersionChange}
                            value={this.state.firmware && this.state.firmware.id}>
                            {
                                this.state.firmwares.map(firmware => {
                                    return <option value={firmware.id}>{firmware.get('version')} ({firmware.get(db.DeviceFirmware.STATUS)})</option>
                                })
                            }
                        </Input>
                    </Col>
                    <Col sm={4}>
                        <Button outline color="secondary" size="sm" onClick={this.addFirmwareUpdateCommand}>
                            Add firmware update command
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm={2}>
                        <Label>Add motor current command</Label>
                    </Col>
                    <Col sm={3}>
                        <Input type="number"
                               name={'motorCurrentValue'}
                               onChange={(e) => this.setState({motorCurrentValue: e.target.value})}
                               value={this.state.motorCurrentValue}>
                        </Input>
                    </Col>
                    <Col sm={4}>
                        <Button outline color="secondary" size="sm" onClick={this.addMotorCurrentCommand}>
                            Add motor current command
                        </Button>
                    </Col>
                </Row>
                <hr/>
                <h1>Device Info</h1>
                <pre style={{maxHeight: '300px'}}>
                    {JSON.stringify(this.state.device, null, 2)}
                </pre>
                <hr/>
                <h1>Command queue</h1><Button onClick={() => this.getCommandQueue()}>Refresh</Button>
                <Table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Command name</th>
                            <th>Data</th>
                            <th>Created at</th>
                            <th>Updateda at</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.commandQueue.map(command => {
                                return <tr key={command.id}>
                                    <td scope="row">{command.id}</td>
                                    <td>{command.get('commandName')}</td>
                                    <td>
                                        <pre>
                                            {command.get('data') ? JSON.stringify(command.get('data'), null, 2) : ''}
                                        </pre>
                                    </td>
                                    <td>{moment(command.get('createdAt')).format('DD/MM/YYYY HH:mm:ss')}</td>
                                    <td>{moment(command.get(db.CommandQueue.UPDATED_AT)).format('DD/MM/YYYY HH:mm:ss')}</td>
                                    <td>
                                        <Button outline color="danger" size="sm" onClick={async () => await this.deleteCommand(command)}>
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            })
                        }
                        {this.state.commandQueue.length === 0 && <tr><td> No commands in the queue</td></tr>}
                    </tbody>
                </Table>
                <hr/>
                <h1>Device installation information</h1>
                <pre>
                    {JSON.stringify(this.state.device.installationInfo, null, 2)}
                </pre>
                <hr/>
                <h1>Device state</h1>
                {this.isLoading(DEVICE_STATE) ? <Loader/> : ''}
                Voltage: {this.state.device.batteryVoltage}<br/>
                Created at: {this.state.device.createdAt}<br/>
                Debug mode: {this.state.device.mode === 'debug' ? 'true' : 'false'}<br/>
                Device state flag: {this.state.device.deviceStateFlag}<br/>
                Device force led: {this.state.device.forceLedColor}<br/>
                Mounted engine: {this.state.device.mountedEngine}<br/>
                Test autonmous mode flag: <Toggle checked={this.state.deviceObject.get(db.Device.TEST_AUTONOMOUS_MODE)} onChange={async () => await this.toggleField(db.Device.TEST_AUTONOMOUS_MODE)} /><br/>
                Motor test mode: <Toggle checked={this.state.deviceObject.get(db.Device.MOTOR_TEST_MODE)} onChange={async () => await this.toggleField(db.Device.MOTOR_TEST_MODE)} /><br/>
                Test request timeout: <Toggle checked={this.state.deviceObject.get(db.Device.TEST_REQUEST_TIMEOUT)} onChange={async () => await this.toggleField(db.Device.TEST_REQUEST_TIMEOUT)} /><br/>
                <hr/>

                <h1>Motor analysis</h1>
                Res 0: {numberRes0}<br/>
                Res 100: {numberRes100}<br/>
                Broken motor tag: {brokenMotorTag}<br/>
                Number calibration command: {numberCalibrationCommand}<br/>
                Defect score: {defectScore} / 100<br/>
                Defect prediction: {defectPrediction}<br/>
                Motor position values: {JSON.stringify(this.state.deviceObject.get(db.Device.NUMBER_MOTOR_POSITION_VALUE))}<br/>
                Last diagnostics done: {this.state.deviceObject.get(db.Device.LAST_MOTOR_ANALYSIS)?.toString()}
                <hr/>

                {renderMeasurement()}
                {renderDeviceDailyStatistics()}

                <h1>Device history</h1>
                <Row>
                    <Col sm={12}>
                        {
                            events.length === 0 && <p>No history found</p>
                        }
                        <Table>
                            <tbody>
                                {
                                    events.map(event => {
                                        let ts = event.ts;
                                        let text = event.text;
                                        return <tr>
                                            <td>{ts.format()}</td>
                                            <td>{text}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>


                <h1>Test batch history</h1>
                <Row>
                    <Col sm={12}>
                        {
                            this.state.testBatchDeviceHistory.length === 0 && <p>No batch history found</p>
                        }
                        <ul>
                            {
                                this.state.testBatchDeviceHistory.map(testBatchHistory => {
                                    let device = testBatchHistory.get(db.TestBatchDeviceHistory.DEVICE);
                                    let oldBatch = testBatchHistory.get(db.TestBatchDeviceHistory.OLD_BATCH);
                                    let newBatch = testBatchHistory.get(db.TestBatchDeviceHistory.NEW_BATCH);

                                    let oldBatchReferenceNumber = oldBatch == null ? 'N/A' : oldBatch.get(db.TestBatch.NAME);
                                    let newBatchReferenceNumber = newBatch == null ? 'N/A' : newBatch.get(db.TestBatch.NAME);

                                    return <li>{oldBatchReferenceNumber} => {newBatchReferenceNumber}</li>;
                                })
                            }
                        </ul>
                    </Col>
                </Row>


                <h1>Device tickets</h1>
                <Row>
                    <Col sm={6}>
                        <Button onClick={this.addDeviceTicket} style={{marginBottom: 10}}>Add new ticket</Button>
                        {
                            tickets.length === 0 && <p>No tickets found</p>
                        }
                        {
                            tickets.map(ticket => {
                                let {ts, text} = ticket;
                                return <div>
                                    {ts.format('DD/MM/YYYY')}: {text}
                                </div>
                            })
                        }
                    </Col>
                </Row>

                <h1>Simulation</h1>
                <Button onClick={this.sendDeviceLoginRequest}>Login</Button>
                {this.state.simulatedDeviceSessionToken && 'Login success'}
                {
                    this.state.simulatedDeviceSessionToken &&
                        <div>
                            <FormGroup>
                                <Label for={'temperature'}>Temperature</Label>
                                <Input
                                    type={'temperature'}
                                    name={'temperature'}
                                    id={'temperature'}
                                    value={this.state.temperature}
                                    onChange={(e) => {
                                        this.setState({temperature: e.target.value})
                                    }}
                                >
                                </Input>
                            </FormGroup>
                            <Button onClick={this.sendDeviceSaveRequest}>DeviceSave</Button>
                        </div>
                }
                <Button onClick={this.invalidateSession}>Invalidate session</Button>
            </div>
        );
    }
}

DeviceView.propTypes = {
    match: PropTypes.any
}