import React, {Component} from 'react';
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import * as db from "../../../lib/dbStructure";
import Toggle from "react-toggle";
import Select from "react-select";
import Parse from '../../../lib/parse';
import _ from 'lodash';
import {booleanToString} from '../../../lib/util';

function is2xVersion(versionFirmware){
    if(!versionFirmware) return false;


    let oldVersion = versionFirmware.startsWith('2.2.');
    let startWith2 = versionFirmware.startsWith('2.');

    return !oldVersion && startWith2;
}

function is3xVersion(versionFirmware){
    if(!versionFirmware) return false;

    return versionFirmware.startsWith('3.');
}

function is4xVersion(versionFirmware){
    if(!versionFirmware) return false;

    return versionFirmware.startsWith('4.');
}

function is5xVersion(versionFirmware){
    if(!versionFirmware) return false;

    return versionFirmware.startsWith('5.');
}

function is6xVersion(versionFirmware){
    if(!versionFirmware) return false;

    return versionFirmware.startsWith('6.');
}

function isVersionCompatible(versionFirmware, deviceType){
    let parts = versionFirmware.split('-');

    if(parts.length < 2) return false;

    return parts[parts.length - 1] === deviceType;
}

export default class GeneralConfigHomeModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            home: this.props.home,
            availableFirmwareVersionTherm2x: [],
            availableFirmwareVersionTherm3x: [],
            availableFirmwareVersionTherm4x: [],
            availableFirmwareVersionTherm5x: [],
            availableFirmwareVersionTherm6x: [],

            availableFirmwareVersionSensp2x: [],
            availableFirmwareVersionSensp3x: [],
            availableFirmwareVersionSensp4x: [],
            availableFirmwareVersionSensp5x: [],
            availableFirmwareVersionSensp6x: []
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onOpened = this.onOpened.bind(this);
    }

    componentDidMount(){
        this.props.setToggleModal(this.toggleModal);
    }

    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    }

    async onOpened(){
        this.setState(await this.loadDeviceFirmwares());
    }

    async loadDeviceFirmwares(){
        let availableFirmwares = await new Parse.Query(db.classes.DeviceFirmware)
            .equalTo(db.DeviceFirmware.STATUS, db.DeviceFirmware.STATUS$STABLE)
            .find();

        let availableFirmwareVersionTherm2x = availableFirmwares.filter(firmware =>
            firmware.get(db.DeviceFirmware.DEVICE_TYPE) === db.Device.DEVICE_TYP$THERM &&
            is2xVersion(firmware.get(db.DeviceFirmware.VERSION)) &&
            isVersionCompatible(firmware.get(db.DeviceFirmware.VERSION), db.Device.DEVICE_TYP$THERM)
        );
        let availableFirmwareVersionTherm3x = availableFirmwares.filter(firmware =>
            firmware.get(db.DeviceFirmware.DEVICE_TYPE) === db.Device.DEVICE_TYP$THERM &&
            is3xVersion(firmware.get(db.DeviceFirmware.VERSION)) &&
            isVersionCompatible(firmware.get(db.DeviceFirmware.VERSION), db.Device.DEVICE_TYP$THERM)
        );
        let availableFirmwareVersionTherm4x = availableFirmwares.filter(firmware =>
            firmware.get(db.DeviceFirmware.DEVICE_TYPE) === db.Device.DEVICE_TYP$THERM &&
            is4xVersion(firmware.get(db.DeviceFirmware.VERSION)) &&
            isVersionCompatible(firmware.get(db.DeviceFirmware.VERSION), db.Device.DEVICE_TYP$THERM)
        );
        let availableFirmwareVersionTherm5x = availableFirmwares.filter(firmware =>
            firmware.get(db.DeviceFirmware.DEVICE_TYPE) === db.Device.DEVICE_TYP$THERM &&
            is5xVersion(firmware.get(db.DeviceFirmware.VERSION)) &&
            isVersionCompatible(firmware.get(db.DeviceFirmware.VERSION), db.Device.DEVICE_TYP$THERM)
        );

        let availableFirmwareVersionTherm6x = availableFirmwares.filter(firmware =>
            firmware.get(db.DeviceFirmware.DEVICE_TYPE) === db.Device.DEVICE_TYP$THERM &&
            is6xVersion(firmware.get(db.DeviceFirmware.VERSION)) &&
            isVersionCompatible(firmware.get(db.DeviceFirmware.VERSION), db.Device.DEVICE_TYP$THERM)
        );

        let availableFirmwareVersionSensp2x = availableFirmwares.filter(firmware =>
            firmware.get(db.DeviceFirmware.DEVICE_TYPE) === db.Device.DEVICE_TYP$SENSP &&
            is2xVersion(firmware.get(db.DeviceFirmware.VERSION)) &&
            isVersionCompatible(firmware.get(db.DeviceFirmware.VERSION), db.Device.DEVICE_TYP$SENSP)
        );
        let availableFirmwareVersionSensp3x = availableFirmwares.filter(firmware =>
            firmware.get(db.DeviceFirmware.DEVICE_TYPE) === db.Device.DEVICE_TYP$SENSP &&
            is3xVersion(firmware.get(db.DeviceFirmware.VERSION)) &&
            isVersionCompatible(firmware.get(db.DeviceFirmware.VERSION), db.Device.DEVICE_TYP$SENSP)
        );
        let availableFirmwareVersionSensp4x = availableFirmwares.filter(firmware =>
            firmware.get(db.DeviceFirmware.DEVICE_TYPE) === db.Device.DEVICE_TYP$SENSP &&
            is4xVersion(firmware.get(db.DeviceFirmware.VERSION)) &&
            isVersionCompatible(firmware.get(db.DeviceFirmware.VERSION), db.Device.DEVICE_TYP$SENSP)
        );
        let availableFirmwareVersionSensp5x = availableFirmwares.filter(firmware =>
            firmware.get(db.DeviceFirmware.DEVICE_TYPE) === db.Device.DEVICE_TYP$SENSP &&
            is5xVersion(firmware.get(db.DeviceFirmware.VERSION)) &&
            isVersionCompatible(firmware.get(db.DeviceFirmware.VERSION), db.Device.DEVICE_TYP$SENSP)
        );
        let availableFirmwareVersionSensp6x = availableFirmwares.filter(firmware =>
            firmware.get(db.DeviceFirmware.DEVICE_TYPE) === db.Device.DEVICE_TYP$SENSP &&
            is6xVersion(firmware.get(db.DeviceFirmware.VERSION)) &&
            isVersionCompatible(firmware.get(db.DeviceFirmware.VERSION), db.Device.DEVICE_TYP$SENSP)
        );

        return {
            availableFirmwareVersionTherm2x,
            availableFirmwareVersionTherm3x,
            availableFirmwareVersionTherm4x,
            availableFirmwareVersionTherm5x,
            availableFirmwareVersionTherm6x,

            availableFirmwareVersionSensp2x,
            availableFirmwareVersionSensp3x,
            availableFirmwareVersionSensp4x,
            availableFirmwareVersionSensp5x,
            availableFirmwareVersionSensp6x
        };
    }

    render() {
        let roles = localStorage.getItem('roles') && localStorage.getItem('roles').split(',');

        if(!roles) roles = [];

        let isAdmin = roles.indexOf('Admin') >= 0;
        let isSupport = roles.indexOf('Support') >= 0;
        let isLeanManagement = roles.indexOf('Lean management') >= 0;

        let booleanFields = [
            db.Home.SENSP_COLOR_OFF,
            db.Home.LED_FAST_REACTION_TIME,
            db.Home.OFFLINE_DEVICES_NOTIFICATION,
            db.Home.ENERGY_REPORT_NOTIFICATION,
            db.Home.ENERGY_REPORT_COMPLIANT,
            db.Home.MONITORING_ACTIVATED,
            db.Home.HIDDEN,
            db.Home.PID_ACTIVE,
            db.Home.SUMMER_MODE,
            db.Home.MOTOR_ENERGY_SAVING_ACTIVE,
            db.Home.SYNC_INTERNAL_LED_CONFIG,
            db.Home.DIAGNOSTIC_ACTIONS_ACTIVATED,
            db.Home.ALLOW_NB_IOT_CHIP_FIRMWARE_UPDATE,
            db.Home.ALLOW_NB_IOT_FIRMWARE_UPDATE,
            db.Home.DISABLE_CLOUD_MOTOR_RESPONSE,
            db.Home.FORCE_LOW_TEMPERATURE_DURING_NIGHT,
            db.Home.DISABLE_EXPONENTIAL_CORRECTION,
            db.Home.FORCE_WIFI_ON_ESP_NOW_DEVICES,
            db.Home.SHOW_ONLY_SENSOR_ON_WEB_APP_CHART,
            db.Home.SHOW_ESTIMATED_ROOM_TEMP_APP_CHART,
            db.Home.ENABLE_CUSTOM_THERMO_MAX_CURRENT_LIMIT,
            db.Home.BATTERY_CHARGING_MONITORING_ACTIVE
        ];

        let qrCodePortalFiels = [
            db.Home.ALLOW_HOLIDAY_ON_QR_CODE_PORTAL,
            db.Home.ALLOW_TEMPERATURE_MODIFICATION_ON_QR_CODE_PORTAL,
            db.Home.ALLOW_ROOM_FEEDBACK_QR_CODE_PORTAL,
            db.Home.SHOW_TEMPERATURE_QR_CODE_PORTAL,
            db.Home.SHOW_CO2_QR_CODE_PORTAL,
            db.Home.ALLOW_DAY_EVENT_CREATION_QR_CODE_PORTAL
        ];

        let webAppConfigFields = [
            db.Home.WEB_UI_HIDE_OFFLINE_DEVICES
        ];

        let firmwareVersionFields = [
            db.Home.FIRMWARE_VERSION_THERM_2x,
            db.Home.FIRMWARE_VERSION_THERM_3x,
            db.Home.FIRMWARE_VERSION_THERM_4x,
            db.Home.FIRMWARE_VERSION_THERM_5x,
            db.Home.FIRMWARE_VERSION_THERM_6x,

            db.Home.FIRMWARE_VERSION_SENSP_2x,
            db.Home.FIRMWARE_VERSION_SENSP_3x,
            db.Home.FIRMWARE_VERSION_SENSP_4x,
            db.Home.FIRMWARE_VERSION_SENSP_5x,
            db.Home.FIRMWARE_VERSION_SENSP_6x
        ];

        const integerFields = [
            db.Home.SUMMER_MODE_MOTOR_VALUE,
            db.Home.MOTOR_ON_VALUE,
            db.Home.THERMO_SLEEP_TIME_MINUTES,
            db.Home.THERMO_SLEEP_REPETION,
            db.Home.EMERGENCY_TEMPERATURE,
            db.Home.CUSTOM_THERMO_SLEEP_HOUR_START,
            db.Home.CUSTOM_THERMO_SLEEP_HOUR_END,
            db.Home.CUSTOM_SLEEP_TIME_MINUTES,
            db.Home.FORCE_HEATING_X_HOURS_BEFORE
        ]

        let renderIntegerField = (key) => {
            return <FormGroup key={key}>
                <Label for={key}>{key}: {this.state.home.get(key) || 'N/A'}</Label>&nbsp;
                <Input
                    type={'number'}
                    name={key}
                    id={key}
                    value={this.state.home.get(key)}
                    onChange={async (e) => {{
                        if(!e.target.value)
                            this.state.home.unset(key);
                        else
                            this.state.home.set(key, parseInt(e.target.value));

                        this.setState({home: this.state.home});
                    }}}>
                </Input>
            </FormGroup>
        }

        let renderFloatField = (key, step = '0.5') => {
            return <FormGroup key={key}>
                <Label for={key}>{key}: {this.state.home.get(key) || 'N/A'}</Label>&nbsp;
                <Input
                    type={'number'}
                    name={key}
                    id={key}
                    value={this.state.home.get(key)}
                    step={step}
                    onChange={async (e) => {{
                        if(!e.target.value)
                            this.state.home.unset(key);
                        else
                            this.state.home.set(key, parseFloat(e.target.value));

                        this.setState({home: this.state.home});
                    }}}>
                </Input>
            </FormGroup>
        }

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()} onOpened={this.onOpened}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Set building configuration
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <h2>General config</h2>
                        <hr/>
                        {
                            booleanFields.map(field => {
                                return <FormGroup key={field}>
                                    <Label for={field}>{field} {booleanToString(this.state.home.get(field))}</Label>&nbsp;
                                    <Toggle
                                        id={field}
                                        checked={this.state.home.get(field)}
                                        onChange={async () => {
                                            this.state.home.set(field, !this.state.home.get(field));

                                            this.setState({home: this.state.home});
                                        }} />
                                </FormGroup>
                            })
                        }
                        <h2>Qr code portal config</h2>
                        <hr/>
                        {
                            qrCodePortalFiels.map(field => {
                                return <FormGroup key={field}>
                                    <Label for={field}>{db.Home.label(field)} {booleanToString(this.state.home.get(field))}</Label>&nbsp;
                                    <Toggle
                                        id={field}
                                        checked={this.state.home.get(field)}
                                        onChange={async () => {
                                            this.state.home.set(field, !this.state.home.get(field));

                                            this.setState({home: this.state.home});
                                        }} />
                                </FormGroup>
                            })
                        }
                        <h2>Web app UI config</h2>
                        <hr/>
                        {
                            webAppConfigFields.map(field => {
                                return <FormGroup key={field}>
                                    <Label for={field}>{field}</Label>&nbsp;
                                    <Toggle
                                        id={field}
                                        checked={this.state.home.get(field)}
                                        onChange={async () => {
                                            this.state.home.set(field, !this.state.home.get(field));

                                            this.setState({home: this.state.home});
                                        }} />
                                </FormGroup>
                            })
                        }
                        <h2>Home fields</h2>
                        <hr/>
                        {
                            firmwareVersionFields.map(firmwareVersionField => {
                                let firmwareVersion = this.state.home.get(firmwareVersionField);
                                let availableFirmwareName = `available${_.upperFirst(firmwareVersionField)}`;

                                return <FormGroup key={firmwareVersionField}>
                                    <Label for={firmwareVersionField}>{db.Home.label(firmwareVersionField)}</Label>
                                    <Select
                                        value={firmwareVersion == null ? null : {value: firmwareVersion, label: firmwareVersion}}
                                        options={this.state[availableFirmwareName].map(firmware => ({
                                            value: firmware.get(db.DeviceFirmware.VERSION),
                                            label: `${firmware.get(db.DeviceFirmware.VERSION)} (${firmware.get(db.DeviceFirmware.STATUS)}) `
                                        }))}
                                        onChange={(item) => {
                                            this.state.home.set(firmwareVersionField, item.value);

                                            this.setState({home: this.state.home});
                                        }}
                                        isMulti={false}
                                    />
                                </FormGroup>
                            })
                        }
                        {
                            integerFields.map(field => {
                                return renderIntegerField(field);
                            })
                        }
                        {renderFloatField(db.Home.THERMO_CORRECTION_TEMP)}
                        {
                            isAdmin && <>
                                {renderFloatField(db.Home.MAX_TEMP_CORRECTION)}
                                {renderFloatField(db.Home.MIN_TEMP_CORRECTION)}
                                {renderFloatField(db.Home.MAX_THERMO_CURRENT_LIMIT)}
                            </>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={async () => {
                            try {
                                await this.state.home.save();

                                swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
                            } catch(e){
                                console.error(e);
                                swal('Error', e?.message ?? 'Unknown error', 'error');
                            }
                        }}>Save</Button>
                        <Button outline color="secondary" onClick={() => {
                            this.toggleModal();
                        }}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

GeneralConfigHomeModal.propTypes = {
    setToggleModal: PropTypes.func
};