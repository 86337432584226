import React from 'react';
import {Col, FormGroup, Input, Label, Row} from 'reactstrap';
import RoomDetails from './RoomDetails';
import RoomViewTabs from './RoomViewTabs';

export default class RoomView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <Row>
                    <Col xs="12" sm="6">
                        <FormGroup>
                            <Label for="UserList">Select User:</Label>
                            <Input type="select" name="select" id="UserList">
                                <option>User1</option>
                                <option>User2</option>
                                <option>User3</option>
                                <option>User4</option>
                                <option>User5</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                        <FormGroup>
                            <Label for="roomList">Select Room:</Label>
                            <Input type="select" name="select" id="roomList">
                                <option>Room1</option>
                                <option>Room2</option>
                                <option>Room3</option>
                                <option>Room4</option>
                                <option>Room5</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>

                <RoomDetails />

                <RoomViewTabs />

            </div>
        );
    }
}