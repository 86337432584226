import React from 'react';
import {
    Button,
    Card,
    CardText,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';

export default class RoomViewTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'devices-tab',
            devices: [],
            room: {},
            editRoomSettings: false
        };
    }

    componentWillMount() {
        this.setState({
            devices: this.getDevices(),
            room: this.getRoomSettings()
        });
    }

    getDevices() {
        return [{
            id: 1,
            name: "Device1",
            mac: '1202-1ssf-23f2-34d3'
        }, {
            id: 2,
            name: "Device2",
            mac: '802-1ssf-23f2-34d3'
        }, {
            id: 3,
            name: "Device3",
            mac: '202-1ssf-23f2-34d3'
        }]
    }

    getRoomSettings() {
        return {
            name: "Room1",
            price: 120,
            temp: 30
        }
    }

    toggleEdit() {
        this.setState({
            editRoomSettings: !this.state.editRoomSettings
        });
    }

    handleChange(event, field) {
        this.setState({
            room: {
                ...this.state.room,
                [field]: event.target.value
            }
        });
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    onDeviceOpen(e, cell, row) {
        alert('should go to device page')
    }

    onDeviceDelete(e, cell, row) {
        this.setState({
            devices: this.state.devices.filter((device, index) => {
                if (row.id !== device.id) {
                    return row;
                }
            })
        });
    }

    onDeviceMove(e, cell, row) {
        alert('should move device')
    }

    actionButtons(cell, row) {
        return <div>
            <Button size="sm" color="success" onClick={(e) => this.onDeviceOpen(e, cell, row)}>Show</Button>
            <Button size="sm" color="danger" onClick={(e) => this.onDeviceDelete(e, cell, row)}>Delete</Button>
            <Button size="sm" color="warning" onClick={(e) => this.onDeviceMove(e, cell, row)}>Move</Button>
        </div>;
    }

    render() {
        return (
            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={this.state.activeTab === 'devices-tab' ? 'active' : ''}
                            onClick={() => { this.toggleTab('devices-tab'); }}
                        >
                            Devices
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={this.state.activeTab === 'issues-tab' ? 'active' : ''}
                            onClick={() => { this.toggleTab('issues-tab'); }}
                        >
                            Issues
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={this.state.activeTab === 'settings-tab' ? 'active' : ''}
                            onClick={() => { this.toggleTab('settings-tab'); }}
                        >
                            Settings
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="devices-tab">
                        {/*<BootstrapTable
                            insertRow={true}
                            data={this.state.devices}>
                            <TableHeaderColumn dataField='id' isKey={true} autovalue>Device ID</TableHeaderColumn>
                            <TableHeaderColumn dataField='name'>Device Name</TableHeaderColumn>
                            <TableHeaderColumn dataField='mac'>MAC address</TableHeaderColumn>
                            <TableHeaderColumn dataField='action' hiddenOnInsert dataFormat={(cell, row) => this.actionButtons(cell, row)}>Action</TableHeaderColumn>
                        </BootstrapTable>*/}
                    </TabPane>
                    <TabPane tabId="issues-tab">
                        <Row>
                            <Col sm="6">
                                <Card body>
                                    <CardTitle>Issue 1</CardTitle>
                                    <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                                    <CardText><strong>Priority:</strong> 1</CardText>
                                    <CardText><strong>Status:</strong> Opend</CardText>
                                    <Button>Go to issue</Button>
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card body>
                                    <CardTitle>Issue 2</CardTitle>
                                    <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                                    <CardText><strong>Priority:</strong> 2</CardText>
                                    <CardText><strong>Status:</strong> Closed</CardText>
                                    <Button>Go to issue</Button>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="settings-tab">
                        {this.state.editRoomSettings
                            ?
                            <Form>
                                <FormGroup row>
                                    <Col sm="4">
                                        <Label for="name">Room name:</Label>
                                        <Input
                                            value={this.state.room.name}
                                            onChange={(e) => this.handleChange(e, 'name')}
                                            type="text"
                                            name="name"
                                            id="name"
                                            placeholder="Enter room name.." />
                                    </Col>
                                    <Col sm="4">
                                        <Label for="city">Room temprature:</Label>
                                        <Input
                                            value={this.state.room.temp}
                                            onChange={(e) => this.handleChange(e, 'temp')}
                                            type="number"
                                            name="temp"
                                            id="temp"
                                            placeholder="Enter room temprature.." />
                                    </Col>
                                    <Col sm="4">
                                        <Label for="city">Room price:</Label>
                                        <Input
                                            value={this.state.room.price}
                                            onChange={(e) => this.handleChange(e, 'price')}
                                            type="number"
                                            name="price"
                                            id="price"
                                            placeholder="Enter room price.." />
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Button outline color="primary">Submit</Button> {' '}
                                    <Button outline color="default" onClick={() => this.toggleEdit()}>Cancel</Button>
                                </FormGroup>
                            </Form>
                            :
                            <div>
                                <FormGroup row>
                                    <Col sm="4">
                                        <Label for="name">Name:</Label>
                                        <p><b>{this.state.room.name}</b></p>
                                    </Col>
                                    <Col sm="4">
                                        <Label for="surname">Room temprature:</Label>
                                        <p><b>{this.state.room.temp}</b></p>
                                    </Col>
                                    <Col sm="4">
                                        <Label for="surname">Room price:</Label>
                                        <p><b>{this.state.room.price}</b></p>
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Button outline color="danger" onClick={() => this.toggleEdit()}>Edit</Button>
                                </FormGroup>
                            </div>
                        }
                    </TabPane>
                </TabContent>
            </div>
        );
    }
}