const db = require( '../../lib/dbStructure');
const moment = require('moment');
const Parse = require('parse');

/**
 * @return {Parse.Promise<Array<Parse.Object>>}
 */
const getOfflineDevices = () => {
    let query1 = new Parse.Query(db.classes.Device);
    query1.lessThanOrEqualTo(db.Device.LAST_MEASUREMENT_DATE, moment().subtract(1, 'day').toDate());

    let query2 = new Parse.Query(db.classes.Device);
    query2.equalTo(db.Device.LAST_MEASUREMENT_DATE, null);

    let query = new Parse.Query(db.classes.Device);
    query.notEqualTo(db.Device.DELETED, true);
    query.include(db.Device.LAST_MEASUREMENT);
    //query.equalTo(db.Device.DEVICE_STATE_FLAG, db.Device.DEVICE_STATE_FLAG$ONLINE);
    query.exists(db.Device.ROOM_ID);
    query.limit(5000);
    query.select([
        db.Device.ROOM_ID,
        db.Device.SERIAL_NUMBER,
        db.Device.LAST_MEASUREMENT
    ]);

    query._orQuery([query1, query2]);

    return query.find();
};

/**
 * @return {Parse.Promise<Array<Parse.Object>>}
 */
const getAllRooms = () => {
    let query1 = new Parse.Query(db.classes.Home);
    query1.notEqualTo(db.Home.MONITORING_ACTIVATED, false);
    query1.notEqualTo(db.Home.DELETED, true);

    let query = new Parse.Query(db.classes.Room);

    query.notEqualTo(db.Room.DELETED, true);
    query.notEqualTo(db.Room.HIDE, true);
    query.exists(db.Room.HOME);
    query.include(db.Room.HOME);
    query.limit(5000);
    query.notEqualTo(db.Room.MONITORING_ACTIVATED, false);
    query.select([
        db.Room.HOME,
        db.Room.ROOM_NAME,
        db.Room.MAIN_PHOTO
    ]);

    query.matchesQuery(db.Room.HOME, query1);

    return query.find();
};

/**
 *
 * @param {Array<Parse.Object>} rooms
 */
const createRoomHomeMap = (rooms) => {
    let roomHomeMap = {};

    rooms.forEach(room => {
        let home = room.get(db.Room.HOME);

        if(!roomHomeMap[room.id])
            roomHomeMap[room.id] = home
    });

    return roomHomeMap;
};

/**
 *
 * @param {Array<Parse.Object>} devices
 * @param {Object} roomHomeMap
 *
 * @return {Array}
 */
const divideByHome = (devices, roomHomeMap) => {
    let homesDevicesMap = {};

    devices
        .filter(device => device.get(db.Device.ROOM_ID) != null)
        .filter(device => roomHomeMap[device.get(db.Device.ROOM_ID).id] != null)
        .forEach(device => {
            let room = device.get(db.Device.ROOM_ID);
            let home = roomHomeMap[room.id];

            if(home.get(db.Home.MONITORING_ACTIVATED) === false) return;

            if(!homesDevicesMap[home.id])
                homesDevicesMap[home.id] = {
                    home: home,
                    rooms: {}
                };

            if(!homesDevicesMap[home.id].rooms[room.id])
                homesDevicesMap[home.id].rooms[room.id] = {
                    room: room,
                    offlineDevices: []
                };

            homesDevicesMap[home.id].rooms[room.id].offlineDevices.push(device);
        });

    let homeDevicesArray = Object.keys(homesDevicesMap).map(homeId => {
        return homesDevicesMap[homeId];
    });

    homeDevicesArray = homeDevicesArray.map(homeDevice => {
        homeDevice.rooms = Object.keys(homeDevice.rooms).map(roomId => {
            return homeDevice.rooms[roomId];
        });

        return homeDevice;
    });

    return homeDevicesArray;
};

const filterDevicesOfflineFor = (devices, value, metric) => {
    return devices.filter(device => {

        if(device.get(db.Device.LAST_MEASUREMENT_DATE) == null)
            return true;

        let lastMeasurementDate =  moment(device.get(db.Device.LAST_MEASUREMENT_DATE));
        let diff = Math.abs(lastMeasurementDate.diff(moment(), metric));

        return  diff >= value;
    });
};

/**
 *
 * @param {Array.<Object>} homeDevicesArray
 */
const printHomesAndOfflineDevices = (homeDevicesArray) => {
    homeDevicesArray.forEach(homeDevices => {
        let home = homeDevices.home;

        for(let roomObject of homeDevices.rooms){
            let room = roomObject.room;
            let devices = filterDevicesOfflineFor(roomObject.offlineDevices, 24, 'hours')
            if(devices<= 0) continue;
        }
    })
};


const loadNotActivatedHomes = () => {
    let query = new Parse.Query(db.classes.Home);

    query.limit(10000);
    query.equalTo(db.Home.MONITORING_ACTIVATED, false);
    query.select([
        db.Home.HOME_NAME,
        db.Home.CITY
    ]);

    return query.find();
};

export default {
    getOfflineDevices,
    getAllRooms,
    createRoomHomeMap,
    divideByHome,
    printHomesAndOfflineDevices,
    loadNotActivatedHomes
};