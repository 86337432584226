'use strict';

import React from 'react';
import db from './dbStructure';
import moment from 'moment';
import _ from 'lodash';
import Parse from './parse';
import swal from 'sweetalert';
import {Badge, Progress} from "reactstrap";
import config from '../conf';

/**
 *
 * @param {Parse.Object} parseObject
 * @return {{__type: string, className: string, objectId: string}}
 */
export let toPointer = (parseObject) => {
    let className = parseObject.className;
    return {
        '__type': 'Pointer',
        'className': className,
        'objectId': parseObject.id
    };
};

/**
 * Get pointer from parse object id.
 *
 * @param parseObjectId
 * @param className
 * @return {{__type: string, className: *, objectId: *}}
 */
export let toPointerFromId = (parseObjectId, className) => {
    return {
        '__type': 'Pointer',
        'className': className,
        'objectId': parseObjectId
    };
};

export let getColorFromMinutes = function(minutes, type, flag) {
    if(flag === 'work-in-progress') {
        return {
            color: 'rgba(255,152,0,1)',
            colorName: 'orange'
        }
    }

    if(flag === 'client-action-required') {
        return {
            color: 'rgba(251,192,45,1)',
            colorName: 'yellow'
        }
    }

    if(flag === 'inactive') {
        return {
            color: 'rgb(150, 150, 150)',
            colorName: 'grey'
        }
    }

    var percentColors = [
        { minutes: 120, color: 'rgba(76,175,80,1)', colorName: 'green' },
        { minutes: 180, color: 'rgb(210,141,43)', colorName: 'orange' },
        { minutes: Number.MAX_SAFE_INTEGER, color: 'rgba(229,57,53,1)', colorName: 'red'}
    ];

    if(type === 'sense' || type === 'sensp') {
        percentColors = [
            { minutes: 6, color: 'rgba(76,175,80,1)', colorName: 'green'  },
            { minutes: 12, color: 'rgb(210,141,43)', colorName: 'orange'  },
            { minutes: Number.MAX_SAFE_INTEGER, color: 'rgba(213,0,0,1)', colorName: 'red'}
        ];
    }

    for (var i = 0; i < percentColors.length; i++) {
        if (minutes <= percentColors[i].minutes) {
            break;
        }
    }

    return  percentColors[i];
};

export function voltToPerc(volt){
    const lessThan10 = 2.9;
    const lessThan15 = 3;
    const lessThan25 = 3.25;
    const lessThan50 = 3.59;
    const lessThan75 = 3.94;
    const lessThan100 = 4.3;

    if (volt == null) return null;
    if (volt === -1) return -1;

    if (volt <= lessThan10) return 10;
    if (volt <= lessThan15) return 15;
    if (volt <= lessThan25) return 25;
    if (volt <= lessThan50) return 50;
    if (volt <= lessThan75) return 75;
    if (volt <= lessThan100) return 100;

    return 100;
}

export function getColorFromBatteryLevel(volt){
    let perc = voltToPerc(volt);

    if (perc === -1) return 'green';

    if (perc <= 20) return 'red';

    if (perc <= 80) return 'lightsalmon';

    return 'green'
}

export function getColorFromWifiDbm(db){
    if (!db) {
        return 'red';
    } else {
        if (db < -80) {
            return 'red';
        } else if (db < -70) {
            return 'orange';
        } else {
            return 'green'
        }
    }
}

function getColorFromMotorPosition(motorPosition){
    if(motorPosition === 102){
        return 'red';
    }
    if(motorPosition === 101){
        return 'red';
    }

    if(motorPosition === -1){
        return 'red';
    }

    return 'black';
}

export let getDataFromMeasurement = (measurement, device) => {
    let data = {
        deviceId: device.id,
        deviceMacAddress: device.get(db.Device.MAC_ADDRESS),
        roomName: null,
        serial: null,
        deviceTyp: null,
        createdAt: null,
        diffMinutes: null,
        diff: null,
        temp: null,
        wifiName: null,
        setStaticIp: null,
        currentIp: null,
        port: null,
        wifiPower: null,
        versionFirmware: null,
        deviceStateFlag: null,
        batteryVoltage: null,
        mode: null
    };
    let roomName = 'Not defined';

    data.roomName = device.get(db.Device.ROOM_ID).get(db.Room.ROOM_NAME) +
        '-' + device.get(db.Device.ROOM_ID).get('numberRadiators') || '';

    if(!data.roomName) {roomName = 'Not defined';}

    data.serial = device.get(db.Device.SERIAL_NUMBER);
    data.deviceTyp = device.get(db.Device.DEVICE_TYP) ||
        measurement.get(db.Measurement.DEVICE_TYP) || 'no-type-defined';

    data.createdAt = moment(measurement.get(db.Measurement.CREATED_AT)).format('DD/MM/YYYY HH:mm:ss');

    data.diffMinutes = Math.abs(moment(measurement.get(db.Measurement.CREATED_AT))
        .diff(moment(), 'minutes'));

    let diff = moment(measurement.get(db.Measurement.CREATED_AT))
        .fromNow();

    data.diff = <span className={getColorFromMinutes(data.diffMinutes, data.deviceTyp).colorName}>{diff}</span>;

    data.temp = measurement.get(db.Measurement.TEMP);

    let request = measurement.get(db.Measurement.REQUEST);

    let dbm = _.get(_.find(_.get(request, 'params.deviceState.data'),
        (state) => state.type[0] === 'State' && state.type[1] === 'RSSI'), 'value');

    data.wifiName = _.get(request, 'params.deviceConfig.data[0].wifiName');
    data.setStaticIp = _.get(request, 'params.deviceConfig.data[0].staticIP');
    data.currentIp = _.get(_.find(_.get(request, 'params.deviceState.data'),
        (state) => state.type[0] === 'State' && state.type[1] === 'IP'), 'value');
    data.port = _.get(request, 'headers.x-request-port');
    data.wifiPower = <span style={{color: getColorFromWifiDbm(dbm)}}>{dbm}dBm</span>;

    data.offlineMode = _.get(_.find(_.get(request, 'params.deviceState.data'),
        (state) => state.type[0] === 'System' && state.type[1] === 'Mode'), 'value');

    data.actualConnectionWifi = _.get(_.find(_.get(request, 'params.deviceState.data'),
        (state) => state.type[0] === 'State' && state.type[1] === 'Connection-SSID'), 'value');

    if(data.actualConnectionWifi){
        let split = data.actualConnectionWifi.split(':');
        data.actualConnectionWifi = split[0];
    } else {
        data.actualConnectionWifi = '-'
    }

    data.versionFirmware = _.get(request, 'params.deviceInfo.versionFirmware');

    data.deviceStateFlag = device.get(db.Device.DEVICE_STATE_FLAG) || '';

    let batteryVoltage = measurement.get(db.Measurement.BATTERY_VOLTAGE);

    data.batteryVoltage = <span style={{color: getColorFromBatteryLevel(batteryVoltage)}}>{batteryVoltage|| '-'}V</span>;
    data.mode = measurement.get(db.Measurement.MODE);

    return data;
};

export function round(x, n) {
    var a = Math.pow(10, n);
    return (Math.round(x * a) / a);
}

export function isRolesInRoles(roles){
    let arrayRoles = Parse.roles;

    if(!arrayRoles || !roles) return false;

    let found = false;

    roles.forEach(role => {
        if(arrayRoles.indexOf(role) >= 0) {
            found = true;
            return;
        }
    });

    return found;
}

/**
 *
 * @param acl
 * @returns {{writeUsers: string[], readUsers: string[]}}
 */
export function getReadWriteUsers(acl) {
    let homeACLObject = acl.toJSON();
    let keys = Object.keys(homeACLObject);

    let readUsers = keys.filter(key => !key.startsWith('role:')).filter(key => homeACLObject[key].read);
    let writeUsers = keys.filter(key => !key.startsWith('role:')).filter(key => homeACLObject[key].write);

    return {
        readUsers,
        writeUsers
    }
}

/**
 *
 * @param readUsers
 * @param writeUsers
 * @returns {Parse.ACL}
 */
export function createBasicACL(readUsers, writeUsers){
    let acl = new Parse.ACL();
    for(let readUser of readUsers){
        acl.setReadAccess(readUser, true);
    }

    for(let writeUser of writeUsers){
        acl.setWriteAccess(writeUser, true);
    }

    acl.setRoleReadAccess(db.roles.ADMIN, true);
    acl.setRoleWriteAccess(db.roles.ADMIN, true);
    acl.setRoleReadAccess(db.roles.LEAN_MANAGEMENT, true);
    acl.setRoleWriteAccess(db.roles.LEAN_MANAGEMENT, true);
    acl.setPublicReadAccess(false);
    acl.setPublicWriteAccess(false);

    return acl;
}

/**
 *
 * @param object1
 * @param object2
 * @returns {Parse.Object} object2
 */
export function copyACLToObject(object1, object2){
    if(!object1) throw Error('object1 is not defined');
    if(!object2) throw Error('Room is not defined');
    let object1ACL = object1.getACL();
    if(!object1ACL) throw Error('object1ACL ACL is not defined');

    let {readUsers, writeUsers} = getReadWriteUsers(object1ACL);
    let object2ACL = createBasicACL(readUsers, writeUsers);

    object2.setACL(object2ACL);
}

export function moveDeviceToRoom(device, newRoom) {
    if(!device) throw Error('Device to be moved is not defined');
    if(!newRoom) throw Error('Target room is not defined');
    let home = newRoom.get(db.Room.HOME);
    if(!home) throw Error('Room should be linked to a building.');

    device.set(db.Device.ROOM_ID, newRoom);
    device.set(db.Device.HOME, home);

    copyACLToObject(newRoom, device);

    return device;
}

export function findDevice(serial){
    let query = new Parse.Query(db.classes.Device);

    query.equalTo(db.Device.SERIAL_NUMBER, serial);

    return query.first();
}

const changeDeviceFlag =  async (e, device) => {
    e.preventDefault();

    let flag = device.get(db.Device.DEVICE_STATE_FLAG);
    let newFlag = null;

    if (flag === 'in-mount') {
        newFlag = 'online';
    }

    if (flag !== 'in-mount') {
        newFlag = 'in-mount';
    }

    device.set(db.Device.DEVICE_STATE_FLAG, newFlag);

    await device.save();

    swal({title: 'Success', text: `Changed to ${newFlag}`, icon: 'success', button: [''], timer: 1000});
};


function getColorFromTemperatureCorrection(tempCorrection){

    var percentColors = [
        { tempCorrection: -3, color: 'rgba(76,175,80,1)', colorName: 'green' },
        { tempCorrection: -5, color: 'rgb(210,141,43)', colorName: 'orange' },
        { tempCorrection: -Number.MAX_SAFE_INTEGER, color: 'rgba(229,57,53,1)', colorName: 'red'}
    ];

    if(!tempCorrection) return percentColors[0];

    for (var i = 0; i < percentColors.length; i++) {
        if (tempCorrection >= percentColors[i].tempCorrection) {
            break;
        }
    }

    return  percentColors[i];
}

export function getMotorErrorsFromDevice(device, isAdmin){
    let numberMotorPositionValue = device.get(db.Device.NUMBER_MOTOR_POSITION_VALUE) || {};

    let motorErrors = [];
    if(numberMotorPositionValue['101'] > 0 && isAdmin)
        motorErrors.push(`101:${numberMotorPositionValue['101']}`);
    if(numberMotorPositionValue['102'] > 0)
        motorErrors.push(`102:${numberMotorPositionValue['102']}`);
    if(numberMotorPositionValue['103'] > 0)
        motorErrors.push(`103:${numberMotorPositionValue['103']}`);
    if(numberMotorPositionValue['-1'] > 0)
        motorErrors.push(`-1:${numberMotorPositionValue['-1']}`);

    return motorErrors;
}

export function groupThermosByMotorError(thermos){
    let thermosByMotorError = {};

    let motorErrors = [
        '101',
        '102',
        '130',
        '-1'
    ];

    thermos.forEach(device => {
        let motorPositionsValue = device.get(db.Device.NUMBER_MOTOR_POSITION_VALUE) || {};

        motorErrors.forEach(motorError => {
            if(motorPositionsValue[motorError] > 0){
                if(thermosByMotorError[motorError] == null){
                    thermosByMotorError[motorError] = {
                        devices: []
                    }
                }

                thermosByMotorError[motorError].devices.push(device);
            }
        });
    });

    return thermosByMotorError;
}

/**
 *
 * @param device
 * @param sensp
 * @return {JSX.Element}
 */
export function getStringFromDevice(device, sensp, mapPeerToSensor, mapNeighborsToRoomsWithWifiStrength){
    let roles = Parse.roles;
    let isAdmin = roles.indexOf('Admin') >= 0;
    let isSupport = roles.indexOf('Support') >= 0;
    let isLeanManagement = roles.indexOf('Lean management') >= 0;
    let isEngineeringSupport = roles.indexOf('Engineering support') >= 0;
    let batteryVoltage = device.get(db.Device.BATTERY_VOLTAGE);
    let lastMeasurementDate = device.get(db.Device.LAST_MEASUREMENT_DATE);
    lastMeasurementDate = lastMeasurementDate  ? moment(lastMeasurementDate) : moment('17/01/2020', 'DD/MM/YYYY');
    let diffMinutes = Math.abs(lastMeasurementDate.diff(moment(), 'minutes'));
    let deviceTyp = device.get(db.Device.DEVICE_TYP);
    let diff = lastMeasurementDate.fromNow();
    let serial = device.get(db.Device.SERIAL_NUMBER);
    let wifiStrength = device.get(db.Device.WIFI_STRENGTH);
    let configuredSsid = device.get(db.Device.CONFIG_WIFI_SSID);
    let configuredPassword = device.get(db.Device.CONFIG_WIFI_PASSWORD);
    let connectionSsid = device.get(db.Device.CONNECTION_WIFI_SSID);
    let connectionPassword = device.get(db.Device.CONNECTION_WIFI_PASSWORD);
    let mode = device.get(db.Device.MODE);
    let temperatureCorrection = device.get(db.Device.CORRECTION_TEMP);
    let temp = device.get(db.Device.MEASURED_TEMP);
    let co2 = device.get(db.Device.CO2);
    let motorPosition = device.get(db.Device.MOTOR_POSITION);
    let motorCurrents = device.get(db.Device.MOTOR_CURRENT_LIMITS);
    let mountedEngine = device.get(db.Device.MOUNTED_ENGINE);
    let connectionPort = device.get(db.Device.HEADER_X_REQUEST_PORT);
    let configuredPort = device.get(db.Device.HEADER_REQUEST_HOST);
    let numberMotorResponseValue = device.get(db.Device.NUMBER_MOTOR_RESPONSE_VALUE);
    let numberMotorTags = device.get(db.Device.MOTOR_TAG);
    let numberCommandValue = device.get(db.Device.NUMBER_COMMAND_VALUE);
    let defectPrediction = device.get(db.Device.DEFECT_PREDICTION);
    let numberRes0 = numberMotorResponseValue && numberMotorResponseValue['0'];
    let numberRes100 = numberMotorResponseValue && numberMotorResponseValue['100'];
    let brokenMotorTag = numberMotorTags && numberMotorTags['errorBrokenMotor'];
    let numberCalibrationCommand = numberCommandValue && numberCommandValue['calibrateMotor'];
    let nbIotEnabled = device.get(db.Device.NB_IOT_ENABLED);
    let iccid = device.get(db.Device.ICCID);
    let sigQ = device.get(db.Device.NB_IOT_SIGNAL_QUALITY);
    let macAddress = device.get(db.Device.MAC_ADDRESS);
    let refurbishedDate = device.get(db.Device.REFURBISHED_DATE);
    let firmwareUpdateStatus = device.get(db.Device.FIRMWARE_UPDATE_STATUS);
    /*
    deviceConfig: {
        data: [
            {
                type: 'CommunicationConfig',
                wifiName: 'simplyhome1',
                wifiPassword: 'simplyhome1',
                staticIP: '192.168.1.100',
                staticDNS: '8.8.8.8',
                staticGateway: '255.255.255.0',
                staticSubnet: '192.268.1.1'
            }
        ]
    }
    */
    let deviceConfig = device.get(db.Device.DEVICE_CONFIG);
    let deviceConfigSSID = '';
    let deviceConfigPASS = '';
    let neighborsList = device.get(db.Device.NEIGHBORS_LIST) || [];
    let lastSleepTimeValue = device.get(db.Device.LAST_RESPONSE_SLEEP_COMMAND_VALUE);
    let tag = device.get(db.Device.TAG);
    let tags = tag?.split(' ');

    if(deviceConfig){
        if(deviceConfig.deviceConfig){
            if(deviceConfig.deviceConfig.data){
                if(deviceConfig.deviceConfig.data[0]){
                    if(deviceConfig.deviceConfig.data[0].wifiName){
                        deviceConfigSSID = deviceConfig.deviceConfig.data[0].wifiName
                    }
                    if(deviceConfig.deviceConfig.data[0].wifiPassword){
                        deviceConfigPASS = deviceConfig.deviceConfig.data[0].wifiPassword
                    }
                }
            }
        }
    }
    let numberMotorPositionValue = device.get(db.Device.NUMBER_MOTOR_POSITION_VALUE) || {};

    let motorErrors = getMotorErrorsFromDevice(device, isAdmin);

    let deviceHasCustomWifiConfig = !_.isEmpty(_.omit(device.get(db.Device.DEVICE_CONFIG)?.deviceConfig?.data?.[0], 'type'));

    let nbIoTPresentButInactive = nbIotEnabled === false && iccid != null;
    let nbIoTPresentAndActive = nbIotEnabled === true && iccid != null;
    let nbIoTNotSupported = iccid == null;

    let defectExplainString = `res0:${numberRes0}, res100:${numberRes100}, numBrokenMotor:${brokenMotorTag}, numberCalibrationCommand:${numberCalibrationCommand}`;

    let lastConnectionProtocol = device.get(db.Device.LAST_CONNECTION_PROTOCOL);
    let peerList = sensp && sensp.get(db.Device.PEER_LIST);
    let neighborsWifiList = sensp && sensp.get(db.Device.NEIGHBORS_WIFI_STRENGTH);
    let chipVersion = device.get(db.Device.NB_IOT_FIRMWARE_VERSION);

    if(sensp != null) console.log(neighborsWifiList);

    let assignedTo;
    if(mapPeerToSensor) {
        let sensor = mapPeerToSensor[device.id];
        let room = sensor && sensor.get(db.Device.ROOM_ID);
        let roomName = room && room.get(db.Room.ROOM_NAME);

        if(!sensor) assignedTo = 'N/A';
        if(sensor) assignedTo = roomName;
    }
    let neighborsRooms;
    if(mapNeighborsToRoomsWithWifiStrength){
        neighborsRooms = mapNeighborsToRoomsWithWifiStrength[device.id];
    }


    let deviceTypeWNBP ;

    if(deviceTyp === db.Device.DEVICE_TYP$THERM && co2 != null && co2 >= 1){
        deviceTypeWNBP = 'WNBP';
    }

    if(deviceTyp  === db.Device.DEVICE_TYP$THERM && co2 == null){
        deviceTypeWNBP = 'WNB';
    }


    let batteryIsCharging = device.get(db.Device.BATTERY_IS_CHARGING);
    let iotCoreShadow = device.get(db.Device.IOT_CORE_SHADOW_1);
    let iotCoreShadowDesired = device.get(db.Device.IOT_CORE_SHADOW_1_DESIRED);
    let versionFirmware = device.get(db.Device.VERSION_FIRMWARE);
    let pidConfiguration = null;
    let emergencyTemp = null;
    let maxT = null;
    let usbAttached = false;
    let fccb;

    if(iotCoreShadow != null){
        if(motorCurrents == null) motorCurrents = {};
        if(pidConfiguration == null) pidConfiguration = {};

        motorCurrents.cL = iotCoreShadow?.mlim?.lclose;
        motorCurrents.fSL = iotCoreShadow?.mlim?.fmax;
        motorCurrents.fSpDC = iotCoreShadow?.mlim?.fmin;
        fccb = iotCoreShadow?.mlim?.fccb;

        versionFirmware = iotCoreShadow?.fwVersion;
        iccid = iotCoreShadow?.iccid;

        pidConfiguration.kp = iotCoreShadow?.reg?.pid?.kp;
        pidConfiguration.ki = iotCoreShadow?.reg?.pid?.ki;

        emergencyTemp = iotCoreShadow?.reg?.minT;
        maxT = iotCoreShadow?.reg?.maxT;


        usbAttached = iotCoreShadow?.usb === true;
    }

    let desiredEmergencyTemp = null;
    let desiredMaxT = null;
    let desiredLClose = null;
    if(iotCoreShadowDesired != null){
        desiredEmergencyTemp = iotCoreShadowDesired?.reg?.minT;
        desiredMaxT = iotCoreShadowDesired?.reg?.maxT;
        desiredLClose = iotCoreShadowDesired?.mlim?.lclose;
    }

    let hideMountMode = serial > 4400000;

    function formatNumber(value){
        if(!value) return '';

        if(typeof value === 'number'){
            return value;
        }

        return '';
    }

    return <span>
        {isAdmin && defectPrediction === db.Device.DEFECT_PREDICTION$DEFECT && !refurbishedDate && <i className="fa fa-exclamation-triangle" title={defectExplainString} aria-hidden="true"></i>}&nbsp;
        <a href={`https://eu-central-1.console.aws.amazon.com/iot/home?region=eu-central-1#/thing/${device.id}/namedShadow/1`} target={'_blank'}>{device.id}</a>&nbsp;
        <a href={`https://eu-central-1.console.aws.amazon.com/iot/home?region=eu-central-1#/thing/${device.id}?tab=jobs`} target={'_blank'}>j</a>&nbsp;
        <a href={`/devices/${device.id}`} target={'_blank'}>{serial}</a>&nbsp;
        <a href={`/devices/${device.id}`} target={'_blank'}>{macAddress && macAddress.substr(macAddress.length - 5)}</a>&nbsp;

        {!!refurbishedDate && <Badge color={'success'}>refurbished ({moment(refurbishedDate).format('MM-YY')})</Badge>}&nbsp;
        {
            isAdmin &&
            tags?.length > 0 &&
            tags.map(tag => {
                let color = 'warning';
                if(tag.includes('err')){
                    color = 'danger';
                }
                if(tag.includes('ok')){
                    color = 'success';
                }
                return <Badge color={color}>{tag}</Badge>;
            })
        }
        {
            lastConnectionProtocol && <Badge>
                {lastConnectionProtocol}
                {
                    assignedTo != null && <>({assignedTo})</>
                }
                {
                    neighborsRooms && diffMinutes > 190 && <>({neighborsRooms.map(neighborsRoom => {
                        let roomName = neighborsRoom.room.get(db.Room.ROOM_NAME);
                        let floor = neighborsRoom.room.get(db.Room.FLOOR);
                        let wifiStrength = neighborsRoom.wifiStrength;

                        return `P${floor}-${roomName}-${wifiStrength}`
                    }).join(',')})</>
                }
            </Badge>
        }
        {
            motorCurrents != null && motorCurrents.cL === 0 && <Badge color={'warning'}>calibration-needed</Badge>
        }
        {
            motorErrors.length > 0 && <Badge color={'warning'}>{motorErrors.join(',')}</Badge>
        }
        {
            lastSleepTimeValue != null && lastSleepTimeValue === 180 && <Badge color={'warning'}>180 sleep</Badge>
        }
        {
            deviceHasCustomWifiConfig &&  <Badge color={'warning'}><i className="fa fa-exclamation-triangle"/>Custom WiFi config</Badge>
        }
        {
            neighborsList.length > 0 && <>
                {
                    neighborsList.map(device => {
                        let mac = device.get(db.Device.MAC_ADDRESS);
                        let macLast4Chars = '';
                        if(mac){
                            macLast4Chars = mac.slice(-5);
                        }
                        if(!mac) console.log(device);
                        let signalStrength = neighborsWifiList && neighborsWifiList[device.id];
                        if(sensp != null){
                            if(device.id === sensp.id) return <Badge key={device.id} color={'success'}>
                                <b>{macLast4Chars},</b>
                            </Badge>;
                        }
                        return <Badge key={device.id}>{macLast4Chars}({signalStrength}),</Badge>;
                    })
                }
            </>
        }
        {
            sensp && device.id === sensp.id && peerList && peerList.length > 0 && <>
                {
                    peerList.map(device => {
                        let mac = device.get(db.Device.MAC_ADDRESS);
                        let macLast4Chars = '';
                        if(mac){
                            macLast4Chars = mac.slice(-5);
                        }
                        if(!mac) console.log(device);

                        return <Badge key={device.id} color={'success'}>{macLast4Chars},</Badge>;
                    })
                }
            </>
        }
        &nbsp;
        {
            !hideMountMode && <a
                href='#'
                onClick={async (e) => await changeDeviceFlag(e, device)}>
                {device.get(db.Device.DEVICE_STATE_FLAG) || 'online'}
            </a>
        }
        &nbsp;
         {device.get(db.Device.DEVICE_TYP)}
        {
            deviceTyp === db.Device.DEVICE_TYP$THERM &&
            deviceTypeWNBP != null &&
            <span>({deviceTypeWNBP})</span>
        }
        &nbsp;
        <span style={{color: getColorFromBatteryLevel(batteryVoltage)}}>{formatNumber(batteryVoltage)|| '-'}V</span>&nbsp;
        <span style={{color: getColorFromWifiDbm(wifiStrength)}}>{wifiStrength}dBm</span>&nbsp;
        {sigQ || ''}&nbsp;
        <span style={{color: getColorFromMotorPosition(motorPosition)}}>{formatNumber(motorPosition)}</span>&nbsp;
        {versionFirmware}&nbsp;
        {device.get(db.Device.STATIC_IP)}&nbsp;
        {mode}&nbsp;
        ({deviceConfigSSID}:{deviceConfigPASS})&nbsp;
        {configuredSsid}:{configuredPassword}&nbsp;
        {connectionSsid}:{connectionPassword}&nbsp;
        {configuredPort && configuredPort.replace('server.simplyhome.tech:', '')}:{connectionPort}&nbsp;
        {fccb === true && <i className="fa fa-long-arrow-right" aria-hidden="true"></i>}&nbsp;
        {motorCurrents != null ? `${motorCurrents.cL || ''} (${desiredLClose || ''}),${motorCurrents.fSL || ''},${motorCurrents.fSpDC || ''},${motorCurrents.maxC || ''},${motorCurrents.minC || ''},${motorCurrents.mCal || ''}` : ''}&nbsp;
        {pidConfiguration != null && `${pidConfiguration.kp || ''},${pidConfiguration.ki || ''}`}&nbsp;
        {`${emergencyTemp || ''} (${desiredEmergencyTemp || ''})`}&nbsp;
        {`${maxT || ''} (${desiredMaxT || ''})`}&nbsp;
        <span ><i className="fa fa-sim-card" aria-hidden="true"></i> {iccid}</span>
        {
            nbIoTPresentButInactive && <span>
                <i className="fa fa-sim-card" aria-hidden="true"></i>
                <i className="fa fa-power-off" aria-hidden="true"></i>
            </span>
        }
        &nbsp;
        {chipVersion}
        {
            chipVersion === config.nbIot.REQUIRED_NB_IOT_FIRMWARE_VERSION && <i className="fa fa-check" style={{color: 'green'}}></i>
        }
        {
            chipVersion !== config.nbIot.REQUIRED_NB_IOT_FIRMWARE_VERSION && <i className="fa fa-times" style={{color: 'red'}}></i>
        }
        &nbsp;
        {
            batteryIsCharging === true &&
            <span style={{color: 'green'}}><i className="fa fa-bolt" aria-hidden="true"></i></span>
        }
        {
            batteryIsCharging === false &&
            <span style={{color: 'red'}}><i className="fa fa-bolt" aria-hidden="true"></i></span>
        }
        &nbsp;
        {lastMeasurementDate.format('DD/MM/YYYY HH:mm')}&nbsp;
        <span className={getColorFromMinutes(diffMinutes, deviceTyp).colorName}>{diff}</span>&nbsp;
        {
            firmwareUpdateStatus && <div style={{display: 'inline-block'}}>
                {firmwareUpdateStatus.version}
                <Progress
                    style={{width: 100}}
                    value={Math.round(firmwareUpdateStatus.percentage)}
                >{Math.round(firmwareUpdateStatus.percentage)}%</Progress>
            </div>
        }
    </span>
}

export function extractRoomFeedbackValues(roomFeedback){
    let room = roomFeedback.get(db.RoomFeedback.ROOM);
    let home = roomFeedback.get(db.RoomFeedback.HOME);
    let createdAt = roomFeedback.get(db.RoomFeedback.CREATED_AT);
    let owner = home ? home.get(db.Home.OWNER) : null;
    let status = roomFeedback.get(db.RoomFeedback.STATUS);
    let temperatureFeedbackValue = roomFeedback.get(db.RoomFeedback.TEMPERATURE_FEEDBACK_VALUE);
    let usageFeedbackValue = roomFeedback.get(db.RoomFeedback.USAGE_FEEDBACK_VALUE);
    let gravityFeedbackValue = roomFeedback.get(db.RoomFeedback.GRAVITY_FEEDBACK_VALUE);
    let radiatorsFeedbackValue = roomFeedback.get(db.RoomFeedback.RADIATORS_FEEDBACK_VALUE);
    let language = roomFeedback.get(db.RoomFeedback.LANGUAGE);
    let text = roomFeedback.get(db.RoomFeedback.TEXT);
    let internalComment = roomFeedback.get(db.RoomFeedback.INTERNAL_COMMENT);
    let ticketId = roomFeedback.get(db.RoomFeedback.CRM_TICKET_ID);
    let ticketUrl = ticketId ? `https://desk.zoho.eu/support/simplyhome/ShowHomePage.do#Cases/dv/$crmTicketId${ticketId}` : null;
    let email = roomFeedback.get(db.RoomFeedback.EMAIL);

    return {
        room,
        home,
        createdAt,
        owner,
        status,
        temperatureFeedbackValue,
        usageFeedbackValue,
        gravityFeedbackValue,
        radiatorsFeedbackValue,
        language,
        text,
        internalComment,
        ticketId,
        ticketUrl,
        email
    }
}

export async function getRoomFeedbackSolutionTag(){
    let roomFeedbackSolutions = [
        db.RoomFeedback.SOLUTION_TAG$TEMPERATURE_CALIBRATION,
        db.RoomFeedback.SOLUTION_TAG$CONTROL_SYSTEM_RESET,
        db.RoomFeedback.SOLUTION_TAG$CONFIGURATION_ADD,
        db.RoomFeedback.SOLUTION_TAG$NETWORK_RESET,
        db.RoomFeedback.SOLUTION_TAG$TEMPERATURE_RADIATORS_TOO_LOW,
        db.RoomFeedback.SOLUTION_TAG$SIGNAL_STRENGTH_TOO_WEAK,
        db.RoomFeedback.SOLUTION_TAG$TEMP_MAX_TOO_LOW,
        db.RoomFeedback.SOLUTION_TAG$TEMP_MIN_TOO_LOW,
        db.RoomFeedback.SOLUTION_TAG$HEAT_BEFORE,
        db.RoomFeedback.SOLUTION_TAG$NONE,
        db.RoomFeedback.SOLUTION_TAG$OTHER
    ];

    let buttons = {
        cancel: 'Cancel'
    };

    roomFeedbackSolutions.forEach(solution => buttons[solution]=solution);

    let resultCategory = await swal({
        title: 'What did you do to solve the problem? Or what is the current issue that CLEVERON cannot solve?',
        text: `
        "Temperature calibration": The measured temperature was not correct
        
        "Control system reset": The control system was not controlling the temperature correctly
        
        "Configuration add": the device were not configured correctly, you added the correct configuration
        
        "Network reset": You did some actions that should help the device to connect properly
        
        "Temperature radiators too low": Cleveron control system works as espected, but even with max power the temperature does not rise. A "Control system reset" can be done for security anyways.
        
        "Signal too low": The network signal is too low in this room, you exepect some device to have connection issues
        
        "Temperature max is too low": The configured max temp. from the customer is too low, the system works as espected.
        
        "Temperature min is too low": The configured min temp. from the customer is too low, the system works as esüected but it's normal that is taking longer time to reach the wanted temperature if the min. temp. is set so low
       
        "None": Nothing was specially wrong, the system is working as espected, we solve it for now that we see if we receive further feedbacks
       `,
        buttons
    });

    return resultCategory;
}


export async function isUserSureToContinue(options = {}) {
    let sure =  await swal({
        title: options.title || 'Are you sure?',
        text: options.text || '',
        icon: 'warning',
        dangerMode: true,
        buttons: ['Cancel', 'Yes']
    });

    if(!sure) throw Error('Action aborted by user.');
}

export function constructStartDiscoveryNowCommand(device, minutes = 10){
    let command = new Parse.Object(db.classes.CommandQueue);
    command.set(db.CommandQueue.COMMAND_NAME, db.commands.START_NOW_DISCOVERY);

    let data = {
        timeout: minutes,
        unit: "minutes"
    };

    command.set(db.CommandQueue.DATA, data);
    command.set(db.CommandQueue.DEVICE, device);

    return command;
}

export function constructStartMeshModeCommand(device, duration=120){
    let command = new Parse.Object(db.classes.CommandQueue);
    command.set(db.CommandQueue.COMMAND_NAME, db.commands.START_MESH_MODE);
    command.set(db.CommandQueue.DATA, {
        duration,  //max Time is 24h
        unit: 'minutes'
    });
    command.set(db.CommandQueue.DEVICE, device);

    return command;
}

export function construct_OLD_ExtendedConfigCommand(device){
    let command = new Parse.Object(db.classes.CommandQueue);
    command.set(db.CommandQueue.COMMAND_NAME, db.commands.EXTENDED_CONFIG);
    command.set(db.CommandQueue.DATA, { // All green
        'offlineMode':	1,
        'ringBrgt': 255,
        'statusBrgt':255,
        'threshold':[
            {
                'value': 100,
                'red': 0,
                'green':255,
                'blue': 0,
                'redF': 0,
                'greenF': 255,
                'blueF':0
            },
            {
                'value': 150,
                'red': 0,
                'green':255,
                'blue': 0,
                'redF': 0,
                'greenF':255,
                'blueF':0
            },
            {
                'value': 200,
                'red': 0,
                'green':255,
                'blue': 0,
                'redF': 0,
                'greenF': 255,
                'blueF':0
            }
        ]
    });
    command.set(db.CommandQueue.DEVICE, device);

    return command;
}

export function constructStartDebugCommand(device){
    let command = new Parse.Object(db.classes.CommandQueue);
    command.set(db.CommandQueue.COMMAND_NAME, db.commands.START_DEBUG);
    command.set(db.CommandQueue.DATA, {});
    command.set(db.CommandQueue.DEVICE, device);

    return command;
}

export function constructStopDebugCommand(device){
    let command = new Parse.Object(db.classes.CommandQueue);
    command.set(db.CommandQueue.COMMAND_NAME, db.commands.STOP_DEBUG);
    command.set(db.CommandQueue.DATA, {});
    command.set(db.CommandQueue.DEVICE, device);

    return command;
}

export function constructChangePeerListCommand(device, listThermos){
    if(!device) throw Error('No device');
    if(listThermos.length === 0) throw new Error('No list devices');

    let command = new Parse.Object(db.classes.CommandQueue);
    command.set(db.CommandQueue.COMMAND_NAME, db.commands.CHANGE_PEER_LIST);

    let peerListString = `${listThermos.length},${listThermos.map(therm => therm.get(db.Device.MAC_ADDRESS)).join(',')}`;

    let data = {
        value: peerListString,
        unit: 'MAC',
    };

    command.set(db.CommandQueue.DATA, data);
    command.set(db.CommandQueue.DEVICE, device);

    return command;
}

export async function getSenspsOfBuilding(buildingId) {
    let sensps = await new Parse.Query(db.classes.Device)
        .equalTo(db.Device.HOME, toPointerFromId(buildingId, db.classes.Home))
        .equalTo(db.Device.DEVICE_TYP, db.Device.DEVICE_TYP$SENSP)
        .select([
            db.Device.ROOM_ID,
            db.Device.MAC_ADDRESS,
            db.Device.DEVICE_TYP
        ])
        .include(db.Device.ROOM_ID)
        .limit(1000)
        .find();

    return sensps.filter(sensp => {
        let room = sensp.get(db.Device.ROOM_ID);

        if(!room) return false;

        let roomName = room.get(db.Room.ROOM_NAME);
        let roomType = room.get(db.Room.ROOM_TYPE);

        return roomName != db.Room.ROOM_NAME$TO_CONFIGURE && roomType != db.Room.ROOM_TYPE$RESERVE;
    });
};

export async function getThermOfBuilding(buildingId) {
    let thermos = await new Parse.Query(db.classes.Device)
        .equalTo(db.Device.HOME, toPointerFromId(buildingId, db.classes.Home))
        .equalTo(db.Device.DEVICE_TYP, db.Device.DEVICE_TYP$THERM)
        .select([
            db.Device.ROOM_ID,
            db.Device.MAC_ADDRESS,
            db.Device.DEVICE_TYP
        ])
        .include(db.Device.ROOM_ID)
        .limit(1000)
        .find();

    return thermos.filter(therm => {
        let room = therm.get(db.Device.ROOM_ID);

        if(!room) return false;

        let roomName = room.get(db.Room.ROOM_NAME);
        let roomType = room.get(db.Room.ROOM_TYPE);

        return roomName != db.Room.ROOM_NAME$TO_CONFIGURE && roomType != db.Room.ROOM_TYPE$RESERVE;
    });
};


export async function getOptionFromUser(options, text = 'Select the option') {
    let buttons = {
        cancel: 'Cancel'
    };

    options.forEach(option => buttons[option]= _.capitalize(option));
    let value = await swal(`${text}: "${options.join(', ')}"`, {
        buttons
    });

    if(options.indexOf(value) < 0)
        throw new Error(`Invalid option ${value} should be: "${options.join(', ')}"`);

    return value;
};

export function createCommandToDevices(devices, singleCommand){
    let commands = [];

    devices.forEach(device => {
        let command = new Parse.Object(db.classes.CommandQueue);
        command.set(db.CommandQueue.COMMAND_NAME, singleCommand.get(db.CommandQueue.COMMAND_NAME));
        command.set(db.CommandQueue.DEVICE, device);
        command.set(db.CommandQueue.DATA, singleCommand.get(db.CommandQueue.DATA));
        command.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID));

        commands.push(command);
    });

    return commands;
};

export function createLinkFromRooms(homeId, rooms, date, title = 'Link to'){
    let dateString = '';
    if(date){
        dateString += `&startDate=${date.format('DD-MM-YYYY')}&endDate=${date.format('DD-MM-YYYY')}`;
    }

    return <a target={'_blank'}
              href={`/homes/${homeId}/room-temperature-chart?selectedRoomId=${rooms.map(room => room.id).join(',')}${dateString}`}>
        {title} ({rooms.length} rooms)
    </a>;
};


export function groupByKey(objects, key){
    let returnObject = {};

    objects.forEach(object => {
        let groupObject = object.get(key);
        let isObject= typeof groupObject === 'object';
        let groupKey = groupObject;

        if(isObject){
            groupKey = groupObject.id;
        }

        if(returnObject[groupKey] == null) returnObject[groupKey] = {
            objects: []
        };

        if(isObject && returnObject[groupKey].groupObject == null) {
            returnObject[groupKey].groupObject = groupObject;
        }

        returnObject[groupKey].objects.push(object);
    });

    return returnObject;
}
export function isAValidRoom(room, reserve = false){
    let roomName = room.get(db.Room.ROOM_NAME);
    let roomType = room.get(db.Room.ROOM_TYPE);

    if(roomName.includes('no-count')) return false;
    if(roomName.includes('deleted')) return false;
    if(roomName.includes('Deleted')) return false;
    if(roomName.includes('config')) return false;
    if(roomName.includes('Config')) return false;
    if(roomName.includes('to-configure')) return false;

    if(reserve === false){
        if(roomName.includes('Reserve')) return false;
        if(roomName.includes('reserve')) return false;
        if(roomType === db.Room.ROOM_TYPE$RESERVE) return false;
    }

    if(roomName === db.Room.ROOM_NAME$TO_CONFIGURE) return false;

    return true;
}

export function booleanToString(boolean){
    if(boolean == null) return 'null';

    if(boolean === true) return 'true';
    if(boolean === false) return 'false';

    return 'N/A';
}

export function downloadXLSX(data, filename) {
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'sheet');
    let buf = XLSX.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
    let str = XLSX.write(wb, {bookType:'xlsx', type:'binary'}); // generate a binary string in web browser
    XLSX.writeFile(wb, `${filename}.xlsx`);
}

export async function selectDeviceFromListModal(devices){
    let options = devices.map(device => device.get(db.Device.SERIAL_NUMBER) + '');

    options.push('all');

    let option = await getOptionFromUser(options, 'Select the device');

    if(option === 'all') return devices;

    let selectedDevices = devices.filter(device => device.get(db.Device.SERIAL_NUMBER) === parseInt(option));

    if(selectedDevices.length === 0) throw new Error('No device found with this selection');

    return selectedDevices;
}

export function isWNBThermo(device){
    let deviceType = device?.get(db.Device.DEVICE_TYP);
    let firmwareVersion = device?.get(db.Device.VERSION_FIRMWARE);
    let co2 = device?.get(db.Device.CO2);

    if(co2 === 0 && deviceType === db.Device.DEVICE_TYP$THERM && firmwareVersion?.startsWith('6.')){
        return true;
    }

    return false;
}

export function isWNBPThermo(device){
    let deviceType = device?.get(db.Device.DEVICE_TYP);
    let firmwareVersion = device?.get(db.Device.VERSION_FIRMWARE);
    let co2 = device?.get(db.Device.CO2);

    if(co2 > 10 && deviceType === db.Device.DEVICE_TYP$THERM && firmwareVersion?.startsWith('6.')){
        return true;
    }

    return false;
}

export async function swalGetInteger(title, min, max){
    let number = await swal({
        heightAuto: false,
        title: title || 'Enter number',
        content: {
            element: 'input',
            attributes: {
                placeholder: 'Enter number',
                type: 'number',
                value: '0',
                min,
                max
            }
        }
    });

    console.log(number);

    if(number == null) return;

    number = parseInt(number);

    if(isNaN(number)) return;

    if(number < min) throw Error(`Minimal value is ${min}`);
    if(number > max) throw Error(`Maximal value is ${max}`);

    return number;
}

const beginFloat = '~begin~float~';
const endFloat = '~end~float~';
export const stringifyWithFloats =
    (config = {
        //pid
        'kp': 'float',
        'ki': 'float',
        'kd': 'float',
        'lp': 'float',
        'li': 'float',
        'ld': 'float',
        'mo': 'float',

        //co2
        'w': 'float',
        'lmin': 'float',
        'o': 'float',

        //reg
        'oHeat': 'float',
        'minT': 'float',
        'maxT': 'float',
        'offT': 'float',
        'maxTD': 'float',
        'offC': 'float',

        //mlim
        'lclose': 'float',
        'lopen': 'float',
        'fmin': 'float',
        'fmax': 'float',
        'frip': 'float',
        'cmin': 'float',
        'nrc': 'float',
        'tst': 'float',
        'tto': 'float',
        'ptd': 'float',
        'pm':'float',
        'spm': 'float',
        'oH': 48
    }, decimals = 1) =>
        (inputValue, inputReplacer, space) => {
            const inputReplacerIsFunction = typeof inputReplacer === 'function';
            let isFirstIteration = true;
            const jsonReplacer = (key, val, parent) => {
                if (isFirstIteration) {
                    isFirstIteration = false;
                    return inputReplacerIsFunction ? inputReplacer(key, val) : val;
                }
                if(Array.isArray(val) && key === 'r'){
                    return val.map(arrayItemValue => {
                        if(typeof arrayItemValue === 'number'){
                            return `${beginFloat}${arrayItemValue}${endFloat}`
                        }
                        return arrayItemValue;
                    });
                }

                let value;
                if (inputReplacerIsFunction) {
                    value = inputReplacer(key, val);
                } else if (Array.isArray(inputReplacer)) {
                    // remove the property if it is not included in the inputReplacer array
                    value = inputReplacer.indexOf(key) !== -1 ? val : undefined;
                } else {
                    value = val;
                }
                const forceFloat =
                    config[key] === 'float' &&
                    (value || value === 0) &&
                    typeof value === 'number' &&
                    !value.toString().toLowerCase().includes('e');
                return forceFloat ? `${beginFloat}${value}${endFloat}` : value;
            };
            const json = JSON.stringify(inputValue, jsonReplacer, space);
            const regexReplacer = (match, num) => {
                return num.includes('.') || Number.isNaN(num)
                    ? Number.isNaN(num)
                        ? num
                        : Number(num).toFixed(decimals)
                    : `${num}.${'0'.repeat(decimals)}`;
            };
            const re = new RegExp(`"${beginFloat}(.+?)${endFloat}"`, 'g');
            return json.replace(re, regexReplacer);
        };
