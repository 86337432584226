import React, {Component} from 'react';
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Parse from 'parse';
import {toPointerFromId} from '../../lib/util';
import * as db from '../../lib/dbStructure';

export default class LinkDeviceRoomModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            homes: [],
            rooms: [],
            devices: '',
            selectedHome: '',
            selectedRoom: '',
            deviceRooms: [{}],
            isValid: {},
            isInvalid: {}
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onHomeSelected = this.onHomeSelected.bind(this);
        this.getHomes = this.getHomes.bind(this);
        this.getRoomsFromHome = this.getRoomsFromHome.bind(this);
        this.onRoomSelected = this.onRoomSelected.bind(this);
        this.onDeviceChange = this.onDeviceChange.bind(this);
        this.acceptRoomInstallationId = this.acceptRoomInstallationId.bind(this);
    }

    getHomes(){
        let query = new Parse.Query('Home');
        query.include('Owner');
        query.notEqualTo(db.Home.DELETED, true);
        query.notEqualTo(db.Home.HIDDEN, true);
        query.limit(1000);

        return query.find()
            .then(homes => this.setState({ homes: homes }))
            .catch(console.error);
    }

    getRoomsFromHome(homeId){
        let query = new Parse.Query('Room');
        query.equalTo('home', toPointerFromId(homeId, 'Home'));
        query.notEqualTo(db.Room.DELETED, true);

        return query.find()
            .then(rooms => this.setState({rooms: rooms}))
            .catch(console.error);
    }

    componentDidMount(){
        this.props.setToggleModal(this.toggleModal);
        this.getHomes();
    }

    toggleModal() {
        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    onHomeSelected(e){
        let homeId = e.target.value;

        this.setState({selectedHome: homeId});

        this.getRoomsFromHome(homeId);
    }

    onRoomSelected(e, i){
        let roomId = e.target.value;

        this.setState(prev => {
            prev.deviceRooms[i].roomId = roomId;

            return prev;
        });
    }

    onDeviceChange(e, i){
        let deviceSerial = e.target.value;

        this.setState(prev => {
            prev.deviceRooms[i].deviceSerial = parseInt(deviceSerial);

            return prev;
        });
    }

    onRoomQRChange(e, i){
        let uuid = e.target.value;

        this.setState(prev => {
            prev.deviceRooms[i].uuid = uuid;

            return prev;
        });
    }

    async acceptRoomInstallationId(event, i){
        if(event.key === 'Enter'){
            event.preventDefault();
            let uuid = this.state.deviceRooms[i].uuid;
            let rid = null;

            if(!uuid) throw new Error('Not defined');

            if(uuid){
                rid = await (new Parse.Query(db.classes.RoomInstallationId))
                    .endsWith(db.RoomInstallationId.UUID, uuid)
                    .first();
            }

            if(!rid) {
                this.setState({isInvalid: {qrCode: true}, isValid: {qrCode: false}});
                return;
            }

            this.setState({isValid: {qrCode: true}, isInvalid: {qrCode: false}});
            this.setState(prev => {
                prev.deviceRooms[i].uuid = rid.get(db.RoomInstallationId.UUID);

                return prev;
            });
        }
    }

    render() {

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Link device to room
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <FormGroup>
                            <Label for={'config-modal-version'}>Home</Label>
                            <Input
                                type={'select'}
                                name={'home'}
                                id={'home'}
                                value={this.state.selectedHome}
                                onChange={this.onHomeSelected}>
                                <option>Select a home</option>
                                {
                                    this.state.homes.map(home => {
                                        return <option key={home.id} value={home.id}>
                                            {home.get(db.Home.HOME_NAME)} {home.get('city')} ({home.get('Owner') && home.get('Owner').get('username')})
                                        </option>
                                    })
                                }
                            </Input>
                        </FormGroup>
                        {
                            this.state.deviceRooms.map((deviceRoom, i) => {
                                return <FormGroup key={i}>
                                    <hr/>
                                    <Label for={'config-modal-wifiName'}>Room {i}</Label>
                                    <Input
                                        type={'select'}
                                        name={'room'}
                                        id={'room'}
                                        value={deviceRoom.roomId}
                                        onChange={(e) => this.onRoomSelected(e, i)}>
                                        <option>Select a room</option>
                                        {
                                            this.state.rooms.map(room => {
                                                return <option key={room.id} value={room.id}>
                                                    {room.get('roomName')} ({room.get(db.Room.NUMBER_RADIATORS)}) P{room.get(db.Room.FLOOR)}
                                                </option>
                                            })
                                        }
                                    </Input>
                                    <Label for="room-id">Enter room id* (or scan)</Label>
                                    <Input type="text" id="room-id" value={this.state.roomId}
                                           placeholder="Ex. 484f03b5-a113-4ff7-a287-c9634a95860e"
                                           onKeyPress={e => this.acceptRoomInstallationId(e, i)}
                                           valid={this.state.isValid.qrCode}
                                           invalid={this.state.isInvalid.qrCode}
                                           value={deviceRoom.uuid}
                                           onChange={e => this.onRoomQRChange(e, i)}/>
                                    <Label for={'devices'}>Devices serial</Label>
                                    <Input
                                        type={'text'}
                                        name={'device'}
                                        id={'device'}
                                        value={deviceRoom.deviceSerial}
                                        onChange={(e) => this.onDeviceChange(e, i)}>
                                    </Input>
                                </FormGroup>
                            })
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            this.setState(prev => {
                                prev.deviceRooms.push({});

                                return prev;
                            })
                        }}>Add room</Button>
                        <Button outline color="primary" onClick={() => {
                            this.setState({error: false, errorMessage: null});

                            let home = this.state.homes.filter(home => home.id === this.state.selectedHome)[0];

                            let deviceRoom = this.state.deviceRooms
                                .filter(deviceRoom => !!deviceRoom.roomId)
                                .map(deviceRoom => {
                                    deviceRoom.room = this.state.rooms.filter(room => room.id === deviceRoom.roomId)[0];

                                    return deviceRoom;
                                });

                            this.props.save(home, deviceRoom)
                                .then(() => this.toggleModal())
                                .catch(err => {
                                    this.setState({error: true, errorMessage: err.message});
                                })
                        }}>Link</Button>
                        <Button outline color="secondary" onClick={() => {
                            this.toggleModal();
                            this.props.cancel(this.state.config);
                        }}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

LinkDeviceRoomModal.propTypes = {
    save: PropTypes.func,
    cancel: PropTypes.func,
    setToggleModal: PropTypes.func
};