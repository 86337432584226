import React, {Component} from 'react';
import {Button, Form, FormGroup, Input, Label} from 'reactstrap';
import Parse from 'parse';
import * as db from '../../lib/dbStructure';
import swal from 'sweetalert';
import {validate} from 'uuid';
import Select from 'react-select';

let timeoutId;

export default class PageLinkRoomQr extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rooms: [],
            homeDevices: [],
            notActiviatedHomes: [],
            loading: {
                monitoring: false
            },
            uniqueIds: []
        };

        this.acceptRoomInstallationId = this.acceptRoomInstallationId.bind(this);
        this.addDevice = this.addDevice.bind(this);
        this.linkCodeToRoom = this.linkCodeToRoom.bind(this);
        this.getRooms = this.getRooms.bind(this);

        this.roomCodeInput = React.createRef();
    }

    async componentDidMount() {
        await this.getRooms();
        setTimeout(() => {
            $('#deviceInput').focus();
        }, 10);
    }

    async getRooms(){
        let query = new Parse.Query(db.classes.Room);

        query.limit(10000);
        query.exists(db.Room.HOME);
        query.select([db.Room.ROOM_NAME, db.Room.UNIQUE_ID, db.Room.HOME]);

        let rooms = await query.find();

        this.setState({rooms});
    }

    async addDevice(id){
        if(!id) return await swal('Error', 'Device id invalid', 'error');

        let isMac = id.split(':').length === 6;
        try {

            if(isMac){
                id = id.toUpperCase();
            }
            else {
                if(id.length !== 7){
                    this.setState({deviceId: ''});
                    return swal({title: 'Not a valid serial number', text: ' ', icon: 'error',
                        button: [''], timer: 1000});
                }

                id = parseInt(id);
            }
            //https://devices.cleveron.ch/device/2C:F4:32:80:CB:96
            //https://room.cleveron.ch/room/753e5873-57c3-430c-8f3b-2e14dd2a9de6

            let query =  (new Parse.Query(db.classes.Device))
                .include(db.Device.ROOM_ID)
                .include(db.Device.HOME);


            if(isMac)
                query.equalTo(db.Device.MAC_ADDRESS, id);
            else
                query.equalTo(db.Device.SERIAL_NUMBER, id);

            let device = await query.first();

            if (!device) {
                throw new Error('No device found with this serial');
            }

            if(!device.get(db.Device.ROOM_ID)){
                throw new Error('This device is not connected to a room');
            }

            this.setState(prev => {
                prev.device = device;
                prev.room = device.get(db.Device.ROOM_ID);

                return prev;
            });

            swal({title: 'Success', text: 'Device found', icon: 'success', button: [''], timer: 1000});

            setTimeout(() => {$('#roomCodeInput').focus();}, 100);
        } catch (e) {
            console.error(e.message);
            swal({title: e.message, text: e.message, icon: 'error', button: [''], timer: 1000});
        }
    }

    async acceptRoomInstallationId(event){
        try {
            if (event.key === 'Enter') {
                event.preventDefault();

                let roomUniqueId = this.state.roomCode;

                if (validate(roomUniqueId)) {

                } else {
                    return swal({title: 'Id format is wrong', text: ' ', icon: 'error', button: [''], timer: 1000});
                }

                let query = new Parse.Query(db.classes.Room);
                query.equalTo(db.Room.UNIQUE_ID, roomUniqueId);

                let rooms = await query.find();

                if (rooms.length > 0) {
                    let result = await swal({
                        title: 'Room code has already be used',
                        text: 'Reset currently assigned room?',
                        buttons: ['Abort', 'Confirm']
                    });

                    if(!result) return this.setState({roomCode: ''});

                    rooms.map(room => room.unset(db.Room.UNIQUE_ID));

                    await Parse.Object.saveAll(rooms);

                    swal({title: 'Success', text: 'This code can be used', icon: 'success', button: [''], timer: 1000});
                    this.setState({roomCodeVerified: true});
                } else {
                    swal({title: 'Success', text: 'This code can be used', icon: 'success', button: [''], timer: 1000});
                    this.setState({roomCodeVerified: true});
                }
            }
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async linkCodeToRoom(){
        try {
            let room = this.state.room;
            let code = this.state.roomCode;

            if(room.get(db.Room.UNIQUE_ID)){
                let result = await swal({
                    title: 'This room has already a Unique code',
                    text: 'Would you like to replace or add on this one?',
                    buttons: {
                        cancel: {
                            text: 'Cancel',
                            value: 'cancel'
                        },
                        replace: {
                            text: 'Replace',
                            value: 'replace'
                        },
                        add: {
                            text: 'Add',
                            value: 'add'
                        }
                    },
                });

                if(!result) throw new Error('Action aborted by user');

                if(result === 'add'){
                    room.set(db.Room.UNIQUE_ID, `${room.get(db.Room.UNIQUE_ID)},${code}`);
                } else if(result === 'replace'){
                    room.set(db.Room.UNIQUE_ID, code);
                }
            } else {
                room.set(db.Room.UNIQUE_ID, code);
            }

            await room.save();

            this.setState({roomCodeVerified: false, room: null, roomCode: '', deviceId: ''});

            setTimeout(() => {$('#deviceInput').focus();}, 100);

            swal('Success', `Test link: https://room.cleveron.ch/room/${code}`, 'success');
        } catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }


    render() {

        return (
            <div style={{margin: 10}}>
                <h1 className="text-center">Page Link Room QR</h1>

                <Form style={{maxWidth: 1000}}>
                    <FormGroup>
                        <Label for="devices">Enter device serial number (optional)</Label>
                        <Input type="string"
                            id={'deviceInput'}
                            value={this.state.deviceId}
                            onKeyPress={async event => {
                                if(event.key === 'Enter'){
                                    event.preventDefault();

                                    await this.addDevice(this.state.deviceId);
                                }
                            }}
                            onChange={(e) => {
                                e.preventDefault();
                                let value = e.target.value.toLowerCase();

                                if(value.startsWith('https://devices.cleveron.ch/device/')){
                                    value = value.replace('https://devices.cleveron.ch/device/', '')
                                    value = value.toUpperCase();
                                }

                                this.setState({deviceId: value});
                            }}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Room</Label>
                        <Select
                            value={this.state.room ? {
                                value: this.state.room ,
                                label: this.state.room.get(db.Room.ROOM_NAME)
                            }: null}
                            onChange={(roomSelection) => this.setState({deviceId: null, room: roomSelection.value})}
                            options={this.state.rooms.map(room => ({value: room, label: `${room.get(db.Room.ROOM_NAME)} (${room.id}) - Home ${room.get(db.Room.HOME) ? room.get(db.Room.HOME).id : 'N/A'}`}))}
                            isMulti={false}
                        />
                    </FormGroup>
                    {
                        this.state.room && <div style={{margin: '20 0 20 0'}}>
                            {
                                !this.state.room.get(db.Room.UNIQUE_ID) && <p>
                                    {this.state.deviceId && <div><span>This device is linked to the</span> Room {this.state.room.id} <strong>{this.state.room.get(db.Room.ROOM_NAME)}</strong></div>}
                                </p>
                            }

                            {
                                this.state.room.get(db.Room.UNIQUE_ID) && <p>
                                    {this.state.deviceId && <span>This device is linked to the</span>}
                                    Room {this.state.room.id} <strong>{this.state.room.get(db.Room.ROOM_NAME)} with code "{this.state.room.get(db.Room.UNIQUE_ID)}"</strong>
                                    <a href={`https://room.cleveron.ch/room/${this.state.room.get(db.Room.UNIQUE_ID)}`} target={'_blank'}>Test link</a>
                                </p>
                            }
                        </div>
                    }
                    {
                        this.state.room && <FormGroup>
                            <Label for="room-id">Room QR code id* (or scan)</Label>
                            <Input type="text" id="room-id" value={this.state.roomCode}
                                placeholder="Ex. 484f03b5-a113-4ff7-a287-c9634a95860e"
                                onKeyPress={this.acceptRoomInstallationId}
                                id={'roomCodeInput'}
                                onChange={async (e) => {
                                    e.preventDefault();
                                    let value = e.target.value;

                                    if(value.startsWith('https://room.cleveron.ch/room/')){
                                        this.setState({roomCode: value.replace('https://room.cleveron.ch/room/', '')});
                                        return;
                                    }

                                    if(value.length > 2){
                                        clearTimeout(timeoutId);
                                        timeoutId = setTimeout(async () => {
                                            let uniqueIds = await (new Parse.Query(db.classes.RoomInstallationId))
                                                .contains(db.RoomInstallationId.UUID, value)
                                                .find();

                                            this.setState({uniqueIds});
                                        }, 1000);


                                    }

                                    this.setState({roomCode: value});
                                }}/>
                        </FormGroup>
                    }

                    {
                        this.state.uniqueIds.length > 0 && <p>Valid uuids:</p>
                    }
                    {
                        this.state.uniqueIds.map(uuid => {
                            return <li>{uuid.get(db.RoomInstallationId.UUID)}</li>
                        })
                    }
                    {
                        this.state.roomCodeVerified && <Button outline color="primary" onClick={this.linkCodeToRoom}>
                            Link QR code to Room {this.state.room.get(db.Room.ROOM_NAME)}
                        </Button>
                    }
                </Form>
            </div>
        );
    }
}

PageLinkRoomQr.propTypes = {};