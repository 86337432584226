import React, {Component} from 'react';
import {Table} from 'reactstrap';
import Parse from 'parse';
import PropTypes from 'prop-types';
import db from '../../lib/dbStructure';

export default class PageHomeDiagnostics extends Component {
    constructor(props){
        super(props);

        this.state = {
            buildingDiagnostics: []
        };
    }

    async componentDidMount(){
        let buildingDiagnostics = await this.getBuildingDiagnostics();

        this.setState({buildingDiagnostics});
    }

    async getBuildingDiagnostics(){
        let buildingDiagnostics = await (new Parse.Query(db.classes.BuildingDiagnostic))
            .include(db.BuildingDiagnostic.HOME)
            .include(db.BuildingDiagnostic.ROOM)
            .find();

        return buildingDiagnostics;
    }

    render() {
        let successIcon = <i className="far fa-check-circle"></i>;

        return (
            <div className={'firmware-testing-list'}>
                <h1>Building diagnostics</h1>
                <Table>
                    <thead>
                        <th>Building name</th>
                        <th>Room name</th>
                        <th>Actions</th>
                    </thead>
                    <tbody>
                        {
                            this.state.buildingDiagnostics.map((buildingDiagnostic, i) => {
                                let building = buildingDiagnostic.get(db.BuildingDiagnostic.HOME);
                                let room = buildingDiagnostic.get(db.BuildingDiagnostic.ROOM);
                                let device = buildingDiagnostic.get(db.BuildingDiagnostic.DEVICE);

                                return <tr key={i}>
                                    <td>{building.get(db.Home.HOME_NAME)}</td>
                                    <td>{room.get(db.Room.ROOM_NAME)}</td>
                                    <td>
                                        <a href={`/homes/diagnostics/${building.id}`}>To go</a>
                                    </td>
                                </tr>;
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}

PageHomeDiagnostics.propTypes = {
    history: PropTypes.any
}