import React, {Component} from 'react';
import {Alert, Button, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Parse from 'parse';
import * as db from '../../../lib/dbStructure';
import assert from 'assert';
import swal from 'sweetalert';
import AsyncSelect from "react-select/async";
import Select from "react-select";
import commands from "../../DeviceView/resources/commands.json";
import _ from "lodash";
import {stringifyWithFloats} from "../../../lib/util";

export default class UpdateShadowOnRoomModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            isModalOpen: false,
            homes: [],
            selectedHome: null,
            selectedUser: null,
            writeAccess: false,
            administrator: false,
            rooms: [],
            selectedRooms: [],
            selectedFromHome: null,
            loading: {
                userSearch: false,
                buildingSearch: false
            }
        };

        this.textAreaRef = React.createRef();

        this.toggleModal = this.toggleModal.bind(this);
        this.onPositiveButtonClick = this.onPositiveButtonClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onHomeSelectedChange = this.onHomeSelectedChange.bind(this);
        this.getHomes = this.getHomes.bind(this);
        this.getRooms = this.getRooms.bind(this);
        this.onRoomFieldChange = this.onRoomFieldChange.bind(this);
        this.onUserSelected = this.onUserSelected.bind(this);
        this.selectRoomsByType = this.selectRoomsByType.bind(this);
        this.selectRoomsByFloor = this.selectRoomsByFloor.bind(this);
        this.selectRoomsByIds = this.selectRoomsByIds.bind(this);
        this.selectHomeById = this.selectHomeById.bind(this);
        this.removeRoomsByType = this.removeRoomsByType.bind(this);
    }

    async getHomes(homeName) {
        if(!homeName) return [];
        if(homeName.length < 2) return [];

        let query = new Parse.Query('Home');

        query.select([
            db.Home.HOME_NAME,
            db.Home.CITY,
            db.Home.OWNER
        ])
        query.notEqualTo(db.Home.DELETED, true);
        query.notEqualTo(db.Home.HIDDEN, true);
        query.limit(100);

        query.fullText(db.Home.HOME_NAME, homeName);
        //query.ascending('$score');

        let homes = await query.find();

        return homes.map(home => {
            return {
                value: home.id,
                label: `${home.get(db.Home.HOME_NAME)} (${home.get(db.Home.CITY)})`,
                object: home
            }
        });
    }
    async getRooms(home) {
        if(!home) return [];

        let query = new Parse.Query(db.classes.Room);
        query.equalTo(db.Room.HOME, home)
        query.select([
            db.Room.ROOM_NAME,
            db.Room.FLOOR,
            db.Room.NUMBER_SENSP,
            db.Room.NUMBER_RADIATORS,
            db.Room.ROOM_TYPE,
            db.Room.ROOM_CODE
        ]);
        query.notEqualTo(db.Home.DELETED, true);
        query.notEqualTo(db.Home.HIDDEN, true);
        query.limit(10000);

        let rooms = await query.find();

        rooms = rooms.map(room => {
            let roomCode = room.get(db.Room.ROOM_CODE);
            let roomName = room.get(db.Room.ROOM_NAME);
            let numberRadiators = room.get(db.Room.NUMBER_RADIATORS);
            let numberSensp = room.get(db.Room.NUMBER_SENSP);
            let floor = room.get(db.Room.FLOOR);
            let roomType = room.get(db.Room.ROOM_TYPE);

            return {
                value: room.id,
                label: `${room.id} ${roomName || ''} (${roomCode || ''}) ${roomType} floor ${floor} r${numberRadiators} s${numberSensp}`,
                object: room
            }
        });

        this.setState({rooms});
        return rooms;
    }

    componentDidMount() {
        this.props.setToggleModal(this.toggleModal);

    }

    async toggleModal(params) {
        if(params){
            let {
               selectedHomeId,
               selectedRoomIds
            } = params;


            await this.selectHomeById(selectedHomeId);
            await this.selectRoomsByIds(selectedRoomIds);
        }
        if(!params){
            let home = await this.selectHomeById('SgUuw501FA');
            await this.getRooms(home);
        }

        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    onHomeSelectedChange(selectedHome) {
        this.setState({selectedHome});
    }

    onUserSelected(e) {
        let userId = e.target.value;

        this.setState({selectedUser: userId});
    }

    onRoomFieldChange(e){
        let value = e.target.value;
        let name = e.target.name;

        this.setState({[name]: value});
    }

    async onPositiveButtonClick(){
        this.setState({error: false, errorMessage: null});

        try {
            assert(this.state.selectedFromHome != null, 'selectedFromHome is required');
            assert(this.state.selectedFromHome.object != null, 'selectedFromHome is required');
            assert(this.state.selectedRooms != null, 'No room selected');
            assert(this.state.selectedRooms.length > 0, 'No room selected');

            let yesContrinue = await swal({
                title: 'Are you sure?',
                text: 'The shadow will modify the temperature control and may cause comfort problems on customers. Continue?',
                icon: 'danger',
                buttons: true,
                dangerMode: true,
            });

            if (!yesContrinue) {
                return this.setState({error: true, errorMessage: 'Aborted by user'});
            }

            let desiredShadow = JSON.parse(this.state.json);

            desiredShadow = _.pick(desiredShadow, [
                'prog',
                'reg',
                'mlim',
                'noSO',
                'noOH'
            ]);

            let result = await Parse.Cloud.run('iot-modify-shadow-on-rooms', {
                fromBuildingId: this.state.selectedFromHome.object.id,
                roomsToMove: this.state.selectedRooms.map(selectedRoom => selectedRoom.object.id),
                desiredShadow
            });

            if(result.status !== 'ok') throw Error('Error in executing the action');

            if(result?.warnings?.length > 0) {
                this.setState({warnings: result?.warnings});
            }



            swal('Shadow applied corectly', '', 'success');

            this.setState({selectedRooms: []});

            await this.getRooms(this.state.selectedFromHome.object);
        } catch (e) {
            console.error(e);
            this.setState({error: true, errorMessage: e.message});
        }
    }

    onCancelClick(){
        this.toggleModal();
        this.props.cancel(this.state.config);
    }

    setShadowTemplate(templateName){
        let desiredShadow = {};
        if(templateName === 'prog-office'){
            desiredShadow = {
                "prog": {
                    "week": {
                        "mo": [0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,0,0,0,0],
                        "tu": [0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,0,0,0,0],
                        "we": [0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,0,0,0,0],
                        "th": [0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,0,0,0,0],
                        "fr": [0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,0,0,0,0],
                        "sa": [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
                        "su": [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
                        "of": [3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3]
                    },
                    "cache": [
                        {
                            "i": 60,
                            "c": 1,
                            "r": [
                                "",
                                18.0
                            ]
                        },
                        {
                            "i": 10,
                            "c": 6,
                            "r": [
                                "",
                                22.0
                            ]
                        },
                        {
                            "i": 10,
                            "c": 6,
                            "r": [
                                "P",
                                22.0,
                                "",
                                20.0
                            ]
                        },
                        {
                            "i": 30,
                            "c": 2,
                            "r": [
                                "",
                                23.0
                            ]
                        }
                    ]
                }
            };
        }
        if(templateName === 'prog-meeting-room'){
            desiredShadow = {
                prog: {
                    week: {
                        "mo": [0,0,0,1,2,2,3,2,2,3,2,2,2,3,2,2,2,3,1,0,0,0,0,0],
                        "tu": [0,0,0,1,0,2,3,2,2,3,2,2,2,3,2,2,2,3,1,0,0,0,0,0],
                        "we": [0,0,0,1,0,2,3,2,2,3,2,2,2,3,2,2,2,3,1,0,0,0,0,0],
                        "th": [0,0,0,1,0,2,3,2,2,3,2,2,2,3,2,2,2,3,1,0,0,0,0,0],
                        "fr": [0,0,0,1,0,2,3,2,2,3,2,2,2,3,2,2,2,3,1,0,0,0,0,0],
                        "sa": [0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,1,1,0,0,0,0],
                        "su": [0,0,0,1,1,0,0,0,0,0,0,1,1,0,0,0,0,0,1,1,0,0,0,0],
                        "of": [4,4,4,4,3,4,4,4,4,4,4,4,3,4,4,4,4,4,3,4,4,4,4,4]
                    },
                    "cache": [
                        {
                            "i": 120,
                            "c": 0,
                            "r": [
                                "",
                                18.0
                            ]
                        },
                        {
                            "i": 120,
                            "c": 1,
                            "r": [
                                "",
                                18.0
                            ]
                        },
                        {
                            "i": 30,
                            "c": 0,
                            "r": [
                                "P",
                                22.0,
                                "",
                                20.0
                            ]
                        },
                        {
                            "i": 30,
                            "c": 1,
                            "r": [
                                "P",
                                22.0,
                                "",
                                20.0
                            ]
                        },
                        {
                            "i": 30,
                            "c": 0,
                            "r": [
                                "",
                                22.0
                            ]
                        }
                    ]
                }
            };
        }
        if(templateName === 'prog-corridor'){
            desiredShadow = {
                "prog": {
                    "week": {
                        "mo": [0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,0,0,0,0],
                        "tu": [0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,0,0,0,0],
                        "we": [0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,0,0,0,0],
                        "th": [0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,0,0,0,0],
                        "fr": [0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,0,0,0,0],
                        "sa": [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
                        "su": [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
                        "of": [3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3,3]
                    },
                    "cache": [
                        {
                            "i": 60,
                            "c": 1,
                            "r": [
                                "",
                                18.0
                            ]
                        },
                        {
                            "i": 30,
                            "c": 1,
                            "r": [
                                "",
                                20.0
                            ]
                        },
                        {
                            "i": 30,
                            "c": 1,
                            "r": [
                                "P",
                                22.0,
                                "",
                                20.0
                            ]
                        },
                        {
                            "i": 30,
                            "c": 2,
                            "r": [
                                "",
                                23.0
                            ]
                        }
                    ]
                }
            };
        }
        const string = stringifyWithFloats()(desiredShadow, null, 2);
        this.setState({json: string});
    }

    selectRoomsByType(types){
        let rooms = this.state.rooms;

        rooms = rooms.filter(roomItem => {
            let room = roomItem.object;
            if(types.indexOf(room.get(db.Room.ROOM_TYPE)) >=0) return true;
            return false;
        });

        let selectedRooms = this.state.selectedRooms.concat(rooms);

        this.setState({selectedRooms});
    }

    selectRoomsByFloor(floors){
        let rooms = this.state.rooms;

        rooms = rooms.filter(roomItem => {
            let room = roomItem.object;
            if(floors.indexOf(room.get(db.Room.FLOOR)) >=0) return true;
            return false;
        });

        let selectedRooms = this.state.selectedRooms.concat(rooms);

        this.setState({selectedRooms});
    }

    async selectRoomsByIds(ids){
        if(!ids) return;

        let rooms = await this.getRooms(this.state.selectedFromHome.object)

        rooms = rooms.filter(roomItem => {
            let room = roomItem.object;
            if(ids.indexOf(room.id) >=0) return true;
            return false;
        });

        this.setState({selectedRooms: rooms});
    }

    async selectHomeById(id){
        let home;
        try {
            home = await new Parse.Query(db.classes.Home).get(id);
        } catch (e) {
            console.log(`No home found ${id}`);
            return;
        }

        if(!home) {
            console.log('No home found');
            return;
        }

        this.setState({selectedFromHome: {
            value: home.id,
            label: `${home.get(db.Home.HOME_NAME)} (${home.get(db.Home.CITY)})`,
            object: home
        }});

        return home;
    }

    async removeRoomsByType(types){
        let selectedRooms = this.state.selectedRooms;

        selectedRooms = selectedRooms.filter(roomItem => {
            let room = roomItem.object;
            if(types.indexOf(room.get(db.Room.ROOM_TYPE)) >=0) return false;

            return true;
        });

        this.setState({selectedRooms});
    }

    async removeRoomsByFloor(floors){
        let selectedRooms = this.state.selectedRooms;

        selectedRooms = selectedRooms.filter(roomItem => {
            let room = roomItem.object;
            if(floors.indexOf(room.get(db.Room.FLOOR)) >=0) return false;

            return true;
        });

        this.setState({selectedRooms});
    }

    render() {
        let desiredShadow;
        let jsonError;
        try{
            if(this.state.json){
                desiredShadow = JSON.parse(this.state.json);

                desiredShadow = _.pick(desiredShadow, [
                    'prog',
                    'reg',
                    'mlim',
                    'noSO',
                    'noOH'
                ]);
            }
        }catch (e) {
            console.error(e);
            jsonError = e.message;
            desiredShadow = null;
        }

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()} style={{maxWidth: '80%'}}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Modify shadow on devices
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }
                        {
                            jsonError && <Alert color="danger">
                                {jsonError}
                            </Alert>
                        }

                        {
                            this.state.warnings?.length > 0 && <Alert color="warning">
                                {
                                    this.state.warnings.map((warning, i) => {
                                        return <p key={i}>{warning}</p>
                                    })
                                }
                            </Alert>
                        }

                        <FormGroup>
                            <label>Select building (from):</label>
                            <AsyncSelect
                                value={this.state.selectedFromHome}
                                onChange={selectedFromHome => this.setState({selectedFromHome}, () => this.getRooms(selectedFromHome.object))}
                                loadOptions={this.getHomes}
                                isMulti={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label>Select rooms to update shadow program:</label>
                            <Select options={this.state.rooms}
                                    isMulti
                                    value={this.state.selectedRooms}
                                    onChange={selectedRooms => this.setState({selectedRooms})}
                            />
                            <a href={'javascript: ;'}
                               onClick={() => this.selectRoomsByType([db.Room.ROOM_TYPE$OFFICE])}><i
                                className="fa fa-plus" aria-hidden="true"></i> Offices</a>&nbsp;
                            <a href={'javascript: ;'}
                               onClick={() => this.selectRoomsByType([db.Room.ROOM_TYPE$CORRIDOR])}><i
                                className="fa fa-plus" aria-hidden="true"></i>
                                Corridor</a>&nbsp;
                            <a href={'javascript: ;'}
                               onClick={() => this.selectRoomsByType([db.Room.ROOM_TYPE$CORRIDOR, db.Room.ROOM_TYPE$ARCHIVE, db.Room.ROOM_TYPE$TOILET, db.Room.ROOM_TYPE$ENTRY])}><i
                                className="fa fa-plus" aria-hidden="true"></i>
                                Corridor,Toilets,Entry</a>&nbsp;
                            <a href={'javascript: ;'}
                               onClick={() => this.selectRoomsByType([db.Room.ROOM_TYPE$MEETING_ROOM])}><i
                                className="fa fa-plus" aria-hidden="true"></i> Meeting
                                rooms</a>&nbsp;
                            <br/>

                            <a href={'javascript: ;'}
                               onClick={() => this.removeRoomsByType([db.Room.ROOM_TYPE$OFFICE])}><i
                                className="fa fa-minus" aria-hidden="true"></i> Offices</a>&nbsp;
                            <a href={'javascript: ;'}
                               onClick={() => this.removeRoomsByType([db.Room.ROOM_TYPE$CORRIDOR, db.Room.ROOM_TYPE$ARCHIVE, db.Room.ROOM_TYPE$TOILET, db.Room.ROOM_TYPE$ENTRY])}><i
                                className="fa fa-minus" aria-hidden="true"></i> Corridor,Toilets,Entry</a>&nbsp;
                            <a href={'javascript: ;'}
                               onClick={() => this.removeRoomsByType([db.Room.ROOM_TYPE$MEETING_ROOM])}><i
                                className="fa fa-minus" aria-hidden="true"></i> Meeting
                                rooms</a>&nbsp;
                            <br/>

                            <a href={'javascript: ;'} onClick={() => this.selectRoomsByFloor([-1])}><i
                                className="fa fa-plus" aria-hidden="true"></i> Floor
                                -1</a>&nbsp;
                            <a href={'javascript: ;'} onClick={() => this.selectRoomsByFloor([0])}><i
                                className="fa fa-plus" aria-hidden="true"></i> Floor 0</a>&nbsp;
                            <a href={'javascript: ;'} onClick={() => this.selectRoomsByFloor([1])}><i
                                className="fa fa-plus" aria-hidden="true"></i> Floor 1</a>&nbsp;
                            <a href={'javascript: ;'} onClick={() => this.selectRoomsByFloor([2])}><i
                                className="fa fa-plus" aria-hidden="true"></i> Floor 2</a>&nbsp;
                            <br/>

                            <a href={'javascript: ;'} onClick={() => this.removeRoomsByFloor([-1])}><i
                                className="fa fa-minus" aria-hidden="true"></i> Floor
                                -1</a>&nbsp;
                            <a href={'javascript: ;'} onClick={() => this.removeRoomsByFloor([0])}><i
                                className="fa fa-minus" aria-hidden="true"></i> Floor 0</a>&nbsp;
                            <a href={'javascript: ;'} onClick={() => this.removeRoomsByFloor([1])}><i
                                className="fa fa-minus" aria-hidden="true"></i> Floor 1</a>&nbsp;
                            <a href={'javascript: ;'} onClick={() => this.removeRoomsByFloor([2])}><i
                                className="fa fa-minus" aria-hidden="true"></i> Floor 2</a>&nbsp;
                            <br/>

                            <a href={'javascript: ;'} onClick={() => this.setShadowTemplate('prog-office')}>Prog
                                office</a>&nbsp;
                            <a href={'javascript: ;'} onClick={() => this.setShadowTemplate('prog-meeting-room')}>Prog
                                Meeting room</a>&nbsp;
                            <a href={'javascript: ;'} onClick={() => this.setShadowTemplate('prog-corridor')}>Prog
                                Corridor</a>&nbsp;
                        </FormGroup>
                        <FormGroup>
                            <label>Json document:</label>
                            <Input
                                style={{height: 400}}
                                type={'textarea'}
                                name={'data'}
                                value={this.state.json}
                                ref={this.textAreaRef}
                                onChange={(e) => {
                                    this.setState({json: e.target.value});
                                }}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 9) {
                                        e.preventDefault();

                                        let value = e.target.value;

                                        console.log('tab pressed');

                                        const { selectionStart, selectionEnd } = e.target;

                                        console.log(selectionStart, selectionEnd);

                                        const newText =
                                            value.substring(0, selectionStart) +
                                            '\t' + // Edit this for type tab you want
                                            // Here it's 2 spaces per tab
                                            value.substring(selectionEnd, value.length);

                                       /*console.log(newText);

                                        this.textAreaRef.current.focus();

                                        console.log(this.textAreaRef.current);
                                        console.log(this.textAreaRef.current.value);

                                        this.textAreaRef.current.setSelectionRange(
                                            selectionStart + 2,
                                            selectionStart + 2
                                        );*/

                                        this.setState({json: newText});
                                    }
                                }}
                            ></Input>
                        </FormGroup>
                        <div>
                            <pre style={{height: 400}}>
                                {
                                    desiredShadow != null && JSON.stringify(
                                        desiredShadow,
                                        null,
                                        2
                                    )
                                }
                            </pre>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={this.onPositiveButtonClick}>Update shadow on selected rooms</Button>
                        <Button outline color="secondary" onClick={this.onCancelClick}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

UpdateShadowOnRoomModal.propTypes = {
    setToggleModal: PropTypes.func
};