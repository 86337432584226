import React, {Component} from 'react';
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Parse from 'parse';
import * as db from '../../../lib/dbStructure';
import assert from 'assert';
import swal from 'sweetalert';
import AsyncSelect from "react-select/async";
import Select from "react-select";
import Toggle from 'react-toggle';

export default class ChangeUserFieldsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            isModalOpen: false,
            homes: [],
            selectedHome: '',
            selectedUser: '',
            writeAccess: false,
            administrator: false,
            loading: {
                userSearch: false,
                buildingSearch: false
            }
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onPositiveButtonClick = this.onPositiveButtonClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onUserSelected = this.onUserSelected.bind(this);
    }

    async getUsers(email) {
        if(!email) return [];
        if(email.length < 2) return [];

        let users = await Parse.Cloud.run('search-user-from-email', {email});

        return users.map(user => {
            return {
                value: user.id,
                label: `${user.get(db._User.USERNAME)} (${user.get(db._User.EMAIL)})`,
                object: user
            }
        });
    }

    componentDidMount() {
        this.props.setToggleModal(this.toggleModal);
    }

    toggleModal() {
        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    onUserSelected(e) {
        let userId = e.target.value;

        this.setState({selectedUser: userId});
    }

    changeUserField(fieldName, newValue){
        this.setState(prev => {
            let user = prev.selectedUser.object;

            user.set(fieldName, newValue);

            prev.selectedUser.object = user;

            return prev;
        });
    }

    async onPositiveButtonClick(){
        this.setState({error: false, errorMessage: null});

        try {
            assert(this.state.selectedUser != null, 'User is required');
            assert(this.state.selectedUser.object != null, 'User is required');
    
            let willDelete = await swal({
                title: 'Are you sure?',
                text: 'Changing user fields can cause the user to not be able to login with it\'s previous credentials as well as visualization changes into the webapp.',
                icon: 'warning',
                buttons: true,
                dangerMode: true
            });

            if (!willDelete) {
                return this.setState({error: true, errorMessage: 'Aborted by user'});
            }
        
            await Parse.Cloud.run('admin-change-user-fields', {
                userId: this.state.selectedUser.object.id,
                typeOfCustomer: this.state.selectedUser.object.get(db._User.TYPE_OF_CUSTOMER),
                email: this.state.selectedUser.object.get(db._User.EMAIL),
                username: this.state.selectedUser.object.get(db._User.USERNAME),
                active: this.state.selectedUser.object.get(db._User.ACTIVE),
                deleted: this.state.selectedUser.object.get(db._User.DELETED)
            });

            swal('Field changed correctly', '', 'success');
        } catch (e) {
            this.setState({error: true, errorMessage: e.message});
        }
    }

    onCancelClick(){
        this.toggleModal();
        this.props.cancel(this.state.config);
    }

    render() {
        const typesOfCustomer = [
            db._User.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER,
            db._User.TYPE_OF_CUSTOMER$FULL_SYSTEM_CUSTOMER,
            db._User.TYPE_OF_CUSTOMER$READ_ONLY_DEMO_USER,
            db._User.TYPE_OF_CUSTOMER$INSTALLER_PARTNER
        ];

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Change user fields
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <FormGroup>
                            <label>Select user:</label>
                            <AsyncSelect
                                value={this.state.selectedUser}
                                onChange={selectedUser => this.setState({selectedUser})}
                                loadOptions={this.getUsers}
                                isMulti={false}
                            />

                            {
                                !!this.state.selectedUser && <>
                                    <label>Type of customer:</label>
                                    <Select
                                        value={{value: this.state.selectedUser && this.state.selectedUser.object.get(db._User.TYPE_OF_CUSTOMER), label: this.state.selectedUser && this.state.selectedUser.object.get(db._User.TYPE_OF_CUSTOMER)}}
                                        onChange={item => this.changeUserField(db._User.TYPE_OF_CUSTOMER, item.value)}
                                        options={typesOfCustomer.map(typeOfCustomer => ({value: typeOfCustomer, label: typeOfCustomer}))}
                                        isMulti={false}
                                    />

                                    <Label for={db._User.USERNAME}>Username:</Label>
                                    <Input
                                        type={'text'}
                                        name={db._User.USERNAME}
                                        id={db._User.USERNAME}
                                        value={this.state.selectedUser && this.state.selectedUser.object.get(db._User.USERNAME)}
                                        onChange={e => this.changeUserField(db._User.USERNAME, e.target.value)}>
                                    </Input>

                                    <Label for={db._User.EMAIL}>E-mail:</Label>
                                    <Input
                                        type={'text'}
                                        name={db._User.EMAIL}
                                        id={db._User.EMAIL}
                                        value={this.state.selectedUser && this.state.selectedUser.object.get(db._User.EMAIL)}
                                        onChange={e => this.changeUserField(db._User.EMAIL, e.target.value)}>
                                    </Input><br/>

                                    Active: <Toggle
                                    checked={this.state.selectedUser && this.state.selectedUser.object.get(db._User.ACTIVE)}
                                    onChange={e => this.changeUserField(db._User.ACTIVE, e.target.checked)} /><br/><br/>

                                    Deleted: <Toggle
                                    checked={this.state.selectedUser && this.state.selectedUser.object.get(db._User.DELETED)}
                                    onChange={e => this.changeUserField(db._User.DELETED, e.target.checked)} />
                                </>
                            }


                        </FormGroup>

                        
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={this.onPositiveButtonClick}>Change user fields</Button>
                        <Button outline color="secondary" onClick={this.onCancelClick}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

ChangeUserFieldsModal.propTypes = {
    setToggleModal: PropTypes.func
};