import React, {Component} from 'react';
import {
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Alert,
    Badge,
    Button,
    Col,
    Row,
    Table,
    UncontrolledAccordion
} from 'reactstrap';
import swal from 'sweetalert';
import * as db from '../../lib/dbStructure';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import Parse from 'parse';
import {diff} from 'deep-object-diff';

import {
    booleanToString,
    createCommandToDevices,
    extractRoomFeedbackValues,
    getOptionFromUser,
    getRoomFeedbackSolutionTag,
    getStringFromDevice,
    isUserSureToContinue,
    moveDeviceToRoom,
    round,
    selectDeviceFromListModal, swalGetInteger, swalGetNumber,
    toPointerFromId
} from '../../lib/util';
import Toggle from 'react-toggle';
import DatePicker from 'react-datepicker';
import Select from 'react-select'
import moment from 'moment-timezone';
import qs from 'query-string';
import AddCustomCommandModal from "../DeviceView/DeviceViewTabs/add-custom-command-modal";
import SelectPeerListModal from "./modals/select-peer-list-modal";
import _ from "lodash";
import assert from "assert";

let globalTempChartConfig = {
    tooltip: {
        xDateFormat: '%Y-%m-%d %H:%M:%S'
    },
    xAxis: {
        type: 'datetime',
    },
    series: [],
    chart: {
        type: 'spline',
        zoomType: 'x'
    }
};
let globalMotorChartConfig = {
    tooltip: {
        xDateFormat: '%Y-%m-%d %H:%M:%S'
    },
    title: {
        text: 'Motor position'
    },
    xAxis: {
        type: 'datetime'
    },
    yAxis: {
        min: -10,
        max: 110,
        plotBands: [
            {
                from: 0,
                to: 100,
                color: 'rgba(0,255,64,0.1)'
            },
            {
                from: -5,
                to: 0,
                color: 'rgba(255,0,0,0.1)'
            },
            {
                from: 100,
                to: 105,
                color: 'rgba(255,0,0,0.1)'
            }
        ]
    },
    series: [],
    chart: {
        height: 300,
        type: 'spline',
        zoomType: 'x'
    }
};

function getEuropeZurichTimestamp(date){
    let offset = Math.abs(moment.tz.zone('Europe/Zurich').utcOffset(date.clone().valueOf())) * 60 * 1000;
    return date.clone().tz('Europe/Zurich').valueOf() + offset;
}

function plotBandsCo2(){

    return [
        {
            from: 0,
            to: 999,
            color: 'rgba(0,92,255,0.1)'
        },
        {
            from: 1000,
            to: 1500,
            color: 'rgba(238,212,0,0.1)'
        },
        {
            from: 1500,
            to: 5000,
            color: 'rgba(255,15,0,0.1)'
        }
    ];
}

async function isUserSure(message, icon){
    return await swal({
        title: 'Are you sure?',
        text: message || '',
        icon: icon || 'warning',
        dangerMode: true,
        buttons: ['Cancel', 'Yes']
    });
}


function getRoomSelectString(room){
    let roomName = room.get(db.Room.ROOM_NAME);
    let roomCode = room.get(db.Room.ROOM_CODE) || '';
    let roomType = room.get(db.Room.ROOM_TYPE) || '';
    let floor = room.get(db.Room.FLOOR) == null ? null : room.get(db.Room.FLOOR);
    let deleted = room.get(db.Room.DELETED);
    let hidden = room.get(db.Room.HIDDEN);

    let string  = `${roomName} (${roomCode}) <${roomType}> P${floor}`;

    if(hidden){
        string += ' (hidden)'
    }
    if(deleted){
        string += ' (deleted)'
    }

    return string;
}

export default class RoomTemperatureChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showMotor: true,
            showCo2: false,
            showMeasuredTemp: false,
            showEstimatedRoomTemp: false,
            showPresence: false,
            startDate: moment(),
            endDate: moment(),
            rooms: [],
            selectedRooms: [],
            devices: [],
            homeEvents: [],
            roomHistoryMap: {},
            roomTicketsMap: {},
            deviceHistories: [],
            roomAccordionSelection: {}
        };

        this.getRoomTemperatureChart = this.getRoomTemperatureChart.bind(this);
        this.getTemperatureCharts = this.getTemperatureCharts.bind(this);
        this.onRoomSelectChange = this.onRoomSelectChange.bind(this);
        this.getPresencePreset = this.getPresencePreset.bind(this);
        this.toggleField = this.toggleField.bind(this);
        this.serieNameFromKey = this.serieNameFromKey.bind(this);
        this.getDevices = this.getDevices.bind(this);
        this.removeRoomFromSelection = this.removeRoomFromSelection.bind(this);
        this.getRoomFeedbacksForRooms = this.getRoomFeedbacksForRooms.bind(this);
        this.getRoomCommandQueueMap = this.getRoomCommandQueueMap.bind(this);
        this.getRoomTickets = this.getRoomTickets.bind(this);
        this.selectRoomsOnFloor = this.selectRoomsOnFloor.bind(this);
        this.selectRoomsOnType = this.selectRoomsOnType.bind(this);
        this.toggleAddCustomCommandModal = {};
        this.toggleSelectPeerList;
    }

    async componentDidMount(){
        try {
            let home = await this.loadHome();

            document.title = `${home.get(db.Home.HOME_NAME)}`;

            await this.getEventsForHome();
            await this.getWeatherSerie(home);

            let rooms = await this.getRoomsFromHome(this.props.match.params.homeId);

            const parsed = qs.parse(location.search);
            let selectedRoomId = parsed.selectedRoomId;
            let selectedRooms = [];

            if(selectedRoomId){
                if(selectedRoomId.includes(',')){
                    let roomIds = selectedRoomId.split(',');
                    selectedRooms = rooms.filter(room => roomIds.indexOf(room.id) >= 0);
                } else {
                    selectedRooms = rooms.filter(room => room.id === selectedRoomId);
                }
            }

            selectedRooms = _.orderBy(selectedRooms, room => room.get(db.Room.FLOOR));

            let startDate = parsed.startDate;
            let endDate = parsed.endDate;

            if(startDate != null && endDate != null){
                startDate = moment(startDate, 'DD-MM-YYYY');
                endDate = moment(endDate, 'DD-MM-YYYY');

                this.setState({startDate, endDate});
            } else {
                window.history.replaceState('', '', `room-temperature-chart?selectedRoomId=${selectedRoomId}&startDate=${this.state.startDate.format('DD-MM-YYYY')}&endDate=${this.state.endDate.format('DD-MM-YYYY')}`);
            }

            let deviceHistories = await this.getDeviceHistory(selectedRooms);

            this.setState({rooms, home, selectedRooms, deviceHistories});
        } catch (e) {
            console.error(e);
        }
    }

    async getPresencePreset(room) {
        let result = await Parse.Cloud.run('client-graph-presence-preset', {
            roomId: room.id,
            startDate: this.state.startDate.toDate(),
            endDate: this.state.endDate.toDate()
        });

        let presencePresetSeries = [
            {
                name: 'Presence preset for room ' + room.id,
                data: result.map(function (record) {
                    return {
                        x: moment(record.date).unix() * 1000,
                        y: record.value,
                        label: record.label
                    };
                })
            }
        ];
        return presencePresetSeries[0];
    };

    serieNameFromKey(key, devices){
        let mapDeviceIdDevice = {};

        devices.forEach(device => {
            mapDeviceIdDevice[device.id] = device;
        });

        let split = key.split('(');

        let idDevice = split[0].replace('Device$', '');

        let device =  mapDeviceIdDevice[idDevice];

        if(!device) {
            console.error(`Cannot read device id or device not found from key: ${key}`);
            return `Not defined`;
        }

        return `${device.get(db.Device.DEVICE_TYP)}-${device.get(db.Device.SERIAL_NUMBER)}`;
    }


    async getRoomHistoryMap(selectedRooms){
        let roomHistoryMap = {};

        let roomHistories = await (new Parse.Query(db.classes.RoomHistory))
            .containedIn(db.RoomHistory.ROOM, selectedRooms)
            .include(db.RoomHistory.USER)
            .limit(1000)
            .find();

        roomHistories.forEach(roomHistory => {
            let room = roomHistory.get(db.RoomHistory.ROOM);
            let oldRoom = roomHistory.get(db.RoomHistory.OLD_ROOM);
            let newRoom = roomHistory.get(db.RoomHistory.NEW_ROOM);
            let user = roomHistory.get(db.RoomHistory.USER);
            let createdAt = roomHistory.get(db.RoomHistory.CREATED_AT);

            if(!roomHistoryMap[room.id]){
                roomHistoryMap[room.id] = {
                    roomHistories: []
                }
            }

            roomHistoryMap[room.id].roomHistories.push({
                changes: diff(oldRoom, newRoom),
                user: user && user.get(db._User.USERNAME),
                timestamp: moment(createdAt).format()
            });
        });

        return roomHistoryMap;
    }

    async getRoomCommandQueueMap(selectedRooms){
        let roomCommandsMap = {};

        let commandsQueue = await (new Parse.Query(db.classes.CommandQueue))
            .containedIn(db.CommandQueue.ROOM, selectedRooms)
            .include(db.CommandQueue.USER)
            .limit(10000)
            .find();

        commandsQueue.forEach(commandQueue => {
            let room = commandQueue.get(db.CommandQueue.ROOM);

            if(!roomCommandsMap[room.id]){
                roomCommandsMap[room.id] = {
                    roomCommands: []
                }
            }

            roomCommandsMap[room.id].roomCommands.push(commandQueue);
        });

        return roomCommandsMap;
    }

    async getTemperatureCharts(){
        let self = this;
        let temperatures = this.state.selectedRooms.map(this.getRoomTemperatureChart);
        let presencePresets = this.state.selectedRooms.map(this.getPresencePreset);
        let devices = await this.getDevices();
        let deviceHistories = await this.getDeviceHistory(this.state.selectedRooms);
        let temperatureResults = await Promise.all(temperatures);
        let presencePresetResults = await Promise.all(presencePresets);

        let roomEventsMap = await this.getEventsForRooms(this.state.selectedRooms);
        let roomRoomFeedbacksMap = await this.getRoomFeedbacksForRooms(this.state.selectedRooms);
        let roomHistoryMap = await this.getRoomHistoryMap(this.state.selectedRooms);
        let roomCommandsMap = await this.getRoomCommandQueueMap(this.state.selectedRooms);
        let roomTicketsMap = await this.getRoomTickets(this.state.selectedRooms);

        let targetMotorSeriesArray = [];
        let currentMotorSeriesArray = [];
        let tempSeriesArray = [];
        let presencePresetArray = [];
        let co2SeriesArray = [];
        let presenceSeriesArray = [];

        temperatureResults.map((result, i) => {
            let room = this.state.selectedRooms[i];
            let presenceForecastEnabled = room.get(db.Room.PRESENCE_FORECAST_ENABLED);

            let tempSeries = Object.keys(result).map(function (key) {
                let name = self.serieNameFromKey(key, devices);
                return {
                    name,
                    data: _.sortBy(result[key].map(function (res) {
                        if(self.state.showMeasuredTemp){
                            return [getEuropeZurichTimestamp(moment(res.timestamp)), res.measuredTemp];
                        }

                        if(self.state.showEstimatedRoomTemp){
                            return [getEuropeZurichTimestamp(moment(res.timestamp)), res.estimatedRoomTemp];
                        }
                        return [getEuropeZurichTimestamp(moment(res.timestamp)), res.temp];
                    }), item => item[0])
                }
            });
            let targetTempSerie = Object.keys(result).map(function (key) {
                //if (key.endsWith('(sense)') || Object.keys(result).filter(name => name.endsWith('(sense)')).length === 0) {
                    let name = self.serieNameFromKey(key, devices);
                    return {
                        name: 'Target temp. ' + name,
                        data: _.sortBy(result[key].map(function (res) {

                            return [getEuropeZurichTimestamp(moment(res.timestamp)), res.targetTemp];
                        }), item => item[0])
                    }
                //}
            }).filter(val => !!val);

            tempSeries = tempSeries.concat(targetTempSerie);

            let targetMotorSeries = Object.keys(result).map(function (key) {
                let name = self.serieNameFromKey(key, devices);
                return {
                    name,
                    data: _.sortBy(result[key].map(function (res) {

                        return [getEuropeZurichTimestamp(moment(res.timestamp)), parseInt(res.targetMotorState)];
                    }), item => item[0])
                }
            });

            let currentMotorStateSeries = Object.keys(result).map(function (key) {
                let name = self.serieNameFromKey(key, devices);
                return {
                    name,
                    data: _.sortBy(result[key].map(function (res) {

                        return [getEuropeZurichTimestamp(moment(res.timestamp)), parseInt(res.motorPosition)];
                    }), item => item[0])
                }
            });

            let co2Series = Object.keys(result).map(function (key) {
                let name = self.serieNameFromKey(key, devices);
                return {
                    name,
                    data: _.sortBy(result[key].map(function (res) {
                        return [getEuropeZurichTimestamp(moment(res.timestamp)), res.co2];
                    }), item => item[0])
                }
            }).filter(val => !!val);

            let presenceSeries = Object.keys(result).map(function (key) {
                let name = self.serieNameFromKey(key, devices);
                return {
                    name,
                    data: _.sortBy(result[key].map(function (res) {
                        return [getEuropeZurichTimestamp(moment(res.timestamp)), res.presence];
                    }), item => item[0])
                }
            }).filter(val => !!val);

            tempSeriesArray.push(tempSeries);
            targetMotorSeriesArray.push(targetMotorSeries);
            currentMotorSeriesArray.push(currentMotorStateSeries);
            co2SeriesArray.push(co2Series);
            presenceSeriesArray.push(presenceSeries);
        });

        /*let presenceForecastSeries = this.state.selectedRooms.map(room => {
            let presencePreset = (new Array(24)).fill(0);
            let min = room.get(db.Room.TEMP_MIN);
            let max = room.get(db.Room.TEMP_MAX);

            return {
                name: 'presence-forecast',
                data: presencePreset.map((value, i) => {
                    let temp = 0;
                    if(value > 0){
                        temp = max;
                    } else {
                        temp = min;
                    }

                    return [getEuropeZurichTimestamp(moment(this.state.startDate).startOf('day').add(i, 'hours')), parseInt(temp)]
                })
            }
        });*/

        let presenceForecastSeries = this.state.selectedRooms.map(room => {
            if(!room) return;

            let weeklyPresencePreset = room.get(db.Room.WEEKLY_PRESENCE_FORECAST);
            let presenceForecastEnabled = room.get(db.Room.PRESENCE_FORECAST_ENABLED);

            let currentDay = moment().day();
            let presencePreset = [];

            if(weeklyPresencePreset)
                presencePreset = weeklyPresencePreset[currentDay];

            let min = room.get(db.Room.TEMP_MIN);
            let max = room.get(db.Room.TEMP_MAX);

            return {
                name: 'presence-forecast',
                data: presencePreset.map((value, i) => {
                    let temp = 0;
                    if(value > 0.10){
                        temp = max;
                    } else {
                        temp = min;
                    }

                    return [
                        getEuropeZurichTimestamp(moment(this.state.startDate).startOf('day').add(i, 'hours')),
                        parseInt(temp)
                    ];
                })
            }
        });

        for(let [i, presenceForecastSerie] of presenceForecastSeries.entries()){
            tempSeriesArray[i].push(presenceForecastSerie);
        }

        for(let [i, presencePresetResult] of presencePresetResults.entries()) {
            tempSeriesArray[i].push(presencePresetResult);
        }

        this.setState({
            devices,
            tempSeriesArray,
            targetMotorSeriesArray,
            currentMotorSeriesArray,
            presencePresetArray,
            co2SeriesArray,
            presenceSeriesArray,
            roomEventsMap,
            roomRoomFeedbacksMap,
            roomHistoryMap,
            roomCommandsMap,
            roomTicketsMap,
            deviceHistories
        });
    }

    loadHome(){
        let query = new Parse.Query('Home');
        query.include('Owner');
        return query.get(this.props.match.params.homeId);
    }

    getRoomsFromHome(homeId){
        let query = new Parse.Query('Room');
        query.equalTo('home', toPointerFromId(homeId, 'Home'));
        query.notEqualTo(db.Room.DELETED, true)
        query.limit(10000);

        return query.find();
    }

    getRoomTemperatureChart(room){
        if(!room) return;

        return Parse.Cloud.run('client-graph-temperature', {
            roomId: room.id,
            startDate: this.state.startDate.startOf('day').toDate(),
            endDate: this.state.endDate.endOf('day').toDate()
        });
    }

    plotBandsRoomTemperature(tempMin, tempMax) {

        return [
            {
                from: 0,
                to: tempMin,
                color: 'rgba(255, 100, 5, 0.1)',
                label: {
                    text: 'Too low',
                    style: {
                        color: '#606060'
                    }
                }
            },
            {
                from: tempMin,
                to: tempMax,
                color: 'rgba(200, 238, 200, 0.5)',
                label: {
                    text: 'Ok',
                    style: {
                        color: '#606060'
                    }
                }
            },
            {
                from: tempMax,
                to: 40,
                color: 'rgba(255, 100, 5, 0.1)',
                label: {
                    text: 'Too high',
                    style: {
                        color: '#606060'
                    }
                }
            }
        ];
    }


    async getEventsForRooms(selectedRooms){
        let masterStart = moment().subtract(1, 'month').toDate();
        let masterEnd = moment().add(1, 'day').toDate();

        let eventsThatStartInRange = (new Parse.Query(db.classes.CalendarEvent))
            .containedIn(db.CalendarEvent.ROOM, selectedRooms)
            .exists(db.CalendarEvent.ROOM)
            .greaterThanOrEqualTo(db.CalendarEvent.END_DATE, masterStart)
            .lessThanOrEqualTo(db.CalendarEvent.END_DATE, masterEnd);

        let eventsThatEndInRange = (new Parse.Query(db.classes.CalendarEvent))
            .containedIn(db.CalendarEvent.ROOM, selectedRooms)
            .exists(db.CalendarEvent.ROOM)
            .lessThanOrEqualTo(db.CalendarEvent.START_DATE, masterEnd)
            .greaterThanOrEqualTo(db.CalendarEvent.START_DATE, masterStart);


        let eventsThatArActiveInRange = (new Parse.Query(db.classes.CalendarEvent))
            .containedIn(db.CalendarEvent.ROOM, selectedRooms)
            .exists(db.CalendarEvent.ROOM)
            .lessThanOrEqualTo(db.CalendarEvent.START_DATE, masterStart)
            .greaterThanOrEqualTo(db.CalendarEvent.END_DATE, masterEnd);

        let query = Parse.Query
            .or(eventsThatStartInRange, eventsThatEndInRange, eventsThatArActiveInRange)
            .ascending(db.CalendarEvent.START_DATE);

        let events = await query.find();

        let roomEventsMap = {};

        events.forEach(event => {
            let room = event.get(db.CalendarEvent.ROOM);

            if(!roomEventsMap[room.id]){
                roomEventsMap[room.id] = {
                    events: []
                }
            }

            roomEventsMap[room.id].events.push(event);
        });

        return roomEventsMap;
    }

    async getEventsForHome(){
        let masterStart = moment().subtract(1, 'month').toDate();
        let masterEnd = moment().add(1, 'day').toDate();

        let eventsThatStartInRange = (new Parse.Query(db.classes.CalendarEvent))
            .equalTo(db.CalendarEvent.HOME, toPointerFromId(this.props.match.params.homeId, db.classes.Home))
            .greaterThanOrEqualTo(db.CalendarEvent.END_DATE, masterStart)
            .lessThanOrEqualTo(db.CalendarEvent.END_DATE, masterEnd)
            .limit(10000);

        let eventsThatEndInRange = (new Parse.Query(db.classes.CalendarEvent))
            .equalTo(db.CalendarEvent.HOME, toPointerFromId(this.props.match.params.homeId, db.classes.Home))
            .lessThanOrEqualTo(db.CalendarEvent.START_DATE, masterEnd)
            .greaterThanOrEqualTo(db.CalendarEvent.START_DATE, masterStart)
            .limit(10000);


        let eventsThatArActiveInRange = (new Parse.Query(db.classes.CalendarEvent))
            .equalTo(db.CalendarEvent.HOME, toPointerFromId(this.props.match.params.homeId, db.classes.Home))
            .lessThanOrEqualTo(db.CalendarEvent.START_DATE, masterStart)
            .greaterThanOrEqualTo(db.CalendarEvent.END_DATE, masterEnd)
            .limit(10000);

        let query = Parse.Query
            .or(eventsThatStartInRange, eventsThatEndInRange, eventsThatArActiveInRange)
            .ascending(db.CalendarEvent.START_DATE)
            .limit(10000);

        let homeEvents = await query
            .limit(10000)
            .find();

        homeEvents = homeEvents.filter(homeEvent => {
            let room = homeEvent.get(db.CalendarEvent.ROOM);

            if(room != null) return false;

            return true;
        });

        this.setState({homeEvents});
    }

    async getRoomFeedbacksForRooms(selectedRooms){
        selectedRooms = selectedRooms || this.state.selectedRooms;

        let oneDayAgo = moment().subtract(7, 'day').toDate();

        let roomFeedbacks = await (new Parse.Query(db.classes.RoomFeedback))
            .containedIn(db.RoomFeedback.ROOM, selectedRooms)
            .greaterThanOrEqualTo(db.RoomFeedback.CREATED_AT, moment().subtract(1, 'month').toDate())
            .notEqualTo(db.RoomFeedback.DELETED, true)
            .exists(db.RoomFeedback.ROOM)
            .include(db.RoomFeedback.CHECKED_BY)
            .limit(10000)
            .find();

        let roomRoomFeedbacksMap = {};

        roomFeedbacks.forEach(roomFeedback => {
            let room = roomFeedback.get(db.CalendarEvent.ROOM);

            if(!roomRoomFeedbacksMap[room.id]){
                roomRoomFeedbacksMap[room.id] = {
                    roomFeedbacks: []
                }
            }

            roomRoomFeedbacksMap[room.id].roomFeedbacks.push(roomFeedback);
        });

        return roomRoomFeedbacksMap;
    }


    async getRoomTickets(selectedRooms){
        selectedRooms = selectedRooms || this.state.selectedRooms;

        let roomTickets = await (new Parse.Query(db.classes.RoomTicket))
            .containedIn(db.RoomTicket.ROOM, selectedRooms)
            .notEqualTo(db.RoomTicket.DELETED, true)
            .limit(100000)
            .find();

        let roomTicketsMap = {};

        roomTickets.forEach(roomTicket => {
            let room = roomTicket.get(db.RoomTicket.ROOM);

            if(!roomTicketsMap[room.id]){
                roomTicketsMap[room.id] = {
                    roomTickets: []
                }
            }

            roomTicketsMap[room.id].roomTickets.push(roomTicket);
        });

        return roomTicketsMap;
    }

    onRoomSelectChange(selectedOptions){
        let roomIds = selectedOptions.map(room => room.value.id);

        this.setState(prev => {

            //Delete temp array
            prev.selectedRooms.forEach((room, i) => {
                let index = roomIds.indexOf(room.id);

                if(roomIds.indexOf(room.id) === -1){
                    if(prev.tempSeriesArray) prev.tempSeriesArray.splice(i, 1);
                    if(prev.targetMotorSeriesArray) prev.targetMotorSeriesArray.splice(i, 1);
                    if(prev.currentMotorSeriesArray) prev.currentMotorSeriesArray.splice(i, 1);
                    if(prev.co2SeriesArray) prev.co2SeriesArray.splice(i, 1);
                    if(prev.presencePresetArray) prev.presencePresetArray.splice(i, 1);
                    if(prev.presenceSeriesArray) prev.presenceSeriesArray.splice(i, 1);
                }
            });

            prev.selectedRooms = selectedOptions.map(selectedOption => selectedOption.value);

            let roomsCsv = selectedOptions.map(selectedOption => selectedOption.value.id);

            window.history.replaceState('', '', `room-temperature-chart?selectedRoomId=${roomsCsv}&startDate=${prev.startDate.format('DD-MM-YYYY')}&endDate=${prev.endDate.format('DD-MM-YYYY')}`);
                            

            return prev;
        });
    }

    removeRoomFromSelection(roomId){
        this.setState(prev => {

            //Delete temp array
            prev.selectedRooms.forEach((room, i) => {
                if(roomId === room.id){
                    if(prev.tempSeriesArray) prev.tempSeriesArray.splice(i, 1);
                    if(prev.motorSeriesArray) prev.motorSeriesArray.splice(i, 1);
                    if(prev.presencePresetArray) prev.presencePresetArray.splice(i, 1);
                }
            });

            prev.selectedRooms =  prev.selectedRooms.filter(room => room.id != roomId);

            let roomsCsv = prev.selectedRooms.map(room => room.id);

            window.history.replaceState('', '', `room-temperature-chart?selectedRoomId=${roomsCsv}`);

            return prev;
        });
    }

    async toggleField(room, index, fieldName, confirmation){
        try {
            if(confirmation){
                let doIt = await swal({
                    title: `Are you sure?`,
                    text: ``,
                    buttons: ['No', 'Yes'],
                });
                if(!doIt) return;
            }

            this.setState(async prev => {
                if (this.state.selectedRooms[index].get(fieldName) === true) {
                    this.state.selectedRooms[index].set(fieldName, false);
                } else if (this.state.selectedRooms[index].get(fieldName) === false) {
                    this.state.selectedRooms[index].set(fieldName, true);
                } else {
                    this.state.selectedRooms[index].set(fieldName, true);
                }

                await this.state.selectedRooms[index].save();
                swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
            });
        } catch (e) {
            swal('Error', e.message, 'error');
        }
    }

    getWeatherSerie(home){
        let weatherForecastToday = home.get(db.Home.WEATHER_FORECAST_TODAY);

        if(!weatherForecastToday) return;

        let date = moment(weatherForecastToday.today).startOf('day');

        let defaultWeatherForecast = (new Array(24))
            .fill({uvIndex: 0, temperature: 0, cloudCover: 0});

        let weatherForecasts = weatherForecastToday.weatherForecasts ?
            weatherForecastToday.weatherForecasts : defaultWeatherForecast;

        let uvIndexSerie = {
            name: 'UV Index',
            data: weatherForecasts.map((element, i) => {
                return [getEuropeZurichTimestamp(date.clone().add(i, 'hours')), element.uvIndex]
            })
        };

        let externalTemperatureSerie = {
            name: "External temperature",
            data: weatherForecasts.map((element, i) => {
                return [getEuropeZurichTimestamp(date.clone().add(i, 'hours')), element.temperature]
            })
        };

        let cloudCoverSerie = {
            name: "Cloud cover",
            data: weatherForecasts.map((element, i) => {
                return [getEuropeZurichTimestamp(date.clone().add(i, 'hours')), element.cloudCover]
            })
        };

        this.setState({weatherSeries: [
            uvIndexSerie,
            externalTemperatureSerie,
            cloudCoverSerie
        ]})
    }

    async changeDeviceFlag(e, device){
        e.preventDefault();

        let flag = device.get(db.Device.DEVICE_STATE_FLAG);
        let newFlag = null;

        if(flag === 'in-mount'){
            newFlag = 'online';
        }

        if(flag !== 'in-mount'){
            newFlag = 'in-mount';
        }

        if(!flag) //So it was online by default
        {
            newFlag = 'in-mount';
        }

        device.set(db.Device.DEVICE_STATE_FLAG, newFlag);

        device = await device.save();


        this.setState(prev => {
            let index = prev.devices.indexOf(device);

            prev.devices[index] = device;

            return prev;
        });

        swal({title: 'Success', text: `Changed to ${newFlag}`, icon: 'success', button: [''], timer: 1000});
    }

    async addCommand(motor, thermos, force){
        try {
            let sure = await swal({
                title: 'Are you sure?',
                text: '',
                icon: 'warning',
                dangerMode: true,
                buttons: ['Cancel', 'Yes']
            });

            if (!sure) throw new Error('Action aborted by user');

            let commandsToSave = [];

            for(let therm of thermos){
                let data = {
                    value: motor,
                    unit: 'percent',
                };

                if(force){
                    data.force = 1;
                }

                let command2 = new Parse.Object(db.classes.CommandQueue);
                command2.set('commandName', 'changeMotorPosition');
                command2.set('device', therm);
                command2.set('data', data);
                command2.set(db.CommandQueue.ROOM, therm.get(db.Device.ROOM_ID));
                command2.set(db.CommandQueue.ADDED_FROM, db.CommandQueue.ADDED_FROM$MANUAL);

                commandsToSave.push(command2);
            }

            await Parse.Object.saveAll(commandsToSave);

            swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
        } catch(e){
            console.error(e);
            swal('Error', e.message, 'error');
        }

    }

    async addPeerListCommand(sensp, thermos){
        try {
            let sure = await isUserSure();

            if (!sure) throw new Error('Action aborted by user');

            if(!sensp) throw new Error('No sensor is this room.');
            if(thermos.length === 0) throw new Error('No thermos in this room.');


            let commands = await new Parse.Query('CommandQueue')
                .notEqualTo(db.CommandQueue.DELETED, true)
                .equalTo('device', sensp)
                .find();

            let isPeerListCommandAlreadyInQueue = commands.filter(command => command.get(db.CommandQueue.COMMAND_NAME) === db.commands.CHANGE_PEER_LIST).length !== 0;

            if(isPeerListCommandAlreadyInQueue) throw new Error('There is already a change peer list command');

            let commandsToSave = [];

            let peerListString = `${thermos.length},${thermos.map(therm => therm.get(db.Device.MAC_ADDRESS)).join(',')}`;

            let data = {
                value: peerListString,
                unit: 'MAC',
            };

            let command2 = new Parse.Object('CommandQueue');
            command2.set(db.CommandQueue.COMMAND_NAME,  db.commands.CHANGE_PEER_LIST);
            command2.set(db.CommandQueue.DEVICE, sensp);
            command2.set(db.CommandQueue.DATA, data);
            command2.set(db.CommandQueue.ROOM, sensp.get(db.Device.ROOM_ID));
            command2.set(db.CommandQueue.ADDED_FROM, db.CommandQueue.ADDED_FROM$MANUAL);

            commandsToSave.push(command2);

            await Parse.Object.saveAll(commandsToSave);

            swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            swal('Error', e.message, 'error');
        }
    }
    async addDiscoveryModeCommand(sensps){
        try {
            if(sensps.length === 0) throw new Error('No sensor is this room.');

            let sure = await isUserSure();

            if (!sure) return;

            let minutes = await swal({
                heightAuto: false,
                title: 'How many minutes',
                content: {
                    element: "input",
                    attributes: {
                        placeholder: "Type the minutes",
                        type: "number",
                        value: null
                    },
                }
            });

            if(!minutes) throw new Error('Action aborted by user');


            let selectedDevices = await selectDeviceFromListModal(sensps);

            function createCommand(device, minutes) {
                let data = {
                    timeout: minutes,
                    unit: "minutes"
                };

                let command2 = new Parse.Object('CommandQueue');
                command2.set(db.CommandQueue.COMMAND_NAME, db.commands.START_NOW_DISCOVERY);
                command2.set(db.CommandQueue.DEVICE, device);
                command2.set(db.CommandQueue.DATA, data);
                command2.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID));
                command2.set(db.CommandQueue.ADDED_FROM, db.CommandQueue.ADDED_FROM$MANUAL);

                return command2;
            }

            let commandsToSave = [];
            for(let device of selectedDevices){
                let commands = await new Parse.Query('CommandQueue')
                    .notEqualTo(db.CommandQueue.DELETED, true)
                    .containedIn('device', selectedDevices)
                    .find();

                let isStartNowDiscoveryCommandAlreadyInQueue = commands.filter(command => command.get(db.CommandQueue.COMMAND_NAME) === db.commands.START_NOW_DISCOVERY).length !== 0;

                if(isStartNowDiscoveryCommandAlreadyInQueue) throw Error('There already a start now discovery command');

                commandsToSave.push(createCommand(device, minutes));
            }

            await Parse.Object.saveAll(commandsToSave);

            swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            swal('Error', e.message, 'error');
        }
    }
    async addMeshModeCommmand(sensp){
        try {
            let sure = await isUserSure("This function will configure all surrounding devices with the config of the building and can lead to a misconfiguration of several devices. Continue only if you have received a training about this function.", 'danger');

            if (!sure) return;

            let minutes = await swal({
                heightAuto: false,
                title: 'How many minutes',
                content: {
                    element: "input",
                    attributes: {
                        placeholder: "Type the minutes",
                        type: "number",
                        value: 10
                    },
                }
            });

            if(!minutes) minutes = 10;

            let commands = await new Parse.Query('CommandQueue')
                .notEqualTo(db.CommandQueue.DELETED, true)
                .equalTo('device', sensp)
                .find();

            let isStartMeshModeCommandAlreadyInQueue = commands.filter(command => command.get(db.CommandQueue.COMMAND_NAME) === db.commands.START_MESH_MODE).length !== 0;

            if(isStartMeshModeCommandAlreadyInQueue) throw Error('There already a start mesh mode command');

            if(!sensp) throw new Error('No sensor is this room.');

            let commandsToSave = [];
            let data = {
                duration: minutes,
                unit: "minutes"
            };

            let command2 = new Parse.Object('CommandQueue');
            command2.set(db.CommandQueue.COMMAND_NAME,  db.commands.START_MESH_MODE);
            command2.set(db.CommandQueue.DEVICE, sensp);
            command2.set(db.CommandQueue.DATA, data);
            command2.set(db.CommandQueue.ROOM, sensp.get(db.Device.ROOM_ID));
            command2.set(db.CommandQueue.ADDED_FROM, db.CommandQueue.ADDED_FROM$MANUAL);

            commandsToSave.push(command2);

            await Parse.Object.saveAll(commandsToSave);

            swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            swal('Error', e.message, 'error');
        }
    }

    async addCalibrateMotorCommand(roomDevices){
        try {
            let sure = await isUserSure("This function will do a calibration of the control system. Continue only if you have received a training about this function.", 'warning');

            if (!sure) throw new Error('Aborted by user');

            let thermos = roomDevices.filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$THERM);
            let options = thermos.map(device => device.get(db.Device.SERIAL_NUMBER) + '');

            options.push('all-thermo');

            let option = await getOptionFromUser(options, 'Select the thermo to calibrate');

            let command = new Parse.Object('CommandQueue');
            command.set(db.CommandQueue.COMMAND_NAME,  db.commands.CALIBRATE_MOTOR);
            command.set(db.CommandQueue.DATA, {});
            command.set(db.CommandQueue.ADDED_FROM, db.CommandQueue.ADDED_FROM$MANUAL);

            if(option === 'all-thermo'){
                const commandsToSave = createCommandToDevices(thermos, command);

                await Parse.Object.saveAll(commandsToSave);
                await swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
                return;
            }

            let devices = roomDevices.filter(device => device.get(db.Device.SERIAL_NUMBER) === parseInt(option));

            if(devices.length === 0) throw new Error('No device found with this serial number in this room');

            let device = devices[0];

            let commands = await new Parse.Query('CommandQueue')
                .notEqualTo(db.CommandQueue.DELETED, true)
                .equalTo('device', device)
                .find();

            let isCalibrateMotorCommandAlreadyInQueue = commands.filter(command => command.get(db.CommandQueue.COMMAND_NAME) === db.commands.CALIBRATE_MOTOR).length !== 0;

            if(isCalibrateMotorCommandAlreadyInQueue) throw Error('There already a motor calibrate command');

            if(!device) throw new Error('This device is not in this room');

            command.set(db.CommandQueue.DEVICE, device);
            command.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID));

            await command.save();

            await swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e);
            await swal('Error', e.message, 'error');
        }
    }

    async addChangeConnectionInterval(roomDevices){
        try {
            let sure = await isUserSure(null, 'warning');

            if (!sure) throw new Error('Aborted by user');

            let sensps = roomDevices.filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$SENSP);
            if(sensps.length === 0) throw new Error('No sensors in this room');

            let selectedDevices = await selectDeviceFromListModal(sensps);

            console.log('selectedDevice', selectedDevices);

            let minutes = await swal({
                heightAuto: false,
                title: 'How many minutes',
                content: {
                    element: "input",
                    attributes: {
                        placeholder: "Type the minutes",
                        type: "number",
                        value: 10
                    },
                }
            });

            if(!minutes) minutes = "10";

            let commandsToBeSaved = [];

            for(let device of selectedDevices){
                let command = new Parse.Object(db.classes.CommandQueue);
                command.set(db.CommandQueue.COMMAND_NAME, db.commands.CONNECTION_INTERVAL);
                command.set(db.CommandQueue.DEVICE, device);
                command.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID));
                command.set(db.CommandQueue.ADDED_FROM, db.CommandQueue.ADDED_FROM$MANUAL);
                command.set(db.CommandQueue.DATA, {
                    value: parseInt(minutes),
                    unit: 'minutes'
                });

                commandsToBeSaved.push(command);
            }

            await Parse.Object.saveAll(commandsToBeSaved);

            await swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            console.error(e);
            await swal('Error', e.message, 'error');
        }
    }


    async toggleMountMode(devices){

    }

    async getDevices(){
        let devices = await (new Parse.Query(db.classes.Device))
            .containedIn(db.Device.ROOM_ID, this.state.selectedRooms)
            .include(db.Device.NEIGHBORS_LIST)
            .limit(10000)
            .find();

        this.setState({devices});

        return devices;
    }

    async getDeviceHistory(selectedRooms){
        let oldRoomQuery = new Parse.Query(db.classes.DeviceHistory);
        oldRoomQuery.containedIn(db.DeviceHistory.OLD_ROOM, selectedRooms);

        let newQuery = new Parse.Query(db.classes.DeviceHistory);
        newQuery.containedIn(db.DeviceHistory.NEW_ROOM, selectedRooms);


        let deviceHistories = await Parse.Query.or(oldRoomQuery, newQuery)
            .include(db.DeviceHistory.DEVICE)
            .include(db.DeviceHistory.OLD_ROOM)
            .include(db.DeviceHistory.NEW_ROOM)
            .include(db.DeviceHistory.OLD_HOME)
            .include(db.DeviceHistory.NEW_HOME)
            .select([
                `${db.DeviceHistory.DEVICE}.${db.Device.SERIAL_NUMBER}`,
                `${db.DeviceHistory.DEVICE}.${db.Device.MAC_ADDRESS}`,
                `${db.DeviceHistory.OLD_ROOM}.${db.Room.ROOM_NAME}`,
                `${db.DeviceHistory.NEW_ROOM}.${db.Room.ROOM_NAME}`,
                `${db.DeviceHistory.OLD_HOME}.${db.Home.HOME_NAME}`,
                `${db.DeviceHistory.NEW_HOME}.${db.Home.HOME_NAME}`
            ])
            .find();

        return deviceHistories;
    }

    async addCustomCommand(name, data, roomDevices){
        try {
            if(!name)
                throw new Error('No name defined');

            if(!data)
                throw new Error('No data defined');

            if(roomDevices == null)
                throw new Error('No devices');

            if(roomDevices.length <= 0)
                throw new Error('No devices');

            try {
                data = JSON.parse(data);
            } catch (e) {
                throw new Error('Json not valid');
            }

            let sure = await swal({
                title: 'Are you sure?',
                text: 'Custom commands are not checked by the system and can potentially compromise their functions.',
                icon: 'warning',
                dangerMode: true,
                buttons: ['Cancel', 'Yes']
            });

            if (!sure) throw new Error('Aborted by user');

            let value = await swal("Please select what devices should receive a command", {
                buttons: {
                    cancel: "Cancel",
                    thermo: {
                        text: "Only to thermo devices",
                        value: "thermo",
                    },
                    sensp: {
                        text: "Only to sensp devices",
                        value: "sensp",
                    },
                    all: {
                        text: "Send command to all devices",
                        value: "all",
                    }
                }
            });
            let sensps = roomDevices.filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$SENSP);
            let thermos = roomDevices.filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$THERM);

            let devices = [];
            if(value === 'sensp'){
                devices = sensps;
            } else if (value === 'thermo'){
                devices = thermos;
            } else if (value === 'all'){
                devices = thermos.concat(sensps);
            } else {
                throw new Error('No selection found');
            }

            let commandsToSend = [];
            for(let device of devices){
                
                let commandQueue = new Parse.Object(db.classes.CommandQueue);
                commandQueue.set('commandName', name);
                commandQueue.set('device', device);
                commandQueue.set('data', data);
                commandQueue.set(db.CommandQueue.ROOM, device.get(db.Device.ROOM_ID));

                commandsToSend.push(commandQueue);
            }

            await Parse.Object.saveAll(commandsToSend);

            await swal('Success', '', 'success');
        } catch (e){
            console.error(e);
            await swal('Error', e.message, 'error');
        }
    }

    async addChangeEspNowStateCommand(roomDevices, enable = true){
        let sure = await swal({
            title: 'Are you sure?',
            text: 'Custom commands are not checked by the system and can potentially compromise their functions.',
            icon: 'warning',
            dangerMode: true,
            buttons: ['Cancel', 'Yes']
        });

        if (!sure) throw new Error('Aborted by user');

        let thermos = roomDevices.filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$THERM);

        try {
            let commandsToSend = [];
            for(let therm of thermos){
                let commands = await new Parse.Query(db.classes.CommandQueue)
                    .notEqualTo(db.CommandQueue.DELETED, true)
                    .equalTo(db.CommandQueue.DEVICE, therm)
                    .find();

                let isDisableNowStateCommandAlreadyInQueue = commands.filter(command => command.get(db.CommandQueue.COMMAND_NAME) === db.commands.CHANGE_NOW_STATE).length !== 0;

                if(!isDisableNowStateCommandAlreadyInQueue){
                    let disableNowStateCommand = new Parse.Object(db.classes.CommandQueue);
                    disableNowStateCommand.set(db.CommandQueue.COMMAND_NAME, db.commands.CHANGE_NOW_STATE);
                    disableNowStateCommand.set(db.CommandQueue.DEVICE, therm);
                    disableNowStateCommand.set(db.CommandQueue.DATA, {
                        value: enable ? 1 : 0
                    });
                    disableNowStateCommand.set(db.CommandQueue.ROOM, therm.get(db.Device.ROOM_ID));
                    disableNowStateCommand.set(db.CommandQueue.ADDED_FROM, db.CommandQueue.ADDED_FROM$MANUAL);

                    commandsToSend.push(disableNowStateCommand);
                }
            }

            await Parse.Object.saveAll(commandsToSend);

            await swal('Success', '', 'success');
        } catch (e){
            console.error(e);
            await swal('Error', e.message, 'error');
        }
    }

    async createTicket(e, roomFeedback){
        e.preventDefault();
        try{

            let willDelete = await swal({
                title: 'Are you sure?',
                text: 'Are you sure to open a ticket about this feedback? This will notify the client about this issue to be careful.',
                icon: 'warning',
                dangerMode: true,
                buttons: ['Cancel', 'Open ticket']
            });

            if (!willDelete) throw new Error('Aborted by user');


            let emailResponse = await swal("Decide the 'from' email of the ticket. If you choose 'client' an email will be sent to client.", {
                buttons: {
                    cancel: "Cancel",
                    cleveron: 'Open ticket from support@cleveron.ch',
                    client: `Open ticket from ${roomFeedback.get(db.RoomFeedback.EMAIL)}`,
                },
            });

            if(!emailResponse) throw new Error('Aborted by user');

            let email = 'support@cleveron.ch';

            switch (emailResponse) {
                case "cleveron": break;
                case "client":email = roomFeedback.get(db.RoomFeedback.EMAIL); break;
            }


            await Parse.Cloud.run('create-ticket-from-room-feedback', {
                roomFeedbackId: roomFeedback.id,
                email
            });

            await swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        }catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async setAllFeedbackToChecked(e, roomFeedbacks){
        e.preventDefault();
        try {
            let currentUser = Parse.User.current();

            await isUserSureToContinue();

            roomFeedbacks = roomFeedbacks
                .filter(roomFeedback => roomFeedback.get(db.RoomFeedback.STATUS) !== db.RoomFeedback.STATUS$CHECKED)
                .map(roomFeedback => {
                    roomFeedback.set(db.RoomFeedback.STATUS, db.RoomFeedback.STATUS$CHECKED);
                    roomFeedback.set(db.RoomFeedback.CHECKED_BY, currentUser);

                    return roomFeedback;
                });

            await Parse.Object.saveAll(roomFeedbacks);

            let roomRoomFeedbacksMap = await this.getRoomFeedbacksForRooms();
            this.setState({roomRoomFeedbacksMap});

            swal('Success', '', 'success');
        }catch(e){
            swal('Error', e.message, 'error');
        }
    }

    async linkToRoom(home, room){
        try {
            if (!home || !room) throw new Error('Home or room are not available. Contact the technical support.');

            let serialNumber = await swal({
                heightAuto: false,
                title: 'Enter device serial',
                content: {
                    element: "input",
                    attributes: {
                        placeholder: "ex. 2300111",
                        type: "text",
                        value: ''
                    },
                }
            });

            if(!serialNumber) throw new Error('Action aborted by user');

            if(serialNumber.startsWith('https://devices.cleveron.ch/device/')){
                serialNumber = serialNumber.replace('https://devices.cleveron.ch/device/', '');
                serialNumber = serialNumber.toUpperCase();
            }

            if( serialNumber.split('?').length > 0){
                serialNumber = serialNumber.split('?')[0];
            }

            assert(serialNumber.toString().length === 7 || serialNumber.toString().length === 10, 'Serial is not valid');

            let device = await new Parse.Query(db.classes.Device)
                .equalTo(db.Device.SERIAL_NUMBER, parseInt(serialNumber))
                .include(db.Device.HOME)
                .include(db.Device.ROOM_ID)
                .first();

            if(!device) {
                throw new Error(`No device found with serial ${serialNumber}`);
            }

            let deviceRoom = device.get(db.Device.ROOM_ID);
            let deviceHome = device.get(db.Device.HOME);

            let sure = await isUserSure(`Device ${device.get(db.Device.SERIAL_NUMBER)} will not be visible anymore on the home ${deviceHome.get(db.Home.HOME_NAME)} section of the client.`);

            if (!sure) throw new Error('Aborted by user');

            moveDeviceToRoom(device, room);

            await device.save();

            swal({title: 'Success', text: 'Device linked correctly', icon: 'success', button: [''], timer: 1000});
        } catch (e){
            swal('Error', e.message, 'error');
        }
    }

    selectRoomsOnFloor(floor){
        let rooms = this.state.rooms;

        let selectedRooms = rooms.filter(room => {
            if(room.get(db.Room.FLOOR) === floor) return true;

            return false;
        });

        this.setState({selectedRooms});
    }
    selectRoomsOnType(roomTypes){
        let rooms = this.state.rooms;

        let selectedRooms = rooms.filter(room => {
            if(roomTypes.indexOf(room.get(db.Room.ROOM_TYPE)) >= 0) return true;

            return false;
        });

        this.setState({selectedRooms});
    }

    render() {
        let roomDeviceHistoryMap = {};

        this.state.deviceHistories.forEach(deviceHistory => {
            let createdAt = deviceHistory.get(db.DeviceHistory.CREATED_AT);
            let device = deviceHistory.get(db.DeviceHistory.DEVICE);
            let oldRoom = deviceHistory.get(db.DeviceHistory.OLD_ROOM);
            let newRoom = deviceHistory.get(db.DeviceHistory.NEW_ROOM);
            let oldHome = deviceHistory.get(db.DeviceHistory.OLD_HOME);
            let newHome = deviceHistory.get(db.DeviceHistory.NEW_HOME);

            const object = {
                ts: moment(createdAt),
                text: <p key={deviceHistory.id}>
                    Device: {device && device.get(db.Device.SERIAL_NUMBER)}&nbsp;
                    {oldRoom && oldRoom.get(db.Room.ROOM_NAME)} ({oldHome && oldHome.get(db.Home.HOME_NAME)})&nbsp; ->
                    {newRoom && newRoom.get(db.Room.ROOM_NAME)} ({newHome && newHome.get(db.Home.HOME_NAME)})
                </p>
            };

            if(oldRoom){
                if(roomDeviceHistoryMap[oldRoom.id] == null)
                    roomDeviceHistoryMap[oldRoom.id] = [];

                roomDeviceHistoryMap[oldRoom.id].unshift(object);
            }

            if(newRoom){
                if(roomDeviceHistoryMap[newRoom.id] == null)
                    roomDeviceHistoryMap[newRoom.id] = [];

                roomDeviceHistoryMap[newRoom.id].unshift(object);
            }
        });

        function loadHighChart(config, callback){
            return <HighchartsReact options={config} highcharts={Highcharts}></HighchartsReact>
        }

        let renderEvent = (event) => {
            let createdAt = moment(event.get(db.CalendarEvent.CREATED_AT)).format('DD/MM/YYYY HH:mm');
            let lastUpdated = moment(event.get(db.CalendarEvent.UPDATED_AT)).format('DD/MM/YYYY HH:mm');
            let start = moment(event.get(db.CalendarEvent.START_DATE)).format('DD/MM/YYYY HH:mm');
            let end = moment(event.get(db.CalendarEvent.END_DATE)).format('DD/MM/YYYY HH:mm');
            let name = event.get(db.CalendarEvent.EVENT_NAME);
            let heat = event.get(db.CalendarEvent.EVENT_TYPE);
            let deleted = event.get(db.CalendarEvent.DELETED) === true;
            let inactive = event.get(db.CalendarEvent.ACTIVE) === false;
            let room = event.get(db.CalendarEvent.ROOM);
            let home = event.get(db.CalendarEvent.HOME);

            return <p key={event.id}>e:{event.id} r:{room && room.id} h:{home && home.id} {createdAt}: {start} -> {end}: {name} -> {heat} {deleted && <Badge color="danger">deleted ({lastUpdated})</Badge>} {inactive && <Badge color="danger">inactive</Badge>}</p>;
        };

        let renderRoomFeedback = (roomFeedback) => {
            let {
                temperatureFeedbackValue,
                gravityFeedbackValue,
                radiatorsFeedbackValue,
                usageFeedbackValue,
                text,
                internalComment,
                ticketId,
                ticketUrl,
                email,
                status
            } = extractRoomFeedbackValues(roomFeedback);

            let createdAt = moment(roomFeedback.get(db.RoomFeedback.CREATED_AT)).format('DD/MM/YYYY HH:mm');
            let temperature = roomFeedback.get(db.RoomFeedback.TEMPERATURE);
            let radiatorsComment = radiatorsFeedbackValue ? `${radiatorsFeedbackValue} radiators are warm`: '';
            let ticketUrlHref;
            let solutionTag = roomFeedback.get(db.RoomFeedback.SOLUTION_TAG);
            let checkedBy = roomFeedback.get(db.RoomFeedback.CHECKED_BY);

            let statusColor = '';
            if(!status || status === db.RoomFeedback.STATUS$TO_REVIEW){
                statusColor = 'warning';
            } else if (status === db.RoomFeedback.STATUS$CHECKED){
                statusColor = 'success';
            }

            if(ticketUrl){
                ticketUrlHref = <a href={ticketUrl} target={'_blank'}>Ticket link</a>;
            }

            return <p key={roomFeedback.id}>
                <Badge color={statusColor} pill>
                    {status || 'to-review'}
                    {checkedBy && <span>({checkedBy.get(db._User.USERNAME)})</span>}
                </Badge>
                {
                    solutionTag && <Badge color={'warning'} pill>
                        {solutionTag}
                    </Badge>
                }
                {createdAt} {email}: {gravityFeedbackValue} {temperatureFeedbackValue} - Comment: {text} -
                Webapp temp.: {round(temperature, 2)},&nbsp;
                {radiatorsComment}.&nbsp;
                {internalComment && <span>Internal comment: {internalComment}&nbsp;</span>}
                {ticketUrlHref && ticketUrlHref}

                {
                    !!status && status !== db.RoomFeedback.STATUS$TO_REVIEW && <>
                        <a href={'javascript: ;'}
                           onClick={async () => {
                               roomFeedback.set(db.RoomFeedback.STATUS, db.RoomFeedback.STATUS$TO_REVIEW);

                               await roomFeedback.save();

                               let roomRoomFeedbacksMap = await this.getRoomFeedbacksForRooms();
                               this.setState({roomRoomFeedbacksMap});

                               swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});
                           }}>
                            Set to-review
                        </a> &nbsp;
                    </>
                }
                {
                    status != db.RoomFeedback.STATUS$CHECKED && <>
                        <a href={'javascript: ;'}
                           onClick={async () => {
                               let solutionTag = await getRoomFeedbackSolutionTag();

                               if(solutionTag === db.RoomFeedback.SOLUTION_TAG$OTHER){
                                   let value = await swal('Please add a comment. What did you do?', {content: 'input', buttons: true});

                                   if(value != null) {
                                       roomFeedback.set(db.RoomFeedback.INTERNAL_COMMENT, value);
                                   }
                               }

                               roomFeedback.set(db.RoomFeedback.STATUS, db.RoomFeedback.STATUS$CHECKED);
                               roomFeedback.set(db.RoomFeedback.SOLUTION_TAG, solutionTag);
                               roomFeedback.set(db.RoomFeedback.CHECKED_BY, Parse.User.current());

                               await roomFeedback.save();

                               let roomRoomFeedbacksMap = await this.getRoomFeedbacksForRooms();
                               this.setState({roomRoomFeedbacksMap});

                               swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});
                           }}>
                            Set checked
                        </a> &nbsp;
                    </>
                }

                <a href='javascript: ;' onClick={e => this.createTicket(e, roomFeedback)}>Create ticket</a>
            </p>;
        };

        let renderCharts = () => {
            let hideTargetTemp = new Array(this.state.selectedRooms.length);
            let hideThermo = new Array(this.state.selectedRooms.length);
            let tempCharts = this.state.tempSeriesArray && this.state.targetMotorSeriesArray && this.state.selectedRooms.map((room, i) => {
                if(!room) return;

                let tempSeries = this.state.tempSeriesArray[i];
                let tempChartConfig = _.cloneDeep(globalTempChartConfig);

                tempChartConfig.xAxis.min = this.state.startDate.clone().hour(0).minute(0).unix() * 1000;
                tempChartConfig.xAxis.max = this.state.endDate?.clone().hour(23).minute(59).unix() * 1000;

                _.set(tempChartConfig, 'title.text', `${room.get(db.Room.ROOM_NAME)} floor ${room.get(db.Room.FLOOR)}`);
                _.set(tempChartConfig, 'yAxis.plotBands', this.plotBandsRoomTemperature(room.get(db.Room.TEMP_MIN), room.get(db.Room.TEMP_MAX)));
                _.set(tempChartConfig, 'series', tempSeries);

                let test = chart => {
                    console.log(chart);
                    hideTargetTemp[i] = () => {
                        chart.series
                        .filter(serie => {
                            return serie.name.startsWith('Target') || 
                                serie.name.startsWith('presence-forecast') || 
                                serie.name.startsWith('Presence');
                        })
                        .forEach(serie => serie.setVisible(!serie.visible));
                    }

                    hideThermo[i] = () => {
                        chart.series
                        .filter(serie => {
                            return serie.name.startsWith('therm');
                        })
                        .forEach(serie => serie.setVisible(!serie.visible));
                    }
                }

                return loadHighChart(tempChartConfig, test);
            });
            let targetMotorCharts = this.state.tempSeriesArray && this.state.targetMotorSeriesArray && this.state.selectedRooms.map((room, i) => {
                if(!room) return;
                let motorSeries = this.state.targetMotorSeriesArray[i];

                let motorChartConfig = _.cloneDeep(globalMotorChartConfig);

                motorChartConfig.xAxis.min = this.state.startDate.clone().hour(0).minute(0).unix() * 1000;
                motorChartConfig.xAxis.max = this.state.endDate?.clone().hour(23).minute(59).unix() * 1000;

                motorChartConfig.title.text = 'Target motor position sent from cloud (cloud -> device)';
                _.set(motorChartConfig, 'series', motorSeries);

                return loadHighChart(motorChartConfig);
            });
            let currentMotorCharts = this.state.tempSeriesArray && this.state.currentMotorSeriesArray && this.state.selectedRooms.map((room, i) => {
                if(!room) return;
                let motorSeries = this.state.currentMotorSeriesArray[i];

                let motorChartConfig = _.cloneDeep(globalMotorChartConfig);

                motorChartConfig.xAxis.min = this.state.startDate.clone().hour(0).minute(0).unix() * 1000;
                motorChartConfig.xAxis.max = this.state.endDate?.clone().hour(23).minute(59).unix() * 1000;

                motorChartConfig.title.text = 'Motor position reported by device (device -> cloud)';
                _.set(motorChartConfig, 'series', motorSeries);

                return loadHighChart(motorChartConfig);
            });
            let co2Charts = this.state.tempSeriesArray && this.state.co2SeriesArray && this.state.selectedRooms.map((room, i) => {
                if(!room) return;
                let co2Series = this.state.co2SeriesArray[i];
                let tempChartConfig = _.cloneDeep(globalTempChartConfig);

                tempChartConfig.xAxis.min = this.state.startDate.clone().hour(0).minute(0).unix() * 1000;
                tempChartConfig.xAxis.max = this.state.endDate?.clone().hour(23).minute(59).unix() * 1000;

                _.set(tempChartConfig, 'title.text', `co2`);
                _.set(tempChartConfig, 'chart.height', 400);
                _.set(tempChartConfig, 'yAxis.plotBands', plotBandsCo2());
                _.set(tempChartConfig, 'series', co2Series);

                return loadHighChart(tempChartConfig);
            });
            let presenceCharts = this.state.presenceSeriesArray && this.state.selectedRooms.map((room, i) => {
                if(!room) return;
                let co2Series = this.state.presenceSeriesArray[i];
                let tempChartConfig = _.cloneDeep(globalTempChartConfig);

                tempChartConfig.xAxis.min = this.state.startDate.clone().hour(0).minute(0).unix() * 1000;
                tempChartConfig.xAxis.max = this.state.endDate?.clone().hour(23).minute(59).unix() * 1000;

                _.set(tempChartConfig, 'title.text', `presence`);
                _.set(tempChartConfig, 'chart.height', 200);
                _.set(tempChartConfig, 'series', co2Series);

                return loadHighChart(tempChartConfig);
            });

            let roles = localStorage.getItem('roles') && localStorage.getItem('roles').split(',');

            if(!roles) window.location = '/login';

            let isAdmin = roles.indexOf('Admin') >= 0;
            let isSupport = roles.indexOf('Support') >= 0;
            let isLeanManagement = roles.indexOf('Lean management') >= 0;

            let renderAccordeon = (home, room, i, roomDevices, events, homeEvents, roomfeedbacks, technicalComments, roomHistories, roomCommands, roomTickets) => {
                let sensps = roomDevices.filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$SENSP);
                let sensp = sensps.length > 0 ? sensps[0] : null;

                return <div style={{marginTop: 10}}>
                    <UncontrolledAccordion defaultOpen="1">
                        <AccordionItem>
                            <AccordionHeader targetId="1">Devices ({roomDevices.length})</AccordionHeader>
                            <AccordionBody accordionId="1">
                                <Button onClick={async () => {
                                    await this.linkToRoom(home, room)
                                }}>Link device to room</Button>
                                {
                                    roomDevices.map(device => {
                                        return <p key={device.id}>
                                            {getStringFromDevice(device, sensp)}
                                            <a href={'javascript: ;'}
                                               onClick={async () => {
                                                   let positionLabel = await swal(`What is the position of the sensor? Currently: ${device.get(db.Device.TEMPERATURE_CORRECTION_LABEL)}`, {
                                                       buttons: {
                                                           cancel: "Cancel",
                                                           wall: 'Concrete wall',
                                                           isolated: `Isolated material`,
                                                       }
                                                   });

                                                   if(!positionLabel) return;

                                                   switch (positionLabel) {
                                                       case "wall": device.set(db.Device.TEMPERATURE_CORRECTION_LABEL, db.Device.TEMPERATURE_CORRECTION_LABEL$CONCRETE_WALL); break;
                                                       case "isolated": device.set(db.Device.TEMPERATURE_CORRECTION_LABEL, db.Device.TEMPERATURE_CORRECTION_LABEL$ISOLATED_MATERIAL); break;
                                                   }

                                                   await device.save();

                                                   await swal('Saved!', '', 'success');

                                                   await this.getDevices();
                                               }}
                                            >
                                                Edit
                                            </a>
                                        </p>
                                    })
                                }
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="2">Thermo V4 Actions</AccordionHeader>
                            <AccordionBody accordionId="2">
                                <Button
                                    size={'sm'}
                                    outline color={'warning'}
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        try {
                                            let thermos = roomDevices.filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$THERM);
                                            let options = thermos.map(device => device.id);
                                            options.push('all-thermo');
                                            let option = await getOptionFromUser(options, 'Select the thermo to send job');
                                            if(!option) throw Error('No device choosen.');
                                            let motorTargetPosition = await swalGetInteger('Enter motor target position', 0, 100);
                                            if(motorTargetPosition == null) throw Error('No motor position choosen.');

                                            if(option === 'all-thermo'){
                                                for(let device of thermos){
                                                    await Parse.Cloud.run('iot-create-job', {
                                                        jobName: db.jobs.CHANGE_MOTOR_POSITION,
                                                        parameters: {
                                                            pos: motorTargetPosition
                                                        },
                                                        deviceId: device.id
                                                    });
                                                }
                                                swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
                                                return;
                                            }

                                            await Parse.Cloud.run('iot-create-job', {
                                                jobName: db.jobs.CHANGE_MOTOR_POSITION,
                                                parameters: {
                                                    pos: motorTargetPosition
                                                },
                                                deviceId: option
                                            });

                                            swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
                                        } catch(e){
                                            console.error(e);
                                            swal('Error', e.message, 'error');
                                        }
                                    }}>Send change motor position job</Button>&nbsp;
                                <Button
                                    size={'sm'}
                                    outline color={'warning'}
                                    onClick={async (e) => {
                                        e.preventDefault();
                                    }}
                                >Send CalibrateMotor job</Button>
                                <Button
                                    size={'sm'}
                                    outline color={'warning'}
                                    onClick={async (e) => {
                                        e.preventDefault();
                                    }}
                                >Send retrieve logs job</Button>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="3">Quick actions</AccordionHeader>
                            <AccordionBody accordionId="3">
                                <Button
                                    size={'sm'}
                                    outline color={'warning'}
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        let sensors = roomDevices
                                            .filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$SENSP);
                                        await this.addMeshModeCommmand(sensors[0]);
                                    }}>Start mesh mode</Button>&nbsp;
                                <Button
                                    size={'sm'}
                                    outline color={'warning'}
                                    onClick={async () => await this.addCalibrateMotorCommand(roomDevices)}
                                >Add CalibrateMotor command</Button>
                                <Button
                                    size={'sm'}
                                    outline color={'warning'}
                                    onClick={async () => await this.addChangeConnectionInterval(roomDevices)}
                                >Chenge sensor connection interval</Button>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="4">ESP now</AccordionHeader>
                            <AccordionBody accordionId="4">
                                <Button
                                    size={'sm'}
                                    outline color={'warning'}
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        let sensors = roomDevices
                                            .filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$SENSP);
                                        await this.addDiscoveryModeCommand(sensors);
                                    }}>Start discovery now</Button>&nbsp;
                                <Button
                                    size={'sm'}
                                    outline color={'warning'}
                                    onClick={(e) => {
                                        e.preventDefault();

                                        this.toggleSelectPeerList(room);
                                    }}
                                >
                                    Change peer list
                                </Button>
                                <Button
                                    size={'sm'}
                                    outline color={'warning'}
                                    onClick={async () => await this.addChangeEspNowStateCommand(roomDevices, false)}
                                >
                                    Disable esp-now
                                </Button>
                                <Button
                                    size={'sm'}
                                    outline color={'warning'}
                                    onClick={async () => await this.addChangeEspNowStateCommand(roomDevices, true)}
                                >
                                    Enable esp-now
                                </Button>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="5">Room events  ({events.length})</AccordionHeader>
                            <AccordionBody accordionId="5">
                                {
                                    events.map(renderEvent)
                                }
                                {
                                    events.length === 0 && <p>No Events</p>
                                }
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="6">Building Holidays ({homeEvents.length})</AccordionHeader>
                            <AccordionBody accordionId="6">
                                {
                                    homeEvents.map(renderEvent)
                                }
                                {
                                    homeEvents.length === 0 && <p>No holidays events</p>
                                }
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="7">Room Feedbacks ({roomfeedbacks.length})</AccordionHeader>
                            <AccordionBody accordionId="7">
                                {
                                    roomfeedbacks.map(renderRoomFeedback)
                                }
                                {
                                    roomfeedbacks.length === 0 && <p>No room feedbacks</p>
                                }
                                <a href={'javascript: ;'} onClick={async e => await this.setAllFeedbackToChecked(e, roomfeedbacks)}>Set all to checked</a>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="8">Room comments ({technicalComments.length})</AccordionHeader>
                            <AccordionBody accordionId="8">
                                <Button
                                    outline color={'warning'}
                                    size={'sm'}
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        let value = await swal('Do you want to add comments?', {content: 'input', buttons: true});

                                        if(!value) return swal({title: 'No comments', text: ' ', icon: 'error', button: [''], timer: 1000});

                                        value = {
                                            text: value,
                                            createdAt: new Date()
                                        };

                                        room.add(db.Room.TECHNICAL_COMMENTS, value);

                                        room = await room.save();

                                        this.setState(prev => {
                                            prev.selectedRooms[i] = room;

                                            return prev;
                                        });

                                        swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});
                                    }}><i className="fa fa-plus" aria-hidden="true"></i> Add a comment</Button>
                                <ul>
                                    {
                                        technicalComments.map(comment => {
                                            return <li key={comment.id}>
                                                {comment.text} {moment(comment.createdAt).format()}
                                                <a href={'javascript: ;'}
                                                   onClick={async () => {
                                                       room.remove(db.Room.TECHNICAL_COMMENTS, comment);

                                                       room = await room.save();

                                                       this.setState(prev => {
                                                           prev.selectedRooms[i] = room;

                                                           return prev;
                                                       });
                                                   }}
                                                >Delete</a>
                                            </li>
                                        })
                                    }
                                </ul>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="9">Room modifications ({roomHistories && roomHistories.length})</AccordionHeader>
                            <AccordionBody accordionId="9">
                                {
                                    roomHistories && roomHistories.map((roomHistory, i) => {
                                        if(!roomHistory) return '';

                                        let {changes, user, timestamp} = roomHistory;

                                        return <div key={i}>
                                            {user ? user : ''} {timestamp ? timestamp : ''}:
                                            <ul>
                                                <li>{JSON.stringify(changes)}</li>
                                            </ul>
                                        </div>
                                    })
                                }
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="10">Device history</AccordionHeader>
                            <AccordionBody accordionId="10">
                                <div style={{maxHeight: 200, overflow: 'scroll'}}>
                                    {
                                        roomDeviceHistoryMap[room.id] && <Table>
                                            <tbody>
                                                {
                                                    roomDeviceHistoryMap[room.id].map(event => {
                                                        let ts = event.ts;
                                                        let text = event.text;

                                                        return <tr>
                                                            <td>{ts.format()}</td>
                                                            <td>{text}</td>
                                                        </tr>;
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    }
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="11">Devices serials ({roomDevices.length})</AccordionHeader>
                            <AccordionBody accordionId="11">
                                <div style={{maxWith: 800, wordWrap: 'break-word'}}>
                                    {roomDevices.map(device => device.get(db.Device.SERIAL_NUMBER)).join(',')}
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="12">Romm commands ({roomCommands.length})</AccordionHeader>
                            <AccordionBody accordionId="12">
                                {
                                    roomCommands.map(command => {
                                        let deleted = command.get(db.CommandQueue.DELETED);
                                        let device = command.get(db.CommandQueue.DEVICE);
                                        let createdAt = command.get(db.CommandQueue.CREATED_AT);

                                        createdAt = createdAt != null ? moment(createdAt) : null;

                                        let data = command.get(db.CommandQueue.DATA);

                                        return <li key={command.id}>
                                            {createdAt && createdAt.format('DD/MM/YYYY HH:mm')}&nbsp;
                                            {command.get(db.CommandQueue.COMMAND_NAME)}&nbsp;
                                            {JSON.stringify(data)}&nbsp;
                                            {device.get(db.Device.SERIAL_NUMBER)}&nbsp;
                                            {
                                                deleted === true && <Badge color={'success'}>Executed or deleted</Badge>
                                            }
                                        </li>;
                                    })
                                }
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="13">Installation info ({roomTickets && roomTickets.length})</AccordionHeader>
                            <AccordionBody accordionId="13">
                                Installation comment: <p>{room.get(db.Room.INSTALLATION_COMMENT) ? room.get(db.Room.INSTALLATION_COMMENT) : ''}</p><br/>
                                Installation description: <p>{room.get(db.Room.INSTALLATION_DESCRIPTION) ? room.get(db.Room.INSTALLATION_DESCRIPTION) : ''}</p><br/>
                                Installation sensor position: <p>{room.get(db.Room.INSTALLATION_SENSOR_POSITION) ? room.get(db.Room.INSTALLATION_SENSOR_POSITION) : ''}</p><br/>
                                Installation radiator size: <p>{room.get(db.Room.RADIATORS_SIZE) ? room.get(db.Room.RADIATORS_SIZE) : ''}</p><br/>
                                Installation room size: <p>{room.get(db.Room.ROOM_SIZE) ? room.get(db.Room.ROOM_SIZE) : ''}</p><br/>
                                <h4>Room tickets made during installation:</h4>
                                <ul>
                                    {
                                        roomTickets && roomTickets.length > 0 && roomTickets.map(roomTicket => {
                                            let status = roomTicket.get(db.RoomTicket.STATUS);
                                            let note = roomTicket.get(db.RoomTicket.NOTE);
                                            let issueCategory = roomTicket.get(db.RoomTicket.ISSUE_CATEGORY);
                                            let createdAt = roomTicket.get(db.RoomTicket.CREATED_AT);
                                            let crmReferenceNumber = roomTicket.get(db.RoomTicket.CRM_OPERATION_REFERENCE_NUMBER);

                                            createdAt = createdAt == null ? null : moment(createdAt);

                                            let categoryName = issueCategory.get(db.IssueCategory.CATEGORY_LABEL_EN);
                                            let subCategoryName = issueCategory.get(db.IssueCategory.SUB_CATEGORY_LABEL_EN);

                                            return <li key={roomTicket.id}>
                                                {createdAt && createdAt.format('DD/MM/YYYY HH:mm')}&nbsp;
                                                {crmReferenceNumber}:&nbsp;
                                                {categoryName}:&nbsp;
                                                {subCategoryName}:&nbsp;
                                                {note} status:{roomTicket.get(db.RoomTicket.STATUS)}
                                            </li>
                                        })
                                    }
                                </ul>
                            </AccordionBody>
                        </AccordionItem>
                    </UncontrolledAccordion>
                </div>;
            };

            return <div>
                {
                    this.state.tempSeriesArray &&
                    this.state.targetMotorSeriesArray &&
                    this.state.currentMotorSeriesArray &&
                    this.state.co2SeriesArray &&
                    this.state.selectedRooms.map((room, i) => {
                        if(!room) return;
                        let roomDevices = this.state.devices
                            .filter(device => device.get(db.Device.ROOM_ID).id === room.id);
                        let co2Sensors = roomDevices.filter(
                            device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$SENSP ||
                                        device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$SENCO
                        );

                        let events = this.state.roomEventsMap[room.id] ? this.state.roomEventsMap[room.id].events : [];
                        let roomfeedbacks = this.state.roomRoomFeedbacksMap[room.id] ? this.state.roomRoomFeedbacksMap[room.id].roomFeedbacks : [];
                        let roomCommands = this.state.roomCommandsMap[room.id] ? this.state.roomCommandsMap[room.id].roomCommands : [];
                        let roomHistories = this.state.roomHistoryMap[room.id] ? this.state.roomHistoryMap[room.id].roomHistories : [];
                        let roomTickets = this.state.roomTicketsMap[room.id] ? this.state.roomTicketsMap[room.id].roomTickets : [];
                        let technicalComments = room.get(db.Room.TECHNICAL_COMMENTS) || [];
                        let home = this.state.home;
                        let roomThermos = roomDevices.filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$THERM);
                        let roomSensors = roomDevices.filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$SENSP);
                        let lastAvgTempUpdate = room.get(db.Room.LAST_UPDATE_AVG_TEMP);

                        let espNowRoom;
                        if(roomSensors.length > 0 && roomSensors[0] != null){
                            espNowRoom = roomSensors[0].get(db.Device.LAST_CONNECTION_PROTOCOL)
                                === db.Device.LAST_CONNECTION_PROTOCOL$ESP_NOW;
                        }

                        lastAvgTempUpdate = lastAvgTempUpdate == null ? null : moment(lastAvgTempUpdate);

                        let updateRoomIntegerField = async (room, field, value) => {
                            let newValue = parseFloat(value);

                            room.set(field, newValue);
                            await room.save();

                            this.setState(prev => {
                                prev.selectedRooms[i] = room;

                                return prev;
                            });

                            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
                        };

                        let unsetRoomField = async (room, field) => {
                            room.unset(field);
                            await room.save();

                            this.setState(prev => {
                                prev.selectedRooms[i] = room;

                                return prev;
                            });

                            swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
                        }

                        let renderLinkButtonMinValueWhenMaxProgram = (room) => {
                            return <>
                                &nbsp;
                                <a href={'javascript: ;'}
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        try{
                                            let result = await swal({
                                                heightAuto: false,
                                                title: 'Min motor value when max program',
                                                content: {
                                                    element: "input",
                                                    attributes: {
                                                        placeholder: "Type min motor value",
                                                        type: "number",
                                                        value: room.get(db.Room.MIN_VALUE_WHEN_MAX_TEMP_PRESET)
                                                    },
                                                },
                                            });
                                            if(result == null || result === '')
                                                return;

                                            await updateRoomIntegerField(room, db.Room.MIN_VALUE_WHEN_MAX_TEMP_PRESET, result);
                                        }catch (e){
                                            console.error(e);
                                            swal({title: 'Error', text: e.message, icon: 'error', button: [''], timer: 2000})
                                        }
                                    }}
                                >
                                    Edit
                                </a>&nbsp;
                            </>
                        }

                        let renderLinkButtonThermoCorrectionTemp = (room) => {
                            return <>
                                &nbsp;
                                <a href={'javascript: ;'}
                                    onClick={async (e) => {
                                        e.preventDefault();

                                        try{
                                            if(roomSensors.length > 0)
                                                await isUserSureToContinue({
                                                    text: 'In this room there is a sensor you should correct sensor temperature instead.'
                                                });

                                            let result = await swal({
                                                heightAuto: false,
                                                title: 'Thermo correctino temp',
                                                content: {
                                                    element: "input",
                                                    attributes: {
                                                        placeholder: "Type temp correction",
                                                        type: "number",
                                                        step: '0.5',
                                                        value: room.get(db.Room.THERMO_CORRECTION_TEMP)
                                                    },
                                                },
                                            });
                                            if(result == null || result === '')
                                                return;

                                            let value = parseFloat(result);

                                            if(value > 4) throw new Error('Maximum allowed is 4°');

                                            await updateRoomIntegerField(room, db.Room.THERMO_CORRECTION_TEMP, result);
                                        }catch (e){
                                            console.error(e);
                                            swal({title: 'Error', text: e.message, icon: 'error', button: [''], timer: 2000})
                                        }
                                    }}
                                >
                                    Edit
                                </a>&nbsp;
                            </>
                        }
                        let renderLinkButtonThermoSleepRepetitionTime = (room) => {
                            return <>
                                &nbsp;
                                <a href={'javascript: ;'}
                                   onClick={async (e) => {
                                       e.preventDefault();
                                       try{
                                           let result = await swal({
                                               heightAuto: false,
                                               title: 'Thermo sleep repetitions',
                                               content: {
                                                   element: "input",
                                                   attributes: {
                                                       placeholder: "Type temp correction",
                                                       type: "number",
                                                       step: '1',
                                                       value: room.get(db.Room.THERMO_SLEEP_REPETION)
                                                   },
                                               },
                                           });
                                           if(result == null || result === '')
                                               return;

                                           await updateRoomIntegerField(room, db.Room.THERMO_SLEEP_REPETION, result);
                                       }catch (e){
                                           console.error(e);
                                           swal({title: 'Error', text: e.message, icon: 'error', button: [''], timer: 1000})
                                       }
                                   }}
                                >
                                    Edit
                                </a>&nbsp;
                            </>
                        }

                        let renderResetRoomField = (room, field) => {
                            return  <a href={'javascript: ;'} onClick={async (e) => {
                                e.preventDefault();
                                try {
                                    await unsetRoomField(room, field);
                                } catch (e) {
                                    swal('Error', e.message, 'error');
                                }
                            }}>Reset</a>
                        }

                        return <Row key={room.id}>
                            {
                                i !== 0 && <hr style={{
                                    marginTop: '1rem',
                                    marginBottom: '1rem',
                                    border: 0,
                                    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                                    width: '100%'
                                }}/>
                            }
                            <Col md={3}>
                                <img src={room.get(db.Room.MAIN_PHOTO) && room.get(db.Room.MAIN_PHOTO)._url} width={'100%'} height={'auto'} /><br/>
                                Room id: {room.id}<br/>
                                <a href={`https://admin.cleveron.ch/rooms/${room.id}/devices`} target={'_blank'}>{room.get(db.Room.ROOM_NAME)}</a><br/>
                                Webapp room: <a href={`https://app.cleveron.ch/room/${room.id}`} target={'_blank'}>Link webapp</a><br/>
                                Room QR Code: {room.get(db.Room.UNIQUE_ID) || 'N/A'} <a href={`https://app.cleveron.ch/room/${room.get(db.Room.UNIQUE_ID)}/report`} target={'_blank'}>test</a> <br/>
                                Room code: {room.get(db.Room.ROOM_CODE)}<br/>
                                Room type: {room.get(db.Room.ROOM_TYPE)}<br/>
                                <hr/>

                                Avg. hum last 30min (as showed in webapp): {room.get(db.Room.AVG_HUM_LAST_HOUR)}<br/>
                                Avg. temp last 30min (as showed in webapp): {room.get(db.Room.AVG_TEMP_LAST_HOUR)}<br/>
                                Avg. est. temp last 30min: {room.get(db.Room.AVG_ROOM_EST_TEMP_LAST_HOUR)}<br/>
                                Last co2 (as showed in webapp): {room.get(db.Room.CO2)}<br/>
                                Last avg. temp,hum update: {lastAvgTempUpdate && lastAvgTempUpdate.format('DD/MM/YYYY HH:mm')}<br/>
                                <hr/>

                                Temp min: <a href={'#'} onClick={async (e) => {
                                    e.preventDefault();
                                    try{
                                        let result = await swal({
                                            heightAuto: false,
                                            title: 'Temperature minimal',
                                            content: {
                                                element: "input",
                                                attributes: {
                                                    placeholder: "Type max temp",
                                                    type: "number",
                                                    value: room.get(db.Room.TEMP_MIN)
                                                },
                                            },
                                        });
                                        if(result == null || result === '')
                                            return;

                                        let newMinTemp = parseFloat(result);
                                        if(newMinTemp < 12) throw Error('Minimal allowed is 12°');

                                        room.set(db.Room.TEMP_MIN, newMinTemp);
                                        await room.save();

                                        this.setState(prev => {
                                            prev.selectedRooms[i] = room;

                                            return prev;
                                        });

                                        swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
                                    }catch (e){
                                        console.error(e);
                                        swal({title: 'Error', text: e.message, icon: 'error', button: [''], timer: 1000})
                                    }
                                }}>{room.get(db.Room.TEMP_MIN)}</a><br/>
                                Temp max: <a href={'#'} onClick={async (e) => {
                                    e.preventDefault();
                                    try{
                                        let result = await swal({
                                            heightAuto: false,
                                            title: 'Temperature maximal',
                                            content: {
                                                element: "input",
                                                attributes: {
                                                    placeholder: "Type max temp",
                                                    type: "number",
                                                    value: room.get(db.Room.TEMP_MAX)
                                                },
                                            },
                                        });
                                        if(result == null || result === '')
                                            return;

                                        let newMaxTemp = parseFloat(result);

                                        if(newMaxTemp > 25) {
                                            let isSure = await isUserSure('Setting an high temperature is often not the best solution to solve temperature issue. Evaluate checking the calibration first. Also setting high temperatures can lead to higher energy usage.');

                                            if(!isSure) throw Error('Action interrupted.');
                                        }

                                        room.set(db.Room.TEMP_MAX, newMaxTemp);
                                        await room.save();

                                        this.setState(prev => {
                                            prev.selectedRooms[i] = room;

                                            return prev;
                                        });

                                        swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
                                    }catch (e){
                                        console.error(e);
                                        swal({title: 'Error', text: e.message, icon: 'error', button: [''], timer: 1000})
                                    }
                                }}>{room.get(db.Room.TEMP_MAX)}</a><br/>
                                N radiators: <b>{room.get(db.Room.NUMBER_RADIATORS) == null ? 'Not set': room.get(db.Room.NUMBER_RADIATORS)}</b><br/>
                                M2: <b>{room.get(db.Room.AREA)|| 'Not set'}</b><br/>
                                Windows: <b>{room.get(db.Room.WINDOWS)|| 'Not set'}</b><br/>
                                <hr/>

                                {
                                    isAdmin && <div>
                                        Thermo max current limit : <b>{room.get(db.Room.MAX_THERMO_CURRENT_LIMIT)|| 'Not set'}</b>
                                        <a href={'#'} onClick={async (e) => {
                                            e.preventDefault();
                                            try {
                                                let result = await swal({
                                                    heightAuto: false,
                                                    title: db.Room.MAX_THERMO_CURRENT_LIMIT,
                                                    content: {
                                                        element: "input",
                                                        attributes: {
                                                            placeholder: "Type new current limit (mA)",
                                                            type: "number",
                                                            value: room.get(db.Room.MAX_THERMO_CURRENT_LIMIT)
                                                        },
                                                    }
                                                });
                                                if(result == null || result === '')
                                                    return;

                                                let number = parseFloat(result);

                                                if(number < 120) throw Error('Value cannot be lower than 120mA');

                                                room.set(db.Room.MAX_THERMO_CURRENT_LIMIT, number);
                                                await room.save();

                                                this.setState(prev => {
                                                    prev.selectedRooms[i] = room;

                                                    return prev;
                                                });

                                                swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
                                            } catch (e) {
                                                swal('Error', e.message, 'error');
                                            }
                                        }}>Edit</a>&nbsp;
                                        {renderResetRoomField(room, db.Room.MAX_THERMO_CURRENT_LIMIT)}<br/>
                                    </div>
                                }

                                Min motor value when max program: <b>{room.get(db.Room.MIN_VALUE_WHEN_MAX_TEMP_PRESET)|| 'Not set'}</b>
                                {renderLinkButtonMinValueWhenMaxProgram(room)}
                                {renderResetRoomField(room, db.Room.MIN_VALUE_WHEN_MAX_TEMP_PRESET)}<br/>
                                Motor on value <b>{room.get(db.Room.MOTOR_ON_VALUE) == null ? 'Not set': room.get(db.Room.MOTOR_ON_VALUE)}</b>
                                <a href={'#'} onClick={async (e) => {
                                    e.preventDefault();
                                    try {
                                        let result = await swal({
                                            heightAuto: false,
                                            title: 'Motor on value',
                                            content: {
                                                element: "input",
                                                attributes: {
                                                    placeholder: "Motor on value",
                                                    type: "number",
                                                    value: room.get(db.Room.MOTOR_ON_VALUE)
                                                },
                                            },
                                        });
                                        if(result == null || result === '')
                                            return;

                                        let newMotorOnValue = parseFloat(result);

                                        if(newMotorOnValue > 100 || newMotorOnValue < 30) throw Error('Range allowed is 30-100. Please also consider activating the PID if it\'s not already.');

                                        room.set(db.Room.MOTOR_ON_VALUE, newMotorOnValue);
                                        await room.save();

                                        this.setState(prev => {
                                            prev.selectedRooms[i] = room;

                                            return prev;
                                        });

                                        swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
                                    } catch (e) {
                                        swal('Error', e.message, 'error');
                                    }
                                }}>Edit</a>&nbsp;
                                {renderResetRoomField(room, db.Room.MOTOR_ON_VALUE)}<br/>
                                Temp correction sensor: <b>{room.get(db.Room.CORRECTION_TEMP) == null ? 'Not set': room.get(db.Room.CORRECTION_TEMP)}</b>&nbsp;
                                <a href={'#'} onClick={async (e) => {
                                    e.preventDefault();
                                    try {
                                        if(roomSensors.length === 0)
                                            throw new Error('In this room there are no sensors, use the thermo correction instead.');

                                        let result = await swal({
                                            heightAuto: false,
                                            title: 'Temperature correction',
                                            content: {
                                                element: "input",
                                                attributes: {
                                                    placeholder: "Type temperature correction",
                                                    type: "number",
                                                    step: '0.5',
                                                    value: room.get(db.Room.CORRECTION_TEMP)
                                                },
                                            },
                                        });
                                        if(result == null || result === '')
                                            return;

                                        let correction = parseFloat(result);

                                        if(correction >= 3){
                                            let result = await swal({
                                                title: 'Are you sure?',
                                                text: `Do you really want to add ${correction}°C on the measurements of this room? This can cause wrong data and room heated wrongly. Be sure to check during night when the CLEVER Thermo are not heating. Normally a correction above 2° indicate a wrong configuration or defected device. If you are not sure please contact the 2nd or 3rd level support.`,
                                                icon: 'warning',
                                                dangerMode: true,
                                                buttons: ['Abort', 'Confirm'],
                                            });

                                            if (!result) throw new Error('Action aborted by user');
                                        }

                                        room.set(db.Room.CORRECTION_TEMP, correction);
                                        await room.save();

                                        this.setState(prev => {
                                            prev.selectedRooms[i] = room;

                                            return prev;
                                        });

                                        swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
                                    } catch (e) {
                                        swal('Error', e.message, 'error');
                                    }
                                }}>Edit</a>&nbsp;
                                {renderResetRoomField(room, db.Room.CORRECTION_TEMP)}<br/>
                                Thermo correction temperature (r:{room.get(db.Room.THERMO_CORRECTION_TEMP)} h:{home.get(db.Home.THERMO_CORRECTION_TEMP)}): <b>{room.get(db.Room.THERMO_CORRECTION_TEMP) == null ? 'Not set': room.get(db.Room.THERMO_CORRECTION_TEMP)}</b>&nbsp;
                                {renderLinkButtonThermoCorrectionTemp(room)}
                                {renderResetRoomField(room, db.Room.THERMO_CORRECTION_TEMP)}<br/>
                                Force heating x hours before: <b>{room.get(db.Room.FORCE_HEATING_X_HOURS_BEFORE) == null ? 'Not set': room.get(db.Room.FORCE_HEATING_X_HOURS_BEFORE)}</b>&nbsp;
                                <a href={'#'} onClick={async (e) => {
                                    e.preventDefault();
                                    try {
                                        let result = await swal({
                                            heightAuto: false,
                                            title: 'Force heating x hours before ',
                                            content: {
                                                element: "input",
                                                attributes: {
                                                    placeholder: "Type hours",
                                                    type: "number",
                                                    value: room.get(db.Room.FORCE_HEATING_X_HOURS_BEFORE)
                                                },
                                            },
                                        });
                                        if(result == null || result === '')
                                            return;

                                        let forceHours = parseFloat(result);

                                        if(forceHours >= 2){
                                            let result = await swal({
                                                title: 'Are you sure?',
                                                text: `Do you really want to heat ${forceHours} hours before the default 2 hours before heating? This could cause excessive energy consumption. We strongly suggest in this case to increase the central heating temperature so that the room will be heated faster. Please aks your supervisor if you are unsure.`,
                                                buttons: ['Abort', 'Confirm'],
                                            });

                                            if (!result) throw new Error('Action aborted by user');
                                        }

                                        room.set(db.Room.FORCE_HEATING_X_HOURS_BEFORE, forceHours);
                                        await room.save();

                                        this.setState(prev => {
                                            prev.selectedRooms[i] = room;

                                            return prev;
                                        });

                                        swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
                                    } catch (e) {
                                        swal('Error', e.message, 'error');
                                    }
                                }}>Edit</a>&nbsp;
                                {renderResetRoomField(room, db.Room.FORCE_HEATING_X_HOURS_BEFORE)}<br/>
                                Emergency temperature: <b>{room.get(db.Room.EMERGENCY_TEMPERATURE) == null ? 'Not set': room.get(db.Room.EMERGENCY_TEMPERATURE)}</b>&nbsp;
                                <a href={'#'} onClick={async (e) => {
                                    e.preventDefault();
                                    try {
                                        let result = await swal({
                                            heightAuto: false,
                                            title: 'Emergency temperature',
                                            content: {
                                                element: "input",
                                                attributes: {
                                                    placeholder: "Type temperature correction",
                                                    type: "number",
                                                    value: room.get(db.Room.EMERGENCY_TEMPERATURE)
                                                },
                                            }
                                        });
                                        if(result == null || result === '')
                                            return;

                                        room.set(db.Room.EMERGENCY_TEMPERATURE, parseFloat(result));
                                        await room.save();

                                        this.setState(prev => {
                                            prev.selectedRooms[i] = room;

                                            return prev;
                                        });

                                        swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
                                    } catch (e) {
                                        swal('Error', e.message, 'error');
                                    }
                                }}>Edit</a>&nbsp;<br/>
                                Thermos sleep time:
                                <b>{room.get(db.Room.THERMO_SLEEP_TIME_MINUTES) == null ? 'Not set': room.get(db.Room.THERMO_SLEEP_TIME_MINUTES)}</b>&nbsp;
                                <a href={'#'} onClick={async (e) => {
                                    e.preventDefault();
                                    try {
                                        let result = await swal({
                                            heightAuto: false,
                                            title: 'Thermos sleep time',
                                            content: {
                                                element: "input",
                                                attributes: {
                                                    placeholder: "Thermos sleep time",
                                                    type: "number",
                                                    value: room.get(db.Room.THERMO_SLEEP_TIME_MINUTES)
                                                },
                                            }
                                        });
                                        if(result == null || result === '')
                                            return;

                                        room.set(db.Room.THERMO_SLEEP_TIME_MINUTES, parseInt(result));
                                        await room.save();

                                        this.setState(prev => {
                                            prev.selectedRooms[i] = room;

                                            return prev;
                                        });

                                        swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
                                    } catch (e) {
                                        swal('Error', e.message, 'error');
                                    }
                                }}>Edit</a>&nbsp;<br/>
                                {renderResetRoomField(room, db.Room.THERMO_SLEEP_TIME_MINUTES)} <br/>
                                Thermo sleep repetitions: {room.get(db.Room.THERMO_SLEEP_REPETION)}
                                {renderLinkButtonThermoSleepRepetitionTime(room)} &nbsp;
                                {renderResetRoomField(room, db.Room.THERMO_SLEEP_REPETION)}<br/>
                                Number of thermo: {room.get(db.Room.NUMBER_RADIATORS)}<br/>
                                Number of sensors: {room.get(db.Room.NUMBER_SENSP)}<br/>

                                <hr/>
                                Hidden: <Toggle checked={room.get(db.Room.HIDDEN)} onChange={async () => await this.toggleField(room, i, db.Room.HIDDEN, true)} /><br/>
                                Deleted: <Toggle checked={room.get(db.Room.DELETED)} onChange={async () => await this.toggleField(room, i, db.Room.DELETED, true)} /><br/>
                                PID (r:{booleanToString(room.get(db.Room.PID_ACTIVE))} h:{booleanToString(home.get(db.Home.PID_ACTIVE))}):
                                <Toggle checked={room.get(db.Room.PID_ACTIVE)} onChange={async () => await this.toggleField(room, i, db.Room.PID_ACTIVE)} /> &nbsp;
                                {renderResetRoomField(room, db.Room.PID_ACTIVE)}<br/>
                                Sun: <Toggle checked={room.get(db.Room.SUN_RADIATED)} onChange={async () => await this.toggleField(room, i, db.Room.SUN_RADIATED)} /><br/>
                                Automatic program: <Toggle checked={room.get(db.Room.PRESENCE_FORECAST_ENABLED)} onChange={async () => await this.toggleField(room, i, db.Room.PRESENCE_FORECAST_ENABLED)} /><br/>
                                Show only sensor on web app charts: <Toggle checked={room.get(db.Room.SHOW_ONLY_SENSOR_ON_WEB_APP_CHART)} onChange={async () => await this.toggleField(room, i, db.Room.SHOW_ONLY_SENSOR_ON_WEB_APP_CHART)} /><br/>
                                Force wifi on esp-now devices: <Toggle checked={room.get(db.Room.FORCE_WIFI_ON_ESP_NOW_DEVICES)} onChange={async () => await this.toggleField(room, i, db.Room.FORCE_WIFI_ON_ESP_NOW_DEVICES)} /><br/>
                                {
                                    isAdmin && <>
                                        Force motor on ESP-NOW thermos: <Toggle checked={room.get(db.Room.FORCE_MOTOR_ESP_NOW_THERMO)} onChange={async () => await this.toggleField(room, i, db.Room.FORCE_MOTOR_ESP_NOW_THERMO)} /><br/>
                                        Enable thermo custom max current limit: <Toggle checked={room.get(db.Room.ENABLE_CUSTOM_THERMO_MAX_CURRENT_LIMIT)} onChange={async () => await this.toggleField(room, i, db.Room.ENABLE_CUSTOM_THERMO_MAX_CURRENT_LIMIT, true)} /><br/>
                                    </>
                                }
                            </Col>
                            <Col md={9}>
                                <AddCustomCommandModal
                                    save={(name, data) => this.addCustomCommand(name, data, roomDevices)}
                                    cancel={() => {}}
                                    setToggleModal={toggleModal => this.toggleAddCustomCommandModal[room.id] = toggleModal}
                                />
                                <Button
                                    size={'sm'}
                                    outline color={'warning'}
                                    onClick={async (e) => {
                                    e.preventDefault();
                                    let thermos = roomDevices
                                        .filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$THERM);

                                    await this.addCommand(100, thermos);
                                }}>Heat</Button>
                                <Button
                                    size={'sm'}
                                    outline color={'warning'}
                                    onClick={async (e) => {
                                    e.preventDefault();
                                    let thermos = roomDevices
                                        .filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$THERM);

                                    await this.addCommand(0, thermos);
                                }}>Stop heat</Button>
                                <Button
                                    size={'sm'}
                                    outline color={'warning'}
                                    onClick={async (e) => {
                                    e.preventDefault();
                                    let thermos = roomDevices
                                        .filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$THERM);

                                    for(let device of thermos){
                                        device.set(db.Device.DEVICE_STATE_FLAG, 'in-mount');
                                        await device.save();
                                    }
                                    this.getDevices();

                                    await swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});

                                }}>Set in mount mode</Button>
                                <Button
                                    size={'sm'}
                                    outline color={'warning'}
                                    onClick={async (e) => {
                                    e.preventDefault();
                                    let thermos = roomDevices
                                        .filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$THERM);

                                    for(let device of thermos){
                                        device.set(db.Device.DEVICE_STATE_FLAG, 'online');
                                        await device.save();
                                    }
                                    this.getDevices();

                                    swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
                                }}>Set online mode</Button>
                                <Button
                                    size={'sm'}
                                    outline color={'danger'}
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        let thermos = roomDevices
                                            .filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$THERM);


                                        if(espNowRoom) {
                                            await swal(
                                                'Warning',
                                                'It looks like this is a ESP-NOW room. The force heat will not work. Use the calibrationMotor instead.',
                                                'warning'
                                            );
                                        }

                                        await this.addCommand(100, thermos, true);
                                    }}>Force heat</Button>
                                <Button
                                    size={'sm'}
                                    outline color={'danger'}
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        let thermos = roomDevices
                                            .filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$THERM);

                                        await this.addCommand(0, thermos, true);
                                    }}>Force stop heat</Button>
                                <Button outline color="danger"
                                        size={'sm'}
                                        onClick={() => {this.toggleAddCustomCommandModal[room.id]()}}>
                                    Add custom command
                                </Button>
                                <a href={'javascript: ;'} style={{marginLeft: 10}} onClick={async () => {
                                    await this.removeRoomFromSelection(room.id);
                                }}><i className="fa fa-eye-slash" aria-hidden="true"></i> Unselect</a>
                                {tempCharts[i]}
                                <Button size={'sm'} outline onClick={() => hideTargetTemp[i] && hideTargetTemp[i]()}><i className="fa fa-eye-slash" aria-hidden="true"></i> Hide Target Temp.</Button>
                                <Button size={'sm'} outline onClick={() => hideThermo[i] && hideThermo[i]()}><i className="fa fa-eye-slash" aria-hidden="true"></i> Hide Thermos</Button>
                                {this.state.showMotor && targetMotorCharts[i]}
                                {this.state.showMotor && currentMotorCharts[i]}
                                {this.state.showCo2 && co2Charts[i]}
                                {this.state.showPresence && presenceCharts[i]}
                                {renderAccordeon(this.state.home, room, i, roomDevices, events, this.state.homeEvents, roomfeedbacks, technicalComments, roomHistories, roomCommands, roomTickets)}
                            </Col>
                            <hr/>
                        </Row>
                    })
                }
            </div>
        };

        let renderWeatherChart = () => {
            let config = _.cloneDeep(globalTempChartConfig);

            _.set(config, 'title.text', `Weather ${this.state.home && this.state.home.get(db.Home.WEATHER_FORECAST_TODAY).today}`);
            _.set(config, 'chart.height', 300);
            config.series = this.state.weatherSeries;

            return <HighchartsReact options={config} highcharts={Highcharts}></HighchartsReact>
        };

        let renderHomeConfig = (home) => {
            let fields = [
                db.Home.SENSP_COLOR_OFF,
                db.Home.OFFLINE_DEVICES_NOTIFICATION,
                db.Home.ENERGY_REPORT_NOTIFICATION,
                db.Home.ENERGY_REPORT_COMPLIANT,
                db.Home.MONITORING_ACTIVATED,
                db.Home.HIDDEN,
                db.Home.PID_ACTIVE,
                db.Home.SUMMER_MODE,
                db.Home.SUMMER_MODE_MOTOR_VALUE,
                db.Home.MOTOR_ENERGY_SAVING_ACTIVE
            ];
            let fieldDefaults = [
                false,
                false,
                false,
                true,
                true,
                false,
                false,
                false,
                100,
                true
            ];
            let qrCodePortalFields = [
                db.Home.ALLOW_HOLIDAY_ON_QR_CODE_PORTAL,
                db.Home.ALLOW_TEMPERATURE_MODIFICATION_ON_QR_CODE_PORTAL,
                db.Home.ALLOW_ROOM_FEEDBACK_QR_CODE_PORTAL,
                db.Home.SHOW_TEMPERATURE_QR_CODE_PORTAL,
                db.Home.SHOW_CO2_QR_CODE_PORTAL
            ];
            let qrCodePortalFielsDefaults = [
                true,
                true,
                true,
                true,
                true
            ];

            let homeSettingsIntegers = [
                db.Home.SUMMER_MODE_MOTOR_VALUE,
                db.Home.MOTOR_ON_VALUE,
                db.Home.THERMO_SLEEP_TIME_MINUTES,
                db.Home.THERMO_SLEEP_REPETION,
                db.Home.EMERGENCY_TEMPERATURE,
                db.Home.CUSTOM_THERMO_SLEEP_HOUR_START,
                db.Home.CUSTOM_THERMO_SLEEP_HOUR_END,
                db.Home.CUSTOM_SLEEP_TIME_MINUTES,
                db.Home.FORCE_HEATING_X_HOURS_BEFORE
            ];

            return <Row>
                <Col md={4}>
                    <h1>Home configuration</h1>
                    <Table>
                        <thead>
                        <tr>
                            <th>Config name</th>
                            <th>Value</th>
                        </tr>
                        </thead>
                        <tbody>
                        {home && fields.map((field, i) => {
                            return <tr key={i}>
                                <td>{field}</td>
                                <td>{home.get(field) == null ? fieldDefaults[i] + '' : home.get(field) + ''}</td>
                            </tr>
                        })}

                        {home && qrCodePortalFields.map((field, i) => {
                            return <tr key={i}>
                                <td>{field}</td>
                                <td>{home.get(field) == null ? qrCodePortalFielsDefaults[i] + '' : home.get(field) + ''}</td>
                            </tr>
                        })}
                        <tr>
                            <td>Custom LED Config</td>
                            <td>{home.get(db.Home.THRESHOLDS) && JSON.stringify(home.get(db.Home.THRESHOLDS))}</td>
                        </tr>
                        {
                            home && homeSettingsIntegers.map((field, i) => {
                                return <tr key={i}>
                                    <td>{field}</td>
                                    <td>{home.get(field) == null ? 'N/A' : home.get(field) + ''}</td>
                                </tr>
                            })
                        }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        }

        let home = this.state.home;
        let summerMode = home && home.get(db.Home.SUMMER_MODE);
        let summerModeMotorValue = home && home.get(db.Home.SUMMER_MODE_MOTOR_VALUE);

        return (
            <div>
                <h1 className="text-center">{home && home.get(db.Home.HOME_NAME)} ({home && home.get(db.Home.CITY)}) {home && home.get(db.Home.OWNER) && home.get(db.Home.OWNER).get(db._User.USERNAME)}</h1>
                <h3 style={{display: 'inline-block'}}>
                    <a href={`/homes/${this.state.home && this.state.home.id}/installation-overview`} target={'_blank'}><i
                        className="fa fa-building"></i></a>&nbsp;
                    <a href={`/homes/${this.state.home && this.state.home.id}/rooms`} target={'_blank'}><i
                        className="fa fa-list"/></a>
                </h3>&nbsp;
                <a
                    target={'_blank'}
                    href={`/create-user-and-room?modal=update-shadow&selectedRoomIds=${this.state.selectedRooms?.map(room => room.id).join(',')}&homeId=${this.state.home?.id}`}>
                    Update shadow modal
                </a>
                {
                    summerMode &&
                    <Alert color={'warning'}>Warning! This building is in Summer mode with motor position set
                        to {summerModeMotorValue != null ? summerModeMotorValue : '100'}.</Alert>
                }

                <SelectPeerListModal setToggleModal={toggle => this.toggleSelectPeerList = toggle}/>

                <Row>
                    <Col md={12}>
                        {
                            this.state.weatherSeries && renderWeatherChart()
                        }
                    </Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col md={5}>
                        <a href={'javascript:;'} onClick={() => this.selectRoomsOnFloor(-1)}>Select floor -1</a>&nbsp;
                        <a href={'javascript:;'} onClick={() => this.selectRoomsOnFloor(0)}>Select floor 0</a>&nbsp;
                        <a href={'javascript:;'} onClick={() => this.selectRoomsOnFloor(1)}>Select floor 1</a>&nbsp;
                        <a href={'javascript:;'} onClick={() => this.selectRoomsOnFloor(2)}>Select floor 2</a>&nbsp;
                        <a href={'javascript:;'} onClick={() => this.selectRoomsOnFloor(3)}>Select floor 3</a>&nbsp;
                        <a href={'javascript:;'} onClick={() => this.selectRoomsOnFloor(4)}>Select floor 4</a>&nbsp;
                        <a href={'javascript:;'} onClick={() => this.selectRoomsOnFloor(5)}>Select floor 5</a>&nbsp;
                        <br/>
                        <a href={'javascript:;'} onClick={() => this.selectRoomsOnType([db.Room.ROOM_TYPE$OFFICE])}>Office</a>&nbsp;
                        <a href={'javascript:;'} onClick={() => this.selectRoomsOnType([
                            db.Room.ROOM_TYPE$CORRIDOR,
                            db.Room.ROOM_TYPE$TOILET,
                            db.Room.ROOM_TYPE$ARCHIVE
                        ])}>Corridor & Toilet & Archive</a>&nbsp;
                        <a href={'javascript:;'} onClick={() => this.selectRoomsOnType([
                            db.Room.ROOM_TYPE$MEETING_ROOM
                        ])}>Meeting rooms</a>&nbsp;
                        <br/>
                        <Select options={this.state.rooms.map(room => ({
                            value: room,
                            label: getRoomSelectString(room)
                        }))} isMulti
                                value={this.state.selectedRooms.map(room => room && ({
                                    value: room,
                                    label: getRoomSelectString(room)
                                }))}
                                onChange={this.onRoomSelectChange}
                        />
                    </Col>
                    <Col md={2}><DatePicker
                        selected={this.state.startDate?.toDate()}
                        startDate={this.state.startDate?.toDate()}
                        endDate={this.state.endDate?.toDate()}
                        onChange={(a, b, c) => {
                            let startDate = moment(a[0]);
                            let endDate = a[1] && moment(a[1]);


                            this.setState({startDate, endDate}, async () => {
                                await this.getTemperatureCharts();

                                let selectedRooms = this.state.selectedRooms;

                                let roomsCsv = selectedRooms.map(room => room.id);

                                window.history.replaceState('', '', `room-temperature-chart?selectedRoomId=${roomsCsv}&startDate=${this.state.startDate.format('DD-MM-YYYY')}&endDate=${this.state.endDate.format('DD-MM-YYYY')}`);
                            });
                        }}
                        selectsRange
                        dateFormat={'d/M/y'}
                    >
                        <Button outline color={'primary'}>Adjust range dates</Button>
                    </DatePicker></Col>
                    <Col md={1}>Show motor: <Toggle
                        checked={this.state.showMotor}
                        onChange={(e) => this.setState({showMotor: e.target.checked})}/>

                    </Col>
                    <Col md={1}>Show co2: <Toggle
                        checked={this.state.showCo2}
                        onChange={(e) => this.setState({showCo2: e.target.checked})}/>
                    </Col>
                    <Col md={1}>Show presence: <Toggle
                        checked={this.state.showPresence}
                        onChange={(e) => this.setState({showPresence: e.target.checked})}/>
                    </Col>
                    <Col md={1}>Show M.Temp.: <Toggle
                        checked={this.state.showMeasuredTemp}
                        onChange={(e) => this.setState({showMeasuredTemp: e.target.checked}, () => this.getTemperatureCharts())}/>
                    </Col>
                    <Col md={1}>Show R. Est. Temp.: <Toggle
                        checked={this.state.showEstimatedRoomTemp}
                        onChange={(e) => this.setState({showEstimatedRoomTemp: e.target.checked}, () => this.getTemperatureCharts())}/>
                    </Col>
                    <Col md={1}><Button outline color={'primary'} onClick={this.getTemperatureCharts}>Get
                        charts</Button></Col>
                </Row>
                <Row style={{marginTop: 20}}>
                    <Col md={12}></Col>
                </Row>

                {renderCharts()}

                {this.state.home && renderHomeConfig(this.state.home)}

                {
                    this.state.home && <Row>
                        <Col md={12}>
                            <h2>Actions on home</h2>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

RoomTemperatureChart.propTypes = {};