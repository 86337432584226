import React, {Component} from 'react';
import {Badge, Button, Table} from 'reactstrap';
import swal from 'sweetalert';
import * as db from '../../lib/dbStructure';
import moment from 'moment';
import Parse from '../../lib/parse';
import Toggle from 'react-toggle';
import {createLinkFromRooms, getRoomFeedbackSolutionTag} from '../../lib/util';
import _ from 'lodash';

let roomFeedbacksOptions = {
    temperature_feedback: {
        options: {
            TOO_COLD: 'tooCold',
            TOO_HOT: 'tooHot',
            COMFORTABLE: 'comfortable'
        }
    },
    usage_feedback: {
        options: {
            SHORT_USAGE: 'shortUsage',
            MEDIUM_USAGE: 'mediumUsage',
            LONG_USAGE: 'longUsage',
            RECURRING_WEEKLY: 'recurringWeekly'
        }
    },
    gravity_feedback: {
        options: {
            tooCold: {
                VERY: 'very',
                BUT_I_CAN_CONTINUE: 'butICanContinue',
                BUT_OK_TO_CHANGE_MAX_TEMP: 'butOkToChangeMaxTemp'
            },
            tooHot: {
                VERY: 'very',
                BUT_I_CAN_CONTINUE: 'butICanContinue',
                BUT_OK_TO_CHANGE_MAX_TEMP: 'butOkToChangeMaxTemp'
            }
        }
    },
    radiators_feedback: {
        options: {
            HOT: 'hot',
            WARM: 'warm',
            COLD: 'cold'
        }
    },
    result: {
    }
}

function downloadXSLX(data, filename){
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'sheet');
    let buf = XLSX.write(wb, {bookType:'xlsx', type:'buffer'}); // generate a nodejs buffer
    let str = XLSX.write(wb, {bookType:'xlsx', type:'binary'}); // generate a binary string in web browser
    XLSX.writeFile(wb, `${filename}.xlsx`);
}

export default class PageRoomFeedbacks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            roomFeedbacks: [],
            showOnlyToCheck: true
        }

        this.modifyRoomFeedback = this.modifyRoomFeedback.bind(this);
        this.loadRoomFeedbacks = this.loadRoomFeedbacks.bind(this);
    }

    componentDidMount() {
        this.loadRoomFeedbacks();
    }

    async loadRoomFeedbacks(){
        let groupFeedbacksByBuilding = (roomFeedbacks)  => {
            let homesToFeedbacksMap = {};
            let homeIdMap = {};

            for(let roomFeedback of roomFeedbacks){
                let home = roomFeedback.get(db.RoomFeedback.HOME);
                let room = roomFeedback.get(db.RoomFeedback.ROOM);


                if(homeIdMap[home.id] == null) homeIdMap[home.id] = home;

                if(homesToFeedbacksMap[home.id] == null) homesToFeedbacksMap[home.id] = {
                    roomFeedbacks: [],
                    roomIds: []
                };

                homesToFeedbacksMap[home.id].roomFeedbacks.push({
                    room,
                    home,
                    roomFeedback
                });

                if(homesToFeedbacksMap[home.id].roomIds.indexOf(room.id)< 0)
                    homesToFeedbacksMap[home.id].roomIds.push(room.id);
            }

            return {
                homeIdMap,
                homesToFeedbacksMap
            };
        }

        let query = (new Parse.Query(db.classes.RoomFeedback))
            .notEqualTo(db.RoomFeedback.DELETED, true)
            .exists(db.RoomFeedback.ROOM)
            .descending(db.RoomFeedback.CREATED_AT)
            .greaterThanOrEqualTo(db.RoomFeedback.CREATED_AT, moment().subtract(1, 'day').toDate())
            .include(db.RoomFeedback.CHECKED_BY)
            .limit(10000)
            .include(db.RoomFeedback.HOME)
            .select([
                `${db.RoomFeedback.HOME}.${db.Home.HOME_NAME}`,
                `${db.RoomFeedback.HOME}.${db.Home.CITY}`,
                `${db.RoomFeedback.HOME}.${db.Home.PID_ACTIVE}`,
                `${db.RoomFeedback.HOME}.${db.Home.SUMMER_MODE}`,
                `${db.RoomFeedback.HOME}.${db.Home.THERMO_CORRECTION_TEMP}`,
                db.RoomFeedback.ROOM,
                db.RoomFeedback.TEXT,
                db.RoomFeedback.SOLUTION_TAG,
                db.RoomFeedback.STATUS,
                db.RoomFeedback.EMAIL,
                db.RoomFeedback.LANGUAGE,
                db.RoomFeedback.CRM_TICKET_ID,
                db.RoomFeedback.FEEDBACK_VALUE,
                db.RoomFeedback.GRAVITY_FEEDBACK_VALUE,
                db.RoomFeedback.TEMPERATURE_FEEDBACK_VALUE,
                db.RoomFeedback.USAGE_FEEDBACK_VALUE,
            ])


        if(this.state.showOnlyToCheck){
            query.equalTo(db.RoomFeedback.STATUS, null);
        }

        let roomFeedbacks = await query.find();

        let {
            homeIdMap,
            homesToFeedbacksMap
        } = groupFeedbacksByBuilding(roomFeedbacks);

        this.setState({roomFeedbacks, feedbacksGroupedByBuilding: homesToFeedbacksMap, homeIdMap});
    }


    async createTicket(e, roomFeedback){
        e.preventDefault();
        try{

            let willDelete = await swal({
                title: 'Are you sure?',
                text: 'Are you sure to open a ticket about this feedback? This will notify the client about this issue to be careful.',
                icon: 'warning',
                dangerMode: true,
                buttons: ['Cancel', 'Open ticket']
            });

            if (!willDelete) throw new Error('Aborted by user');


            let emailResponse = await swal("Decide the 'from' email of the ticket. If you choose 'client' an email will be sent to client.", {
                buttons: {
                    cancel: "Cancel",
                    cleveron: 'Open ticket from support@cleveron.ch',
                    client: `Open ticket from ${roomFeedback.get(db.RoomFeedback.EMAIL)}`,
                },
            });

            if(!emailResponse) throw new Error('Aborted by user');

            let email = 'support@cleveron.ch';

            switch (emailResponse) {
                case "cleveron": break;
                case "client":email = roomFeedback.get(db.RoomFeedback.EMAIL); break;
            }


            await Parse.Cloud.run('create-ticket-from-room-feedback', {
                roomFeedbackId: roomFeedback.id,
                email
            });

            await swal({title: 'Success', text: ' ', icon: 'success', button: [''], timer: 1000});
        }catch (e) {
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    async modifyRoomFeedback(index, field, value){
        let solutionTag = await getRoomFeedbackSolutionTag();

        let roomFeedback  = this.state.roomFeedbacks[index];
        roomFeedback.set(field, value);
        roomFeedback.set(db.RoomFeedback.CHECKED_BY, Parse.User.current());
        roomFeedback.set(db.RoomFeedback.SOLUTION_TAG, solutionTag);
        roomFeedback = await roomFeedback.save();

        this.setState(prev => {
            prev.roomFeedbacks[index] = roomFeedback;

            return prev;
        });
    }

    async setRoomFeedbacksToChecked(roomFeedbacks){
        try {
            let solutionTag = await getRoomFeedbackSolutionTag();
            let internalComment ;

            if(solutionTag === db.RoomFeedback.SOLUTION_TAG$OTHER){
                let value = await swal('Please add a comment. What did you do?', {content: 'input', buttons: true});

                if(value != null) {
                    internalComment = value;
                }
            }

            let currentUser = Parse.User.current();

            let roomFeedbacksToBeSaved = roomFeedbacks
                .filter(roomFeedback => roomFeedback.get(db.RoomFeedback.STATUS) !== db.RoomFeedback.STATUS$CHECKED)
                .map(roomFeedback => {
                    roomFeedback.set(db.RoomFeedback.STATUS, db.RoomFeedback.STATUS$CHECKED);
                    roomFeedback.set(db.RoomFeedback.INTERNAL_COMMENT, internalComment);
                    roomFeedback.set(db.RoomFeedback.SOLUTION_TAG, solutionTag);
                    roomFeedback.set(db.RoomFeedback.CHECKED_BY, currentUser);

                    return roomFeedback;
                });

            await Parse.Object.saveAll(roomFeedbacksToBeSaved);

            swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
        } catch(e){
            console.error(e);
            swal('Error', e.message, 'error');
        }
    }

    render() {
        let renderAllFeedbacks = () => {
            return <Table>
                    <thead>
                    <tr>
                        <th>{db.RoomFeedback.CREATED_AT}</th>
                        <th>Building</th>
                        <th>{db.RoomFeedback.ROOM_NAME}</th>
                        <th>Temp. feed.</th>
                        <th>Duration feed.</th>
                        <th>Gravity feed.</th>
                        <th>Radiators warm feed.</th>
                        <th>{db.RoomFeedback.EMAIL}</th>
                        <th>{db.RoomFeedback.TEXT}</th>
                        <th>Temp.</th>
                        <th>Lang.</th>
                        <th>Checked</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.roomFeedbacks.map((roomFeedback, i) => {
                            let objectId = roomFeedback.id;
                            let room = roomFeedback.get(db.RoomFeedback.ROOM);
                            let home = roomFeedback.get(db.RoomFeedback.HOME);
                            let createdAt = roomFeedback.get(db.RoomFeedback.CREATED_AT);
                            let owner = home && home.get(db.Home.OWNER);
                            let status = roomFeedback.get(db.RoomFeedback.STATUS);
                            let temperatureFeedbackValue = roomFeedback.get(db.RoomFeedback.TEMPERATURE_FEEDBACK_VALUE);
                            let usageFeedbackValue = roomFeedback.get(db.RoomFeedback.USAGE_FEEDBACK_VALUE);
                            let gravityFeedbackValue = roomFeedback.get(db.RoomFeedback.GRAVITY_FEEDBACK_VALUE);
                            let radiatorsFeedbackValue = roomFeedback.get(db.RoomFeedback.RADIATORS_FEEDBACK_VALUE);
                            let crmTicketId = roomFeedback.get(db.RoomFeedback.CRM_TICKET_ID);
                            let checkedBy = roomFeedback.get(db.RoomFeedback.CHECKED_BY);
                            let temperature = roomFeedback.get(db.RoomFeedback.TEMPERATURE);

                            let statusColor = '';
                            if(!status || status === db.RoomFeedback.STATUS$TO_REVIEW){
                                statusColor = 'warning';
                            } else if (status === db.RoomFeedback.STATUS$CHECKED){
                                statusColor = 'success';
                            }

                            let gravityColor = 'success';

                            if(
                                gravityFeedbackValue === roomFeedbacksOptions.gravity_feedback.options.tooCold.VERY ||
                                gravityFeedbackValue === roomFeedbacksOptions.gravity_feedback.options.tooHot.VERY
                            ){
                                gravityColor = 'warning';
                            }

                            return <tr key={roomFeedback.id}>
                                <td>{moment(createdAt).format('DD/MM/YYYY HH:mm')}</td>
                                <td><a
                                    href={`/homes/${home && home.id}/installation-overview`}
                                    target={'_blank'}
                                >
                                    {home.get(db.Home.HOME_NAME)}
                                </a></td>
                                <td><a
                                    href={`/homes/${home && home.id}/room-temperature-chart?selectedRoomId=${room && room.id}`}
                                    target={'_blank'}
                                >
                                    {room.get(db.Room.ROOM_NAME)}
                                </a></td>
                                <td>{temperatureFeedbackValue}</td>
                                <td>{usageFeedbackValue}</td>
                                <td><Badge color={gravityColor}>{gravityFeedbackValue}</Badge></td>
                                <td>{radiatorsFeedbackValue}</td>
                                <td>{roomFeedback.get(db.RoomFeedback.EMAIL)}</td>
                                <td>{roomFeedback.get(db.RoomFeedback.TEXT)}</td>
                                <td>{temperature && temperature.toFixed(1)}</td>
                                <td>{roomFeedback.get(db.RoomFeedback.LANGUAGE)}</td>
                                <td>
                                    <Badge color={statusColor} pill>
                                        {roomFeedback.get(db.RoomFeedback.STATUS) || 'to-review'}
                                        {checkedBy && <span>({checkedBy.get(db._User.USERNAME)})</span>}
                                    </Badge>
                                    <p>{roomFeedback.get(db.RoomFeedback.INTERNAL_COMMENT)}</p>
                                    {
                                        crmTicketId && <span>
                                                    <a href={`https://desk.zoho.eu/support/simplyhome/ShowHomePage.do#Cases/dv/$crmTicketId${crmTicketId}`}
                                                       target={'_blank'}
                                                    >
                                                        Ticket link
                                                    </a>
                                                </span>
                                    }
                                    {
                                        roomFeedback.get(db.RoomFeedback.SOLUTION_TAG) && <Badge color={'warning'} pill>
                                            {roomFeedback.get(db.RoomFeedback.SOLUTION_TAG)}
                                        </Badge>
                                    }
                                </td>
                                <td>
                                    <Button onClick={async (e) => await this.createTicket(e, roomFeedback)}>
                                        Create ticket
                                    </Button>
                                    <Button onClick={async () => {
                                        await this.modifyRoomFeedback(i, db.RoomFeedback.STATUS, db.RoomFeedback.STATUS$CHECKED);
                                        let value = await swal('Do you want to add comments?', {content: 'input', buttons: true});

                                        if(value) {
                                            await this.modifyRoomFeedback(i, db.RoomFeedback.INTERNAL_COMMENT, value);
                                        }
                                        let ticketId = await swal('There is a ticket open?', {content: 'input', buttons: true});

                                        if(ticketId) {
                                            await this.modifyRoomFeedback(i, db.RoomFeedback.CRM_TICKET_ID, ticketId);
                                        }

                                        swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});
                                    }}>
                                        Set to checked
                                    </Button>
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </Table>;
        };

        let renderGroupedFeedbacks = () => {
            function getColorFromNumbers(numberFeedbacks, numberUrgentFeedbacks, numberVeryUrgendFeedbaks){
                if(numberVeryUrgendFeedbaks >= 1) return 'red';
                if(numberUrgentFeedbacks >= 1) return 'orange';
                if(numberFeedbacks > 5) return 'orange';

                return 'green';
            }

            return <div>
                {
                    this.state.feedbacksGroupedByBuilding &&
                    Object.keys(this.state.feedbacksGroupedByBuilding)
                        .sort((a, b) => {
                            let roomFeedbacksA = this.state.feedbacksGroupedByBuilding[a].roomFeedbacks;
                            let roomFeedbacksB = this.state.feedbacksGroupedByBuilding[b].roomFeedbacks;

                            if(roomFeedbacksA.length > roomFeedbacksB.length) return -1;
                            if(roomFeedbacksA.length < roomFeedbacksB.length) return 1;

                            return 0;
                        })
                        .map(homeId => {
                            let home = this.state.homeIdMap[homeId];
                            let pidActive = home.get(db.Home.PID_ACTIVE);
                            let thermoCorrectionTemp = home.get(db.Home.THERMO_CORRECTION_TEMP);
                            let summerMode = home.get(db.Home.SUMMER_MODE);
                            let homeName = home.get(db.Home.HOME_NAME);
                            let roomIds = this.state.feedbacksGroupedByBuilding[homeId].roomIds;
                            let roomFeedbacks = this.state.feedbacksGroupedByBuilding[homeId].roomFeedbacks;
                            let emails = roomFeedbacks
                                .map(roomFeedback => roomFeedback.roomFeedback.get(db.RoomFeedback.EMAIL))
                                .filter(email => !!email);

                            emails = _.uniq(emails);

                            emails = emails.map(email => ({email}));

                            let numberRoomFeedbacks = roomFeedbacks.length;
                            let tooColdRoomFeedbacks = roomFeedbacks.filter(roomFeedback => roomFeedback.roomFeedback.get(db.RoomFeedback.TEMPERATURE_FEEDBACK_VALUE) === db.RoomFeedback.TEMPERATURE_FEEDBACK_VALUE$TOO_COLD);
                            let tooHotRoomFeedbacks = roomFeedbacks.filter(roomFeedback => roomFeedback.roomFeedback.get(db.RoomFeedback.TEMPERATURE_FEEDBACK_VALUE) === db.RoomFeedback.TEMPERATURE_FEEDBACK_VALUE$TOO_HOT);
                            let comfortableRoomFeedbacks = roomFeedbacks.filter(roomFeedback => roomFeedback.roomFeedback.get(db.RoomFeedback.TEMPERATURE_FEEDBACK_VALUE) === db.RoomFeedback.TEMPERATURE_FEEDBACK_VALUE$COMFORTABLE);
                            let numberUrgentFeedbacks = roomFeedbacks.filter(roomFeedback => roomFeedback.roomFeedback.get(db.RoomFeedback.GRAVITY_FEEDBACK_VALUE) === db.RoomFeedback.GRAVITY_FEEDBACK_VALUE$VERY).length;
                            let numberVeryUrgentFeedbacks = roomFeedbacks.filter(roomFeedback => roomFeedback.roomFeedback.get(db.RoomFeedback.TEXT) != null).length;
                            let numberOfTooHotFeedbacks = tooHotRoomFeedbacks.length;
                            let numberOfTooColdFeedbacks = tooColdRoomFeedbacks.length;

                            let settingsLabels = [];

                            if(pidActive === true) settingsLabels.push('[PID]');
                            if(thermoCorrectionTemp != null) settingsLabels.push(`[${thermoCorrectionTemp}]`);
                            if(summerMode === true) settingsLabels.push(`[SUMMER_MODE]`);

                            let settingString = settingsLabels.join(' ');


                            let roomIdsTooCold = _.uniq(tooColdRoomFeedbacks.map(roomFeedback => roomFeedback.roomFeedback.get(db.RoomFeedback.ROOM).id));
                            let roomIdsTooHot = _.uniq(tooHotRoomFeedbacks.map(roomFeedback => roomFeedback.roomFeedback.get(db.RoomFeedback.ROOM).id));
                            let roomIdsComfortable = _.uniq(comfortableRoomFeedbacks.map(roomFeedback => roomFeedback.roomFeedback.get(db.RoomFeedback.ROOM).id));

                            return <div key={homeId} style={{color: getColorFromNumbers(numberRoomFeedbacks, numberUrgentFeedbacks, numberVeryUrgentFeedbacks)}}>
                                {settingString} {home.get(db.Home.HOME_NAME)} ({home.get(db.Home.CITY)}): {roomIds.length} rooms {numberRoomFeedbacks} feedbacks {numberUrgentFeedbacks} urgent {numberVeryUrgentFeedbacks} very urgent&nbsp;
                                {createLinkFromRooms(home.id, roomIdsTooHot.map(id => ({id})), null, 'Too hot')}&nbsp;
                                {createLinkFromRooms(home.id, roomIdsTooCold.map(id => ({id})), null, 'Too cold')}&nbsp;
                                {createLinkFromRooms(home.id, roomIdsComfortable.map(id => ({id})), null, 'Comfortable')}&nbsp;
                                {createLinkFromRooms(home.id, roomIds.map(id => ({id})))}&nbsp;
                                <a href={'javascript: ;'}
                                   onClick={async () => await this.setRoomFeedbacksToChecked(roomFeedbacks.map(object => object.roomFeedback))}>
                                    Set all feedback to checked
                                </a>&nbsp;
                                {
                                    emails.length > 0 && <a
                                        href={'javascript: ;'}
                                        onClick={() => downloadXSLX(emails, `${homeName}_room_feedbacks_emails`)}>
                                        Download RF users email list
                                    </a>
                                }
                             </div>;
                        })
                }
            </div>;
        };

        return (
            <div>
                Only show un-checked <Toggle defaultChecked={false}
                        checked={this.state.showOnlyToCheck}
                        onChange={(e) =>{
                            this.setState({showOnlyToCheck: !this.state.showOnlyToCheck}, () => this.loadRoomFeedbacks());
                        }}
                />
                {renderGroupedFeedbacks()}
                Total shown room feedbacks: {this.state.roomFeedbacks.length}
                {renderAllFeedbacks()}
            </div>
        );
    }
}

PageRoomFeedbacks.propTypes = {};