import React, {Component} from 'react';
import {Button, Table} from 'reactstrap';
import swal from 'sweetalert';
import * as db from '../../lib/dbStructure';
import moment from 'moment';
import Parse from '../../lib/parse';

export default class PageUsers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: []
        }

        this.getUsers = this.getUsers.bind(this);
    }

    async componentDidMount() {
        await this.getUsers();
    }

    async getUsers(){
        let users = await (new Parse.Query(db.classes._User))
            .containedIn(db._User.TYPE_OF_CUSTOMER, [
                db._User.TYPE_OF_CUSTOMER$CLEVER_SENSE_CUSTOMER,
                db._User.TYPE_OF_CUSTOMER$FULL_SYSTEM_CUSTOMER,
                db._User.TYPE_OF_CUSTOMER$READ_ONLY_DEMO_USER
            ])
            .descending(db._User.CREATED_AT)
            .limit(10000)
            .find();

        this.setState({users});
    }

    render() {
        return (
            <div>
                <h1>Users</h1>
                <Table>
                    <thead>
                        <tr>
                            <th>{db._User.OBJECT_ID}</th>
                            <th>{db._User.USERNAME}</th>
                            <th>{db._User.CREATED_AT}</th>
                            <th>{db._User.TYPE_OF_CUSTOMER}</th>
                            <th>{db._User.REGISTRATION_METHOD}</th>
                            <th>{db._User.EMAIL_VERIFIED}</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.users.map(user => {
                                return <tr key={user.id}>
                                    <td>{user.id}</td>
                                    <td>{user.get(db._User.USERNAME)}</td>
                                    <td>{moment(user.get(db._User.CREATED_AT)).format()}</td>
                                    <td>{user.get(db._User.TYPE_OF_CUSTOMER)}</td>
                                    <td>{user.get(db._User.REGISTRATION_METHOD)}</td>
                                    <td>{user.get(db._User.EMAIL_VERIFIED) === true ? 'True' : 'False'}</td>
                                    <td>
                                        <Button onClick={async () => {
                                            try {
                                                let result = await Parse.Cloud.run('verify-user-email', {userId: user.id});

                                                swal({title: 'Saved', text: ' ', icon: 'success', button: [''], timer: 1000});

                                                this.getUsers();
                                            } catch (e) {
                                                swal({title: 'Error', text: e.message, icon: 'error', button: [''], timer: 1000});
                                            }
                                        }}>Force verify email</Button>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>
            </div>
        );
    }
}

PageUsers.propTypes = {};