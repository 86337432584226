import React, {Component} from 'react';
import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Parse from 'parse';
import * as db from '../../../lib/dbStructure';
import assert from 'assert';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {moveDeviceToRoom} from "../../../lib/util";

export default class ExchangeDeviceModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            operationReferenceNumber: '',
            ticketNumber: ''
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.onPositiveButtonClick = this.onPositiveButtonClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onUserSelected = this.onUserSelected.bind(this);
    }


    componentDidMount() {
        this.props.setToggleModal(this.toggleModal);
    }

    toggleModal() {
        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    onUserSelected(e) {
        let userId = e.target.value;

        this.setState({selectedUser: userId});
    }

    reset(){
        this.setState({});
    }

    async onPositiveButtonClick(){
        this.setState({error: false, errorMessage: null});

        try {
            assert(this.state.selectedDeviceToBeReplaced != null, 'Select device to be replaced is required');
            assert(this.state.selectedDeviceInReplacement != null, 'Selected device in replacement is required');

            if(this.state.reason == null && this.state.operationReferenceNumber == null && this.state.ticketNumber == null)
                throw new Error('At least one of: Reason, Operation reference, Ticket number should be set.')

            let sure = await swal({
                title: 'Are you sure?',
                text: 'These devices will linked in the system and, potentially, an automation will start for example for requesting the sent of the defected devices back to Cleveron AG. So be careful.',
                icon: 'warning',
                buttons: true,
                dangerMode: true,
            });

            if (!sure) {
                return this.setState({error: true, errorMessage: 'Aborted by user'});
            }

            let selectedDeviceToBeReplaced = this.state.selectedDeviceToBeReplaced.object;
            let selectedDeviceInReplacement = this.state.selectedDeviceInReplacement.object;

            let targetRoom = selectedDeviceToBeReplaced.get(db.Device.ROOM_ID);
            let roomOfDeviceInReplacement = selectedDeviceInReplacement.get(db.Device.ROOM_ID);

            assert(targetRoom != null, 'Target room is not defined. You probably selected a wrong device as device to be replaced.');

            if(
                roomOfDeviceInReplacement != null &&
                roomOfDeviceInReplacement.id !== '47Vk3ljLmF' &&
                roomOfDeviceInReplacement.get(db.Room.ROOM_NAME) !== 'to-configure'
            ){
                sure = await swal({
                    title: `Double check if everything is correct`,
                    text: `The device ${selectedDeviceInReplacement.get(db.Device.SERIAL_NUMBER)} is already attached to room ${roomOfDeviceInReplacement && roomOfDeviceInReplacement.get(db.Room.ROOM_NAME)}. Proceding will unlink it from this room. Continue?`,
                    icon: 'warning',
                    buttons: true,
                    dangerMode: true,
                });

                if (!sure) {
                    return this.setState({error: true, errorMessage: 'Aborted by user'});
                }
            }

            let home = selectedDeviceToBeReplaced.get(db.Device.HOME);
            let toConfigureRoom = await new Parse.Query(db.classes.Room)
                .equalTo(db.Room.HOME, home)
                .equalTo(db.Room.ROOM_NAME, 'to-configure')
                .first();


            assert(toConfigureRoom != null, 'No room to-configure on target home. Please before create one.');

            let deviceInReplacemenetToBeSaved = moveDeviceToRoom(selectedDeviceInReplacement, targetRoom);
            let deviceToBeReplacedToBeSaved = moveDeviceToRoom(selectedDeviceToBeReplaced, toConfigureRoom);

            deviceInReplacemenetToBeSaved = await deviceInReplacemenetToBeSaved.save();
            deviceToBeReplacedToBeSaved = await deviceToBeReplacedToBeSaved.save();

            let deviceReplacement = new Parse.Object(db.classes.DeviceReplacement);

            deviceReplacement.set(db.DeviceReplacement.REASON, this.state.reason && this.state.reason.value);
            deviceReplacement.set(db.DeviceReplacement.DEVICE_REPLACED, deviceToBeReplacedToBeSaved);
            deviceReplacement.set(db.DeviceReplacement.DEVICE_IN_REPLACEMENT, deviceInReplacemenetToBeSaved);
            deviceReplacement.set(db.DeviceReplacement.OPERATION_REFERENCE_NUMBER, this.state.operationReferenceNumber);
            deviceReplacement.set(db.DeviceReplacement.TICKET_NUMBER, this.state.ticketNumber);

            await deviceReplacement.save();

            this.reset();

            swal('Device exchanged correctly', '', 'success');
        } catch (e){
            console.error(e);
            this.setState({error: true, errorMessage: e.message});
        }
    }

    onCancelClick(){
        this.toggleModal();
    }

    async loadDevices(serialNumber){
        try {
            serialNumber = parseInt(serialNumber);

            if(serialNumber < 10000) return [];

            if(serialNumber < 1000) {
                serialNumber = serialNumber * 10000;
            } else if(serialNumber < 10000){
                serialNumber = serialNumber * 1000;
            } else if(serialNumber < 100000){
                serialNumber = serialNumber * 100;
            } else if(serialNumber < 1000000){
                serialNumber = serialNumber * 10;
            }

            let devices = await new Parse.Query(db.classes.Device)
                .greaterThanOrEqualTo(db.Device.SERIAL_NUMBER, serialNumber)
                .include(db.Device.HOME)
                .include(db.Device.ROOM_ID)
                .ascending(db.Device.SERIAL_NUMBER)
                .find();

            return devices.map(device => {
                let serialNumber = device.get(db.Device.SERIAL_NUMBER);
                let homeName = device.get(db.Device.HOME) && device.get(db.Device.HOME).get(db.Home.HOME_NAME);
                let city = device.get(db.Device.HOME) && device.get(db.Device.HOME).get(db.Home.CITY);
                let roomName = device.get(db.Device.ROOM_ID) &&  device.get(db.Device.ROOM_ID).get(db.Room.ROOM_NAME);

                return {
                    value: serialNumber,
                    label: `${device.get(db.Device.SERIAL_NUMBER)} BU:${homeName}) R:${roomName} C:${city}`,
                    object: device
                };
            });
        }catch (e) {
            console.error(e);
        }
    }

    render() {

        return (
            <div>
                <Modal isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Exchange device
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }

                        <FormGroup>
                            <label>Select device to be replaced:</label>
                            <AsyncSelect
                                value={this.state.selectedDeviceToBeReplaced}
                                onChange={selectedDeviceToBeReplaced => this.setState({selectedDeviceToBeReplaced})}
                                loadOptions={this.loadDevices}
                                isMulti={false}
                            />
                            <label>Selected device in replacement:</label>
                            <AsyncSelect
                                value={this.state.selectedDeviceInReplacement}
                                onChange={selectedDeviceInReplacement => this.setState({selectedDeviceInReplacement})}
                                loadOptions={this.loadDevices}
                                isMulti={false}
                            />
                            <label>Reason:</label>
                            <Select
                                value={this.state.reason}
                                options={[
                                    {
                                        value: 'battery-problem',
                                        label: 'Battery problem'
                                    },{
                                        value: 'broken-motor',
                                        label: 'Broken motor'
                                    },{
                                        value: 'broken-mounting-part',
                                        label: 'Broken mounting part'
                                    },{
                                        value: 'broken-co2-sensor',
                                        label: 'Broken CO2 sensor'
                                    },{
                                        value: 'broken-plastic',
                                        label: 'Broken plastic'
                                    },{
                                        value: 'obsolete',
                                        label: 'Obsolete '
                                    },
                                    {
                                        value: 'defect-connection-module',
                                        label: 'Connection module defect'
                                    },
                                    {
                                        value: 'other',
                                        label: 'Other '
                                    },
                                ]}
                                onChange={reason => this.setState({reason})}
                                isMulti={false}
                            />

                            <Label for={'password'}>Operation reference number:</Label>
                            <Input
                                type={'text'}
                                name={'password'}
                                id={'password'}
                                value={this.state.operationReferenceNumber}
                                onChange={(e) => this.setState({operationReferenceNumber: e.target.value})}>
                            </Input>
                            <Label for={'password'}>Desk ticket number:</Label>
                            <Input
                                type={'text'}
                                name={'password'}
                                id={'password'}
                                value={this.state.ticketNumber}
                                onChange={(e) => this.setState({ticketNumber: e.target.value})}>
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="secondary" onClick={this.onCancelClick}>Cancel</Button>
                        <Button outline color="primary" onClick={this.onPositiveButtonClick}>Exchange devices</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

ExchangeDeviceModal.propTypes = {
    setToggleModal: PropTypes.func
};