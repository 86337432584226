import React, {Component} from 'react';
import {Button} from 'reactstrap';
import CreateRoomOnUser from './lib/create-room-on-user';
import CreateUserModal from './lib/create-user-modal';
import LinkUserToAccountModal from './lib/link-user-to-account';
import swal from 'sweetalert';
import Parse from 'parse';
import {copyACLToObject, createBasicACL, toPointer} from '../../lib/util';
import CreateHomeOnUser from './lib/create-home-on-user';
import * as db from '../../lib/dbStructure';
import _ from 'lodash';
import ChangePasswordToUserModal from './lib/change-password';
import ExchangeDeviceModal from "./lib/exchange-device-modal";
import RemoveUserFromBuildingModal from './lib/remove-user-from-building';
import ChangeUserFieldsModal from './lib/change-user-fields';
import ChangeUserRolesModal from './lib/change-user-roles';
import RotateImagesFromBuildingModal from './lib/rotate-images-on-building';
import UploadCrmBuildingPhotoModal from "./lib/upload-crm-building-photo-modal";
import MoveRoomToBuildingModal from "./lib/move-room-to-building-modal";
import ChangeBuildingFieldsModal from './lib/change-building-fields';
import GenerateQrCodeUuidsModal from './lib/generate-qr-code-uuids-modal';
import UpdateShadowOnRoomModal from "./lib/update-shadow-on-room";
import qs from "query-string";

export default class CreateUserAndRoom extends Component {
    constructor(props){
        super(props);
        this.toggleCreateRoomModal = null;
        this.toggleCreateUserModal = null;
        this.toggleLinkUserToAccountModal = null;
        this.toggleCreateHomeOnUserModal = null;
        this.toggleChangePasswordToUserModal = null;
        this.toggleExchangeDeviceModal = null;
        this.toggleRemoveUserFromBuildingModal = null;
        this.toggleChangeUserFieldsModal = null;
        this.toggleRotateImagesOnBuildingModal = null;
        this.toggleUploadCrmBuildingPhotoModal = null;
        this.toggleChangeUserRolesModal = null;
        this.toggleMoveRoomsToBuildingModal = null;
        this.toggleChangeBuildingFieldsModal = null;
        this.toggleGenerateQrCodeUuidsModal = null;
        this.toggleUpdateShadowModal = null;
        this.createUser = this.createUser.bind(this);
        this.createRoom = this.createRoom.bind(this);
    }

    componentDidMount(){
        document.title = 'Create user, buildings and room';

        const parsed = qs.parse(location.search);
        let selectedRoomIds = parsed.selectedRoomIds;
        let selectedHomeId = parsed.homeId;
        let modal = parsed.modal;

        console.log(parsed);

        if(modal){
            if(modal === 'update-shadow'){
                this.toggleUpdateShadowModal({
                    selectedHomeId,
                    selectedRoomIds
                });
            }
        }
    }

    async createUser(user) {
        try {
            let oldSessionToken = Parse.User.current().getSessionToken();

            let requiredFields = ['username', 'password', 'email', 'city', 'language', 'typeOfCustomer'];

            for (let requiredField of requiredFields) {
                if (_.isEmpty(user[requiredField])) throw new Error(`${requiredField} is required`);
            }

            let userObject = new Parse.User(_.merge({
                utcTime: new Date(),
                isDevice: false
            }, user));

            await userObject.signUp();

            await Parse.User.become(oldSessionToken);

            return await swal('Success', 'User signup completed', 'success') && this.toggleCreateUserModal();
        } catch (err) {
            return await swal('Error', err.message, 'error');
        }
    }

    createRoom(home, room){
        if(!home) return swal('Error', 'Please select a home', 'error');
        if(!room.roomName) return swal('Error', 'Please choose a room name', 'error');
        if(!room.tempMin) return swal('Error', 'Please choose a tempMin', 'error');
        if(!room.tempMax) return swal('Error', 'Please choose a tempMax', 'error');
        if(!room.floor) return swal('Error', 'Please choose a floor', 'error');

        let roomObject = new Parse.Object(db.classes.Room);
        roomObject.set(db.Room.ROOM_NAME, room.roomName);
        roomObject.set(db.Room.AREA, parseFloat(room.area));
        roomObject.set(db.Room.HEIGHT, parseFloat(room.height));
        roomObject.set(db.Room.WINDOWS, parseInt(room.windows));
        roomObject.set(db.Room.TEMP_MIN, parseFloat(room.tempMin));
        roomObject.set(db.Room.TEMP_MAX, parseFloat(room.tempMax));
        roomObject.set(db.Room.NUMBER_RADIATORS, parseInt(room.numberRadiators));
        roomObject.set(db.Room.NUMBER_SENSE, parseInt(room.numberSense));
        roomObject.set(db.Room.FLOOR, parseInt(room.floor));

        roomObject.set(db.Room.HOME, toPointer(home));

        copyACLToObject(home, roomObject);

        return roomObject.save()
            .then(() => swal('Success', 'Room created', 'success') && this.toggleCreateRoomModal())
            .catch(err => swal('Error', err.message, 'error'));
    }

    async createHomeOnUser(user, homeFields){
        let home = new Parse.Object(db.classes.Home);

        let requiredFields = [
            db.Home.HOME_NAME,
            db.Home.CITY,
            db.Home.CANTON,
            db.Home.ADDRESS,
            db.Home.YEAR,
            db.Home.LANGUAGE,
            db.Home.TYPE_OF_CUSTOMER
        ];

        for (let requiredField of requiredFields) {
            if (_.isEmpty(homeFields[requiredField])) throw new Error(`${requiredField} is required`);
        }

        home.set(db.Home.OWNER, user);
        home.set(db.Home.HOME_NAME, homeFields[db.Home.HOME_NAME]);

        if(homeFields[db.Home.AREA] != null)
            home.set(db.Home.AREA, parseInt(homeFields[db.Home.AREA]));

        home.set(db.Home.CITY, homeFields[db.Home.CITY]);
        home.set(db.Home.CANTON, homeFields[db.Home.CANTON]);
        home.set(db.Home.ADDRESS, homeFields[db.Home.ADDRESS]);
        home.set(db.Home.YEAR, parseInt(homeFields[db.Home.YEAR]));
        home.set(db.Home.LANGUAGE, homeFields[db.Home.LANGUAGE]);
        home.set(db.Home.U_VALUE, 1);
        home.set(db.Home.TYPE_OF_CUSTOMER, user.get(db._User.TYPE_OF_CUSTOMER));

        let homeACL = createBasicACL([user.id], [user.id]);

        home.setACL(homeACL);

        await home.save();
    }

    async linkUserToAccount(userId, buildingId, writeAccess, administrator){
        await Parse.Cloud.run('link-user-to-building-with-role', {
            userId,
            buildingId,
            writeAccess,
            administrator
        });

        swal('User linked correctly', '', 'success');
    }

    render() {
        return (
            <div>
                <CreateRoomOnUser
                    setToggleModal={(toggleModal) => this.toggleCreateRoomModal = toggleModal}
                    save={this.createRoom}
                    cancel={() => {
                    }}
                />
                <CreateUserModal
                    setToggleModal={(toggleModal) => this.toggleCreateUserModal = toggleModal}
                    save={this.createUser}
                    cancel={() => {
                    }}
                />
                <CreateHomeOnUser
                    setToggleModal={(toggleModal) => this.toggleCreateHomeOnUserModal = toggleModal}
                    save={this.createHomeOnUser}
                    cancel={() => {
                    }}
                />
                <LinkUserToAccountModal
                    setToggleModal={(toggleModal) => this.toggleLinkUserToAccountModal = toggleModal}
                    save={this.linkUserToAccount}
                    cancel={() => {
                    }}
                />
                <ChangePasswordToUserModal
                    setToggleModal={(toggleModal) => this.toggleChangePasswordToUserModal = toggleModal}
                    cancel={() => {
                    }}
                />
                <UpdateShadowOnRoomModal
                    setToggleModal={(toggleModal) => this.toggleUpdateShadowModal = toggleModal}
                />
                <ExchangeDeviceModal setToggleModal={(toggleModal) => this.toggleExchangeDeviceModal = toggleModal}/>
                <RemoveUserFromBuildingModal
                    setToggleModal={(toggleModal) => this.toggleRemoveUserFromBuildingModal = toggleModal}/>
                <ChangeUserFieldsModal
                    setToggleModal={(toggleModal) => this.toggleChangeUserFieldsModal = toggleModal}/>
                <RotateImagesFromBuildingModal
                    setToggleModal={(toggleModal) => this.toggleRotateImagesOnBuildingModal = toggleModal}/>
                <UploadCrmBuildingPhotoModal
                    setToggleModal={(toggleModal) => this.toggleUploadCrmBuildingPhotoModal = toggleModal}/>
                <ChangeUserRolesModal setToggleModal={toggleModal => this.toggleChangeUserRolesModal = toggleModal}/>
                <MoveRoomToBuildingModal
                    setToggleModal={toggleModal => this.toggleMoveRoomsToBuildingModal = toggleModal}/>
                <ChangeBuildingFieldsModal
                    setToggleModal={toggleModal => this.toggleChangeBuildingFieldsModal = toggleModal}/>
                <GenerateQrCodeUuidsModal
                    setToggleModal={toggleModal => this.toggleGenerateQrCodeUuidsModal = toggleModal}/>
                <h2>Create user</h2>
                <Button outline onClick={() => this.toggleCreateUserModal()}>Create user</Button>
                <h2>Create room on building</h2>
                <Button outline onClick={() => this.toggleCreateRoomModal()}>Create room</Button>
                <h2>Create building and link to an user</h2>
                <Button outline onClick={() => this.toggleCreateHomeOnUserModal()}>Create building on user</Button>
                <h2>Link user to a building</h2>
                <Button outline onClick={() => this.toggleLinkUserToAccountModal()}>Link user to a building</Button>
                <h2>Remove user from a building</h2>
                <Button outline onClick={() => this.toggleRemoveUserFromBuildingModal()}>Remove user from
                    building</Button>
                <h2>Change password of user</h2>
                <Button outline onClick={() => this.toggleChangePasswordToUserModal()}>Change password of user</Button>
                <h2>Change user fields</h2>
                <Button outline onClick={() => this.toggleChangeUserFieldsModal()}>Change user fields</Button>
                <h2>Change user roles</h2>
                <Button outline onClick={() => this.toggleChangeUserRolesModal()}>Change user roles</Button>
                <h2>Exchange devices</h2>
                <Button outline onClick={() => this.toggleExchangeDeviceModal()}>Exchange devices</Button>
                <h2>Rotate images on building</h2>
                <Button outline onClick={() => this.toggleRotateImagesOnBuildingModal()}>Rotate images on
                    building</Button>
                <h2>Upload image from CRM building to the Web App</h2>
                <Button outline onClick={() => this.toggleUploadCrmBuildingPhotoModal()}>Upload image from CRM</Button>
                <h2>Move rooms to building:</h2>
                <Button outline onClick={() => this.toggleMoveRoomsToBuildingModal()}>Move rooms to building</Button>
                <h2>Change building fields:</h2>
                <Button outline onClick={() => this.toggleChangeBuildingFieldsModal()}>Change building fields</Button>
                <h2>Generate QR Code labels:</h2>
                <Button outline onClick={() => this.toggleGenerateQrCodeUuidsModal()}>Generate QR code labels</Button>
                <h2>Update shadow program:</h2>
                <Button outline onClick={() => this.toggleUpdateShadowModal()}>Update shadow program</Button>
            </div>
        )
    }
}