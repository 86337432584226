import React, {Component} from 'react';
import {Alert, Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Parse from 'parse';
import * as db from '../../../lib/dbStructure';
import swal from 'sweetalert';
import AsyncSelect from "react-select/async";
import _ from 'lodash';
import {getColorFromMinutes} from '../../../lib/util';
import moment from 'moment';

async function yesOrNo(yesTitle, yesButtonLabel, danger=true){
    return await swal({
        title: yesTitle,
        text: '',
        icon: 'warning',
        dangerMode: danger,
        buttons: ['Cancel', yesButtonLabel]
    });
}

export default class SelectPeerListModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            selectedDevices: [],
            neighbors: [],
            thermos: [],
            roomsMap: {},
            mapPeerToSensor: {},
            mapNeighborsToRoomsWithWifiStrength: {}
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.searchDevices = this.searchDevices.bind(this);
        this.addPeerListCommand = this.addPeerListCommand.bind(this);
        this.selectAllRoomDevice = this.selectAllRoomDevice.bind(this);
    }

    componentDidMount() {
        this.props.setToggleModal(this.toggleModal);
    }

    async init(room){
        let building = room.get(db.Room.HOME);
        let buildingRooms = await new Parse.Query(db.classes.Room)
            .equalTo(db.Room.HOME, building)
            .find();

        let roomsMap = {};
        buildingRooms.forEach(room => {
            roomsMap[room.id] = {
                room
            };
        })

        let buildingSensors = await new Parse.Query(db.classes.Device)
            .equalTo(db.Device.DEVICE_TYP, db.Device.DEVICE_TYP$SENSP)
            .equalTo(db.Device.HOME, building)
            .select([
                db.Device.SERIAL_NUMBER,
                db.Device.MAC_ADDRESS,
                db.Device.PEER_LIST,
                db.Device.ROOM_ID,
                db.Device.NEIGHBORS_WIFI_STRENGTH
            ])
            .include(db.Device.PEER_LIST)
            .include(db.Device.ROOM_ID)
            .find();

        let mapPeerToSensor = {};

        buildingSensors.forEach(sensor => {
            let serialNumber = sensor.get(db.Device.SERIAL_NUMBER);
            let room = sensor.get(db.Device.ROOM_ID);
            let peerList = sensor.get(db.Device.PEER_LIST);

            if(!peerList) return;

            peerList.forEach(peer => {
                mapPeerToSensor[peer.id] = sensor;
            });
        });

        let mapNeighborsToRoomsWithWifiStrength = {};

        buildingSensors.forEach(sensor => {
            let room = sensor.get(db.Device.ROOM_ID);
            let wifiStrengthList = sensor.get(db.Device.NEIGHBORS_WIFI_STRENGTH);

            if(!wifiStrengthList) return;

            Object.keys(wifiStrengthList).forEach(deviceId => {
                let wifiStrength = wifiStrengthList[deviceId];
                if(mapNeighborsToRoomsWithWifiStrength[deviceId] == null){
                    mapNeighborsToRoomsWithWifiStrength[deviceId] = [];
                }
                mapNeighborsToRoomsWithWifiStrength[deviceId].push({
                    room,
                    wifiStrength
                });
            });
        });

        let roomDevices = await new Parse.Query(db.classes.Device)
            .equalTo(db.Device.ROOM_ID, room)
            .include(db.Device.NEIGHBORS_LIST)
            .include(db.Device.PEER_LIST)
            .find();

        let thermos = roomDevices.filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$THERM);
        let sensps = roomDevices.filter(device => device.get(db.Device.DEVICE_TYP) === db.Device.DEVICE_TYP$SENSP);

        let getSerial = async (sensps) => {
            //--------------- BATCH TYPE --------------------------------------------
            let serials = sensps.map(sensp => sensp.get(db.Device.SERIAL_NUMBER).toString());

            let buttons = {
                cancel: 'Cancel'
            };
            serials.forEach(serial => buttons[serial]=serial);

            let serial = await swal(`Select serial of device: "${serials.join(', ')}"`, {
                buttons
            });

            if(serials.indexOf(serial) < 0)
                throw new Error(`Invalid serial should be one of: "${serials.join(', ')}"`);

            return parseInt(serial);
        }

        let serial = await getSerial(sensps);

        let sensp = sensps.filter(sensp => sensp.get(db.Device.SERIAL_NUMBER) === serial)[0];

        let neighbors = sensp.get(db.Device.NEIGHBORS_LIST) || [];
        let neighborsWifiStrength = sensp.get(db.Device.NEIGHBORS_WIFI_STRENGTH) || {};
        let peerList = sensp.get(db.Device.PEER_LIST) || [];

        let neighborsRoomMap = {}
        neighbors.forEach(device => {
            let room = device.get(db.Device.ROOM_ID);

            if(!room) return;

            if (neighborsRoomMap[room.id] == null) {
                neighborsRoomMap[room.id] = {
                    room,
                    devices: []
                };
            }

            neighborsRoomMap[room.id].devices.push(device);
        });

        const average = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;

        Object.keys(neighborsRoomMap).forEach(key => {
            let {room, devices} = neighborsRoomMap[key];
            let signalStrengthArray = devices.map(device => neighborsWifiStrength[device.id]);

            let averageSignalStrength = average(signalStrengthArray);


            neighborsRoomMap[key].averageSignalStrength = averageSignalStrength;
        });

        let selectedDevices = peerList.map(therm => {
            return {
                value: therm.id,
                label: `${therm.get(db.Device.SERIAL_NUMBER)}(${therm.get(db.Device.MAC_ADDRESS).slice(-5)})`,
                object: therm
            }
        });

        this.setState({selectedDevices, room, sensp, neighbors, neighborsWifiStrength, thermos, buildingRooms, roomsMap, neighborsRoomMap, mapPeerToSensor, mapNeighborsToRoomsWithWifiStrength});
    }

    toggleModal(room) {
        if(!this.state.isModalOpen) this.init(room);

        this.setState({isModalOpen: !this.state.isModalOpen});
    }

    onCancelClick(){
        this.toggleModal();
    }

    async searchDevices(serial){
        if(!serial) return [];
        if(serial.length < 7) return [];

        let query = new Parse.Query(db.classes.Device);
        query.limit(20);
        query.equalTo(db.Device.SERIAL_NUMBER, parseInt(serial));
        query.equalTo(db.Device.HOME, this.state.room.get(db.Room.HOME));
        query.include(db.Device.ROOM_ID);

        let devices = await query.find();

        return devices.map(device => {
            let room = device.get(db.Device.ROOM_ID);
            let roomName = room && room.get(db.Room.ROOM_NAME) || '';
            let roomCode = room && room.get(db.Room.ROOM_CODE) || '';
            let floor = room && room.get(db.Room.FLOOR);

            return {
                value: device.id,
                label: `${device.get(db.Device.SERIAL_NUMBER)} / ${device.get(db.Device.MAC_ADDRESS)} (${roomName} ${roomCode} P${floor})`,
                object: device
            }
        });
    }

    async addPeerListCommand(){
        try {
            let thermos = this.state.selectedDevices.map(selectedDevice => selectedDevice.object);
            let sensp = this.state.sensp;

            if(!sensp) throw new Error('No sensor in this room');

            let sure = await yesOrNo('Are you sure?', 'Yes');

            if (!sure) return;

            let commands = await new Parse.Query('CommandQueue')
                .notEqualTo(db.CommandQueue.DELETED, true)
                .equalTo('device', sensp)
                .find();

            let isPeerListCommandAlreadyInQueue = commands.filter(command => command.get(db.CommandQueue.COMMAND_NAME) === db.commands.CHANGE_PEER_LIST).length !== 0;

            if(isPeerListCommandAlreadyInQueue){
                let yesDelete = await yesOrNo('There is already a change peer list command. Do you want to replace it?', 'Yes');
                if(!yesDelete) return;

                let peerListCommand = commands.filter(command => command.get(db.CommandQueue.COMMAND_NAME) === db.commands.CHANGE_PEER_LIST)[0];
                await peerListCommand.remove();

                commands = await new Parse.Query('CommandQueue')
                    .notEqualTo(db.CommandQueue.DELETED, true)
                    .equalTo('device', sensp)
                    .find();

                isPeerListCommandAlreadyInQueue = commands.filter(command => command.get(db.CommandQueue.COMMAND_NAME) === db.commands.CHANGE_PEER_LIST).length !== 0;
            }

            let commandsToSave = [];

            let peerListString = `${thermos.length},${thermos.map(therm => therm.get(db.Device.MAC_ADDRESS)).join(',')}`;

            let data = {
                value: peerListString,
                unit: 'MAC',
            };

            let command2 = new Parse.Object('CommandQueue');
            command2.set(db.CommandQueue.COMMAND_NAME,  db.commands.CHANGE_PEER_LIST);
            command2.set(db.CommandQueue.DEVICE, sensp);
            command2.set(db.CommandQueue.DATA, data);
            command2.set(db.CommandQueue.ROOM, sensp.get(db.Device.ROOM_ID));
            command2.set(db.CommandQueue.ADDED_FROM, db.CommandQueue.ADDED_FROM$MANUAL);

            commandsToSave.push(command2);

            await Parse.Object.saveAll(commandsToSave);

            swal({title: 'Success', text: ``, icon: 'success', button: [''], timer: 1000});
        } catch (e) {
            swal('Error', e.message, 'error');
        }
    }

    selectAllRoomDevice(){
        let thermos = this.state.thermos;
        let sensp = this.state.sensp;

        if(!sensp) return;

        let neighbors = sensp.get(db.Device.NEIGHBORS_LIST) || [];
        let neighborsWifiStrength = sensp.get(db.Device.NEIGHBORS_WIFI_STRENGTH) || {};
        let peerList = sensp.get(db.Device.PEER_LIST);

        let peerListPlusRoomList = [...peerList, ...thermos];

        let selectedDevices = peerListPlusRoomList.map(therm => {
            return {
                value: therm.id,
                label: `${therm.get(db.Device.SERIAL_NUMBER)}(${therm.get(db.Device.MAC_ADDRESS).slice(-5)})`,
                object: therm
            }
        });

        this.setState({selectedDevices: _.uniqBy(selectedDevices, 'value')});
    }

    render() {
        let room = this.state.room;
        let sensp = this.state.sensp;
        let roomName = room && room.get(db.Room.ROOM_NAME);
        let roomCode = room && room.get(db.Room.ROOM_CODE);
        let floor = room && room.get(db.Room.FLOOR);

        return (
            <div>
                <Modal size={'xl'} isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        Select peer list for sensor {sensp?.get(db.Device.SERIAL_NUMBER)} (Room: {roomName} {roomCode} P{floor})
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.error && <Alert color="danger">
                                {this.state.errorMessage}
                            </Alert>
                        }
                        Neighbors:<br/>
                        {
                            this.state.neighborsRoomMap  && Object.keys(this.state.neighborsRoomMap).map(roomId => {
                                const {
                                    room,
                                    devices,
                                    averageSignalStrength
                                } = this.state.neighborsRoomMap[roomId];

                                let roomName = room && room.get(db.Room.ROOM_NAME);
                                let floor = room && room.get(db.Room.FLOOR);
                                let roomCode = room && room.get(db.Room.ROOM_CODE);

                                function getColorFromSignalStrength(signalStrength){
                                    let color = 'red';

                                    if(averageSignalStrength >= -60){
                                        color = 'green';
                                    } else if(averageSignalStrength >= -80){
                                        color = 'orange';
                                    } else {
                                        color = 'red';
                                    }

                                    return color;
                                }

                                return <>
                                    Room "{roomName} ({roomCode})" P{floor} (Average signal <span style={{color: getColorFromSignalStrength(averageSignalStrength)}}>{Math.round(averageSignalStrength, 1)})</span><br/>
                                    {
                                        devices && devices.length > 0 && devices.map(device => {
                                            let serial = device.get(db.Device.SERIAL_NUMBER);
                                            let wifiStrength = this.state.neighborsWifiStrength[device.id];
                                            let macAddress = device.get(db.Device.MAC_ADDRESS).slice(-5);
                                            let lastMeasurementDate = device.get(db.Device.LAST_MEASUREMENT_DATE);
                                            lastMeasurementDate = lastMeasurementDate  ? moment(lastMeasurementDate) : moment('17/01/2020', 'DD/MM/YYYY');
                                            let diffMinutes = Math.abs(lastMeasurementDate.diff(moment(), 'minutes'));
                                            let diff = lastMeasurementDate.fromNow();
                                            let deviceTyp = device.get(db.Device.DEVICE_TYP);
                                            let deviceSelection = {
                                                value: device.id,
                                                label: `${device.get(db.Device.SERIAL_NUMBER)}(${device.get(db.Device.MAC_ADDRESS).slice(-5)})`,
                                                object: device
                                            };
                                            let sensor = this.state.mapPeerToSensor[device.id];
                                            let room = sensor && sensor.get(db.Device.ROOM_ID);
                                            let roomName = room && room.get(db.Room.ROOM_NAME);
                                            let floor = room && room.get(db.Room.FLOOR);

                                            if(!sensor) roomName = 'Not assigned';

                                            let neighborsRooms = this.state.mapNeighborsToRoomsWithWifiStrength[device.id];

                                            return <>
                                                <span key={device.id}>{serial}/{macAddress} {deviceTyp} ({wifiStrength || 'N/A'}) Assigned to:{roomName} P{floor}&nbsp;
                                                    (
                                                    {
                                                        neighborsRooms && <>{neighborsRooms.map(neighborsRoom => {
                                                            if(!neighborsRoom.room) return;

                                                            let roomName = neighborsRoom.room.get(db.Room.ROOM_NAME);
                                                            let floor = neighborsRoom.room.get(db.Room.FLOOR);
                                                            let wifiStrength = neighborsRoom.wifiStrength;

                                                            return `P${floor}-${roomName}-${wifiStrength}`;
                                                        }).join(',')}</>
                                                    })
                                                </span>&nbsp;
                                                <span className={getColorFromMinutes(diffMinutes, deviceTyp).colorName}>{diff}</span>&nbsp;
                                                <a href={'javascript:;'} onClick={() => {
                                                    this.setState(prev => {
                                                        prev.selectedDevices.push(deviceSelection);

                                                        return prev;
                                                    })
                                                }}>Add to list</a>
                                                <br/>
                                            </>;
                                        })
                                    }
                                    <hr/>
                                </>
                            })
                        }
                        <br/>
                        Thermo in the room:<br/>
                        {
                            this.state.thermos && this.state.thermos.map(neighbor => {
                                let serial = neighbor.get(db.Device.SERIAL_NUMBER);
                                return <span key={neighbor.id}>{serial},</span>
                            })
                        }
                        <br/>
                        <Button onClick={this.selectAllRoomDevice}>Add all room devices</Button>
                        <br/>
                        <FormGroup>
                            <label>Select devices to add in the peer list:</label>
                            {
                                this.state.isModalOpen && <AsyncSelect
                                    value={this.state.selectedDevices}
                                    onChange={selectedDevices => this.setState({selectedDevices})}
                                    loadOptions={this.searchDevices}
                                    isMulti
                                />
                            }

                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="primary" onClick={this.addPeerListCommand}>Add peer list command</Button>
                        <Button outline color="secondary" onClick={this.onCancelClick}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

SelectPeerListModal.propTypes = {
    setToggleModal: PropTypes.func,
    room: PropTypes.object
};