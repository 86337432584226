import React, {Component} from 'react';
import * as db from '../../lib/dbStructure';
import Parse from '../../lib/parse';
import {createLinkFromRooms} from '../../lib/util';
import moment from 'moment';

export default class PageAIDetection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            windowsDetectionEvents: []
        }
    }

    async componentDidMount() {
        let windowsDetectionEvents = await this.getWindowsDetectionEvents();

        this.setState({windowsDetectionEvents});
    }

    getWindowsDetectionEvents(){
        return new Parse.Query(db.classes.CalendarEvent)
            .equalTo(db.CalendarEvent.EVENT_NAME, db.CalendarEvent.EVENT_NAME$WINDOW_OPNE_DETECTED)
            .include(`${db.CalendarEvent.ROOM}.${db.Room.ROOM_NAME}`)
            .include(`${db.CalendarEvent.HOME}.${db.Home.HOME_NAME}`)
            .include(`${db.CalendarEvent.HOME}.${db.Home.CITY}`)
            .descending(db.CalendarEvent.CREATED_AT)
            .find();
    }


    render() {
        let windowsDetectionEvents = this.state.windowsDetectionEvents;

        return <div>
            <ul>
                {windowsDetectionEvents.map(event => {
                    let room = event.get(db.CalendarEvent.ROOM);
                    let home = event.get(db.CalendarEvent.HOME);
                    let createdAt = moment(event.get(db.CalendarEvent.CREATED_AT));

                    return <li key={event.id}>
                        {createdAt.format('DD/MM/YYYY HH:mm')} &nbsp;
                        {event.get(db.CalendarEvent.EVENT_NAME)}&nbsp;
                        {home.get(db.Home.HOME_NAME)} ({home.get(db.Home.CITY)})&nbsp;
                        {room.get(db.Room.ROOM_NAME)}&nbsp;
                        {createLinkFromRooms(home.id, [room], createdAt)}
                    </li>
                })}
            </ul>
        </div>;
    }
}