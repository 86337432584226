import React, {Component} from 'react';
import {Button, CardImg, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";

import UserSettingsTabs from "./UserSettingsTabs";

export default class UserSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: {
                name: '',
                surname: '',
                email: '',
                city: '',
                rooms: []
            },
            editForm: false
        };
    }

    componentWillMount() {
        this.setState({
            user: this.getUserSettings()
        });
    }

    getUserSettings() {
        return {
            name: 'John',
            surname: 'Doe',
            email: 'john@example.com',
            city: 'NA',
            rooms: [{
                id: 1,
                name: "User1",
                price: 120,
                temp: 30
            }, {
                id: 2,
                name: "User2",
                price: 80,
                temp: 47
            }, {
                id: 3,
                name: "User3",
                price: 20,
                temp: 33
            }]
        };
    }

    handleChange(event, field) {
        this.setState({
            user: {
                ...this.state.user,
                [field]: event.target.value
            } 
        });
    }

    toggleEdit() {
        this.setState({
            editForm: !this.state.editForm
        });
    }

    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <Col>
                            {!this.state.editForm? <Button onClick={() => this.toggleEdit()}>Edit</Button> : ''}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3">
                            <CardImg top width="100%" src="https://placeholdit.imgix.net/~text?txtsize=33&txt=318%C3%97180&w=318&h=180" alt="Card image cap" />
                            {this.state.editForm ? <Button outline color="warning" block>Upload</Button> : ''}
                        </Col>
                        <Col sm="9">
                            {this.state.editForm
                            ?
                            <Form>
                                <FormGroup row>
                                    <Col sm="6">
                                        <Label for="name">Name</Label>
                                        <Input
                                        value={this.state.user.name}
                                        onChange={(e) => this.handleChange(e, 'name')}
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Enter name.." />
                                    </Col>
                                    <Col sm="6">
                                        <Label for="surname">Surname</Label>
                                        <Input
                                        value={this.state.user.surname}
                                        onChange={(e) => this.handleChange(e, 'surname')}
                                        type="text"
                                        name="surname"
                                        id="surname"
                                        placeholder="Enter surname.." />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm="6">
                                        <Label for="email">Email</Label>
                                        <Input
                                        value={this.state.user.email}
                                        onChange={(e) => this.handleChange(e, 'email')}
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Enter email.." />
                                    </Col>
                                    <Col sm="6">
                                        <Label for="city">City</Label>
                                        <Input
                                        value={this.state.user.city}
                                        onChange={(e) => this.handleChange(e, 'city')}
                                        type="text"
                                        name="city"
                                        id="city"
                                        placeholder="Enter city.." />
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Button outline color="primary">Submit</Button> {' '}
                                    <Button outline color="default" onClick={() => this.toggleEdit()}>Cancel</Button>
                                </FormGroup>
                            </Form>
                            :
                            <div>
                                <FormGroup row>
                                    <Col sm="6">
                                        <Label for="name">Name</Label>
                                        <p><b>{this.state.user.name}</b></p>
                                    </Col>
                                    <Col sm="6">
                                        <Label for="surname">Surname</Label>
                                        <p><b>{this.state.user.surname}</b></p>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col sm="6">
                                        <Label for="email">Email</Label>
                                        <p><b>{this.state.user.email}</b></p>
                                    </Col>
                                    <Col sm="6">
                                        <Label for="city">City</Label>
                                        <p><b>{this.state.user.city}</b></p>
                                    </Col>
                                </FormGroup>
                            </div>
                            }
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <UserSettingsTabs
                            rooms={this.state.user.rooms}
                        />
                    </Row>
                </Container>
            </div>
        )
    }
}